import { Product, ProductOption, Voucher } from 'graphql/types'
import {
  AccountData,
  AccountHolder,
  AccountType,
  Address,
  AppartmentData,
  CheckoutState,
  DeliveryAddressData,
  MainData,
  MainTenantData,
  Offer,
  PortabilityNumber,
  PreviousContractor,
} from 'store/Checkout/Checkout.reducer'

export enum CheckoutAction {
  ADD_PORTABILITY_NUMBER = 'ADD_PORTABILITY_NUMBER',
  ADD_PREVIOUS_CONTRACTOR = 'ADD_PREVIOUS_CONTRACTOR',
  ADD_UPLOADED_FILES = 'ADD_UPLOADED_FILES',
  CLEAR_INVALID_FIELD = 'CLEAR_INVALID_FIELD',
  CLEAR_SELECTED_PRODUCT_OPTIONS = 'CLEAR_SELECTED_PRODUCT_OPTIONS',
  DELETE_UPLOADED_FILE = 'DELETE_UPLOADED_FILE',
  EDIT_ACCOUNT_DATA = 'EDIT_ACCOUNT_DATA',
  EDIT_ACCOUNT_HOLDER = 'EDIT_ACCOUNT_HOLDER',
  EDIT_APPARTMENT_DATA = 'EDIT_APPARTMENT_DATA',
  EDIT_DESIRED_INSTALLATION_DATE = 'EDIT_DESIRED_INSTALLATION_DATE',
  EDIT_IBAN = 'EDIT_IBAN',
  EDIT_MAIN_DATA = 'EDIT_MAIN_DATA',
  EDIT_MAIN_TENANT = 'EDIT_MAIN_TENANT',
  EDIT_PORTABILITY_NUMBER = 'EDIT_PORTABILITY_NUMBER',
  EDIT_PREVIOUS_CONNECTION_ADDRESS = 'EDIT_PREVIOUS_CONNECTION_ADDRESS',
  EDIT_PREVIOUS_CONNECTION_TERMINATION = 'EDIT_PREVIOUS_CONNECTION_TERMINATION',
  EDIT_PREVIOUS_CONTRACTOR = 'EDIT_PREVIOUS_CONTRACTOR',
  REMOVE_PORTABILITY_NUMBER = 'REMOVE_PORTABILITY_NUMBER',
  REMOVE_PREVIOUS_CONTRACTOR = 'REMOVE_PREVIOUS_CONTRACTOR',
  SET_INVALID_FIELDS = 'SET_INVALID_FIELDS',
  SET_LANDLINE_CONTRACT_CANCEL_DATE = 'SET_LANDLINE_CONTRACT_CANCEL_DATE',
  SET_OFFERS = 'SET_OFFERS',
  SET_PREVIOUS_PROVIDER = 'SET_PREVIOUS_PROVIDER',
  SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
  SET_STATE = 'SET_CHECKOUT_STATE',
  SET_TO_INIT_STATE = 'SET_CHECKOUT_TO_INIT_STATE',
  SET_TO_INIT_STATE_EXCEPT_QUERY = 'SET_CHECKOUT_TO_INIT_STATE_EXCEPT_QUERY',
  SET_VZF_ID = 'SET_VZF_ID',
  SET_USER_PREVIOUS_CONTRACT = 'SET_USER_PREVIOUS_CONTRACT',
  SET_APPLIED_VOUCHER = 'SET_APPLIED_VOUCHER',
  SET_USER_VOUCHER = 'SET_USER_VOUCHER',
  TOGGLE_PRODUCT_OPTION = 'TOGGLE_PRODUCT_OPTION',
  TOGGLE_ACCOUNT_HOLDER = 'TOGGLE_ACCOUNT_HOLDER',
  TOGGLE_ACCOUNT_TYPE = 'TOGGLE_ACCOUNT_TYPE',
  TOGGLE_BUNDLE_LEGAL_REQUIREMENT = 'TOGGLE_BUNDLE_LEGAL_REQUIREMENT',
  TOGGLE_DESIRED_INSTALLATION_DATE = 'TOGGLE_DESIRED_INSTALLATION_DATE',
  TOGGLE_IS_LANDLINE_CONTRACT_CANCELLED = 'TOGGLE_IS_LANDLINE_CONTRACT_CANCELLED',
  TOGGLE_MAIN_TENANT = 'TOGGLE_MAIN_TENANT',
  TOGGLE_PAYMENT_AUTHORIZATION = 'TOGGLE_PAYMENT_AUTHORIZATION',
  TOGGLE_PORTABILITY = 'TOGGLE_PORTABILITY',
  TOGGLE_PREVIOUS_CONNECTION_ADDRESS = 'TOGGLE_PREVIOUS_CONNECTION_ADDRESS',
  TOGGLE_PREVIOUS_CONNECTION_TERMINATION = 'TOGGLE_PREVIOUS_CONNECTION_TERMINATION',
  TOGGLE_PREVIOUS_CONTRACTOR = 'TOGGLE_PREVIOUS_CONTRACTOR',
  TOGGLE_RECURRING_PAYMENT_AUTHORIZATION = 'TOGGLE_RECURRING_PAYMENT_AUTHORIZATION',
  SET_CHECKOUT_PARTIAL = 'SET_CHECKOUT_PARTIAL',
  EDIT_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS',
}

type CheckoutActions =
  | { type: CheckoutAction.SET_CHECKOUT_PARTIAL; payload: Partial<CheckoutState> }
  | { type: CheckoutAction.ADD_PORTABILITY_NUMBER }
  | { type: CheckoutAction.ADD_PREVIOUS_CONTRACTOR }
  | { type: CheckoutAction.ADD_UPLOADED_FILES; payload: File[] }
  | { type: CheckoutAction.CLEAR_INVALID_FIELD; payload: string }
  | { type: CheckoutAction.CLEAR_SELECTED_PRODUCT_OPTIONS }
  | { type: CheckoutAction.DELETE_UPLOADED_FILE; payload: File }
  | { type: CheckoutAction.EDIT_ACCOUNT_DATA; payload: AccountData }
  | { type: CheckoutAction.EDIT_ACCOUNT_HOLDER; payload: AccountHolder }
  | { type: CheckoutAction.EDIT_APPARTMENT_DATA; payload: AppartmentData }
  | { type: CheckoutAction.EDIT_DESIRED_INSTALLATION_DATE; payload: string | null }
  | { type: CheckoutAction.EDIT_IBAN; payload: string }
  | { type: CheckoutAction.EDIT_MAIN_DATA; payload: MainData }
  | { type: CheckoutAction.EDIT_MAIN_TENANT; payload: MainTenantData }
  | { type: CheckoutAction.EDIT_PORTABILITY_NUMBER; payload: { portabilityNumber: PortabilityNumber; index: number } }
  | { type: CheckoutAction.EDIT_PREVIOUS_CONNECTION_ADDRESS; payload: Address }
  | { type: CheckoutAction.EDIT_PREVIOUS_CONNECTION_TERMINATION; payload: string }
  | {
      type: CheckoutAction.EDIT_PREVIOUS_CONTRACTOR
      payload: { previousContractor: PreviousContractor; index: number }
    }
  | { type: CheckoutAction.REMOVE_PORTABILITY_NUMBER; payload: number }
  | { type: CheckoutAction.REMOVE_PREVIOUS_CONTRACTOR; payload: number }
  | { type: CheckoutAction.SET_INVALID_FIELDS; payload: string[] }
  | { type: CheckoutAction.SET_LANDLINE_CONTRACT_CANCEL_DATE; payload: string }
  | { type: CheckoutAction.SET_OFFERS; payload: Offer[] }
  | { type: CheckoutAction.SET_PREVIOUS_PROVIDER; payload: string }
  | { type: CheckoutAction.SET_SELECTED_PRODUCT; payload: Product | undefined }
  | { type: CheckoutAction.SET_STATE; payload: Partial<CheckoutState> }
  | { type: CheckoutAction.SET_TO_INIT_STATE }
  | { type: CheckoutAction.SET_TO_INIT_STATE_EXCEPT_QUERY }
  | { type: CheckoutAction.TOGGLE_ACCOUNT_HOLDER; payload: boolean }
  | { type: CheckoutAction.TOGGLE_ACCOUNT_TYPE; payload: AccountType }
  | { type: CheckoutAction.TOGGLE_DESIRED_INSTALLATION_DATE; payload: boolean }
  | { type: CheckoutAction.TOGGLE_IS_LANDLINE_CONTRACT_CANCELLED; payload: boolean }
  | { type: CheckoutAction.TOGGLE_MAIN_TENANT; payload: boolean }
  | { type: CheckoutAction.TOGGLE_PAYMENT_AUTHORIZATION; payload: boolean }
  | { type: CheckoutAction.TOGGLE_PORTABILITY; payload: boolean }
  | { type: CheckoutAction.TOGGLE_PREVIOUS_CONNECTION_ADDRESS; payload: boolean }
  | { type: CheckoutAction.TOGGLE_PREVIOUS_CONNECTION_TERMINATION; payload: boolean }
  | { type: CheckoutAction.TOGGLE_PREVIOUS_CONTRACTOR; payload: boolean }
  | { type: CheckoutAction.TOGGLE_PRODUCT_OPTION; payload: ProductOption }
  | { type: CheckoutAction.TOGGLE_RECURRING_PAYMENT_AUTHORIZATION; payload: boolean }
  | {
      payload: string | undefined
      type: CheckoutAction.SET_VZF_ID
    }
  | {
      payload: boolean
      type: CheckoutAction.TOGGLE_BUNDLE_LEGAL_REQUIREMENT
    }
  | {
      payload: boolean | undefined
      type: CheckoutAction.SET_USER_PREVIOUS_CONTRACT
    }
  | {
      payload: string
      type: CheckoutAction.SET_USER_VOUCHER
    }
  | {
      payload: Voucher
      type: CheckoutAction.SET_APPLIED_VOUCHER
    }
  | {
      payload: DeliveryAddressData
      type: CheckoutAction.EDIT_DELIVERY_ADDRESS
    }

export default CheckoutActions
