import gql from 'graphql-tag'

export default gql`
  query getLegalNotice {
    legalNotice {
      id
      renderIndex
      text
      category
    }
  }
`
