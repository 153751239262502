import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import Basket from 'components/Basket'
import CheckboxIcon from 'components/CheckboxIcon'
import ChevronRightIcon from 'components/ChevronRightIcon'
import DataTable from 'components/DataTable'
import { LegalNoticeCategory, ProductCategory } from 'graphql/types'
import { useCheckoutState } from 'pages/Checkout/useCheckoutReducer'
import React, { useEffect, useState } from 'react'
import { AccountType } from 'store/Checkout/Checkout.reducer'
import { replacePToSpanTags } from 'utils/function'
import { getBackEndURL } from 'utils/getEnvConfig'
import TestIDs from 'utils/TestIDs'
import SaveOrder from './saveOrder'
import useConfirmReducer from './useConfirmReducer'

const useStyles = makeStyles(() => ({
  confirmBlock: {
    marginBottom: '20px',
  },
  agbBlock: {
    marginTop: '40px',
    marginBottom: '80px',

    '& p': {
      color: '#878787',
    },

    '& a': {
      color: '#009AA8',
      textDecoration: 'none',
    },
  },
  headline: {
    padding: '88px 0 37px 0',
  },

  subline: {
    marginTop: '47px',
  },
  submitButton: {
    padding: '21px 24px',
    '& .MuiButton-startIcon': {
      marginRight: '18px',
    },
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  checkboxError: {
    '& *': {
      color: 'red !important',
    },
  },
  noBlock: {
    display: 'inline',
  },
}))

const Confirmation = (): JSX.Element => {
  const { sendingDataInProgress, setSendingDataInProgress, saveOrderMutation } = useConfirmReducer()
  const classes = useStyles()

  const [legalAccepted, setLegalAccepted] = useState<boolean>(false)
  const [legalClassName, setLegalClassName] = useState<string>('')
  const [infosheetAccepted, setInfosheetAccepted] = useState<boolean>(false)
  const [vzfAccepted, setVzfAccepted] = useState<boolean>(false)
  const [infosheetClassName, setInfosheet] = useState<string>('')
  const [vzfClassName, setVzfClassName] = useState<string>('')

  const {
    accountData,
    accountHolder,
    accountHolderData,
    accountType,
    appartmentData,
    deliveryAddressData,
    desiredInstallationDate,
    desiredInstallationDateData,
    iban,
    mainData,
    mainTenant,
    mainTenantData,
    portability,
    portabilityNumbers,
    previousConnectionAddressData,
    previousContractorData,
    previousProvider,
    landlineContractCancelDate,
    isLandlineContractCancelled,
    selectedProduct,
    legalNotices,
    selectedOptions,
    vzfID,
    bundleLegalRequirementAccepted,
    products,
    appliedVoucher,
  } = useCheckoutState()

  //NOTE: this was requested by simon shattner to remove the Produktinformationsblatt and technische Leistungsbeschreibung on condition:
  // when produkt is internet with addon tv we show the Produktinformationsblatt and Leistungsbeschreibung andcheck on the save condition
  //NOTE: this needs to be changed in the future so the customer can pick his own requirements for products
  //https://revolution.dev/app/-Ma96ekJDwnOAtfPRLd0/-MejN15BNoRfiNWnvDXP/STORY/-MzZU3zMgzGFLopHqFfF/
  const [displayPIS, setDisplayPIS] = useState(false)
  useEffect(() => {
    if (!displayPIS) {
      if (selectedProduct?.category === 'INTERNET' || selectedProduct?.category === 'TELEFON') {
        setDisplayPIS(true)
      }
    }
  }, [selectedProduct, selectedOptions, setDisplayPIS, displayPIS])

  // we retrieve this additionally so, we can inject it to determineSaveOrderParams
  const reducerData = useCheckoutState()

  const handleConfirmClick = (): void => {
    if (!legalAccepted) {
      setLegalClassName(classes.checkboxError)
      return
    }

    if (!vzfAccepted) {
      setVzfClassName(classes.checkboxError)
      return
    }
    if (displayPIS) {
      if (!infosheetAccepted) {
        setInfosheet(classes.checkboxError)
        return
      }
    }
    setLegalClassName('')
    setInfosheet('')
    setVzfClassName('')
    setSendingDataInProgress(true)

    SaveOrder(reducerData, saveOrderMutation)
  }

  const setLegalCheckbox = (value: boolean): void => {
    setLegalAccepted(value)
  }

  const setInfosheetCheckbox = (value: boolean): void => {
    setInfosheetAccepted(value)
  }

  const setField = (field: string): string | null => {
    if (!field) {
      return null
    }
    return field.toString()
  }

  const setCombinedField = (fields: string[]): string | null => {
    return fields
      .filter((value) => {
        return !value ? false : true
      })
      .join(' ')
  }

  const getNumbersToBePorted = (): string[] | null => {
    if (!portabilityNumbers) {
      return null
    }
    return portabilityNumbers.map((numberfields) => {
      return `+49 ${numberfields.areaCode} ${numberfields.number}`
    })
  }

  const getInhaberToBePorted = (): string[] | null => {
    if (!previousContractorData || previousContractorData[0].name === '') {
      return [`${mainData.basicData.name} ${mainData.basicData.lastName}`]
    }
    return previousContractorData.map((contractor) => {
      return `${contractor.name} ${contractor.lastName}`
    })
  }

  const personalData = {
    Anrede: mainData.basicData.salutation.toString(),
    Titel: setField(mainData.basicData.title),
    Name: setCombinedField([mainData.basicData.name, mainData.basicData.lastName]),
    Geburtsdatum: !mainData.date ? null : mainData.date,
    'Straße & Hausnummer': setCombinedField([mainData.address.street, mainData.address.number]),
    'PLZ & Ort': setCombinedField([mainData.address.zip, mainData.address.city]),
    Zusatz: mainData.address.additionalInfo !== '' ? mainData.address.additionalInfo : null,
    'Telefon-Nr.': setField(mainData.telephoneNr),
    'E-Mail-Adresse': setField(mainData.email),
    Wohnung: setField(appartmentData.flat),
    Etage: setField(appartmentData.floor),
    Mietvertragsnummer: setField(appartmentData.rentalAgreementNumber),
    'Hauptmieter der Wohnung': mainTenant
      ? setCombinedField([mainTenantData.name, mainTenantData.lastName])
      : setCombinedField([mainData.basicData.name, mainData.basicData.lastName]),
  }

  const deliveryAddress = {
    Anrede: deliveryAddressData.salutation,
    Titel: deliveryAddressData.title,
    Name: setCombinedField([deliveryAddressData.firstName, deliveryAddressData.lastName]),
    'Straße & Hausnummer': setCombinedField([
      deliveryAddressData.street,
      deliveryAddressData.number,
      deliveryAddressData.additionalInfo,
    ]),
    'PLZ & Ort': setCombinedField([deliveryAddressData.zip, deliveryAddressData.city]),
    //Zusatz: deliveryAddressData.additionalInfo !== '' ? deliveryAddressData.additionalInfo : null,
  }

  const selectedDate = {
    Wunschtermin:
      desiredInstallationDate && desiredInstallationDateData !== null
        ? desiredInstallationDateData
        : 'So schnell wie möglich',
  }

  const accountHolderText = !accountHolder ? (
    setCombinedField([mainData.basicData.name, mainData.basicData.lastName])
  ) : (
    <>
      <Typography {...TestIDs.DATA_TABLE_VALUE} className={'TextNormal'}>
        {accountHolderData.name + ' ' + accountHolderData.lastName + ' bezahlt für mich'}
      </Typography>
      <Typography {...TestIDs.DATA_TABLE_VALUE} className={'TextNormal'}>
        Bitte schicken Sie uns das ausgefüllte und unterschriebene{' '}
        <Link
          href="/assets/docs/SEPA-Lastschriftmandat_Stand_Maerz_2020.pdf"
          display={'inline'}
          target="_blank"
          className={clsx('AccountHolderLink', 'InlineLink')}
        >
          SEPA-Lastschriftmandat
        </Link>{' '}
        per E-Mail an{' '}
        <Link display={'inline'} href="mailto:internet@vonovia.de" className={'InlineLink'}>
          internet@vonovia.de
        </Link>{' '}
        oder per Post an folgende Adresse:
      </Typography>
      <Typography {...TestIDs.DATA_TABLE_VALUE} className={'TextNormal'}>
        Deutsche Multimedia Service GmbH
      </Typography>
      <Typography {...TestIDs.DATA_TABLE_VALUE} className={'TextNormal'}>
        Universitätsstr. 133
      </Typography>
      <Typography {...TestIDs.DATA_TABLE_VALUE} className={'TextNormal'}>
        44803 Bochum
      </Typography>
      <Typography {...TestIDs.DATA_TABLE_VALUE} className={'TextNormal'}>
        Deuschland
      </Typography>
    </>
  )

  const paymentData =
    accountType === AccountType.IBAN
      ? {
          IBAN: setField(iban),
          BIC: null,
          Kontonummer: null,
          Kontoinhaber: accountHolderText,
        }
      : {
          IBAN: null,
          BIC: setField(accountData.bankCode),
          Kontonummer: setField(accountData.accountNumber),
          Kontoinhaber: accountHolderText,
        }

  const transferNumbers: Record<string, any> = {
    Telefonnummer: getNumbersToBePorted(),
    'Bisheriger Vertragsinhaber': getInhaberToBePorted(),
    'Bisheriger Anbieter': previousProvider,
    'Bisherige Anschlussadresse': setCombinedField([
      previousConnectionAddressData.street,
      previousConnectionAddressData.number,
      previousConnectionAddressData.zip,
      previousConnectionAddressData.city,
    ]),
  }
  if (isLandlineContractCancelled) {
    if (landlineContractCancelDate.length > 0) {
      transferNumbers['Bisheriger Festnetzvertrag ist gekündigt zum'] = landlineContractCancelDate
    } else {
      transferNumbers['Bisheriger Festnetzvertrag ist gekündigt.'] = ''
    }
  }

  const renderOldNumberInfo = (): JSX.Element | null => {
    if (!portability) {
      return null
    }

    return (
      <Grid item xs={12} sm={8} className={classes.confirmBlock}>
        <DataTable title="Rufnummernmitnahme" items={transferNumbers} anchor={'Rufnummernmitnahme'} marginBottom={2} />
      </Grid>
    )
  }

  const renderSubmitIcon = (): JSX.Element => {
    return sendingDataInProgress ? <CircularProgress color="secondary" /> : <ChevronRightIcon />
  }

  //NOTE: this is for debugging the current selected items and vouchers
  //console.log('selectedProduct:', selectedProduct)
  //console.log('selectedOptions:', selectedOptions)
  //console.log('appliedVouchers:', appliedVouchers)
  const [basketLegalNoticeIDs, setBasketLegalNoticeIDs] = useState<string[]>([])

  //NOTE: this will create an array of legalNoticeIDs for the basket only
  useEffect(() => {
    const legalNoticeBasketIDS: string[] = []
    if (selectedProduct) {
      const lnIDproduct = selectedProduct.basket.legalNotice
      if (lnIDproduct) {
        legalNoticeBasketIDS.push(lnIDproduct)
      }
    }
    for (let i = 0; i < selectedOptions.length; i++) {
      const lnIDoption = selectedOptions[i].basket.legalNotice
      if (lnIDoption) {
        legalNoticeBasketIDS.push(lnIDoption)
      }
    }
    if (appliedVoucher) {
      const lnIDvoucher = appliedVoucher.legalNotice
      if (lnIDvoucher) {
        legalNoticeBasketIDS.push(lnIDvoucher)
      }
    }
    if (bundleLegalRequirementAccepted) {
      //check if bundleIsIn
      for (let i = 0; i < products.length; i++) {
        if (products[i].category === ProductCategory.BUNDLE) {
          const bundleID = products[i].basket.legalNotice
          if (bundleID) {
            legalNoticeBasketIDS.push(bundleID)
          }
        }
      }
    }
    setBasketLegalNoticeIDs(legalNoticeBasketIDS)
  }, [
    selectedProduct,
    appliedVoucher,
    selectedOptions,
    setBasketLegalNoticeIDs,
    products,
    bundleLegalRequirementAccepted,
  ])

  return (
    <>
      <Paper elevation={0} square className={'Checkout'}>
        <Typography {...TestIDs.CONFIRMPAGE_HEADER_TITLE} color={'secondary'} variant={'h1'} align={'center'}>
          {'Prüfung der Bestellung'}
        </Typography>
        <Typography
          {...TestIDs.CONFIRMPAGE_HEADER_TITLE}
          color={'secondary'}
          align={'center'}
          className={classes.subline}
        >
          Bitte prüfen Sie noch einmal die Richtigkeit aller Daten.
        </Typography>
      </Paper>

      <Box>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={8} className={classes.confirmBlock}>
              <Typography
                {...TestIDs.CONFIRMPAGE_HEADER_TITLE}
                variant={'h3'}
                align={'left'}
                className={classes.headline}
              >
                Meine Daten
              </Typography>
              <DataTable title="Meine Daten" items={personalData} anchor={'MeineDaten'} />
            </Grid>
            {deliveryAddressData.zip !== '' && (
              <Grid item xs={12} sm={8} className={classes.confirmBlock}>
                <DataTable title="Abweichende Lieferadresse" items={deliveryAddress} anchor={'Versandadresse'} />
              </Grid>
            )}
            <Grid item xs={12} sm={8} className={classes.confirmBlock}>
              <DataTable title="Wunschtermin" items={selectedDate} anchor={'Wunschtermin'} />
            </Grid>
            <Grid item xs={12} sm={8} className={classes.confirmBlock}>
              <DataTable title="Zahlungsdaten" items={paymentData} anchor={'Zahlungsdaten'} />
            </Grid>
            {renderOldNumberInfo()}
            <Grid item xs={12} sm={8} className={classes.confirmBlock}>
              <Basket
                bundleProduct={
                  bundleLegalRequirementAccepted === true
                    ? products.find((p) => p.category === ProductCategory.BUNDLE)
                    : undefined
                }
                vzfID={vzfID}
                legalNotices={legalNotices}
                selectedOptions={selectedOptions}
                selectedProduct={selectedProduct}
                mobileFixed={false}
                appliedVouchers={appliedVoucher ? [appliedVoucher] : []}
              />
            </Grid>
            <Grid xs={12} sm={8} container item spacing={2} className={classes.agbBlock}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...TestIDs.CONFIRMPAGE_LEGAL_CHECKBOX}
                      icon={<CheckboxIcon checked={false} error={false} />}
                      checkedIcon={<CheckboxIcon checked={true} error={false} />}
                      onClick={(): void => {
                        setLegalCheckbox(!legalAccepted)
                      }}
                    />
                  }
                  style={{ alignItems: 'flex-start' }}
                  checked={legalAccepted}
                  className={legalClassName}
                  label={
                    <>
                      <Box marginBottom={2} marginLeft={2}>
                        <Typography className={'TextNormal'}>
                          Ich bestätige hiermit, die{' '}
                          <Link href="/assets/docs/agb_stand_04_2022.pdf" className={classes.noBlock} target="_blank">
                            AGB
                          </Link>
                          , die{' '}
                          <Link href="/#/datenschutz" className={classes.noBlock} target="_blank">
                            Datenschutzhinweise
                          </Link>{' '}
                          , und die{' '}
                          <Link
                            href="/assets/docs/widerrufsformular_stand_maerz_2020.pdf"
                            className={classes.noBlock}
                            target="_blank"
                          >
                            Widerrufsbelehrung
                          </Link>{' '}
                          zur Kenntnis genommen zu haben und die AGB zu akzeptieren.
                        </Typography>
                      </Box>
                    </>
                  }
                />
              </Grid>
              {displayPIS && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...TestIDs.CONFIRMPAGE_INFOSHEET_CHECKBOX}
                        icon={<CheckboxIcon checked={false} error={false} />}
                        checkedIcon={<CheckboxIcon checked={true} error={false} />}
                        onClick={(): void => {
                          setInfosheetCheckbox(!infosheetAccepted)
                        }}
                      />
                    }
                    style={{ alignItems: 'flex-start' }}
                    checked={infosheetAccepted}
                    className={infosheetClassName}
                    label={
                      <>
                        <Box marginBottom={2} marginLeft={2}>
                          <Typography className={'TextNormal'}>
                            Ich bestätige hiermit, das{' '}
                            <Link
                              // href={getProductSheetLink(selectedOptions, selectedProduct)}
                              href={getBackEndURL() + 'pis/' + vzfID}
                              className={classes.noBlock}
                              target="_blank"
                            >
                              Produktinformationsblatt
                            </Link>{' '}
                            und die{' '}
                            <Link
                              href="/assets/docs/techn_leistungsbeschreibung_2023.pdf"
                              className={classes.noBlock}
                              target="_blank"
                            >
                              technische Leistungsbeschreibung
                            </Link>{' '}
                            zur Kenntnis genommen zu haben und den Inhalt zu akzeptieren.
                          </Typography>
                        </Box>
                      </>
                    }
                  />
                </Grid>
              )}
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...TestIDs.CONFIRMPAGE_INFOSHEET_CHECKBOX}
                      icon={<CheckboxIcon checked={false} error={false} />}
                      checkedIcon={<CheckboxIcon checked={true} error={false} />}
                      onClick={(): void => {
                        setVzfAccepted(!vzfAccepted)
                      }}
                    />
                  }
                  style={{ alignItems: 'flex-start' }}
                  checked={vzfAccepted}
                  className={vzfClassName}
                  label={
                    <>
                      <Box marginBottom={2} marginLeft={2}>
                        <Typography className={'TextNormal'}>
                          Ich bestätige, dass ich die{' '}
                          <Link href={getBackEndURL() + 'vzf/' + vzfID} className={classes.noBlock} target="_blank">
                            Vertragszusammenfassung
                          </Link>{' '}
                          geprüft habe und ihrem Inhalt zustimme
                        </Typography>
                      </Box>
                    </>
                  }
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={8} className={clsx(classes.confirmBlock, classes.alignRight)}>
              <Button
                focusRipple={true}
                color={'secondary'}
                onClick={handleConfirmClick}
                startIcon={renderSubmitIcon()}
                className={'AvailabilityCheck'}
                {...TestIDs.CONFIRMPAGE_ORDER_BUTTON}
              >
                Kostenpflichtig bestellen
              </Button>
            </Grid>

            <Grid container item lg={8}>
              <Grid item>
                <Box marginTop={10} marginBottom={2}>
                  {legalNotices.length > 0 &&
                    [...legalNotices]
                      .sort((a, b) => a.renderIndex - b.renderIndex)
                      .map((ln, index) =>
                        ln.category === LegalNoticeCategory.WARENKORB &&
                        basketLegalNoticeIDs.includes(ln.id) &&
                        ln.text ? (
                          <Typography
                            component={'div'}
                            variant={'subtitle2'}
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: `${ln.renderIndex}) ${replacePToSpanTags(ln.text)}`,
                            }}
                          />
                        ) : null,
                      )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Confirmation
