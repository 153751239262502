import { Fade, Grid, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'

interface TextFieldProps {
  value: string
  label: string
  editField: string
  columnValue: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
  mobileColumnValue?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
  maxLength?: number
  errorField: string
  tooltipTitle: string
}

export interface TextFieldRendererProps {
  values: TextFieldProps[]
  invalidFields: string[]
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
  index: number
  //eslint-disable-next-line
  combineAndEdit: (field: string, index: number, value: any, errorField: string) => void
}

const TextFieldRenderer = (props: TextFieldRendererProps): JSX.Element => {
  const { values, combineAndEdit, index, invalidFields, focusedField, setFocusedField } = props
  return (
    <>
      {values.map((value) => (
        <Grid
          item
          xs={value.mobileColumnValue ? value.mobileColumnValue : value.columnValue}
          md={value.columnValue}
          key={value.label + index}
        >
          <Tooltip
            open={invalidFields.indexOf(value.errorField) !== -1 && focusedField === value.errorField}
            placement={'top-start'}
            PopperProps={{
              className:
                value.tooltipTitle.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : value.tooltipTitle.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            title={value.tooltipTitle}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
            {...TestIDs.TEXT_FIELD_RENDERER_COMPONENT_TOOLTIP}
          >
            <TextField
              {...TestIDs.TEXT_FIELD_RENDERER_COMPONENT_INPUT}
              onClick={(): void => setFocusedField(value.errorField)}
              onBlur={(): void => setFocusedField('')}
              error={value.errorField === '' ? false : invalidFields.indexOf(value.errorField) !== -1}
              variant={'filled'}
              fullWidth={true}
              inputProps={{
                maxLength: 100,
              }}
              label={value.label}
              value={value.value}
              onChange={(e): void => {
                combineAndEdit(value.editField, index, e.target.value, value.errorField)
              }}
              InputProps={{
                className: 'fallbackLightBackground',
                startAdornment:
                  value.label === 'Vorwahl' ? (
                    <InputAdornment position={'start'}>
                      <Typography>+49</Typography>
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          </Tooltip>
        </Grid>
      ))}
    </>
  )
}

export default React.memo(TextFieldRenderer, (prevProps, nextProps) => isEqual(prevProps, nextProps))
