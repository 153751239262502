import { Box, Button, Container, Divider, Fade, Grid, Paper, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AvailabilityCheckInput from 'components/AvailabilityCheckInput'
import Basket from 'components/Basket'
import {
  AppartmentDataFields,
  DesiredInstallationDateFields,
  MainDataFields,
  MainTenantFields,
  PaymentDetailFields,
  PortabilityFields,
  ProductSelector,
} from 'components/CheckoutFields'
import ContractSummary from 'components/CheckoutFields/ContractSummary'
import DeliveryAdress from 'components/CheckoutFields/DeliveryAdress'
import ProductOptionSelector from 'components/CheckoutFields/ProductOptionSelector'
import VoucherFields from 'components/CheckoutFields/VoucherFields'
import ChevronRightIcon from 'components/ChevronRightIcon'
import UploadFile from 'components/UploadFile'
import { addBusinessDays } from 'date-fns'
import { ProductCategory, ProductOptionCategory } from 'graphql/types'
import { useCheckoutReducer } from 'pages/Checkout/useCheckoutReduxReducer'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AvailabilityCheckField } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import { getInvalidFormFields } from 'utils/checkoutFunctions'
import { replacePToSpanTags } from 'utils/function'
import TestIDs from 'utils/TestIDs'
import useAvailabilityCheckDoneReducer from 'utils/useAvailabilityCheckDoneReducer'
import { useCheckoutState } from './useCheckoutReducer'

const Checkout = (): JSX.Element => {
  const navigate = useNavigate()
  const { isAvailabilityCheckDone } = useAvailabilityCheckDoneReducer()
  const {
    accountData,
    accountHolder,
    accountHolderData,
    accountType,
    appartmentData,
    deliveryAddressData,
    desiredInstallationDate,
    desiredInstallationDateData,
    filesForUploading,
    handleSaveVZF,
    iban,
    invalidFields,
    isLandlineContractCancelled,
    landlineContractCancelDate,
    landlineContractCancelDateChange,
    mainData,
    mainTenant,
    mainTenantData,
    paymentAuthorization,
    portability,
    portabilityNumbers,
    previousConnectionAddress,
    previousConnectionAddressData,
    previousConnectionTermination,
    previousConnectionTerminationData,
    previousContractor,
    previousContractorData,
    previousProviderDropdownChange,
    previousProviderDropdownOptions,
    previousProviderDropdownValue,
    previousProviderTextFieldChange,
    previousProviderTextFieldValue,
    products,
    productOptions,
    selectedAdditionalInfo,
    selectedCity,
    selectedNumber,
    selectedStreet,
    zip,
    selectedProduct,
    selectedOptions,
    legalNotices,
    isDeliveryDateInTheFuture,
    afterDeliveryDay,
    vzfID,
    isProductAvailable,
    bundleLegalRequirementAccepted,
    userHasPreviousContract,
    userVoucherCode,
    appliedVoucher,
  } = useCheckoutState()

  const {
    addPortabilityNumber,
    addPreviousContractor,
    clearInvalidField,
    deleteUploadFile,
    editAccountData,
    editAccountHolder,
    editAppartmentData,
    editConnectionAddress,
    editConnectionTermination,
    editDesiredInstallationDate,
    editDeliveryAddress,
    editIban,
    editMainData,
    editMainTenantData,
    editPortabilityNumber,
    editPreviousContractor,
    removePortabilityNumber,
    removePreviousContractor,
    setInvalidFields,
    setUploadFile,
    toggleAccountHolder,
    toggleAccountType,
    toggleConnectionAddress,
    toggleConnectionTermination,
    toggleDesiredInstallationDate,
    toggleIsLandlineContractCancelled,
    toggleMainTenant,
    togglePaymentAuthorization,
    togglePortability,
    togglePreviousContractor,
    setSelectedProduct,
    toggleProductOption,
    toggleBundleLegalRequirementAcceptance,
    setUserPreviousContract,
    resetStateNotQueriesAvaCheck,
    resetStateNotQueriesCheckout,
    setUserVoucherCode,
    setAppliedVoucher,
  } = useCheckoutReducer()

  useEffect((): void => {
    if (!isAvailabilityCheckDone) {
      navigate('/')
      return
    }
  }, [isAvailabilityCheckDone, history])

  const [focusedField, setFocusedField] = useState('')
  const showPortabilityFields = useMemo((): boolean => {
    if (
      (selectedProduct && selectedProduct.category === ProductCategory.TELEFON) ||
      selectedOptions.find((opt) => opt.category === ProductOptionCategory.TELEFON)
    ) {
      return true
    }
    return false
  }, [selectedProduct, selectedOptions])

  const minDate = (): Date => {
    let date: Date
    if (addBusinessDays(Date.now(), 4).getTime() > afterDeliveryDay.getTime()) {
      date = addBusinessDays(Date.now(), 4)
    } else {
      date = afterDeliveryDay
    }
    date.setHours(0, 0, 0, 0)
    return date
  }

  useEffect(() => {
    if (mainData.address.zip !== zip) {
      editMainData({
        ...mainData,
        address: {
          additionalInfo: selectedAdditionalInfo,
          city: selectedCity,
          number: selectedNumber,
          street: selectedStreet,
          zip,
        },
      })
    }
  }, [mainData, zip, selectedCity, selectedNumber, selectedStreet, editMainData, selectedAdditionalInfo])

  //NOTE: this is for debugging the current selected items and vouchers
  //console.log('selectedProduct:', selectedProduct)
  //console.log('selectedOptions:', selectedOptions)
  //console.log('appliedVouchers:', appliedVouchers)
  const [basketLegalNoticeIDs, setBasketLegalNoticeIDs] = useState<string[]>([])

  //NOTE: this will create an array of legalNoticeIDs for the basket only
  useEffect(() => {
    const legalNoticeBasketIDS: string[] = []
    if (selectedProduct) {
      const lnIDproduct = selectedProduct.basket.legalNotice
      if (lnIDproduct) {
        legalNoticeBasketIDS.push(lnIDproduct)
      }
    }
    for (let i = 0; i < selectedOptions.length; i++) {
      const lnIDoption = selectedOptions[i].basket.legalNotice
      if (lnIDoption) {
        legalNoticeBasketIDS.push(lnIDoption)
      }
    }
    if (appliedVoucher) {
      const lnIDvoucher = appliedVoucher.legalNotice
      if (lnIDvoucher) {
        legalNoticeBasketIDS.push(lnIDvoucher)
      }
    }
    if (bundleLegalRequirementAccepted) {
      //check if bundleIsIn
      for (let i = 0; i < products.length; i++) {
        if (products[i].category === ProductCategory.BUNDLE) {
          const bundleID = products[i].basket.legalNotice
          if (bundleID) {
            legalNoticeBasketIDS.push(bundleID)
          }
        }
      }
    }
    setBasketLegalNoticeIDs(legalNoticeBasketIDS)
  }, [
    selectedProduct,
    appliedVoucher,
    selectedOptions,
    setBasketLegalNoticeIDs,
    products,
    bundleLegalRequirementAccepted,
  ])

  return (
    <>
      <Paper elevation={0} square className={'Checkout'}>
        <Box className={'h1Container'}>
          {!isProductAvailable && (
            <Container maxWidth={'md'}>
              <Typography variant={'h1'} color={'secondary'} align={'center'}>
                {'Produkt nicht verfügbar'}
              </Typography>
              <Box marginTop={5}>
                <Typography color={'secondary'} variant={'h5'} align={'center'}>
                  {
                    'Leider ist das ausgewählte Produkt an Ihrer Adresse nicht verfügbar. Folgende Produkte können Sie auswählen:'
                  }
                </Typography>
              </Box>
            </Container>
          )}
          {isProductAvailable && (
            <Typography variant={'h1'} color={'secondary'} align={'center'} className={'narrower'}>
              Ihre Anschluss&shy;adresse
            </Typography>
          )}
        </Box>
        <AvailabilityCheckInput
          autoFocusOnMount={false}
          buttonText={'Adresse ändern'}
          disabledAdditionalInfo={true}
          disabledCity={true}
          disabledNumber={true}
          disabledStreet={true}
          disabledZip={true}
          disabledSubmit={false}
          additionalInfos={[selectedAdditionalInfo]}
          cities={[selectedCity]}
          focusedFieldId={AvailabilityCheckField.ZIP}
          numbers={[selectedNumber]}
          onSubmit={(): void => {
            resetStateNotQueriesAvaCheck()
            resetStateNotQueriesCheckout()
            navigate('/verfuegbarkeitspruefung')
          }}
          selectedAdditionalInfo={selectedAdditionalInfo}
          selectedCity={selectedCity}
          selectedNumber={selectedNumber}
          selectedStreet={selectedStreet}
          streets={[selectedStreet]}
          zip={zip}
        />
      </Paper>
      <Box marginTop={8} marginBottom={2}>
        {isDeliveryDateInTheFuture && (
          <Container maxWidth={'md'} style={{ marginBottom: 16 }}>
            <Typography variant={'h1'} align={'center'}>
              {'Alles OK.'}
            </Typography>
            <Box marginTop={5}>
              <Typography variant={'h5'} align={'center'}>
                {
                  'Nur noch wenige Schritte und Sie können ab Ihrem Bereitstellungstermin mit Höchstgeschwindigkeit in Ihrem Zuhause surfen.'
                }
              </Typography>
            </Box>
          </Container>
        )}
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h3'} {...TestIDs.CHECKOUT_SPEED_SELECT_HEADER_TYPOGRAPHY} className={'sectionH3'}>
                {!vzfID ? `Produkt` : `Sie haben bereits eine Produktkonfiguration gewählt`}
              </Typography>
              {vzfID !== undefined && (
                <Typography variant={'h5'} {...TestIDs.CHECKOUT_SPEED_SELECT_HEADER_TYPOGRAPHY} className={'sectionH3'}>
                  Vertragszusammenfassung-Nummer: {vzfID}
                </Typography>
              )}
            </Grid>
            <Grid container item xs={12} lg={8}>
              <ProductSelector
                products={products.filter((p) => p.category !== ProductCategory.BUNDLE)}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                onlyShowSelected={!!vzfID}
                setUserPreviousContract={setUserPreviousContract}
              />
              {!vzfID ? (
                <Grid item xs={12}>
                  <Divider variant={'middle'} />
                </Grid>
              ) : (
                selectedProduct && (
                  <Grid item xs={12}>
                    <Box marginTop={2} />
                  </Grid>
                )
              )}
              {productOptions.length > 0 && (
                <>
                  {!vzfID && (
                    <Grid item xs={12}>
                      <Box marginBottom={5}>
                        <Typography
                          variant={'h3'}
                          {...TestIDs.CHECKOUT_SPEED_SELECT_HEADER_TYPOGRAPHY}
                          className={'sectionH3'}
                        >
                          Zusatz-Optionen
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <ProductOptionSelector
                    selectedOptions={selectedOptions}
                    toggleProductOption={toggleProductOption}
                    options={productOptions.filter(
                      (po) => po.category !== ProductOptionCategory.HARDWARE && po.orderPage.displayed,
                    )}
                    onlyShowSelected={!!vzfID}
                  />
                </>
              )}
              {!vzfID && (
                <Grid item xs={12}>
                  {productOptions.length > 0 && <Divider className={'LessMarginTop'} variant={'middle'} />}
                  <VoucherFields
                    setAppliedVoucher={setAppliedVoucher}
                    setVoucherCode={setUserVoucherCode}
                    voucherCode={userVoucherCode}
                  />
                  <Divider className={'LessMarginTop'} variant={'middle'} />
                </Grid>
              )}
              <ContractSummary
                bundleAvailable={products.find((p) => p.category === ProductCategory.BUNDLE)}
                legalAccepted={bundleLegalRequirementAccepted}
                selectedOption={userHasPreviousContract}
                setSelectedOption={setUserPreviousContract}
                toggleLegalAccepted={toggleBundleLegalRequirementAcceptance}
                onClickVZF={handleSaveVZF}
                vzfID={vzfID}
                selectedProduct={selectedProduct}
              />
              {vzfID !== undefined && (
                <>
                  <MainDataFields
                    editMainData={editMainData}
                    mainData={mainData}
                    invalidFields={invalidFields}
                    clearInvalidField={clearInvalidField}
                    focusedField={focusedField}
                    setFocusedField={setFocusedField}
                  />
                  <Grid item xs={12}>
                    <Divider variant={'middle'} />
                  </Grid>
                  <AppartmentDataFields
                    appartmentData={appartmentData}
                    editAppartmentData={editAppartmentData}
                    invalidFields={invalidFields}
                    clearInvalidField={clearInvalidField}
                    focusedField={focusedField}
                    setFocusedField={setFocusedField}
                  />
                  <Grid item xs={12}>
                    <Divider variant={'middle'} />
                  </Grid>
                  <MainTenantFields
                    clearInvalidField={clearInvalidField}
                    editMainTenantData={editMainTenantData}
                    focusedField={focusedField}
                    invalidFields={invalidFields}
                    mainTenant={mainTenant}
                    mainTenantData={mainTenantData}
                    setFocusedField={setFocusedField}
                    toggleMainTenant={toggleMainTenant}
                  />
                  {showPortabilityFields && (
                    <>
                      <Grid item xs={12}>
                        <Divider variant={'middle'} />
                      </Grid>
                      <PortabilityFields
                        portability={portability}
                        portabilityNumbers={portabilityNumbers}
                        previousConnectionAddress={previousConnectionAddress}
                        previousConnectionAddressData={previousConnectionAddressData}
                        deliverAddressData={deliveryAddressData}
                        previousConnectionTermination={previousConnectionTermination}
                        previousConnectionTerminationData={previousConnectionTerminationData}
                        previousContractor={previousContractor}
                        previousContractorData={previousContractorData}
                        togglePortability={togglePortability}
                        editPortabilityNumber={editPortabilityNumber}
                        addPortabilityNumber={addPortabilityNumber}
                        removePortabilityNumber={removePortabilityNumber}
                        togglePreviousContractor={togglePreviousContractor}
                        editPreviousContractor={editPreviousContractor}
                        addPreviousContractor={addPreviousContractor}
                        removePreviousContractor={removePreviousContractor}
                        toggleConnectionAddress={toggleConnectionAddress}
                        editConnectionAddress={editConnectionAddress}
                        toggleConnectionTermination={toggleConnectionTermination}
                        editConnectionTermination={editConnectionTermination}
                        invalidFields={invalidFields}
                        clearInvalidField={clearInvalidField}
                        focusedField={focusedField}
                        setFocusedField={setFocusedField}
                        previousProviderDropdownValue={previousProviderDropdownValue}
                        previousProviderDropdownChange={previousProviderDropdownChange}
                        previousProviderDropdownOptions={previousProviderDropdownOptions}
                        previousProviderTextFieldValue={previousProviderTextFieldValue}
                        previousProviderTextFieldChange={previousProviderTextFieldChange}
                        toggleIsLandlineContractCancelled={toggleIsLandlineContractCancelled}
                        isLandlineContractCancelled={isLandlineContractCancelled}
                        landlineContractCancelDate={landlineContractCancelDate}
                        landlineContractCancelDateChange={landlineContractCancelDateChange}
                      />
                    </>
                  )}
                  <Grid item xs={12}>
                    <Divider variant={'middle'} />
                  </Grid>
                  <Grid container spacing={2}>
                    <DeliveryAdress
                      deliverAddressData={deliveryAddressData}
                      clearInvalidField={clearInvalidField}
                      focusedField={focusedField}
                      invalidFields={invalidFields}
                      setFocusedField={setFocusedField}
                      editDeliveryAddress={editDeliveryAddress}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant={'middle'} />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid container item xs={12}>
                      <Typography variant={'h3'} className={'sectionH3'}>
                        Rechnung hochladen
                      </Typography>
                    </Grid>
                    <UploadFile
                      files={filesForUploading}
                      onFileRemove={deleteUploadFile}
                      onFileChosen={setUploadFile}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant={'middle'} />
                  </Grid>
                  <DesiredInstallationDateFields
                    desiredInstallationDate={desiredInstallationDate}
                    editDesiredInstallationDate={editDesiredInstallationDate}
                    toggleDesiredInstallationDate={toggleDesiredInstallationDate}
                    invalidFields={invalidFields}
                    clearInvalidField={clearInvalidField}
                    focusedField={focusedField}
                    setFocusedField={setFocusedField}
                    minDate={minDate}
                  />
                  <Grid item xs={12}>
                    <Divider variant={'middle'} />
                  </Grid>
                  <PaymentDetailFields
                    accountHolder={accountHolder}
                    accountHolderData={accountHolderData}
                    toggleAccountHolder={toggleAccountHolder}
                    editAccountHolderData={editAccountHolder}
                    editAccountData={editAccountData}
                    editIban={editIban}
                    toggleAccountType={toggleAccountType}
                    togglePaymentAuthorization={togglePaymentAuthorization}
                    accountData={accountData}
                    accountType={accountType}
                    iban={iban}
                    paymentAuthorization={paymentAuthorization}
                    invalidFields={invalidFields}
                    clearInvalidField={clearInvalidField}
                    focusedField={focusedField}
                    setFocusedField={setFocusedField}
                  />
                  <Grid item xs={12}>
                    <Divider variant={'middle'} />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item container xs={12} lg={4}>
              <Grid item xs>
                <Basket
                  bundleProduct={
                    bundleLegalRequirementAccepted === true
                      ? products.find((p) => p.category === ProductCategory.BUNDLE)
                      : undefined
                  }
                  legalNotices={legalNotices}
                  mobileFixed={true}
                  selectedOptions={selectedOptions}
                  selectedProduct={selectedProduct}
                  vzfID={vzfID}
                  appliedVouchers={appliedVoucher ? [appliedVoucher] : []}
                />
              </Grid>
            </Grid>
            {vzfID !== undefined && (
              <Grid
                container
                item
                lg={8}
                justifyContent={'flex-end'}
                className={'SubmitButtonContainer'}
                alignItems={'center'}
              >
                {invalidFields.length > 0 && (
                  <Fade in={invalidFields.length > 0}>
                    <Grid item xs={12} md={'auto'}>
                      <Box>
                        <Alert severity="error">Einige Eingaben waren nicht gültig. Bitte überprüfen.</Alert>
                      </Box>
                    </Grid>
                  </Fade>
                )}
                <Grid item xs={12} md={1}>
                  &nbsp;
                </Grid>
                <Grid item xs={12} md={'auto'} {...TestIDs.CHECKOUT_SUBMIT_BUTTON}>
                  <Button
                    className={'AvailabilityCheck'}
                    focusRipple={true}
                    color={'secondary'}
                    onClick={(): void => {
                      if (
                        getInvalidFormFields(
                          mainData,
                          appartmentData,
                          mainTenant,
                          mainTenantData,
                          portability,
                          portabilityNumbers,
                          previousContractor,
                          previousContractorData,
                          previousConnectionAddress,
                          previousConnectionAddressData,
                          deliveryAddressData,
                          desiredInstallationDate,
                          desiredInstallationDateData,
                          minDate,
                          accountHolder,
                          accountHolderData,
                          accountType,
                          iban,
                          accountData,
                          paymentAuthorization,
                          previousProviderDropdownValue,
                          previousProviderTextFieldValue,
                          setInvalidFields,
                        ) === 0
                      ) {
                        navigate('/bestaetigung')
                      }
                    }}
                    startIcon={<ChevronRightIcon />}
                  >
                    Bestellung überprüfen
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid container item lg={8}>
              <Grid item>
                <Box marginTop={10}>
                  <Typography variant={'subtitle2'}>* Pflichtfeld</Typography>
                  {legalNotices.length > 0 &&
                    [...legalNotices]
                      .sort((a, b) => a.renderIndex - b.renderIndex)
                      .map((ln, index) =>
                        ln.text && basketLegalNoticeIDs.includes(ln.id) ? (
                          <Typography
                            component={'div'}
                            variant={'subtitle2'}
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: `${ln.renderIndex}) ${replacePToSpanTags(ln.text)}`,
                            }}
                          />
                        ) : null,
                      )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Checkout
