import { LandingPageResponse, LegalNotice, Product, ProductOption } from 'graphql/types'
import { useAddressesData } from 'pages/Addresses/useAddressesData'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, { AvailabilityCheckAction } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import CheckoutActions, { CheckoutAction } from 'store/Checkout/Checkout.actions'
import { CheckoutState, Offer } from 'store/Checkout/Checkout.reducer'
import { AppState } from 'store/store'

interface LandingPageStateReturn {
  legalNotices: LegalNotice[]
  loading: boolean
  offers: Offer[]
  productOptions: ProductOption[]
  products: Product[]
  selectedOptions: ProductOption[]
  selectedProduct: Product | undefined
  setCheckoutState: (payload: Partial<CheckoutState>) => void
  setSelectedAddress: () => void
  setSelectedProduct: (payload: Product) => void
  toggleProductOption: (payload: ProductOption) => void
  landingPageProducts: Product[]
  lps: LandingPageResponse | undefined
}

export const useLandingPageState = (path: string | undefined): LandingPageStateReturn => {
  const { loading, lps } = useAddressesData(path)
  const dispatch = useDispatch<Dispatch<AvailabilityCheckActions | CheckoutActions>>()
  const {
    checkout: { offers, productOptions, products, selectedOptions, selectedProduct, legalNotices },
  } = useSelector((appState: AppState) => {
    return {
      checkout: appState.checkout,
    }
  })

  const setOffers = useCallback(
    (payload: Offer[]) => {
      dispatch({
        type: CheckoutAction.SET_OFFERS,
        payload,
      })
    },
    [dispatch],
  )

  const setCheckoutState = useCallback(
    (payload: Partial<CheckoutState>) => {
      dispatch({ payload, type: CheckoutAction.SET_STATE })
    },
    [dispatch],
  )

  const setSelectedProduct = useCallback(
    (payload: Product) => {
      dispatch({ payload, type: CheckoutAction.SET_SELECTED_PRODUCT })
    },
    [dispatch],
  )

  const toggleProductOption = useCallback(
    (payload: ProductOption) => {
      dispatch({ payload, type: CheckoutAction.TOGGLE_PRODUCT_OPTION })
    },
    [dispatch],
  )

  const setSelectedAddress = useCallback(() => {
    dispatch({ type: AvailabilityCheckAction.SET_SELECTED_ADDRESS })
  }, [dispatch])

  useEffect(() => {
    const newOffers: Offer[] = (window as any).offers
    if (offers.length === 0) {
      setOffers(newOffers)
    }
  }, [setOffers, offers])

  useEffect(() => {
    if (loading === false && products.length > 0 && selectedProduct === undefined && lps) {
      const preselected = products.find((p) => p.id === lps.preseletedProductID)
      if (preselected) {
        setSelectedProduct(preselected)
      }
    }
  }, [lps, products, loading, selectedProduct, setSelectedProduct])

  return {
    legalNotices,
    loading,
    offers,
    productOptions,
    products,
    selectedOptions,
    selectedProduct,
    setCheckoutState,
    setSelectedAddress,
    setSelectedProduct,
    toggleProductOption,
    landingPageProducts: lps ? lps.products : [],
    lps,
  }
}
