import * as React from 'react'

export interface CheckIconProps {
  small?: boolean
}

const CheckIcon = (props: CheckIconProps): JSX.Element => {
  const width = !props.small ? 29 : 16
  const height = !props.small ? 28 : 15

  return (
    <svg width={width} height={height} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.70633 27.6831C9.01267 27.8973 9.36374 28 9.71145 28C10.2646 28 10.8092 27.7396 11.1506 27.2527L28.6825 2.7577C29.2384 1.96494 29.0454 0.872225 28.2515 0.317174C27.4575 -0.238075 26.3631 -0.0454179 25.8072 0.747344L8.27531 25.2425C7.71941 26.0353 7.91236 27.128 8.70633 27.6831Z"
        fill="white"
      />
      <path
        d="M6.02721 24.8408C7.3836 23.4865 7.3935 21.2975 6.05789 19.9302L2.99602 16.8387C2.3107 16.1547 1.19931 16.1547 0.513989 16.8387C-0.17133 17.5232 -0.17133 18.6327 0.513989 19.317L6.02721 24.8408Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckIcon
