import { Box, Link, makeStyles, Typography } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { getTestID } from 'utils/TestIDs'

const breakpoints = createBreakpoints({})

const useStyles = makeStyles(() => ({
  block: {
    display: 'inline-block',
    borderRight: '1px solid rgba(255,255,255,0.6)',

    '& p': {
      lineHeight: '1 !important',
    },

    [breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
      borderRight: 0,

      '& p': {
        textAlign: 'center',
        color: 'white',
        lineHeight: '40px !important',

        opacity: '1 !important',
        fontWeight: 'normal !important',
      },
    },
  },
  link: {
    lineHeight: '1 !important',
  },
  links: {
    display: 'inline-block',
  },
  wrapper: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))
interface LinkInterface {
  name: string
  url: string
}

export interface FooterChainedLinksProps {
  links: LinkInterface[]
}

const FooterRowLinks: FunctionComponent<FooterChainedLinksProps> = (props: FooterChainedLinksProps) => {
  const classes = useStyles()

  return (
    <Box mt={{ xs: 0, md: 3 }} display={'block'} className={classes.wrapper}>
      {props.links.map((link, index) => {
        if (index === 0) {
          return (
            <Box component={'span'} key={index.toString()} className={classes.block}>
              <Typography className={'FooterTypography'} color={'textSecondary'}>
                {link.name}
              </Typography>
            </Box>
          )
        }
        if (index === props.links.length - 1) {
          return (
            <Box component={'span'} key={index.toString()} className={classes.links}>
              <Link
                component={RouterLink}
                to={link.url}
                className={clsx('FooterRowLink FooterLink LastRowLink', classes.link)}
                color={'textSecondary'}
                {...getTestID('page_footerLink_' + link.url.substring(1))}
              >
                {link.name}
              </Link>
            </Box>
          )
        }
        return (
          <Box
            component={'span'}
            key={index.toString()}
            borderRight={'1px solid rgba(255,255,255,0.6)'}
            className={classes.links}
          >
            <Link
              component={RouterLink}
              to={link.url}
              className={clsx('FooterRowLink FooterLink', classes.link)}
              color={'textSecondary'}
              {...getTestID('page_footerLink_' + link.url.substring(1))}
            >
              {link.name}
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}

export default FooterRowLinks
