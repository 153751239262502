import { Box, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core'
import CheckboxIcon from 'components/CheckboxIcon'
import OptionItem from 'components/OptionItem'
import { Product } from 'graphql/types'
import { isEqual } from 'lodash'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'
import { useMemo, useEffect } from 'react'

interface BundleProductFieldsProps {
  bundleProduct: Product | undefined
  bundleProductState?: boolean
  clearInvalidField: (field: string) => void
  hasBundleProduct: boolean
  invalidFields: string[]
  setBundleProductState: (hasBundleProduct: boolean) => void
  toggleHasBundleProduct: (hasBundleProduct: boolean) => void
  disable?: boolean
}

const BundleProductFields = (props: BundleProductFieldsProps): JSX.Element | null => {
  const {
    bundleProduct,
    bundleProductState,
    clearInvalidField,
    hasBundleProduct,
    invalidFields,
    setBundleProductState,
    toggleHasBundleProduct,
    disable,
  } = props

  const showError = useMemo(() => {
    return bundleProductState === undefined && invalidFields.includes('bundleProduct')
  }, [invalidFields, bundleProductState])

  useEffect(() => {
    if (bundleProductState === false && invalidFields.includes('bundleProduct')) {
      clearInvalidField('bundleProduct')
    }
    if (bundleProductState === true && hasBundleProduct && invalidFields.includes('bundleProduct')) {
      clearInvalidField('bundleProduct')
    }
  }, [bundleProductState, hasBundleProduct, clearInvalidField, invalidFields])

  if (!bundleProduct) {
    return null
  }

  const renderDetails = bundleProduct.orderPage.renderDetails

  if (!renderDetails) {
    return null
  }

  if (disable === true) {
    if (!hasBundleProduct) {
      return null
    }
    return (
      <Grid item xs={12}>
        <OptionItem
          onClick={(): void => {
            setBundleProductState(false)
            if (hasBundleProduct === true) {
              toggleHasBundleProduct(false)
            }
          }}
          selected={true}
          disable={true}
          title={renderDetails.options ? renderDetails.options[1] : 'Ja, ich bin Stromkunde'}
          variant={'h4'}
          testId={TestIDs.BUNDLE_PRODUCT_RADIO_NO}
        />
      </Grid>
    )
  }

  return (
    <>
      <Grid id={'Stromkunde'} container item spacing={3}>
        <Grid item xs={12}>
          <Typography variant={'h3'} className={'sectionH3'} {...TestIDs.BUNDLE_PRODUCT_FIELDS_HEADER}>
            {renderDetails.title ?? 'Ich bin schon Stromkunde'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography {...TestIDs.BUNDLE_PRODUCT_FIELDS_SUBHEADER}>
            {renderDetails.subTitle ??
              'Ihr Vorteil: Als Bündelkunde bekommen Sie eine Einmalgutschrift auf Ihr Multimedia-Konto.'}
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <OptionItem
              onClick={(): void => {
                setBundleProductState(false)
                if (hasBundleProduct === true) {
                  toggleHasBundleProduct(false)
                }
              }}
              selected={bundleProductState === false}
              title={renderDetails.options ? renderDetails.options[0] : 'Nein'}
              variant={'h4'}
              testId={TestIDs.BUNDLE_PRODUCT_RADIO_NO}
            />
          </Grid>
          <Grid item xs={12}>
            <OptionItem
              onClick={(): void => setBundleProductState(true)}
              selected={bundleProductState === true}
              title={renderDetails.options ? renderDetails.options[1] : 'Ja, ich bin Stromkunde'}
              variant={'h4'}
              testId={TestIDs.BUNDLE_PRODUCT_RADIO_YES}
            />
          </Grid>
        </Grid>
        {bundleProductState === true && (
          <Grid container item spacing={2}>
            <Grid item {...TestIDs.BUNDLE_PRODUCT_FIELDS_CHECKBOX}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <CheckboxIcon
                        error={invalidFields.includes('bundleProduct') && !hasBundleProduct}
                        checked={false}
                      />
                    }
                    checkedIcon={
                      <CheckboxIcon
                        error={invalidFields.includes('bundleProduct') && !hasBundleProduct}
                        checked={true}
                      />
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      toggleHasBundleProduct(event.target.checked)
                    }}
                  />
                }
                style={{ alignItems: 'flex-start' }}
                checked={hasBundleProduct}
                label={
                  <Box marginBottom={2} marginLeft={2}>
                    <Typography
                      className={invalidFields.includes('bundleProduct') && !hasBundleProduct ? 'TextError' : ''}
                    >
                      {renderDetails.renderSelected ??
                        'Ich habe einen Grünstrom Vertrag bei Vonovia. Ich bin damit einverstanden, dass die Deutsche Multimedia Service GmbH (Internetanbieter) bei der Vonovia Energie Service GmbH (Energieversorger) abfragt, ob ich einen Grünstrom Vertrag abgeschlossen habe, um die Einmalgutschrift zu erhalten.*'}
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          </Grid>
        )}
        {showError && (
          <Box marginBottom={2} marginLeft={2}>
            <Typography className={'TextError'}>Sie sollten eine davon auswählen.</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider variant={'middle'} />
      </Grid>
    </>
  )
}

export default React.memo(BundleProductFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
