export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Time: Date
}

export type Address = {
  __typename?: 'Address'
  additionalInfo: Scalars['String']
  city: Scalars['String']
  houseNumber: Scalars['String']
  street: Scalars['String']
  zipcode: Scalars['String']
}

export type AddressData = {
  __typename?: 'AddressData'
  addressAddition?: Maybe<Scalars['String']>
  buildingNr: Scalars['Int']
  city: Scalars['String']
  houseNumber: Scalars['String']
  id: Scalars['ID']
  online?: Maybe<Scalars['Boolean']>
  products?: Maybe<Array<Scalars['String']>>
  projectId: Scalars['String']
  projectIdDescr: Scalars['String']
  projectTitle: Scalars['String']
  startOfDelivery?: Maybe<Scalars['Time']>
  startOfMarketing?: Maybe<Scalars['Time']>
  street: Scalars['String']
  uniqueAddress: Scalars['String']
  vouchers?: Maybe<Array<Scalars['String']>>
  zipCode: Scalars['String']
}

export type AddressInput = {
  ids: Array<Scalars['String']>
  online: Scalars['Boolean']
  products: Array<Scalars['String']>
  startOfDelivery?: InputMaybe<Scalars['Time']>
  startOfMarketing?: InputMaybe<Scalars['Time']>
  vouchers: Array<Scalars['String']>
  what: Scalars['String']
}

export type AddressInputIntern = {
  ids: Array<Scalars['String']>
  online: Scalars['Boolean']
  products: Array<Scalars['String']>
  productsIntermediate: Array<Scalars['String']>
  startOfDelivery: Scalars['String']
  startOfMarketing: Scalars['String']
  vouchers: Array<Scalars['String']>
}

export type CheckVzfResponse = {
  __typename?: 'CheckVZFResponse'
  vzfData: VzfData
  vzfID: Scalars['String']
}

export type ContractAddressData = {
  __typename?: 'ContractAddressData'
  addressAddition?: Maybe<Scalars['String']>
  city: Scalars['String']
  houseNumber: Scalars['String']
  street: Scalars['String']
  zipCode: Scalars['String']
}

export type ContractAddressDataInput = {
  addressAddition?: InputMaybe<Scalars['String']>
  city: Scalars['String']
  houseNumber: Scalars['String']
  street: Scalars['String']
  zipCode: Scalars['String']
}

export type ContractData = {
  __typename?: 'ContractData'
  contractAddress?: Maybe<ContractAddressData>
  contractHolders: Array<HolderData>
  documents: Array<DocumentsData>
  hasTelephoneFlat: Scalars['Boolean']
  isLandlineContractCancelled?: Maybe<Scalars['Boolean']>
  landlineContractCancelDate?: Maybe<Scalars['String']>
  lineNumberPortabilitys: Array<LineNumberPortabilityData>
  previousProvider?: Maybe<Scalars['String']>
}

export type ContractDataInput = {
  contractAddress?: InputMaybe<ContractAddressDataInput>
  contractHolders?: InputMaybe<Array<InputMaybe<HolderDataInput>>>
  documents?: InputMaybe<Array<InputMaybe<DocumentsDataInput>>>
  hasTelephoneFlat: Scalars['Boolean']
  isLandlineContractCancelled?: InputMaybe<Scalars['Boolean']>
  landlineContractCancelDate?: InputMaybe<Scalars['String']>
  lineNumberPortabilitys?: InputMaybe<Array<InputMaybe<LineNumberPortabilityDataInput>>>
  previousProvider?: InputMaybe<Scalars['String']>
}

export type CustomerData = {
  __typename?: 'CustomerData'
  accountHolder?: Maybe<HolderData>
  addressAddition?: Maybe<Scalars['String']>
  bankAccountNumber?: Maybe<Scalars['String']>
  bankCode?: Maybe<Scalars['String']>
  city: Scalars['String']
  dateOfBirth: Scalars['String']
  desiredAppointment?: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  flat?: Maybe<Scalars['String']>
  floor?: Maybe<Scalars['String']>
  houseNumber: Scalars['String']
  iban?: Maybe<Scalars['String']>
  isAccountHolder: Scalars['Boolean']
  lastName: Scalars['String']
  mainTenant?: Maybe<HolderData>
  rentalAgreementNumber?: Maybe<Scalars['String']>
  salutation: Scalars['String']
  street: Scalars['String']
  telephoneNumber: Scalars['String']
  title?: Maybe<Scalars['String']>
  zipCode: Scalars['String']
}

export type CustomerDataInput = {
  accountHolder?: InputMaybe<HolderDataInput>
  addressAddition?: InputMaybe<Scalars['String']>
  bankAccountNumber?: InputMaybe<Scalars['String']>
  bankCode?: InputMaybe<Scalars['String']>
  city: Scalars['String']
  dateOfBirth: Scalars['String']
  deliveryAddress?: InputMaybe<DeliveryAddress>
  desiredAppointment?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  flat?: InputMaybe<Scalars['String']>
  floor?: InputMaybe<Scalars['String']>
  houseNumber: Scalars['String']
  iban?: InputMaybe<Scalars['String']>
  isAccountHolder: Scalars['Boolean']
  lastName: Scalars['String']
  mainTenant?: InputMaybe<HolderDataInput>
  rentalAgreementNumber?: InputMaybe<Scalars['String']>
  salutation: Scalars['String']
  street: Scalars['String']
  telephoneNumber: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  zipCode: Scalars['String']
}

export type DeliveryAddress = {
  additionalInfo: Scalars['String']
  city: Scalars['String']
  firstName: Scalars['String']
  houseNumber: Scalars['String']
  lastName: Scalars['String']
  salutation: Scalars['String']
  street: Scalars['String']
  title: Scalars['String']
  zipcode: Scalars['String']
}

export type DocumentsData = {
  __typename?: 'DocumentsData'
  data: Scalars['String']
  name: Scalars['String']
}

export type DocumentsDataInput = {
  data: Scalars['String']
  name: Scalars['String']
}

export type ErrorConfig = {
  __typename?: 'ErrorConfig'
  content: Scalars['String']
  messages?: Maybe<Array<Scalars['String']>>
  title: Scalars['String']
}

export type GetLandingPageInput = {
  path: Scalars['String']
}

export type HolderData = {
  __typename?: 'HolderData'
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export type HolderDataInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export type InternetSpeed = {
  __typename?: 'InternetSpeed'
  download: InternetSpeedValue
  upload: InternetSpeedValue
}

export type InternetSpeedInput = {
  download: InternetSpeedValueInput
  upload: InternetSpeedValueInput
}

export type InternetSpeedValue = {
  __typename?: 'InternetSpeedValue'
  max: Scalars['Float']
  min: Scalars['Float']
  norm: Scalars['Float']
}

export type InternetSpeedValueInput = {
  max: Scalars['Float']
  min: Scalars['Float']
  norm: Scalars['Float']
}

export type LandingPage = {
  __typename?: 'LandingPage'
  id: Scalars['ID']
  info: Scalars['String']
  path: Scalars['String']
  preseletedID: Scalars['String']
  productIDs: Array<Scalars['String']>
  title: Scalars['String']
}

export type LandingPageInput = {
  id: Scalars['ID']
  info: Scalars['String']
  path: Scalars['String']
  preseletedID: Scalars['String']
  productIDs: Array<Scalars['String']>
  title: Scalars['String']
}

export type LandingPageResponse = {
  __typename?: 'LandingPageResponse'
  preseletedProductID: Scalars['String']
  products: Array<Product>
  title: Scalars['String']
}

export type LegalNotice = {
  __typename?: 'LegalNotice'
  category: LegalNoticeCategory
  id: Scalars['ID']
  name: Scalars['String']
  renderIndex: Scalars['Int']
  startOfMarketing?: Maybe<Scalars['Time']>
  text: Scalars['String']
}

export enum LegalNoticeCategory {
  BESTELLSTRECKE = 'BESTELLSTRECKE',
  INFO_BLATT = 'INFO_BLATT',
  LANDINGPAGE = 'LANDINGPAGE',
  VZF = 'VZF',
  WARENKORB = 'WARENKORB',
}

export type LegalNoticeInput = {
  category: LegalNoticeCategory
  id: Scalars['ID']
  name: Scalars['String']
  renderIndex: Scalars['Int']
  startOfMarketing?: InputMaybe<Scalars['Time']>
  text: Scalars['String']
}

export type LegalNoticeInputIntern = {
  category: LegalNoticeCategory
  id: Scalars['ID']
  name: Scalars['String']
  renderIndex: Scalars['Int']
  startOfMarketing?: InputMaybe<Scalars['String']>
  text: Scalars['String']
}

export type LineNumberPortabilityData = {
  __typename?: 'LineNumberPortabilityData'
  number: Scalars['String']
  prefix: Scalars['String']
}

export type LineNumberPortabilityDataInput = {
  number: Scalars['String']
  prefix: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  deleteLandingPage?: Maybe<MutationResponse>
  deleteLegalNotice?: Maybe<MutationResponse>
  deleteProduct?: Maybe<MutationResponse>
  deleteProductOption?: Maybe<MutationResponse>
  deleteVoucher?: Maybe<MutationResponse>
  saveAddress?: Maybe<MutationResponse>
  saveLandingPage?: Maybe<MutationResponse>
  saveLegalNotice?: Maybe<MutationResponse>
  saveOrder?: Maybe<MutationResponse>
  saveProduct?: Maybe<MutationResponse>
  saveProductOption?: Maybe<MutationResponse>
  saveVZF?: Maybe<Scalars['String']>
  saveVoucher?: Maybe<MutationResponse>
  validateMail?: Maybe<MutationResponse>
}

export type MutationDeleteLandingPageArgs = {
  id: Scalars['String']
}

export type MutationDeleteLegalNoticeArgs = {
  id: Scalars['String']
}

export type MutationDeleteProductArgs = {
  id: Scalars['String']
}

export type MutationDeleteProductOptionArgs = {
  id: Scalars['String']
}

export type MutationDeleteVoucherArgs = {
  id: Scalars['String']
}

export type MutationSaveAddressArgs = {
  input: AddressInput
}

export type MutationSaveLandingPageArgs = {
  input: LandingPageInput
}

export type MutationSaveLegalNoticeArgs = {
  input: LegalNoticeInput
}

export type MutationSaveOrderArgs = {
  input: SaveOrderInput
}

export type MutationSaveProductArgs = {
  input: ProductInput
}

export type MutationSaveProductOptionArgs = {
  input: ProductOptionInput
}

export type MutationSaveVzfArgs = {
  input: VzfDataInput
}

export type MutationSaveVoucherArgs = {
  input: VoucherInput
}

export type MutationValidateMailArgs = {
  input: ValidateMailInput
}

export type MutationResponse = {
  __typename?: 'MutationResponse'
  status: Scalars['String']
}

export type OldProduct = {
  __typename?: 'OldProduct'
  bundleProduct?: Maybe<Scalars['Boolean']>
  fritzboxPlus?: Maybe<Scalars['Boolean']>
  internet100?: Maybe<Scalars['Boolean']>
  internet500?: Maybe<Scalars['Boolean']>
  internet1000?: Maybe<Scalars['Boolean']>
  telephonPlus?: Maybe<Scalars['Boolean']>
  telephonPure?: Maybe<Scalars['Boolean']>
  tvPlus?: Maybe<Scalars['Boolean']>
  tvPure?: Maybe<Scalars['Boolean']>
  uploadPlus?: Maybe<Scalars['Boolean']>
}

export type OldProductsInput = {
  bundleProduct?: InputMaybe<Scalars['Boolean']>
  fritzboxPlus?: InputMaybe<Scalars['Boolean']>
  internet100?: InputMaybe<Scalars['Boolean']>
  internet500?: InputMaybe<Scalars['Boolean']>
  internet1000?: InputMaybe<Scalars['Boolean']>
  telephonPlus?: InputMaybe<Scalars['Boolean']>
  telephonPure?: InputMaybe<Scalars['Boolean']>
  tvPlus?: InputMaybe<Scalars['Boolean']>
  tvPure?: InputMaybe<Scalars['Boolean']>
  uploadPlus?: InputMaybe<Scalars['Boolean']>
}

export enum OptionAvailability {
  AVAILABLE = 'AVAILABLE',
  PRESELECTED = 'PRESELECTED',
}

export enum OptionDependency {
  EXCLUDED = 'EXCLUDED',
  INCLUDED = 'INCLUDED',
}

export type OptionDependent = {
  __typename?: 'OptionDependent'
  dependency: OptionDependency
  productOption: Scalars['ID']
}

export type OptionDependentInput = {
  dependency: OptionDependency
  productOption: Scalars['ID']
}

export enum PriceInterval {
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
}

export enum PriceMarket {
  BUSINESS = 'BUSINESS',
  PRIVATE = 'PRIVATE',
}

export enum PriceVat {
  GROSS = 'GROSS',
  NET = 'NET',
}

export type Product = {
  __typename?: 'Product'
  basket: ProductDetails
  category: ProductCategory
  color: Scalars['String']
  id: Scalars['ID']
  infoBlatt?: Maybe<Scalars['String']>
  internetSpeed?: Maybe<InternetSpeed>
  knmID: Scalars['String']
  landingPage: ProductDetails
  name: Scalars['String']
  options: Array<ProductOptionInstance>
  orderPage: ProductDetails
  preSelected: Scalars['Boolean']
  price: ProductPrice
  renderIndex: Scalars['Int']
  vzf?: Maybe<Scalars['String']>
}

export enum ProductCategory {
  BUNDLE = 'BUNDLE',
  INTERNET = 'INTERNET',
  TELEFON = 'TELEFON',
  TV = 'TV',
}

export type ProductData = {
  __typename?: 'ProductData'
  productOptions: Array<Scalars['String']>
  products: Array<Scalars['String']>
}

export type ProductDataInput = {
  productOptions: Array<Scalars['String']>
  products: Array<Scalars['String']>
}

export type ProductDependentOn = {
  __typename?: 'ProductDependentOn'
  options: Array<ProductDependentOnObject>
  products: Array<ProductDependentOnObject>
}

export type ProductDependentOnInput = {
  options: Array<ProductDependentOnObjectInput>
  products: Array<ProductDependentOnObjectInput>
}

export type ProductDependentOnObject = {
  __typename?: 'ProductDependentOnObject'
  id: Scalars['String']
  oneTimeCut: Scalars['Float']
}

export type ProductDependentOnObjectInput = {
  id: Scalars['String']
  oneTimeCut: Scalars['Float']
}

export type ProductDetails = {
  __typename?: 'ProductDetails'
  dependentOn?: Maybe<ProductDependentOn>
  displayed: Scalars['Boolean']
  featureText?: Maybe<Scalars['String']>
  legalNotice?: Maybe<Scalars['String']>
  priceInfoText?: Maybe<Scalars['String']>
  renderDetails?: Maybe<ProductRenderDetails>
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ProductDetailsInput = {
  dependentOn?: InputMaybe<ProductDependentOnInput>
  displayed: Scalars['Boolean']
  featureText?: InputMaybe<Scalars['String']>
  legalNotice?: InputMaybe<Scalars['String']>
  priceInfoText?: InputMaybe<Scalars['String']>
  renderDetails?: InputMaybe<ProductRenderDetailsInput>
  subTitle?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type ProductInput = {
  basket: ProductDetailsInput
  category: ProductCategory
  color: Scalars['String']
  id: Scalars['ID']
  infoBlatt?: InputMaybe<Scalars['String']>
  internetSpeed?: InputMaybe<InternetSpeedInput>
  knmID: Scalars['String']
  landingPage: ProductDetailsInput
  name: Scalars['String']
  options: Array<ProductOptionInstanceInput>
  orderPage: ProductDetailsInput
  preSelected: Scalars['Boolean']
  price: ProductPriceInput
  renderIndex: Scalars['Int']
  vzf?: InputMaybe<Scalars['String']>
}

export type ProductOption = {
  __typename?: 'ProductOption'
  basket: ProductDetails
  category: ProductOptionCategory
  color: Scalars['String']
  id: Scalars['ID']
  infoBlatt?: Maybe<Scalars['String']>
  knmID: Scalars['String']
  landingPage: ProductDetails
  name: Scalars['String']
  orderPage: ProductDetails
  price: ProductPrice
  renderIndex: Scalars['Int']
  type: ProductOptionType
  vzf?: Maybe<Scalars['String']>
}

export enum ProductOptionCategory {
  HARDWARE = 'HARDWARE',
  INTERNET = 'INTERNET',
  TELEFON = 'TELEFON',
  TV = 'TV',
}

export type ProductOptionInput = {
  basket: ProductDetailsInput
  category: ProductOptionCategory
  color: Scalars['String']
  id: Scalars['ID']
  infoBlatt?: InputMaybe<Scalars['String']>
  knmID: Scalars['String']
  landingPage: ProductDetailsInput
  name: Scalars['String']
  orderPage: ProductDetailsInput
  price: ProductPriceInput
  renderIndex: Scalars['Int']
  type: ProductOptionType
  vzf?: InputMaybe<Scalars['String']>
}

export type ProductOptionInstance = {
  __typename?: 'ProductOptionInstance'
  availability: OptionAvailability
  dependent: Array<OptionDependent>
  productOption: Scalars['String']
}

export type ProductOptionInstanceInput = {
  availability: OptionAvailability
  dependent: Array<OptionDependentInput>
  productOption: Scalars['String']
}

export enum ProductOptionType {
  ADDON = 'ADDON',
  HARDWARE = 'HARDWARE',
}

export type ProductPrice = {
  __typename?: 'ProductPrice'
  interval: PriceInterval
  market?: Maybe<PriceMarket>
  value: Scalars['Float']
  vat: PriceVat
}

export type ProductPriceInput = {
  interval: PriceInterval
  market?: InputMaybe<PriceMarket>
  value: Scalars['Float']
  vat: PriceVat
}

export type ProductRenderDetails = {
  __typename?: 'ProductRenderDetails'
  defaultOption?: Maybe<Scalars['String']>
  options?: Maybe<Array<Scalars['String']>>
  renderSelected: Scalars['String']
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ProductRenderDetailsInput = {
  defaultOption?: InputMaybe<Scalars['String']>
  options?: InputMaybe<Array<Scalars['String']>>
  renderSelected: Scalars['String']
  subTitle?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  addresses: Array<AddressData>
  checkVZF?: Maybe<CheckVzfResponse>
  getCustomLandingPage: LandingPageResponse
  landingPages: Array<LandingPage>
  legalNotice: Array<LegalNotice>
  productOptions: Array<ProductOption>
  products: Array<Product>
  validateVoucherCode: ValidateVoucherResponse
  vouchers: Array<Voucher>
}

export type QueryCheckVzfArgs = {
  vzfID: Scalars['String']
}

export type QueryGetCustomLandingPageArgs = {
  input: GetLandingPageInput
}

export type QueryValidateVoucherCodeArgs = {
  code: Scalars['String']
  selectedAddressID: Scalars['ID']
}

export type SaveOrderInput = {
  contractData?: InputMaybe<ContractDataInput>
  customerData?: InputMaybe<CustomerDataInput>
  productData?: InputMaybe<ProductDataInput>
  voucher?: InputMaybe<Scalars['String']>
  vzfID: Scalars['String']
}

export type VzfAddressInput = {
  additionalInfo: Scalars['String']
  city: Scalars['String']
  houseNumber: Scalars['String']
  street: Scalars['String']
  zipcode: Scalars['String']
}

export type VzfData = {
  __typename?: 'VZFData'
  address: Address
  productOptions: Array<Scalars['String']>
  products: Array<Scalars['String']>
  voucher?: Maybe<Voucher>
}

export type VzfDataInput = {
  address: VzfAddressInput
  productOptions: Array<Scalars['String']>
  products: Array<Scalars['String']>
  voucherCode: Scalars['String']
}

export type ValidateMailInput = {
  id: Scalars['String']
}

export type ValidateVoucherResponse = {
  __typename?: 'ValidateVoucherResponse'
  isValid: Scalars['Boolean']
  voucher?: Maybe<Voucher>
}

export type Voucher = {
  __typename?: 'Voucher'
  amount: Scalars['Int']
  availableFor: Array<Scalars['String']>
  availableType: VoucherAvailableType
  basketSubtitle: Scalars['String']
  basketTitle: Scalars['String']
  code: Scalars['String']
  dateFrom?: Maybe<Scalars['Time']>
  dateTo?: Maybe<Scalars['Time']>
  discountType: VoucherDiscountType
  id: Scalars['ID']
  legalNotice?: Maybe<Scalars['String']>
  name: Scalars['String']
  numberOfMonth?: Maybe<Scalars['Int']>
  redeemed: Scalars['Int']
  type: VoucherType
  validityType: VoucherValidityType
  value: Scalars['Float']
  valueType: VoucherValueType
}

export enum VoucherAvailableType {
  OPTIONS = 'OPTIONS',
  PRODUCTS = 'PRODUCTS',
}

export enum VoucherDiscountType {
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
}

export type VoucherInput = {
  amount: Scalars['Int']
  availableFor: Array<Scalars['String']>
  availableType: VoucherAvailableType
  basketSubtitle: Scalars['String']
  basketTitle: Scalars['String']
  code: Scalars['String']
  dateFrom?: InputMaybe<Scalars['Time']>
  dateTo?: InputMaybe<Scalars['Time']>
  discountType: VoucherDiscountType
  id: Scalars['ID']
  legalNotice?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  numberOfMonth?: InputMaybe<Scalars['Int']>
  redeemed?: InputMaybe<Scalars['Int']>
  type: VoucherType
  validityType: VoucherValidityType
  value: Scalars['Float']
  valueType: VoucherValueType
}

export type VoucherInputIntern = {
  amount: Scalars['Int']
  availableFor: Array<Scalars['String']>
  availableType: VoucherAvailableType
  basketSubtitle: Scalars['String']
  basketTitle: Scalars['String']
  code: Scalars['String']
  dateFrom?: InputMaybe<Scalars['String']>
  dateTo?: InputMaybe<Scalars['String']>
  discountType: VoucherDiscountType
  id: Scalars['ID']
  legalNotice?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  numberOfMonth?: InputMaybe<Scalars['Int']>
  redeemed?: InputMaybe<Scalars['Int']>
  type: VoucherType
  validityType: VoucherValidityType
  value: Scalars['Int']
  valueType: VoucherValueType
}

export enum VoucherType {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum VoucherValidityType {
  DATE_FROM = 'DATE_FROM',
  DATE_TO = 'DATE_TO',
  TIME_SPAN = 'TIME_SPAN',
  UNLIMITED = 'UNLIMITED',
}

export enum VoucherValueType {
  EURO = 'EURO',
  PERCENT = 'PERCENT',
}
