import { Box } from '@material-ui/core'
import LoadingSpinner from 'components/LoadingSpinner'
import _ from 'lodash'
import React, { CSSProperties } from 'react'
import { useAddressesData } from './useAddressesData'

const Addresses = (): JSX.Element => {
  const { addressList, loading } = useAddressesData()

  const productStyle: CSSProperties = {
    writingMode: 'vertical-lr',
    textOrientation: 'mixed',
    transform: 'rotate(-180deg)',
  }

  return (
    <>
      <Box marginTop={8} marginBottom={2}>
        <table className={'addressTable'} style={{ width: '100%' }}>
          <tr className={'addressRow'}>
            <th className={'addressHeader'}>PLZ</th>
            <th className={'addressHeader'}>City</th>
            <th className={'addressHeader'}>Street</th>
            <th className={'addressHeader'}>Number</th>
            <th className={'addressHeader'}>Additional</th>
            <th className={'addressHeader'}>Start of Delivery</th>
            <th className={'addressHeader'}>Start of Marketing</th>
            <th className={'addressHeader'}>
              <span style={productStyle}>Internet 100</span>
            </th>
            <th className={'addressHeader'}>
              <span style={productStyle}>Internet 500</span>
            </th>
            <th className={'addressHeader'}>
              <span style={productStyle}>Internet 1000</span>
            </th>
            <th className={'addressHeader'}>
              <span style={productStyle}>Telefon pur</span>
            </th>
            <th className={'addressHeader'}>
              <span style={productStyle}>TV pur</span>
            </th>
            <th className={'addressHeader'}>
              <span style={productStyle}>TV +</span>
            </th>
            <th className={'addressHeader'}>
              <span style={productStyle}>Upload +</span>
            </th>
            <th className={'addressHeader'}>
              <span style={productStyle}>Fritzbox +</span>
            </th>
          </tr>
          {_.sortBy(addressList, ['zipCode', 'city', 'street', (record): number => Number(record.houseNumber)]).map(
            (record) => (
              <tr className={'addressRow'} key={JSON.stringify(record)}>
                <td className={'addressCell'}>{record.zipCode}</td>
                <td className={'addressCell'}>{record.city}</td>
                <td className={'addressCell'}>{record.street}</td>
                <td className={'addressCell'}>{record.houseNumber}</td>
                <td className={'addressCell'}>{record.addressAddition}</td>
                <td className={'addressCell'}>{record.startOfDelivery ? record.startOfDelivery.toString() : ''}</td>
                <td className={'addressCell'}>{record.startOfMarketing ? record.startOfMarketing.toString() : ''}</td>
              </tr>
            ),
          )}
        </table>
      </Box>
      <LoadingSpinner loading={loading} />
    </>
  )
}

export default Addresses
