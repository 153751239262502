import { AddressData } from 'graphql/types'

export enum AvailabilityCheckAction {
  SET_ADDRESS_LIST = 'SET_ADDRESS_LIST',
  SET_TO_INIT_STATE = 'SET_TO_INIT_STATE',
  SET_TO_INIT_STATE_EXCEPT_QUERY = 'SET_TO_INIT_STATE_EXCEPT_QUERY',
  SET_ADDITIONAL = 'SET_ADDITIONAL',
  SET_CITY = 'SET_CITY',
  SET_FOCUSED_FIELD = 'SET_FOCUSED_FIELD',
  SET_NUMBER = 'SET_NUMBER',
  SET_STREET = 'SET_STREET',
  SET_ZIP = 'SET_ZIP',
  SET_LOADING = 'SET_LOADING',
  SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS',
  SET_VZF_ADDRESS = 'SET_VZF_ADDRESS',
}

export enum AvailabilityCheckField {
  NONE = -1,
  ZIP,
  CITY,
  STREET,
  NUMBER,
  ADDITIONAL,
  SUBMIT,
}

type AvailabilityCheckActions =
  | { payload: AddressData[]; type: AvailabilityCheckAction.SET_ADDRESS_LIST }
  | { payload: null; type: AvailabilityCheckAction.SET_TO_INIT_STATE }
  | { type: AvailabilityCheckAction.SET_TO_INIT_STATE_EXCEPT_QUERY }
  | { payload: AvailabilityCheckField; type: AvailabilityCheckAction.SET_FOCUSED_FIELD }
  | { payload: string; type: AvailabilityCheckAction.SET_ADDITIONAL }
  | { payload: string; type: AvailabilityCheckAction.SET_CITY }
  | { type: AvailabilityCheckAction.SET_LOADING; payload: boolean }
  | { payload: string; type: AvailabilityCheckAction.SET_NUMBER }
  | { payload: string; type: AvailabilityCheckAction.SET_STREET }
  | { payload: string; type: AvailabilityCheckAction.SET_ZIP }
  | { type: AvailabilityCheckAction.SET_SELECTED_ADDRESS }
  | {
      payload: { zip: string; street: string; city: string; additional: string; number: string }
      type: AvailabilityCheckAction.SET_VZF_ADDRESS
    }

export default AvailabilityCheckActions
