import * as React from 'react'

export interface CheckboxIconProps {
  checked: boolean
  error: boolean
  inverted?: boolean
}

const CheckboxIcon = (props: CheckboxIconProps): JSX.Element => {
  const color = props.inverted ? '#ffffff' : '#085368'
  return (
    <svg width={22} height={22} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      {props.checked ? (
        <>
          <path
            d="M46.0454 6.00796C49.3333 5.98459 51.9917 3.29422 51.9917 0H3.00283C1.3443 0 0 1.35501 0 3.0138V60.9869C0 62.6457 1.3443 64 3.00283 64H60.9474C62.6059 64 63.9898 62.6457 63.9898 60.9869V18.0544H57.9446V57.9836H6.00565V6.00796H46.0454Z"
            fill={color}
          />
          <path
            d="M29.2761 47.4567C29.8003 47.8238 30.401 47.9999 30.9959 47.9999C31.9424 47.9999 32.8742 47.5535 33.4583 46.7191L63.4565 4.7365C64.4076 3.37777 64.0775 1.50494 62.719 0.553622C61.3605 -0.398034 59.4879 -0.067833 58.5367 1.2909L28.5386 43.2738C27.5875 44.6325 27.9176 46.5054 29.2761 47.4567Z"
            fill={color}
          />
          <path
            d="M24.692 42.5854C27.0129 40.2642 27.0298 36.5125 24.7445 34.1689L19.5055 28.8704C18.3329 27.698 16.4312 27.698 15.2586 28.8704C14.086 30.0436 14.086 31.9452 15.2586 33.118L24.692 42.5854Z"
            fill={color}
          />
        </>
      ) : (
        <>
          <path
            d="M46.0454 6.00796C49.3333 5.98459 51.9917 3.29422 51.9917 0H3.00283C1.3443 0 0 1.35501 0 3.0138V60.9869C0 62.6457 1.3443 64 3.00283 64H60.9474C62.6059 64 63.9898 62.6457 63.9898 60.9869V18.0544H57.9446V57.9836H6.00565V6.00796H46.0454Z"
            fill={props.error ? '#DE4A3A' : color}
          />
        </>
      )}
    </svg>
  )
}

export default CheckboxIcon
