import { useLazyQuery } from '@apollo/client'
import getVZF from 'graphql/queries/getVZF'
import { Product, ProductCategory, Query, QueryCheckVzfArgs, AddressData, Voucher } from 'graphql/types'
import { ChangeEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, {
  AvailabilityCheckAction,
  AvailabilityCheckField,
} from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import CheckoutActions, { CheckoutAction } from 'store/Checkout/Checkout.actions'
import { CheckoutState } from 'store/Checkout/Checkout.reducer'
import { AppState } from 'store/store'

interface AvailabilityStateReturn {
  addressList: AddressData[]
  additionalInfos: string[]
  cities: string[]
  focusedFieldId: AvailabilityCheckField
  numbers: string[]
  selectedAdditionalInfo: string
  selectedCity: string
  selectedNumber: string
  selectedStreet: string
  setSelectedAdditionalInfo: (payload: string) => void
  setSelectedAddress: () => void
  setSelectedCity: (
    payload: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  setSelectedNumber: (
    payload: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  setSelectedStreet: (
    payload: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  setZip: (payload: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  streets: string[]
  zip: string
  handleCheckVZF: (vzfID: string) => void
  vzfError: boolean
}

export const useAvailabilityCheckState: () => AvailabilityStateReturn = () => {
  const dispatch = useDispatch<Dispatch<AvailabilityCheckActions | CheckoutActions>>()
  const navigate = useNavigate()

  const {
    availabilityCheck: {
      addressList,
      additionalInfos,
      cities,
      numbers,
      streets,
      selectedAdditionalInfo,
      selectedCity,
      selectedNumber,
      selectedStreet,
      zip,
      focusedFieldId,
    },
    checkout,
  } = useSelector((appState: AppState) => {
    return {
      availabilityCheck: appState.availabilityCheck,
      checkout: appState.checkout,
    }
  })

  const setSelectedAdditionalInfo = useCallback(
    (payload: string) => {
      dispatch({ type: AvailabilityCheckAction.SET_ADDITIONAL, payload })
    },
    [dispatch],
  )
  const setSelectedCity = useCallback(
    (
      payload: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>,
    ) => {
      dispatch({
        type: AvailabilityCheckAction.SET_CITY,
        payload: payload.target.value as string,
      })
    },
    [dispatch],
  )
  const setSelectedStreet = useCallback(
    (
      payload: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>,
    ) => {
      dispatch({
        type: AvailabilityCheckAction.SET_STREET,
        payload: payload.target.value as string,
      })
    },
    [dispatch],
  )
  const setZip = useCallback(
    (payload: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch({
        type: AvailabilityCheckAction.SET_ZIP,
        payload: payload.target.value as string,
      })
    },
    [dispatch],
  )
  const setSelectedNumber = useCallback(
    (
      payload: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>,
    ) => {
      dispatch({
        type: AvailabilityCheckAction.SET_NUMBER,
        payload: payload.target.value as string,
      })
    },
    [dispatch],
  )
  const setVZFAddress = useCallback(
    (payload: { zip: string; street: string; city: string; additional: string; number: string }) => {
      dispatch({
        type: AvailabilityCheckAction.SET_VZF_ADDRESS,
        payload,
      })
    },
    [dispatch],
  )
  const setSelectedAddress = useCallback(() => {
    dispatch({ type: AvailabilityCheckAction.SET_SELECTED_ADDRESS })
  }, [dispatch])

  const setCheckoutState = useCallback(
    (payload: Partial<CheckoutState>) => {
      dispatch({ type: CheckoutAction.SET_CHECKOUT_PARTIAL, payload })
    },
    [dispatch],
  )

  const setAppliedVoucher = useCallback(
    (payload: Voucher) => {
      dispatch({ type: CheckoutAction.SET_APPLIED_VOUCHER, payload })
    },
    [dispatch],
  )

  const [vzfError, setVzvError] = useState(false)
  const [requestVZF] = useLazyQuery<Query>(getVZF, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data): void => {
      if (data.checkVZF) {
        const vzfData = data.checkVZF.vzfData
        const p: Product[] = checkout.products.filter((p) => vzfData.products.includes(p.id))
        const isBundle = p.filter((pro) => pro.category === ProductCategory.BUNDLE).length > 0
        setVZFAddress({
          additional: data.checkVZF.vzfData.address.additionalInfo,
          city: data.checkVZF.vzfData.address.city,
          street: data.checkVZF.vzfData.address.street,
          zip: data.checkVZF.vzfData.address.zipcode,
          number: data.checkVZF.vzfData.address.houseNumber,
        })
        if (data.checkVZF.vzfData.voucher) {
          setAppliedVoucher(data.checkVZF.vzfData.voucher)
        }
        // NOTE: im not 100% sure that bundleLegalRequirementAccepted is correct please take a look
        setCheckoutState({
          selectedProduct: p.filter((pro) => pro.category !== ProductCategory.BUNDLE)[0],
          selectedOptions: checkout.productOptions.filter((po) => vzfData.productOptions.includes(po.id)),
          bundleLegalRequirementAccepted: isBundle,
          vzfID: data.checkVZF.vzfID,
        })
        setSelectedAddress()
        navigate('/auschecken')
      } else {
        setVzvError(true)
      }
    },
  })

  const handleCheckVZF = (vzfID: string): void => {
    const vars: QueryCheckVzfArgs = { vzfID: vzfID }
    requestVZF({ variables: vars })
  }

  return {
    addressList,
    additionalInfos,
    cities,
    focusedFieldId,
    numbers,
    selectedAdditionalInfo,
    selectedCity,
    selectedNumber,
    selectedStreet,
    setSelectedAdditionalInfo,
    setSelectedAddress,
    setSelectedCity,
    setSelectedNumber,
    setSelectedStreet,
    setZip,
    streets,
    zip,
    handleCheckVZF,
    vzfError,
  }
}
