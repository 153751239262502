import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@material-ui/core'
import React, { HTMLAttributes } from 'react'
import { getTestID } from 'utils/TestIDs'

export interface DropdownProps extends SelectProps {
  label: string
  options: string[]
}

const Dropdown = (props: DropdownProps): JSX.Element => {
  const { className, disabled, inputRef, label, onChange, options, value, ...rest } = props
  const iconComponent = (e: HTMLAttributes<HTMLImageElement>): JSX.Element => (
    <img
      className={e.className}
      style={{ marginRight: 16, marginTop: 8 }}
      alt={'button chevron'}
      src={'/assets/IconComponent.svg'}
    />
  )

  return (
    <FormControl variant={'filled'} fullWidth={true}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        {...getTestID('dropdown-' + label + '-select')}
        disabled={disabled}
        inputRef={inputRef}
        labelId={label}
        variant={'filled'}
        fullWidth={true}
        IconComponent={iconComponent}
        onChange={onChange}
        value={value}
        className={className}
        MenuProps={{
          PopoverClasses: { paper: 'DropdownPaper' },
        }}
        {...rest}
      >
        {options.map((option, index) => (
          <MenuItem
            style={option.length > 0 ? {} : { minHeight: 40 }}
            {...getTestID('dropdown-' + label + '-select-' + index)}
            key={option + index}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default Dropdown
