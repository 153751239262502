import gql from 'graphql-tag'

export default gql`
  query getAddresses {
    addresses {
      addressAddition
      buildingNr
      city
      houseNumber
      id
      online
      products
      startOfDelivery
      startOfMarketing
      street
      vouchers
      uniqueAddress
      zipCode
    }
  }
`
