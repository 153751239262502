import { Box, Grid, Typography } from '@material-ui/core'
import OptionItem from 'components/OptionItem'
import { ProductOption } from 'graphql/types'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'

export interface ProductOptionSelectorProps {
  onlyShowSelected: boolean
  toggleProductOption: (options: ProductOption) => void
  selectedOptions: ProductOption[]
  options: ProductOption[]
}

const ProductOptionSelector = (props: ProductOptionSelectorProps): JSX.Element | null => {
  const { toggleProductOption, selectedOptions, options, onlyShowSelected } = props

  if (options.length === 0) {
    return null
  }
  return (
    <>
      {/* <Divider variant={'middle'} /> */}
      <Grid item xs={12}>
        <Box width={1} display={'flex'} flexDirection={'column'} style={{ rowGap: '16px' }}>
          {options
            .filter((opt) => (onlyShowSelected ? selectedOptions.map((sOpt) => sOpt.id).includes(opt.id) : true))
            .map((op: ProductOption, index: number): JSX.Element => {
              return (
                <Box key={index + '' + selectedOptions.includes(op)} width={1} {...TestIDs.CHECKOUT_FLATRATE_SELECT}>
                  <OptionItem
                    disable={onlyShowSelected}
                    checkBox={true}
                    onClick={(): void => toggleProductOption(op)}
                    selected={selectedOptions.includes(op)}
                    title={op.orderPage.title ?? ''}
                    subtitle={op.orderPage.subTitle ? <Typography>{op.orderPage.subTitle}</Typography> : undefined}
                    price={op.price.value}
                    variant={'h3'}
                  />
                </Box>
              )
            })}
        </Box>
      </Grid>
    </>
  )
}

export default ProductOptionSelector
