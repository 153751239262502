import { useMutation } from '@apollo/client'
import SaveVZF from 'graphql/mutations/SaveVZF'
import {
  AddressData,
  LegalNotice,
  Mutation,
  MutationSaveVzfArgs,
  OptionAvailability,
  Product,
  ProductCategory,
  ProductOption,
  Voucher,
} from 'graphql/types'
import { useCheckoutReducer } from 'pages/Checkout/useCheckoutReduxReducer'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  AccountData,
  AccountHolder,
  AccountType,
  Address,
  AppartmentData,
  DeliveryAddressData,
  MainData,
  MainTenantData,
  Offer,
  PortabilityNumber,
  PreviousContractor,
} from 'store/Checkout/Checkout.reducer'
import { AppState } from 'store/store'
import { dlFile } from 'utils/dlfile'
import { getBackEndURL } from 'utils/getEnvConfig'

export interface CheckoutState {
  accountData: AccountData
  accountHolder: boolean
  accountHolderData: AccountHolder
  accountType: AccountType
  addressList: AddressData[]
  afterDeliveryDay: Date
  appartmentData: AppartmentData
  bundleLegalRequirementAccepted: boolean
  deliveryAddressData: DeliveryAddressData
  desiredInstallationDate: boolean
  desiredInstallationDateData: string | null
  filesForUploading: File[]
  handleSaveVZF: () => void
  iban: string
  invalidFields: string[]
  isDeliveryDateInTheFuture: boolean
  isLandlineContractCancelled: boolean
  landlineContractCancelDate: string
  landlineContractCancelDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  legalNotices: LegalNotice[]
  mainData: MainData
  mainTenant: boolean
  mainTenantData: MainTenantData
  offers: Offer[]
  onlyShowSelected: boolean
  openOffer: Offer | undefined
  paymentAuthorization: boolean
  portability: boolean
  portabilityNumbers: PortabilityNumber[]
  previousConnectionAddress: boolean
  previousConnectionAddressData: Address
  previousConnectionTermination: boolean
  previousConnectionTerminationData: string
  previousContractor: boolean
  previousContractorData: PreviousContractor[]
  previousProvider: string
  previousProviderDropdownChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  previousProviderDropdownOptions: string[]
  previousProviderDropdownValue: string
  previousProviderTextFieldChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  previousProviderTextFieldValue: string
  productOptions: ProductOption[]
  products: Product[]
  recurringPaymentAuthorization: boolean
  selectedAdditionalInfo: string
  selectedCity: string
  selectedNumber: string
  selectedOptions: ProductOption[]
  selectedProduct: Product | undefined
  selectedStreet: string
  selectedAddress: AddressData | undefined
  setOpenOffer: (payload: Offer | undefined) => void
  toggleOnlyShowSelected: Dispatch<SetStateAction<boolean>>
  isProductAvailable: boolean
  vzfID: string | undefined
  zip: string
  userVoucherCode: string
  appliedVoucher: Voucher | undefined
  userHasPreviousContract: boolean | undefined
}

export const useCheckoutState = (): CheckoutState => {
  const {
    clearInvalidField,
    clearProductOptions,
    setLandlineContractCancelDate,
    setPreviousProvider,
    setSelectedProduct,
    setVZFID,
    toggleProductOption,
  } = useCheckoutReducer()
  const {
    availabilityCheck: {
      addressList,
      selectedAdditionalInfo,
      selectedCity,
      selectedNumber,
      selectedStreet,
      zip,
      selectedAddress,
    },
    checkout: {
      accountData,
      accountHolder,
      accountHolderData,
      accountType,
      appartmentData,
      bundleLegalRequirementAccepted,
      deliveryAddressData,
      desiredInstallationDate,
      desiredInstallationDateData,
      mainTenant,
      mainTenantData,
      iban,
      mainData,
      paymentAuthorization,
      portability,
      portabilityNumbers,
      previousConnectionAddress,
      previousConnectionAddressData,
      previousConnectionTermination,
      previousConnectionTerminationData,
      previousContractor,
      previousContractorData,
      recurringPaymentAuthorization,
      filesForUploading,
      invalidFields,
      offers,
      previousProvider,
      isLandlineContractCancelled,
      landlineContractCancelDate,
      products,
      productOptions,
      selectedProduct,
      legalNotices,
      selectedOptions,
      vzfID,
      userVoucher,
      appliedVoucher,
      userHasPreviousContract,
    },
  } = useSelector((appState: AppState) => {
    return {
      availabilityCheck: appState.availabilityCheck,
      checkout: appState.checkout,
    }
  })

  const [onlyShowSelected, toggleOnlyShowSelected] = useState(false)
  const [openOffer, setOpenOffer] = useState<Offer>()
  const [productsToShow, setProductsToShow] = useState<Product[]>([])
  const [optionsToShow, setOptionsToShow] = useState<ProductOption[]>([])
  const [isDeliveryDateInTheFuture, setIsDeliveryDateInTheFuture] = useState(false)
  const [isProductAvailable, setIsProductAvailable] = useState(true)
  const [afterDeliveryDay, setAfterDeliveryDay] = useState<Date>(new Date())

  const previousProviderDropdownOptions = [
    '',
    '1&1 Versatel Deutschland GmbH',
    '1&1 Telecom GmbH',
    'Telekom Deutschland GmbH',
    'Vodafone GmbH',
    'Vodafone Kabel Deutschland GmbH',
    'Tele Columbus AG (Pyur)',
    'Primacom Berlin GmbH',
    'Telefónica Germany GmbH & Co. OHG',
    'Vodafone NRW GmbH',
    'congstar GmbH',
    'Sonstiger Anbieter',
  ]

  const [previousProviderDropdownValue, setPreviousProviderDropdownValue] = useState<string>(
    previousProviderDropdownOptions.includes(previousProvider)
      ? previousProvider
      : previousProvider === ''
      ? ''
      : previousProviderDropdownOptions[previousProviderDropdownOptions.length - 1],
  )
  const [previousProviderTextFieldValue, setpreviousProviderTextFieldValue] = useState<string>(
    !previousProviderDropdownOptions.includes(previousProvider) ? previousProvider : '',
  )

  const previousProviderDropdownChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ): void => {
    clearInvalidField('previousProvider-0')
    const newPreviousProvider = event.target.value as string
    setPreviousProviderDropdownValue(newPreviousProvider)
    setPreviousProvider(newPreviousProvider)
    setpreviousProviderTextFieldValue('')
  }
  const previousProviderTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    clearInvalidField('previousProvider-1')
    const newPreviousProvider = event.target.value
    setpreviousProviderTextFieldValue(newPreviousProvider)
    setPreviousProvider(newPreviousProvider)
  }
  const landlineContractCancelDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newLandlineContractCancelDate = event.target.value
    setLandlineContractCancelDate(newLandlineContractCancelDate)
  }

  useEffect(() => {
    if (selectedAddress && selectedAddress.products && products.length > 0) {
      const productsToShow = products.filter((p) => selectedAddress.products?.includes(p.id))
      setProductsToShow(productsToShow)
      if (selectedProduct === undefined) {
        clearProductOptions()
      } else {
        const isAvailable = productsToShow.find((p) => p.id === selectedProduct.id) !== undefined
        setIsProductAvailable(isAvailable)
        if (isAvailable === false) {
          setSelectedProduct(undefined)
          clearProductOptions()
        }
      }
    }
  }, [selectedAddress, products, clearProductOptions, selectedProduct, setSelectedProduct])

  useEffect(() => {
    if (selectedProduct !== undefined && selectedProduct.options) {
      const availableOptions = productOptions.filter(
        (po) =>
          selectedProduct.options?.find(
            (opt) => opt.availability === OptionAvailability.AVAILABLE && opt.productOption === po.id,
          ) !== undefined,
      )
      setOptionsToShow(availableOptions)
      for (let i = 0; i < selectedProduct.options.length; i++) {
        const option = selectedProduct.options[i]
        if (option.availability === OptionAvailability.PRESELECTED) {
          const po = productOptions.find((opt) => opt.id === option.productOption)
          if (po && selectedOptions.find((o) => o.id === po.id) === undefined) {
            toggleProductOption(po)
          }
        }
      }
    }
  }, [selectedProduct, setOptionsToShow, productOptions, clearProductOptions, toggleProductOption, selectedOptions])

  useEffect(() => {
    if (selectedAddress && selectedAddress.startOfDelivery) {
      setAfterDeliveryDay(new Date(selectedAddress.startOfDelivery))
      if (new Date(selectedAddress.startOfDelivery).getTime() > new Date().getTime()) {
        setIsDeliveryDateInTheFuture(true)
      }
    }
  }, [selectedAddress])

  const [saveVZF] = useMutation<Mutation>(SaveVZF, {
    onCompleted: (data): void => {
      if (data.saveVZF) {
        setVZFID(data.saveVZF)
        const fileURL = getBackEndURL() + 'vzf/' + data.saveVZF
        dlFile(fileURL, 'VZF_' + data.saveVZF + '.pdf')
      }
    },
  })

  const handleSaveVZF = (): void => {
    if (vzfID === undefined && selectedProduct) {
      const vars: MutationSaveVzfArgs = {
        input: {
          voucherCode: appliedVoucher ? appliedVoucher.code : '',
          products: [selectedProduct.id],
          productOptions: selectedOptions.map((po) => po.id),
          address: {
            zipcode: zip,
            city: selectedCity,
            street: selectedStreet,
            houseNumber: selectedNumber,
            additionalInfo: selectedAdditionalInfo,
          },
        },
      }

      if (bundleLegalRequirementAccepted) {
        const bundleProduct: Product | undefined = products.find((p) => p.category === ProductCategory.BUNDLE)
        if (bundleProduct) {
          vars.input.products.push(bundleProduct.id)
        }
      }
      saveVZF({ variables: vars })
    } else {
      const fileURL = getBackEndURL() + 'vzf/' + vzfID
      dlFile(fileURL, 'VZF_' + vzfID + '.pdf')
    }
  }

  useEffect(() => {
    if (vzfID !== undefined) {
      toggleOnlyShowSelected(true)
    } else {
      toggleOnlyShowSelected(false)
    }
  }, [vzfID])

  return {
    accountData,
    accountHolder,
    accountHolderData,
    accountType,
    addressList,
    afterDeliveryDay,
    appartmentData,
    bundleLegalRequirementAccepted,
    deliveryAddressData,
    desiredInstallationDate,
    desiredInstallationDateData,
    filesForUploading,
    handleSaveVZF,
    iban,
    invalidFields,
    isDeliveryDateInTheFuture,
    isLandlineContractCancelled,
    landlineContractCancelDate,
    landlineContractCancelDateChange,
    legalNotices,
    mainData,
    mainTenant,
    mainTenantData,
    offers,
    onlyShowSelected,
    openOffer,
    paymentAuthorization,
    portability,
    portabilityNumbers,
    previousConnectionAddress,
    previousConnectionAddressData,
    previousConnectionTermination,
    previousConnectionTerminationData,
    previousContractor,
    previousContractorData,
    previousProvider,
    previousProviderDropdownChange,
    previousProviderDropdownOptions,
    previousProviderDropdownValue,
    previousProviderTextFieldChange,
    previousProviderTextFieldValue,
    productOptions: optionsToShow,
    products: productsToShow,
    recurringPaymentAuthorization,
    selectedAdditionalInfo,
    selectedCity,
    selectedNumber,
    selectedOptions,
    selectedProduct,
    selectedStreet,
    setOpenOffer,
    toggleOnlyShowSelected,
    isProductAvailable,
    vzfID,
    zip,
    userVoucherCode: userVoucher,
    userHasPreviousContract,
    selectedAddress,
    appliedVoucher,
  }
}
