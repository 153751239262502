import { ApolloProvider } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { Box, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ErrorDialog from 'components/ErrorDialog'
import Footer from 'components/Footer'
import Header from 'components/Header'
import deLocale from 'date-fns/locale/de'
import { ErrorConfig } from 'graphql/types'
import Addresses from 'pages/Addresses'
import AvailabilityCheck from 'pages/AvailabilityCheck'
import Checkout from 'pages/Checkout'
import Confirmation from 'pages/Confirmation'
import CookieLegals from 'pages/CookieLegals'
import Dataprivacy from 'pages/Dataprivacy'
import ErrorPage from 'pages/ErrorPage'
import Imprint from 'pages/Imprint'
import LandingPage from 'pages/LandingPage'
import Succeeded from 'pages/Succeeded'
import ValidateMail from 'pages/ValidateMail/ValidateMail'
import React from 'react'
import { HashRouter } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'
import { getBackEndURL } from 'utils/getEnvConfig'
import { ModifiedTheme } from 'utils/theme'
import { useApolloClient } from 'utils/useApolloClient'

const App = (): JSX.Element => {
  const graphQLServerURI = getBackEndURL() + 'gql'

  const [error, setError] = React.useState<ErrorConfig>()
  const apolloClient = useApolloClient(graphQLServerURI, setError)
  return (
    <>
      {apolloClient && (
        <ThemeProvider theme={ModifiedTheme}>
          <ApolloProvider client={apolloClient}>
            {error && <ErrorDialog errorConfig={error} />}
            {!error && (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <Box textAlign={'center'} minHeight={'calc(100vh - 225px)'}>
                  <HashRouter>
                    <Header />
                    <Routes>
                      <Route path="/" element={<LandingPage />} />
                      <Route path="/lp/:path" element={<LandingPage />} />
                      <Route path="/verfuegbarkeitspruefung" element={<AvailabilityCheck />} />
                      <Route path="/auschecken" element={<Checkout />} />
                      <Route path="/bestaetigung" element={<Confirmation />} />
                      <Route path="/erfolgreiche-bestellung" element={<Succeeded />} />
                      <Route path="/impressum" element={<Imprint />} />
                      <Route path="/datenschutz" element={<Dataprivacy />} />
                      <Route path="/cookie-richtlinien" element={<CookieLegals />} />
                      <Route path="/404" element={<ErrorPage />} />
                      <Route path="/addresses" element={<Addresses />} />
                      <Route path="/emailValidieren/:id" element={<ValidateMail />} />
                    </Routes>
                    <Footer />
                  </HashRouter>
                </Box>
              </MuiPickersUtilsProvider>
            )}
          </ApolloProvider>
        </ThemeProvider>
      )}
    </>
  )
}

export default App
