import { FetchResult } from '@apollo/client'
import {
  ContractDataInput,
  CustomerData,
  DeliveryAddress,
  DocumentsData,
  DocumentsDataInput,
  HolderData,
  LineNumberPortabilityData,
  MutationSaveOrderArgs,
  ProductCategory,
  SaveOrderInput,
} from 'graphql/types'
import { CheckoutState } from 'pages/Checkout/useCheckoutReducer'
import { AccountType } from 'store/Checkout/Checkout.reducer'

export interface ServerResponse {
  OK: boolean
  Code: number
  Error?: string
}

export default (
  reducerData: CheckoutState,
  saveOrderMutation: (options?: any) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
): void => {
  const {
    selectedAdditionalInfo,
    selectedCity,
    selectedNumber,
    selectedStreet,
    zip,
    accountData,
    accountHolder,
    accountHolderData,
    accountType,
    appartmentData,
    desiredInstallationDate,
    desiredInstallationDateData,
    iban,
    mainData,
    mainTenant,
    mainTenantData,
    filesForUploading,
    selectedProduct,
    selectedOptions,
    vzfID,
    bundleLegalRequirementAccepted,
    products,
    appliedVoucher,
    portability,
    portabilityNumbers,
    previousConnectionAddress,
    previousConnectionAddressData,
    previousContractor,
    previousContractorData,
    previousProvider,
    isLandlineContractCancelled,
    landlineContractCancelDate,
  } = reducerData

  const mt = mainTenant
    ? ({ firstName: mainTenantData.name, lastName: mainTenantData.lastName } as HolderData)
    : undefined

  const actualAccountInfo: Partial<CustomerData> =
    accountType === AccountType.IBAN
      ? {
          iban: iban,
        }
      : {
          bankAccountNumber: accountData.accountNumber,
          bankCode: accountData.bankCode,
        }

  const title: Partial<CustomerData> = mainData.basicData.title ? { title: mainData.basicData.title } : {}
  const addressAddition: Partial<CustomerData> = selectedAdditionalInfo
    ? { addressAddition: selectedAdditionalInfo }
    : {}
  const floor: Partial<CustomerData> = appartmentData.floor ? { floor: appartmentData.floor } : {}
  const flat: Partial<CustomerData> = appartmentData.flat ? { flat: appartmentData.flat } : {}
  const rentalAgreementNumber: Partial<CustomerData> = appartmentData.rentalAgreementNumber
    ? { rentalAgreementNumber: appartmentData.rentalAgreementNumber }
    : {}
  const desiredAppointment: Partial<CustomerData> =
    desiredInstallationDate && desiredInstallationDateData !== null
      ? {
          desiredAppointment: desiredInstallationDateData,
        }
      : {}

  const hasTelephoneFlat =
    selectedOptions.findIndex((option) => {
      return option.name === 'Telefon Flatrate'
    }) !== -1
  const contractDataInput: ContractDataInput = {
    hasTelephoneFlat: hasTelephoneFlat,
  }
  if (previousConnectionAddress) {
    contractDataInput.contractAddress = {
      zipCode: previousConnectionAddressData.zip,
      city: previousConnectionAddressData.city,
      street: previousConnectionAddressData.street,
      houseNumber: previousConnectionAddressData.number,
      addressAddition: previousConnectionAddressData.additionalInfo,
    }
  }
  if (portability) {
    contractDataInput.previousProvider = previousProvider
    contractDataInput.isLandlineContractCancelled = isLandlineContractCancelled
    contractDataInput.landlineContractCancelDate = landlineContractCancelDate
    contractDataInput.lineNumberPortabilitys = portabilityNumbers.map(
      (portabilityNumber): LineNumberPortabilityData => ({
        number: portabilityNumber.number,
        prefix: portabilityNumber.areaCode,
      }),
    )
  }
  if (previousContractor) {
    contractDataInput.contractHolders = previousContractorData.map(
      (contractor): HolderData => ({
        firstName: contractor.name,
        lastName: contractor.lastName,
      }),
    )
  }
  let deliveryAddr: DeliveryAddress | undefined = undefined

  if (reducerData.deliveryAddressData.zip !== '') {
    deliveryAddr = {
      title: reducerData.deliveryAddressData.title,
      salutation: reducerData.deliveryAddressData.salutation,
      firstName: reducerData.deliveryAddressData.firstName,
      lastName: reducerData.deliveryAddressData.lastName,
      city: reducerData.deliveryAddressData.city,
      zipcode: reducerData.deliveryAddressData.zip,
      street: reducerData.deliveryAddressData.street,
      houseNumber: reducerData.deliveryAddressData.number,
      additionalInfo: reducerData.deliveryAddressData.additionalInfo,
    }
  }
  const actualAccountHolder = accountHolder
    ? {
        accountHolder: {
          firstName: accountHolderData.name,
          lastName: accountHolderData.lastName,
        } as HolderData,
      }
    : {}

  const newProduct: string[] = []
  if (selectedProduct) {
    newProduct.push(selectedProduct.id)
  }
  if (bundleLegalRequirementAccepted) {
    products.forEach((p) => {
      if (p.category === ProductCategory.BUNDLE) {
        newProduct.push(p.id)
      }
    })
  }

  const dataToSend: SaveOrderInput = {
    voucher: appliedVoucher ? appliedVoucher.code : '',
    customerData: {
      ...title,
      salutation: mainData.basicData.salutation,
      firstName: mainData.basicData.name,
      lastName: mainData.basicData.lastName,
      dateOfBirth: mainData.date,
      zipCode: zip,
      city: selectedCity,
      street: selectedStreet,
      houseNumber: selectedNumber,
      mainTenant: mt,
      ...addressAddition,
      ...floor,
      ...flat,
      ...rentalAgreementNumber,
      email: mainData.email,
      telephoneNumber: mainData.telephoneNr,
      ...desiredAppointment,
      isAccountHolder: !accountHolder,
      ...actualAccountInfo,
      ...actualAccountHolder,
      deliveryAddress: deliveryAddr ? deliveryAddr : null,
    },
    productData: {
      products: newProduct,
      productOptions: selectedOptions.map((op) => op.id),
    },
    vzfID: vzfID ?? '',
    contractData: contractDataInput,
  }

  if (filesForUploading && filesForUploading.length > 0) {
    const newDocumentsData: DocumentsData[] = []
    const getBase64 = (file: File): void => {
      const reader = new FileReader()
      reader.onload = (): void => {
        const result = reader.result
        if (result) {
          let encoded = result.toString().replace(/^data:(.*,)?/, '')
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4))
          }
          newDocumentsData.push({
            name: file.name,
            data: encoded,
          })
          if (newDocumentsData.length === filesForUploading.length) {
            // actualProductOptionsData.Documents = newDocumentsData
            const docs: DocumentsDataInput[] = []
            for (let i = 0; i < newDocumentsData.length; i++) {
              const doc: DocumentsDataInput = {
                name: newDocumentsData[i].name,
                data: newDocumentsData[i].data,
              }
              docs.push(doc)
            }
            //TODO: FIX disambguity
            if (docs.length > 0) {
              if (dataToSend.contractData) {
                dataToSend.contractData.documents = docs
              } else {
                const dd: ContractDataInput = { hasTelephoneFlat, documents: docs }
                dataToSend.contractData = dd
              }
            }
            const vars: MutationSaveOrderArgs = { input: dataToSend }
            saveOrderMutation({
              variables: vars,
            })
          }
        }
      }
      reader.readAsDataURL(file)
    }
    filesForUploading.map(getBase64)
  } else {
    const vars: MutationSaveOrderArgs = { input: dataToSend }
    saveOrderMutation({
      variables: vars,
    })
  }
}
