const ErrorList = {
  MainFieldsAge: 'Bitte geben Sie ein gültiges Geburtsdatum ein. Sie müssen mindestens 18 Jahre alt sein.',
  MainFieldsName: 'Bitte geben Sie Ihren Vornamen ein.',
  MainFieldsLastName: 'Bitte geben Sie Ihren Nachnamen ein.',
  MainFieldsEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
  AppartmentDataFloor: 'Bitte geben Sie Ihre Etage ein.',
  AppartmentDataFlat: 'Bitte geben Sie die Nummer Ihrer Wohnung ein.',
  DesiredInstallationDate: 'Bitte wählen Sie einen Wunschtermin.',
  PaymentDetailsIban: 'Bitte geben Sie eine gültige IBAN ein.',
  AccountDataNumber: 'Bitte geben Sie eine gültige Kontonummer ein.',
  AccountDataBankCode: 'Bitte geben Sie eine gültige BLZ ein.',
  MainFieldsTelephone: 'Bitte geben Sie eine gültige Telefonnummer ein.',
  MainTenantName: 'Bitte geben Sie den Vornamen des Hauptmieters ein.',
  MainTenantLastName: 'Bitte geben Sie den Nachnamen des Hauptmieters ein.',
  PortabilityAreaCode: 'Bitte geben Sie die Vorwahl ein.',
  PortabilityNumber: 'Bitte geben Sie die Rufnummer ein.',
  AccountHolderName: 'Bitte geben Sie den Vornamen des Kontoinhaber ein.',
  AccountHolderLastName: 'Bitte geben Sie den Nachnamen des Kontoinhaber ein.',
  PreviousContractorName: 'Bitte geben Sie den Vornamen des Anschlussinhabers ein.',
  PreviousContractorLastName: 'Bitte geben Sie den Nachnamen des Anschlussinhabers ein.',
  PreviousContractorAddressZip: 'Bitte geben Sie die PLZ der vorigen Anschlussadresse ein.',
  PreviousContractorAddressCity: 'Bitte geben Sie die Stadt der vorigen Anschlussadresse ein.',
  PreviousContractorAddressStreet: 'Bitte geben Sie die Straße der vorigen Anschlussadresse ein.',
  PreviousContractorAddressNumber: 'Bitte geben Sie die Hausnummer der vorigen Anschlussadresse ein.',
  PreviousProviderDropdown: 'Bitte wählen Sie Ihren vorigen Anbieter.',
  PreviousProviderTextField: 'Bitte geben Sie Ihren vorigen Anbieter ein, wie er auf Ihrer Rechnung erscheint.',
  DeliveryAddressZip: 'Bitte geben Sie die PLZ der Lieferadresse an.',
  DeliveryAddressFirstname: 'Bitte geben Sie den Vornamen der Lieferadresse an.',
  DeliveryAddressLastname: 'Bitte geben Sie den Nachnamen der Lieferadresse an.',
  DeliveryAddressCity: 'Bitte geben Sie die Stadt der Lieferadresse ein.',
  DeliveryAddressStreet: 'Bitte geben Sie die Straße der Lieferadresse ein.',
  DeliveryAddressNumber: 'Bitte geben Sie die Hausnummer der Lieferadresse ein.',
}

export default ErrorList
