import { Grid, Typography } from '@material-ui/core'
import { isEqual } from 'lodash'
import * as React from 'react'
import { MainTenantData } from 'store/Checkout/Checkout.reducer'
import TestIDs from 'utils/TestIDs'
import { OptionItemRenderer, TextFieldRenderer } from '.'
import ErrorList from '../../utils/ErrorList'

export interface MainTenantFieldsProps {
  mainTenant: boolean
  mainTenantData: MainTenantData
  toggleMainTenant: (payload: boolean) => void
  editMainTenantData: (payload: MainTenantData) => void
  invalidFields: string[]
  clearInvalidField: (field: string) => void
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
}

const MainTenantFields = (props: MainTenantFieldsProps): JSX.Element => {
  const {
    mainTenant,
    mainTenantData,
    editMainTenantData,
    toggleMainTenant,
    clearInvalidField,
    invalidFields,
    focusedField,
    setFocusedField,
  } = props

  const combineAndEditMainTenantData = React.useCallback(
    // eslint-disable-next-line
    (field: string, index: number, value: any, errorField: string) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        clearInvalidField(errorField)
      }
      switch (field) {
        case 'Name':
          editMainTenantData({
            ...mainTenantData,
            name: value,
          })
          break
        case 'LastName':
          editMainTenantData({
            ...mainTenantData,
            lastName: value,
          })
          break
      }
    },
    [editMainTenantData, mainTenantData, invalidFields, clearInvalidField],
  )

  return (
    <Grid id={'Hauptmieter-der-Wohnung'} container item spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography className={'centerWhenSmall'} {...TestIDs.MAIN_TENANT_HEADER} variant={'h4'}>
            Hauptmieter der Wohnung
          </Typography>
        </Grid>
        <OptionItemRenderer
          editOptionItem={toggleMainTenant}
          selectedValue={mainTenant}
          values={[
            {
              selectionValue: false,
              title: 'Ich selbst',
            },
            {
              selectionValue: true,
              title: 'Andere Person',
            },
          ]}
        />
      </Grid>
      {mainTenant && (
        <Grid container item spacing={2}>
          <TextFieldRenderer
            setFocusedField={setFocusedField}
            combineAndEdit={combineAndEditMainTenantData}
            index={0}
            values={[
              {
                columnValue: 6,
                mobileColumnValue: 12,
                editField: 'Name',
                label: 'Vorname *',
                value: mainTenantData.name,
                errorField: 'mainTenant-name',
                tooltipTitle: ErrorList.MainTenantName,
              },
              {
                columnValue: 6,
                mobileColumnValue: 12,
                editField: 'LastName',
                label: 'Nachname *',
                value: mainTenantData.lastName,
                errorField: 'mainTenant-lastName',
                tooltipTitle: ErrorList.MainTenantLastName,
              },
            ]}
            focusedField={focusedField}
            invalidFields={invalidFields}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(MainTenantFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
