import { makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import BasketIcon from 'components/BasketIcon'
import { LegalNotice, Product, ProductOption, Voucher, VoucherDiscountType } from 'graphql/types'
import * as React from 'react'
import {
  calcNormalSumBasket,
  calcSumBasket,
  calcSumBundleProduct,
  calcSumVoucher,
  priseTofixed,
  SumBasketReturn,
} from 'utils/checkoutFunctions'
import TestIDs from 'utils/TestIDs'
import { smallMobile } from 'utils/theme'

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    [theme.breakpoints.down('xs')]: {
      right: 0,
    },
  },
  inActive: {
    [theme.breakpoints.down('xs')]: {
      right: '-320px',
    },
    [theme.breakpoints.down(smallMobile)]: {
      right: -269,
    },
  },
  tabActive: {
    [theme.breakpoints.down('xs')]: {
      right: 320,
    },
    [theme.breakpoints.down(smallMobile)]: {
      right: 269,
    },
  },
  tabInActive: {
    [theme.breakpoints.down('xs')]: {
      right: 0,
    },
  },
  basket: {
    borderRadius: '8px',
    background: '#DDE2E5',
    padding: '20px',
    zIndex: 1000,
  },
  basketMobile: {
    [theme.breakpoints.down('xs')]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      width: 280,
      boxShadow: '0 0 12px #00000040',
      position: 'fixed',
      top: window.innerHeight > 670 ? 144 : 0,
      transition: 'right 250ms;',
    },
    [theme.breakpoints.down(smallMobile)]: {
      width: 230,
    },
  },
  basketTitle: {
    [theme.breakpoints.down(smallMobile)]: {
      marginBottom: 10,
    },
  },
  item: {
    display: 'grid',
    // gridTemplateColumns: '1fr 100px',
    marginTop: '30px',
    [theme.breakpoints.down(smallMobile)]: {
      marginTop: 0,
    },
  },
  noMargin: {
    marginTop: 0,
  },
  tab: {
    backgroundColor: '#DDE2E5',
    borderBottomLeftRadius: '8px',
    borderTopLeftRadius: '8px',
    display: 'none',
    height: 70,
    width: 70,
    zIndex: 3,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: window.innerHeight > 670 ? 144 : 0,
    transition: 'right 250ms;',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      boxShadow: '0 0 12px #00000040',
    },
    [theme.breakpoints.down(smallMobile)]: {
      height: 70 * 0.8,
      width: 70 * 0.8,
    },
  },
  title: {
    gridArea: '1/1',
  },
  price: {
    gridArea: '1/2',
    textAlign: 'right',
  },
  divider: {
    border: 0,
    height: '1px',
    marginTop: '30px',
    backgroundColor: '#878787',
  },
}))

export interface BasketProps {
  legalNotices: LegalNotice[]
  mobileFixed: boolean
  selectedOptions: ProductOption[]
  selectedProduct: Product | undefined
  vzfID: string | undefined
  bundleProduct: Product | undefined
  appliedVouchers: Voucher[]
}

const Basket = (props: BasketProps): JSX.Element => {
  const classes = useStyles()
  const { mobileFixed, selectedProduct, legalNotices, selectedOptions, vzfID, bundleProduct, appliedVouchers } = props
  const { monthlySum, oneTimeSum } = calcNormalSumBasket(selectedOptions, selectedProduct)

  const [isBasketOpen, setIsBasketOpen] = React.useState(false)

  const renderProduct = (product: Product | undefined): JSX.Element | null => {
    if (!product || product.basket.displayed === false) return null

    const legalNotice = legalNotices.find((ln) => ln.id === product.basket.legalNotice)

    return (
      <div className={classes.item} {...TestIDs.BASKET_PRODUCT}>
        {(product.basket.title || product.basket.subTitle) && (
          <div className={classes.title}>
            {product.basket.title && <Typography variant={'h5'}>{product.basket.title}</Typography>}
            {product.basket.subTitle && <Typography variant={'subtitle1'}>{product.basket.subTitle}</Typography>}
          </div>
        )}
        <div className={classes.price}>
          <Typography component={'p'} variant={'h5'} align={'right'}>
            {priseTofixed(product.price.value)} &euro;{' '}
            {legalNotice ? (
              <sup>
                <Typography component={'span'}>{legalNotice.renderIndex})</Typography>
              </sup>
            ) : null}
          </Typography>
        </div>
      </div>
    )
  }

  const renderOptions = (productOptions: ProductOption[]): JSX.Element | null => {
    if (productOptions.length === 0) return null

    const renderOption = (option: ProductOption, index: number): JSX.Element | null => {
      const legalNotice = legalNotices.find((ln) => ln.id === option.basket.legalNotice)
      if (option.basket.displayed === false) return null

      return (
        <div key={index} className={classes.item} {...TestIDs.BASKET_OPTION}>
          {(option.basket.title || option.basket.subTitle) && (
            <div className={classes.title}>
              {option.basket.title && <Typography variant={'h5'}>{option.basket.title}</Typography>}
              {option.basket.subTitle && <Typography variant="subtitle1">{option.basket.subTitle}</Typography>}
            </div>
          )}
          <div className={classes.price}>
            <Typography component={'p'} variant={'h5'} align={'right'}>
              {option.price.value === 0 ? 'inkl' : priseTofixed(option.price.value) + ' €'}{' '}
              {legalNotice ? (
                <sup>
                  <Typography component={'span'}>{legalNotice.renderIndex})</Typography>
                </sup>
              ) : null}
            </Typography>
          </div>
        </div>
      )
    }

    return <>{productOptions.map(renderOption)}</>
  }

  const renderFreeOption = (productOptions: ProductOption[]): JSX.Element | null => {
    if (
      productOptions.length === 0 ||
      productOptions.find((o) => o.price.value === 0) === undefined ||
      selectedProduct === undefined
    )
      return null
    const freeOptions = productOptions.filter(
      (o) => o.price.value === 0 && selectedOptions.find((so) => so.id === o.id) !== undefined,
    )
    return renderOptions(freeOptions)
  }

  const renderVouchers = (voucherList: Voucher[]): JSX.Element | null => {
    if (voucherList.length === 0) return null

    const renderVoucher = (voucher: Voucher, index: number): JSX.Element => {
      const legalNotice = legalNotices.find((ln) => ln.id === voucher.legalNotice)
      const sum = calcSumVoucher(voucher, selectedOptions, selectedProduct)

      return (
        <div key={index} className={classes.item} {...TestIDs.BASKET_OPTION}>
          <div className={classes.title}>
            {voucher.basketTitle && <Typography variant={'h5'}>{voucher.basketTitle}</Typography>}
            {voucher.basketSubtitle && <Typography variant="subtitle1">{voucher.basketSubtitle}</Typography>}
          </div>
          <div className={classes.price}>
            <Typography component={'p'} variant={'h5'} align={'right'}>
              {'-' +
                priseTofixed(sum) +
                ' €' +
                (voucher.discountType === VoucherDiscountType.MONTHLY ? ' mtl.' : ' einm.')}{' '}
              {legalNotice ? (
                <sup>
                  <Typography component={'span'}>{legalNotice.renderIndex})</Typography>
                </sup>
              ) : null}
            </Typography>
          </div>
        </div>
      )
    }
    return <>{voucherList.map(renderVoucher)}</>
  }

  const renderBundleProduct = (bundleProduct: Product | undefined): JSX.Element | null => {
    if (bundleProduct === undefined) return null
    const basketConfig = bundleProduct.basket
    const bundleDependencies = basketConfig.dependentOn
    if (bundleDependencies === undefined || bundleDependencies === null) {
      return null
    }

    const legalNotice = legalNotices.find((ln) => ln.id === basketConfig.legalNotice)
    const sum = calcSumBundleProduct(bundleProduct, selectedOptions, selectedProduct)
    if (sum === 0) {
      return null
    }

    return (
      <div className={classes.item} {...TestIDs.BASKET_SUM_MONTHLY}>
        <div className={classes.title}>
          {basketConfig.title && <Typography variant={'h5'}>{basketConfig.title}</Typography>}
          {basketConfig.subTitle && <Typography variant="subtitle1">{basketConfig.subTitle}</Typography>}
        </div>
        <div className={classes.price}>
          <Typography component={'p'} variant={'h5'} align={'right'}>
            {'- ' + priseTofixed(sum) + ' € einm.'}{' '}
            {legalNotice ? (
              <sup>
                <Typography component={'span'}>{legalNotice.renderIndex})</Typography>
              </sup>
            ) : null}
          </Typography>
        </div>
      </div>
    )
  }

  const toggleBasket = (): void => {
    setIsBasketOpen(!isBasketOpen)
  }

  const tabStyles = [classes.tab]
  const basketStyles = [classes.basket]
  if (mobileFixed) {
    basketStyles.push(classes.basketMobile)
    basketStyles.push(isBasketOpen ? classes.active : classes.inActive)
    tabStyles.push(isBasketOpen ? classes.tabActive : classes.tabInActive)
  }
  const tabIconSize = window.innerWidth >= smallMobile ? 50 : 40

  const renderSumMonthly = (): JSX.Element => {
    const renderObject: SumBasketReturn[] = calcSumBasket(monthlySum, appliedVouchers, selectedOptions, selectedProduct)
    return (
      <div className={clsx([classes.item, classes.noMargin])} {...TestIDs.BASKET_SUM_MONTHLY}>
        <div className={classes.title}>
          <Typography component="h3">{'Monatliche Kosten ' + renderObject[0].text}</Typography>
          {renderObject.length > 1 &&
            renderObject.map((o, index) =>
              index !== 0 ? (
                <Typography key={index} variant="subtitle1">
                  {'Monatliche Kosten ' + o.text}
                </Typography>
              ) : null,
            )}
        </div>
        <div className={classes.price} {...TestIDs.BASKET_PRICE_MONTHLY}>
          <Typography component="p" align={'right'}>
            {priseTofixed(renderObject[0].value)} &euro;
          </Typography>
          {renderObject.length > 1 &&
            renderObject.map((o, index) =>
              index !== 0 ? (
                <Typography key={index} component="p" align={'right'} variant="subtitle1">
                  {priseTofixed(o.value)} &euro;
                </Typography>
              ) : null,
            )}
        </div>
      </div>
    )
  }

  return (
    <div>
      {mobileFixed && (
        <span className={clsx([...tabStyles])} onClick={toggleBasket}>
          <BasketIcon width={tabIconSize} height={tabIconSize} />
        </span>
      )}
      <div className={clsx([...basketStyles])} {...TestIDs.BASKET_CONTAINER}>
        {vzfID && (
          <div className={clsx([classes.item, classes.noMargin])}>
            <div className={classes.price} {...TestIDs.BASKET_PRICE_ONCE}>
              <Typography component="p" align={'right'}>
                {vzfID}
              </Typography>
            </div>
          </div>
        )}
        <Typography className={classes.basketTitle} component="h4" variant="h4" {...TestIDs.BASKET_HEADER_TYPOGRAPHY}>
          Ihre Bestellung
        </Typography>
        {renderProduct(selectedProduct)}
        {renderOptions(selectedOptions.filter((opt) => opt.price.value !== 0))}
        {renderFreeOption(selectedOptions)}
        {renderVouchers(appliedVouchers)}
        {renderBundleProduct(bundleProduct)}
        <hr className={classes.divider} />
        {renderSumMonthly()}
        <div className={clsx([classes.item, classes.noMargin])} {...TestIDs.BASKET_SUM_ONCE}>
          <div className={classes.title}>
            <Typography component="p">Einmalige Kosten</Typography>
          </div>
          <div className={classes.price} {...TestIDs.BASKET_PRICE_ONCE}>
            <Typography component="p" align={'right'}>
              {priseTofixed(oneTimeSum)} &euro;
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Basket
