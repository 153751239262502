import * as React from 'react'

const ChevronRightIcon = (): JSX.Element => {
  return (
    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 14.0003C16 14.4908 15.8117 14.9821 15.4352 15.3567L3.29094 27.4386C2.53854 28.1871 1.31794 28.1871 0.564831 27.4386C-0.188277 26.6886 -0.188277 25.475 0.564831 24.7258L12.7091 12.6439C13.4622 11.8953 14.6828 11.8953 15.4352 12.6439C15.8117 13.0185 16 13.509 16 14.0003Z"
        fill="white"
      />
      <path
        d="M0.564831 0.561933C-0.188277 1.31118 -0.188277 2.52552 0.564831 3.27406L6.83695 9.51399C8.34529 10.9785 10.7609 10.9687 12.2551 9.48147L12.2565 9.48077L3.29094 0.561933C2.53854 -0.187311 1.31794 -0.187311 0.564831 0.561933Z"
        fill="white"
      />
    </svg>
  )
}

export default ChevronRightIcon
