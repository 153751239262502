import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql/error/GraphQLError'
import { ErrorConfig } from 'graphql/types'
import * as React from 'react'
import { useEffect } from 'react'

export const useApolloClient = (
  graphQLServerURI: string,
  setError: (error: ErrorConfig) => void,
): ApolloClient<unknown> | undefined => {
  const [apolloClient, setApolloClient] = React.useState<ApolloClient<unknown>>()

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      let auxErr: string[] = []
      auxErr = graphQLErrors.map(({ message }: GraphQLError): string => {
        return message
      })
      setError({
        title: 'Datenbank Fehler',
        content: 'Es ist ein Fehler aufgetreten. Message: ',
        messages: auxErr,
      })
    } else if (networkError) {
      setError({
        title: 'Netzwerk Fehler',
        content: 'Es ist ein Problem mit aufgetreten. Bitte überprüfen Sie ihre Netzwerk: ',
        messages: [networkError.message],
      })
    }
  })
  const httpLink = new HttpLink({ uri: graphQLServerURI })

  useEffect(() => {
    if (!apolloClient) {
      setApolloClient(
        new ApolloClient({
          cache: new InMemoryCache(),
          link: from([errorLink, httpLink]),
        }),
      )
    }
  }, [setApolloClient, errorLink, httpLink, apolloClient])

  return apolloClient
}
