import { Box, Grid, IconButton, Link, makeStyles, Popover, Typography } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import HelpIcon from 'components/HelpIcon'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'

const breakpoints = createBreakpoints({})
const useStyles = makeStyles(() => ({
  helpIcon: {
    position: 'absolute',
    top: '12px',
    right: '23px',
  },
  helpPopupContainer: {
    marginTop: '20px',
  },
  popupBox: {
    [breakpoints.down('xs')]: {
      width: '295px',
      padding: '19px',
    },
  },
}))

const HelpPopup = (): JSX.Element => {
  const classes = useStyles()

  return (
    <PopupState variant="popover" popupId="vonovia-popup-popover">
      {(popupState): JSX.Element => (
        <div>
          <IconButton color={'inherit'} {...bindTrigger(popupState)}>
            <HelpIcon color="white" />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box p={3} {...TestIDs.PAGE_HEADER_HELP_POPUP} className={classes.popupBox}>
              <Grid container>
                <Grid item xs={10} sm={11}>
                  <Typography className={'helpPopover'}>Benötigen Sie Hilfe bei der Bestellung?</Typography>
                </Grid>
                <Grid item xs={2} sm={1} className={classes.helpIcon} onClick={popupState.toggle}>
                  <HelpIcon color="#6AAF23" />
                </Grid>
              </Grid>
              <Grid container className={classes.helpPopupContainer}>
                <Grid item xs={3}>
                  <Typography>Hotline: </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link href={'tel:080028221016'}>0800 2822-1016</Link>
                </Grid>
                <Grid item xs={3}>
                  <Typography>E-Mail: </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link href={'mailto:internet@vonovia.de'}>internet@vonovia.de</Link>
                </Grid>
              </Grid>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

export default HelpPopup
