import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import clsx from 'clsx'
import EditIcon from 'components/EditIcon'
import * as React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import TestIDs from 'utils/TestIDs'

const breakpoints = createBreakpoints({})
const useStyles = makeStyles(() => ({
  basket: {
    borderRadius: '8px',
    background: '#F6F6F6',
    padding: '20px',

    '& table': {
      width: 'auto',
    },

    '& td': {
      padding: '0 20px 0 0',
      border: 0,

      '& p': {
        lineHeight: '30px',
      },

      [breakpoints.down('xs')]: {
        display: 'block',
        width: '100%',
      },
    },

    '& tr': {
      [breakpoints.down('xs')]: {
        paddingBottom: '1em',
        display: 'block',
      },
    },
  },
  icon: {
    [breakpoints.down('xs')]: {
      '& svg': {
        width: '30px',
      },
    },
  },
  headline: {
    paddingBottom: '20px',
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    alignItems: 'center',
  },

  cellKey: {
    width: 'auto',
    minWidth: '165px',
    color: '#878787',
    fontWeight: 'bold',
  },
}))

export interface DataTableItems {
  [key: string]: string | string[] | JSX.Element | null
}

export interface DataTableProps {
  title: string
  items: DataTableItems
  anchor: string
  marginBottom?: number
}

const DataTable = (props: DataTableProps): JSX.Element => {
  const classes = useStyles()

  const renderValue = (value: string | string[] | JSX.Element | null): JSX.Element | JSX.Element[] | null => {
    if (value === null) {
      return null
    }

    return typeof value === 'string' ? (
      <Typography {...TestIDs.DATA_TABLE_VALUE} className={'TextNormal'}>
        {value}
      </Typography>
    ) : Array.isArray(value) ? (
      value.map((data) => (
        <Typography key={data} {...TestIDs.DATA_TABLE_VALUE} className={'TextNormal'}>
          {data}
        </Typography>
      ))
    ) : (
      value
    )
  }
  return (
    <div className={classes.basket}>
      <div className={classes.headline}>
        <Typography {...TestIDs.DATA_TABLE_HEADER} component="h4" variant="h4">
          {props.title}
        </Typography>
        <div className={classes.icon}>
          <Link smooth={true} to={'/auschecken#' + props.anchor}>
            <EditIcon />
          </Link>
        </div>
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            {Object.entries(props.items)
              .filter(([, value]) => {
                return value === null || (Array.isArray(value) && value.length === 0) ? false : true
              })
              .map(([key, value], index: number) => (
                <TableRow key={index}>
                  <TableCell scope="row" className={classes.cellKey}>
                    <Box
                      marginBottom={{
                        xs: 0,
                        sm: props.marginBottom ? props.marginBottom : 0,
                        md: 0,
                      }}
                    >
                      <Typography {...TestIDs.DATA_TABLE_KEY} className={clsx('TextNormal', 'TextBold')}>
                        {key}
                        {typeof value === 'string' && value.length === 0 ? '' : ':'}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      marginBottom={{
                        xs: 0,
                        sm: props.marginBottom ? props.marginBottom : 0,
                        md: 0,
                      }}
                    >
                      {renderValue(value)}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default DataTable
