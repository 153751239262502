export interface EnvConfig {
  HTTPListen: string
  Domain: string
  KeycloakURL: string
  KeycloakRealm: string
  KeycloakClientID: string
}

export default function getEnvConfig(): EnvConfig {
  // @ts-expect-error: __envConfig came from backend
  const envConfig: EnvConfig = __envConfig
  return envConfig
}

export function getBackEndURL(): string {
  const envConfig: EnvConfig = getEnvConfig()
  return process.env.NODE_ENV !== 'production' ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/` : '/'
}
