import { AddressData } from 'graphql/types'
import { validateAddressData } from 'utils/checkoutFunctions'
import AvailabilityCheckActions, { AvailabilityCheckAction, AvailabilityCheckField } from './AvailabilityCheck.actions'

export interface AvailabilityCheckState {
  addressList: AddressData[]
  additionalInfos: string[]
  cities: string[]
  loading: boolean
  numbers: string[]
  streets: string[]
  selectedAdditionalInfo: string
  selectedAddress: AddressData | undefined
  selectedCity: string
  focusedFieldId: AvailabilityCheckField
  selectedNumber: string
  selectedStreet: string
  zip: string
}

export const initialAvailabilityCheckState: AvailabilityCheckState = {
  addressList: [],
  additionalInfos: [],
  cities: [],
  loading: false,
  numbers: [],
  streets: [],
  selectedAdditionalInfo: '',
  selectedAddress: undefined,
  selectedCity: '',
  selectedNumber: '',
  selectedStreet: '',
  zip: '',
  focusedFieldId: AvailabilityCheckField.NONE,
}

// TODO: there is a quest for a shorter version of this. ARE YOU UP FOR THE CHALLENGE??? BOUNTY: 500XP
function AvailabilityCheckReducer(
  state: AvailabilityCheckState = initialAvailabilityCheckState,
  action: AvailabilityCheckActions,
): AvailabilityCheckState {
  switch (action.type) {
    case AvailabilityCheckAction.SET_TO_INIT_STATE:
      return {
        ...initialAvailabilityCheckState,
      }
    case AvailabilityCheckAction.SET_TO_INIT_STATE_EXCEPT_QUERY:
      return {
        ...initialAvailabilityCheckState,
        addressList: state.addressList,
      }
    case AvailabilityCheckAction.SET_VZF_ADDRESS:
      return {
        ...state,
        selectedAdditionalInfo: action.payload.additional,
        additionalInfos: [action.payload.additional],
        selectedCity: action.payload.city,
        cities: [action.payload.city],
        selectedNumber: action.payload.number,
        numbers: [action.payload.number],
        selectedStreet: action.payload.street,
        streets: [action.payload.street],
        zip: action.payload.zip,
      }
    case AvailabilityCheckAction.SET_ADDRESS_LIST:
      return {
        ...state,
        addressList: action.payload,
      }
    case AvailabilityCheckAction.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case AvailabilityCheckAction.SET_FOCUSED_FIELD:
      return {
        ...state,
        focusedFieldId: action.payload,
      }
    case AvailabilityCheckAction.SET_ADDITIONAL:
      return {
        ...state,
        selectedAdditionalInfo: action.payload,
        focusedFieldId: AvailabilityCheckField.SUBMIT,
      }
    case AvailabilityCheckAction.SET_ZIP:
      // Correct number of digits
      if (action.payload.length === 5) {
        const possibleCities: string[] = []
        state.addressList.forEach((record) => {
          if (validateAddressData(record) && record.zipCode === action.payload) {
            if (!possibleCities.includes(record.city)) {
              possibleCities.push(record.city)
            }
          }
        })
        // No cities
        if (possibleCities.length === 0) {
          return {
            ...state,
            additionalInfos: [],
            cities: possibleCities,
            focusedFieldId: AvailabilityCheckField.ZIP,
            numbers: [],
            selectedAdditionalInfo: '',
            selectedCity: '',
            selectedNumber: '',
            selectedStreet: '',
            zip: action.payload,
            selectedAddress: undefined,
          }
        }
        // Only one city
        if (possibleCities.length === 1) {
          const possibleStreets: string[] = []
          state.addressList.forEach((record) => {
            if (validateAddressData(record) && record.zipCode === action.payload) {
              if (record.city === possibleCities[0]) {
                if (!possibleStreets.includes(record.street)) {
                  possibleStreets.push(record.street)
                }
              }
            }
          })
          // Only one street in that city
          if (possibleStreets.length === 1) {
            const possibleNumbers: string[] = []
            state.addressList.forEach((record) => {
              if (validateAddressData(record) && record.zipCode === action.payload) {
                if (record.city === possibleCities[0]) {
                  if (record.street === possibleStreets[0]) {
                    if (!possibleNumbers.includes(record.houseNumber)) {
                      possibleNumbers.push(record.houseNumber)
                    }
                  }
                }
              }
            })
            // Only one number in that street
            if (possibleNumbers.length === 1) {
              const possibleAdditionalInfos: string[] = []
              state.addressList.forEach((record) => {
                if (validateAddressData(record) && record.zipCode === action.payload) {
                  if (record.city === possibleCities[0]) {
                    if (record.street === possibleStreets[0]) {
                      if (record.houseNumber === possibleNumbers[0]) {
                        if (
                          record.addressAddition !== undefined &&
                          record.addressAddition !== null &&
                          !possibleAdditionalInfos.includes(record.addressAddition)
                        ) {
                          possibleAdditionalInfos.push(record.addressAddition)
                        }
                      }
                    }
                  }
                }
              })
              // No additional infos in that number
              if (possibleAdditionalInfos.length === 0) {
                return {
                  ...state,
                  additionalInfos: [],
                  cities: possibleCities,
                  focusedFieldId: AvailabilityCheckField.SUBMIT,
                  numbers: possibleNumbers,
                  selectedAdditionalInfo: '',
                  selectedCity: possibleCities[0],
                  selectedNumber: possibleNumbers[0],
                  selectedStreet: possibleStreets[0],
                  streets: possibleStreets,
                  zip: action.payload,
                  selectedAddress: undefined,
                }
              }
              // One additional info in that number
              if (possibleAdditionalInfos.length === 1) {
                return {
                  ...state,
                  additionalInfos: possibleAdditionalInfos,
                  cities: possibleCities,
                  focusedFieldId: AvailabilityCheckField.SUBMIT,
                  numbers: possibleNumbers,
                  selectedAdditionalInfo: possibleAdditionalInfos[0],
                  selectedCity: possibleCities[0],
                  selectedNumber: possibleNumbers[0],
                  selectedStreet: possibleStreets[0],
                  streets: possibleStreets,
                  zip: action.payload,
                  selectedAddress: undefined,
                }
              }
              // More than one additional info in that number
              // One of them is empty
              for (let i = 0; i < possibleAdditionalInfos.length; i++) {
                const additionalInfo = possibleAdditionalInfos[i]
                if (additionalInfo.length === 0) {
                  return {
                    ...state,
                    additionalInfos: possibleAdditionalInfos,
                    cities: possibleCities,
                    focusedFieldId: AvailabilityCheckField.SUBMIT,
                    numbers: possibleNumbers,
                    selectedAdditionalInfo: additionalInfo,
                    selectedCity: possibleCities[0],
                    selectedNumber: possibleNumbers[0],
                    selectedStreet: possibleStreets[0],
                    streets: possibleStreets,
                    zip: action.payload,
                    selectedAddress: undefined,
                  }
                }
              }
              return {
                ...state,
                additionalInfos: possibleAdditionalInfos,
                cities: possibleCities,
                focusedFieldId: AvailabilityCheckField.ADDITIONAL,
                numbers: possibleNumbers,
                selectedAdditionalInfo: '',
                selectedCity: possibleCities[0],
                selectedNumber: possibleNumbers[0],
                selectedStreet: possibleStreets[0],
                streets: possibleStreets,
                zip: action.payload,
                selectedAddress: undefined,
              }
            }
            // More than one number in that street
            return {
              ...state,
              additionalInfos: [],
              cities: possibleCities,
              focusedFieldId: AvailabilityCheckField.NUMBER,
              numbers: possibleNumbers,
              selectedAdditionalInfo: '',
              selectedCity: possibleCities[0],
              selectedNumber: '',
              selectedStreet: possibleStreets[0],
              streets: possibleStreets,
              zip: action.payload,
              selectedAddress: undefined,
            }
          }
          // More than one street in that city
          return {
            ...state,
            additionalInfos: [],
            cities: possibleCities,
            focusedFieldId: AvailabilityCheckField.STREET,
            numbers: [],
            selectedAdditionalInfo: '',
            selectedCity: possibleCities[0],
            selectedNumber: '',
            selectedStreet: '',
            streets: possibleStreets,
            zip: action.payload,
            selectedAddress: undefined,
          }
        }
        // More than one city
        return {
          ...state,
          additionalInfos: [],
          cities: possibleCities,
          focusedFieldId: AvailabilityCheckField.CITY,
          numbers: [],
          selectedAdditionalInfo: '',
          selectedCity: '',
          selectedNumber: '',
          selectedStreet: '',
          zip: action.payload,
          streets: [],
          selectedAddress: undefined,
        }
      }
      // Incorrect number of digits
      return {
        ...state,
        additionalInfos: [],
        focusedFieldId: AvailabilityCheckField.ZIP,
        cities: [],
        numbers: [],
        streets: [],
        selectedAdditionalInfo: '',
        selectedCity: '',
        selectedNumber: '',
        selectedStreet: '',
        zip: action.payload,
        selectedAddress: undefined,
      }
    case AvailabilityCheckAction.SET_CITY:
      if (action.payload !== '') {
        const possibleStreets: string[] = []
        const possibleCities: string[] = state.cities
        state.addressList.forEach((record) => {
          if (validateAddressData(record) && record.zipCode === state.zip) {
            if (record.city === action.payload) {
              if (!possibleStreets.includes(record.street)) {
                possibleStreets.push(record.street)
              }
            }
          }
        })
        // Only one street in that city
        if (possibleStreets.length === 1) {
          const possibleNumbers: string[] = []
          state.addressList.forEach((record) => {
            if (validateAddressData(record) && record.zipCode === state.zip) {
              if (record.city === action.payload) {
                if (record.street === possibleStreets[0]) {
                  if (!possibleNumbers.includes(record.houseNumber)) {
                    possibleNumbers.push(record.houseNumber)
                  }
                }
              }
            }
          })
          // Only one number in that street
          if (possibleNumbers.length === 1) {
            const possibleAdditionalInfos: string[] = []
            state.addressList.forEach((record) => {
              if (validateAddressData(record) && record.zipCode === state.zip) {
                if (record.city === action.payload) {
                  if (record.street === possibleStreets[0]) {
                    if (record.houseNumber === possibleNumbers[0]) {
                      if (
                        record.addressAddition !== undefined &&
                        record.addressAddition !== null &&
                        !possibleAdditionalInfos.includes(record.addressAddition)
                      ) {
                        possibleAdditionalInfos.push(record.addressAddition)
                      }
                    }
                  }
                }
              }
            })
            // No additional infos in that number
            if (possibleAdditionalInfos.length === 0) {
              return {
                ...state,
                additionalInfos: [],
                cities: possibleCities,
                focusedFieldId: AvailabilityCheckField.SUBMIT,
                numbers: possibleNumbers,
                selectedAdditionalInfo: '',
                selectedCity: action.payload,
                selectedNumber: possibleNumbers[0],
                selectedStreet: possibleStreets[0],
                streets: possibleStreets,
                selectedAddress: undefined,
              }
            }
            // One additional info in that number
            if (possibleAdditionalInfos.length === 1) {
              return {
                ...state,
                additionalInfos: possibleAdditionalInfos,
                cities: possibleCities,
                focusedFieldId: AvailabilityCheckField.SUBMIT,
                numbers: possibleNumbers,
                selectedAdditionalInfo: possibleAdditionalInfos[0],
                selectedCity: action.payload,
                selectedNumber: possibleNumbers[0],
                selectedStreet: possibleStreets[0],
                streets: possibleStreets,
                selectedAddress: undefined,
              }
            }
            // More than one additional info in that number
            // One of them is empty
            for (let i = 0; i < possibleAdditionalInfos.length; i++) {
              const additionalInfo = possibleAdditionalInfos[i]
              if (additionalInfo.length === 0) {
                return {
                  ...state,
                  additionalInfos: possibleAdditionalInfos,
                  cities: possibleCities,
                  focusedFieldId: AvailabilityCheckField.SUBMIT,
                  numbers: possibleNumbers,
                  selectedAdditionalInfo: additionalInfo,
                  selectedCity: action.payload,
                  selectedNumber: possibleNumbers[0],
                  selectedStreet: state.selectedStreet,
                  streets: possibleStreets,
                  zip: state.zip,
                  selectedAddress: undefined,
                }
              }
            }
            return {
              ...state,
              additionalInfos: possibleAdditionalInfos,
              cities: possibleCities,
              focusedFieldId: AvailabilityCheckField.ADDITIONAL,
              numbers: possibleNumbers,
              selectedAdditionalInfo: '',
              selectedCity: action.payload,
              selectedNumber: possibleNumbers[0],
              selectedStreet: possibleStreets[0],
              streets: possibleStreets,
              selectedAddress: undefined,
            }
          }
          // More than one number in that street
          return {
            ...state,
            additionalInfos: [],
            cities: possibleCities,
            focusedFieldId: AvailabilityCheckField.NUMBER,
            numbers: possibleNumbers,
            selectedAdditionalInfo: '',
            selectedCity: possibleCities[0],
            selectedNumber: '',
            selectedStreet: possibleStreets[0],
            streets: possibleStreets,
            selectedAddress: undefined,
          }
        }
        // More than one street in that city
        return {
          ...state,
          additionalInfos: [],
          cities: possibleCities,
          focusedFieldId: AvailabilityCheckField.STREET,
          numbers: [],
          selectedAdditionalInfo: '',
          selectedCity: action.payload,
          selectedNumber: '',
          selectedStreet: '',
          streets: possibleStreets,
          selectedAddress: undefined,
        }
      }
      return state
    case AvailabilityCheckAction.SET_NUMBER:
      if (action.payload !== '') {
        const possibleCities: string[] = state.cities
        const possibleStreets: string[] = state.streets
        const possibleNumbers: string[] = state.numbers
        const possibleAdditionalInfos: string[] = []
        state.addressList.forEach((record) => {
          if (validateAddressData(record) && record.zipCode === state.zip) {
            if (
              record.city === state.selectedCity &&
              record.street === state.selectedStreet &&
              record.houseNumber === action.payload
            ) {
              if (
                record.addressAddition !== undefined &&
                record.addressAddition !== null &&
                !possibleAdditionalInfos.includes(record.addressAddition)
              ) {
                possibleAdditionalInfos.push(record.addressAddition)
              }
            }
          }
        })
        // No additional infos in that number
        if (possibleAdditionalInfos.length === 0) {
          return {
            ...state,
            additionalInfos: [],
            cities: possibleCities,
            focusedFieldId: AvailabilityCheckField.SUBMIT,
            numbers: possibleNumbers,
            selectedAdditionalInfo: '',
            selectedCity: state.selectedCity,
            selectedNumber: action.payload,
            selectedStreet: state.selectedStreet,
            streets: possibleStreets,
            zip: state.zip,
            selectedAddress: undefined,
          }
        }
        // One additional info in that number
        if (possibleAdditionalInfos.length === 1) {
          return {
            ...state,
            additionalInfos: possibleAdditionalInfos,
            cities: possibleCities,
            focusedFieldId: AvailabilityCheckField.SUBMIT,
            numbers: possibleNumbers,
            selectedAdditionalInfo: possibleAdditionalInfos[0],
            selectedCity: state.selectedCity,
            selectedNumber: action.payload,
            selectedStreet: state.selectedStreet,
            streets: possibleStreets,
            zip: state.zip,
            selectedAddress: undefined,
          }
        }
        // More than one additional info in that number
        // One of them is empty
        for (let i = 0; i < possibleAdditionalInfos.length; i++) {
          const additionalInfo = possibleAdditionalInfos[i]
          if (additionalInfo.length === 0) {
            return {
              ...state,
              additionalInfos: possibleAdditionalInfos,
              cities: possibleCities,
              focusedFieldId: AvailabilityCheckField.SUBMIT,
              numbers: possibleNumbers,
              selectedAdditionalInfo: additionalInfo,
              selectedCity: state.selectedCity,
              selectedNumber: action.payload,
              selectedStreet: state.selectedStreet,
              streets: possibleStreets,
              zip: state.zip,
              selectedAddress: undefined,
            }
          }
        }
        return {
          ...state,
          additionalInfos: possibleAdditionalInfos,
          cities: possibleCities,
          focusedFieldId: AvailabilityCheckField.ADDITIONAL,
          numbers: possibleNumbers,
          selectedAdditionalInfo: '',
          selectedCity: state.selectedCity,
          selectedNumber: action.payload,
          selectedStreet: state.selectedStreet,
          streets: possibleStreets,
          zip: state.zip,
          selectedAddress: undefined,
        }
      }
      return state
    case AvailabilityCheckAction.SET_STREET:
      const possibleCities: string[] = state.cities
      const possibleStreets: string[] = state.streets
      const possibleNumbers: string[] = []
      state.addressList.forEach((record) => {
        if (validateAddressData(record) && record.zipCode === state.zip) {
          if (record.city === state.selectedCity && record.street === action.payload) {
            if (!possibleNumbers.includes(record.houseNumber)) {
              possibleNumbers.push(record.houseNumber)
            }
          }
        }
      })
      // Only one number in that street
      if (possibleNumbers.length === 1) {
        const possibleAdditionalInfos: string[] = []
        state.addressList.forEach((record) => {
          if (validateAddressData(record) && record.zipCode === state.zip) {
            if (record.city === state.selectedCity) {
              if (record.street === action.payload) {
                if (record.houseNumber === possibleNumbers[0]) {
                  if (
                    record.addressAddition !== undefined &&
                    record.addressAddition !== null &&
                    !possibleAdditionalInfos.includes(record.addressAddition)
                  ) {
                    possibleAdditionalInfos.push(record.addressAddition)
                  }
                }
              }
            }
          }
        })
        // No additional infos in that number
        if (possibleAdditionalInfos.length === 0) {
          return {
            ...state,
            additionalInfos: [],
            cities: possibleCities,
            focusedFieldId: AvailabilityCheckField.SUBMIT,
            numbers: possibleNumbers,
            selectedAdditionalInfo: '',
            selectedCity: state.selectedCity,
            selectedNumber: possibleNumbers[0],
            selectedStreet: action.payload,
            streets: possibleStreets,
            zip: state.zip,
            selectedAddress: undefined,
          }
        }
        // One additional info in that number
        if (possibleAdditionalInfos.length === 1) {
          return {
            ...state,
            additionalInfos: possibleAdditionalInfos,
            cities: possibleCities,
            focusedFieldId: AvailabilityCheckField.SUBMIT,
            numbers: possibleNumbers,
            selectedAdditionalInfo: possibleAdditionalInfos[0],
            selectedCity: state.selectedCity,
            selectedNumber: possibleNumbers[0],
            selectedStreet: action.payload,
            streets: possibleStreets,
            zip: state.zip,
            selectedAddress: undefined,
          }
        }
        // More than one additional info in that number
        // One of them is empty
        for (let i = 0; i < possibleAdditionalInfos.length; i++) {
          const additionalInfo = possibleAdditionalInfos[i]
          if (additionalInfo.length === 0) {
            return {
              ...state,
              additionalInfos: possibleAdditionalInfos,
              cities: possibleCities,
              focusedFieldId: AvailabilityCheckField.SUBMIT,
              numbers: possibleNumbers,
              selectedAdditionalInfo: additionalInfo,
              selectedCity: state.selectedCity,
              selectedNumber: possibleNumbers[0],
              selectedStreet: action.payload,
              streets: possibleStreets,
              zip: state.zip,
              selectedAddress: undefined,
            }
          }
        }
        return {
          ...state,
          additionalInfos: possibleAdditionalInfos,
          cities: possibleCities,
          focusedFieldId: AvailabilityCheckField.ADDITIONAL,
          numbers: possibleNumbers,
          selectedAdditionalInfo: '',
          selectedCity: state.selectedCity,
          selectedNumber: possibleNumbers[0],
          selectedStreet: action.payload,
          streets: possibleStreets,
          zip: state.zip,
          selectedAddress: undefined,
        }
      }
      // More than one number in that street
      return {
        ...state,
        additionalInfos: [],
        cities: possibleCities,
        focusedFieldId: AvailabilityCheckField.NUMBER,
        numbers: possibleNumbers,
        selectedAdditionalInfo: '',
        selectedCity: state.selectedCity,
        selectedNumber: '',
        selectedStreet: action.payload,
        streets: possibleStreets,
        zip: state.zip,
        selectedAddress: undefined,
      }
    case AvailabilityCheckAction.SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: state.addressList.find(
          (a) =>
            a.zipCode === state.zip &&
            a.city === state.selectedCity &&
            a.street === state.selectedStreet &&
            a.houseNumber === state.selectedNumber &&
            (state.selectedAdditionalInfo !== initialAvailabilityCheckState.selectedAdditionalInfo
              ? a.addressAddition && state.selectedAdditionalInfo === a.addressAddition
              : true),
        ),
      }
    default:
      return state
  }
}

export default AvailabilityCheckReducer
