import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { initialAvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import { AppState } from 'store/store'

interface IsAvailabilityCheckDone {
  isAvailabilityCheckDone: boolean
}

export default (): IsAvailabilityCheckDone => {
  const { availabilityCheck } = useSelector((appState: AppState) => ({
    availabilityCheck: appState.availabilityCheck,
  }))

  const isAvailabilityCheckDone = !isEqual(availabilityCheck, initialAvailabilityCheckState)

  return {
    isAvailabilityCheckDone,
  }
}
