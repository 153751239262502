import { AppBar, Box, Container, Grid, IconButton, makeStyles } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import HelpPopup from 'components/HelpPopup'
import * as React from 'react'
import { useAppState } from 'useAppState'
import TestIDs from 'utils/TestIDs'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const breakpoints = createBreakpoints({})

const useStyles = makeStyles(() => ({
  logo: {
    [breakpoints.down('xs')]: {
      width: '146px',
    },
  },
}))

const useScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
}

const Header = (): JSX.Element => {
  const { logoClick } = useAppState()
  const classes = useStyles()
  useScrollToTop()

  return (
    <AppBar position={'static'} {...TestIDs.PAGE_HEADER} elevation={0}>
      <Container maxWidth={'lg'}>
        <Grid container alignItems="center">
          <Grid item xs={11} {...TestIDs.PAGE_HEADER_LOGO}>
            <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <IconButton
                disableFocusRipple={true}
                disableRipple={true}
                disableTouchRipple={true}
                onClick={logoClick}
                {...TestIDs.PAGE_HEADER_LOGO_ICON_BUTTON}
              >
                <img src={'/assets/logo.svg'} alt={'placeholder'} className={classes.logo} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={1} className={'AppBarIconButton'} {...TestIDs.PAGE_HEADER_HELP_BUTTON}>
            <HelpPopup />
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  )
}

export default Header
