import { Button, Container, Grid, TextField } from '@material-ui/core'
import clsx from 'clsx'
import ChevronRightIcon from 'components/ChevronRightIcon'
import Dropdown from 'components/Dropdown'
import React, { useEffect, useRef } from 'react'
import { AvailabilityCheckField } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import TestIDs from 'utils/TestIDs'

interface AvailabilityCheckProps {
  additionalInfos: string[]
  cities: string[]
  focusedFieldId: AvailabilityCheckField
  numbers: string[]
  buttonText: string
  disabledSubmit: boolean
  disabledAdditionalInfo: boolean
  selectedAdditionalInfo: string
  disabledCity: boolean
  selectedCity: string
  disabledNumber: boolean
  selectedNumber: string
  disabledStreet: boolean
  selectedStreet: string
  streets: string[]
  disabledZip: boolean
  zip: string
  autoFocusOnMount: boolean
  onSubmit: () => void
  setSelectedAdditionalInfo?: (payload: string) => void
  setSelectedCity?: (
    payload: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  setSelectedNumber?: (
    payload: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  setSelectedStreet?: (
    payload: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  setZip?: (payload: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const AvailabilityCheckInput = (props: AvailabilityCheckProps): JSX.Element => {
  const {
    additionalInfos,
    cities,
    focusedFieldId,
    numbers,
    selectedAdditionalInfo,
    selectedCity,
    selectedNumber,
    selectedStreet,
    setSelectedAdditionalInfo,
    setSelectedCity,
    setSelectedNumber,
    setSelectedStreet,
    setZip,
    streets,
    zip,
    onSubmit,
  } = props

  const zipRef = useRef<any>(null)
  const cityRef = useRef<any>(null)
  const streetRef = useRef<any>(null)
  const numberRef = useRef<any>(null)
  const additionalInfoRef = useRef<any>(null)
  const buttonRef = useRef<any>(null)

  useEffect(() => {
    switch (focusedFieldId) {
      case AvailabilityCheckField.ZIP:
        if (zipRef.current != null) {
          zipRef.current.focus()
        }
        break
      case AvailabilityCheckField.CITY:
        if (cityRef.current != null) {
          cityRef.current.focus()
        }
        break
      case AvailabilityCheckField.STREET:
        if (streetRef.current != null) {
          streetRef.current.focus()
        }
        break
      case AvailabilityCheckField.NUMBER:
        if (numberRef.current != null) {
          numberRef.current.focus()
        }
        break
      case AvailabilityCheckField.ADDITIONAL:
        if (additionalInfoRef.current != null) {
          additionalInfoRef.current.focus()
        }
        break
      case AvailabilityCheckField.SUBMIT:
        if (buttonRef.current != null) {
          buttonRef.current.focus()
        }
        break
    }
  }, [focusedFieldId, zip, selectedAdditionalInfo, selectedCity, selectedNumber, selectedStreet])

  return (
    <Container className={'ContentContainer'} maxWidth={'lg'} {...TestIDs.AVAILABILITY_CHECK_FORM}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <TextField
            {...TestIDs.AVAILABILITY_CHECK_ZIP_INPUT}
            error={zip.length === 5 && cities.length === 0}
            disabled={props.disabledZip}
            InputProps={{
              className:
                zip.length === 5 && cities.length === 0
                  ? 'TextError fallbackDarkBackground availabilityCheck'
                  : 'fallbackDarkBackground availabilityCheck',
            }}
            inputProps={{
              maxLength: 5,
            }}
            inputRef={zipRef}
            fullWidth={true}
            label={'Postleitzahl'}
            variant={'filled'}
            value={zip}
            onChange={(event): void => {
              if (!/[^0-9]+/g.test(event.target.value) && setZip) setZip(event)
            }}
            className={'fallbackDarkBackground'}
            autoFocus={props.autoFocusOnMount}
            type={'text'}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          {cities.length === 1 ? (
            <TextField
              inputProps={{
                maxLength: 100,
              }}
              {...TestIDs.AVAILABILITY_CHECK_CITY_INPUT}
              disabled={true}
              fullWidth={true}
              label={'Ort'}
              variant={'filled'}
              value={cities[0]}
              className={'fallbackDarkBackground'}
            />
          ) : (
            <Dropdown
              label={'Ort'}
              disabled={props.disabledCity}
              inputRef={cityRef}
              value={selectedCity}
              onChange={setSelectedCity}
              options={cities.sort()}
              className={'fallbackDarkBackground'}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={3}>
          <Dropdown
            label={'Straße'}
            disabled={props.disabledStreet}
            inputRef={streetRef}
            value={selectedStreet}
            onChange={setSelectedStreet}
            options={streets.sort()}
            className={'fallbackDarkBackground'}
          />
        </Grid>
        <Grid container item xs={12} lg={3} className={clsx('GridSubcontainer', 'AvailabilityInputRight')} spacing={2}>
          <Grid item xs={6}>
            <Dropdown
              label={'Nr.'}
              inputRef={numberRef}
              disabled={props.disabledNumber}
              value={selectedNumber}
              onChange={setSelectedNumber}
              options={numbers.sort(function (a, b) {
                return Number(a) - Number(b)
              })}
              className={'fallbackDarkBackground'}
            />
          </Grid>
          <Grid item xs={6} className={'AvailabilityInputRight'}>
            <Dropdown
              label={'Zusatz'}
              disabled={props.disabledAdditionalInfo}
              inputRef={additionalInfoRef}
              value={selectedAdditionalInfo}
              onChange={(e): void => setSelectedAdditionalInfo && setSelectedAdditionalInfo(e.target.value as string)}
              options={additionalInfos.sort()}
              className={'fallbackDarkBackground'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Button
        {...TestIDs.AVAILABILITY_CHECK_SUBMIT_BUTTON}
        ref={buttonRef}
        focusRipple={true}
        disabled={props.disabledSubmit}
        className={'AvailabilityCheck'}
        color={'secondary'}
        onClick={(): void => onSubmit()}
        startIcon={<ChevronRightIcon />}
      >
        {props.buttonText}
      </Button>
    </Container>
  )
}

export default AvailabilityCheckInput
