import { Grid, TextField, Typography, Button } from '@material-ui/core'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'
import { useLazyQuery } from '@apollo/client'
import { VALIDATE_VOUCHER } from 'graphql/queries/validateVoucher'
import { Query, QueryValidateVoucherCodeArgs, Voucher } from 'graphql/types'
import { useEffect, useState } from 'react'
import ChevronRightIcon from 'components/ChevronRightIcon'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'

interface VoucherFieldsProps {
  setVoucherCode: (userVoucher: string) => void
  voucherCode: string
  setAppliedVoucher: (v: Voucher) => void
}

const isVoucherForSelectedOptions = (voucher: Voucher, selectedOptionIDs: string[]): boolean => {
  for (let i = 0; i < voucher.availableFor.length; i++) {
    const availableFor = voucher.availableFor[i]
    if (selectedOptionIDs.includes(availableFor)) {
      return true
    }
  }
  return false
}

const VoucherFields = (props: VoucherFieldsProps): JSX.Element => {
  const av = useSelector((state: AppState) => state.availabilityCheck)
  const sp = useSelector((state: AppState) => state.checkout)
  const { voucherCode, setVoucherCode, setAppliedVoucher } = props
  const [successFullyValidated, setSuccessFullyValidated] = useState(false)
  const [codeWrongText, setCodeWrongText] = useState('')
  const [validateVoucherCode, { loading }] = useLazyQuery<Query>(VALIDATE_VOUCHER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.validateVoucherCode.isValid && data.validateVoucherCode.voucher) {
        //set info box
        // check if voucher is valid for selected products
        if (sp.selectedProduct) {
          const selectedProduct = sp.selectedProduct
          const selectedOptions = sp.selectedOptions
          if (
            data.validateVoucherCode.voucher.availableFor.includes(selectedProduct.id) ||
            isVoucherForSelectedOptions(
              data.validateVoucherCode.voucher,
              selectedOptions.map((it) => it.id),
            )
          ) {
            setSuccessFullyValidated(true)
            setAppliedVoucher(data.validateVoucherCode.voucher)
          }
        }
      } else if (data.validateVoucherCode.isValid === false) {
        //set error box
        setCodeWrongText('Der Gutscheincode ist nicht mehr gültig oder inkorrekt')
      }
    },
  })

  useEffect(() => {
    // reset voucher if selected product changes
    if (sp.selectedProduct) {
      setSuccessFullyValidated(false)
      setCodeWrongText('')
      setVoucherCode('')
    }
  }, [sp.selectedProduct])

  const checkVoucherCode = (code: string): void => {
    if (!av.selectedAddress) {
      setCodeWrongText('Bitte wählen Sie eine Adresse aus')
      return
    }
    const vars: QueryValidateVoucherCodeArgs = {
      code: code,
      selectedAddressID: av.selectedAddress.id,
    }
    validateVoucherCode({ variables: vars })
  }
  return (
    <Grid id={'Voucher'} container item spacing={3}>
      <Grid item xs={12}>
        <Typography variant={'h3'} className={'sectionH3'} {...TestIDs.BUNDLE_PRODUCT_FIELDS_HEADER}>
          Gutscheineingabe
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography {...TestIDs.BUNDLE_PRODUCT_FIELDS_SUBHEADER}>
          Wenn Sie einen Gutschein für die ausgewählten Produkte haben, dann können Sie den Gutscheincode hier eingeben
          und der Betrag wird automatisch verrechnet
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          inputProps={{
            maxLength: 100,
          }}
          {...TestIDs.APPARTMENT_FIELDS_FLOOR_INPUT}
          label={'Gutscheincode'}
          variant={'filled'}
          fullWidth={true}
          style={{ height: '100%', flexDirection: 'row' }}
          value={voucherCode}
          onChange={(e): void => {
            setVoucherCode(e.target.value)
          }}
          InputProps={{
            className: 'fallbackLightBackground',
            disabled: successFullyValidated,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          color={'secondary'}
          startIcon={<ChevronRightIcon />}
          disabled={successFullyValidated}
          onClick={(): void => checkVoucherCode(voucherCode)}
        >
          {loading && 'Einlösen...'}
          {!loading && !successFullyValidated && 'Einlösen'}
          {successFullyValidated && 'Gutschein eingelöst'}
        </Button>
      </Grid>
      {!successFullyValidated && codeWrongText.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="body1">{codeWrongText}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default VoucherFields
