import { Container, Link, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    margin: theme.spacing(6),
    textAlign: 'left',
  },
}))

const Dataprivacy = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Paper className={classes.contentContainer} elevation={0} square {...TestIDs.DATAPRIVACY_CONTENT}>
      <Container maxWidth={'lg'} className={'LegalNotes'}>
        <Typography variant={'h1'}>Datenschutzinformation</Typography>
        <br />
        <Typography>(Stand 02.04.2020)</Typography>
        <Typography>
          Diese Datenschutzinformation informiert über die Verarbeitung Ihrer personenbezogenen Daten (im Folgenden
          „Sie“, „Ihre, „Nutzer“ oder „Kunde“) bei Besuch und Nutzung dieser Webseite, bei Anbahnung und Abschluss eines
          Vertrags mit der Deutsche Multimedia Service GmbH (nachfolgend „wir“ und/oder „DMSG“) über den Bezug von
          Telekommunikationsprodukten wie etwa Festnetztelefonie und/oder Internetzugang (im Folgenden
          „Telekommunikationsdienste“), bei Bezug unserer sonstigen Services und im Rahmen von Marketingmaßnahmen nach
          der EU-Datenschutzgrundverordnung (im Folgenden „DSGVO“).
        </Typography>
        <br />
        <ol>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Anwendungsbereich</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Diese Datenschutzinformation gilt für die Verarbeitung Ihrer personenbezogenen Daten, wenn Sie
                  Besucher unserer Webseite oder Nutzer unserer Telekommunikationsdienste oder Services sind/werden
                  sowie im Rahmen unserer Marketingmaßnahmen. Sofern Sie als juristische Person agieren, findet diese
                  Datenschutzinformation nur eingeschränkt Anwendung (die DSGVO betrifft nur Daten von natürlichen
                  Personen). Unter Umständen sollten Sie dann Ihre Mitarbeiter entsprechend informieren.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Sie können diese Datenschutzinformation von unserer Webseite jederzeit kostenlos abrufen, speichern
                  und ausdrucken.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Verantwortlicher im Sinne des Art. 4 Nr. 7 DSGVO</Typography>
            <br />
            <Typography>Für die Verarbeitungen Ihrer Daten ist verantwortlich:</Typography>
            <Typography>Deutsche Multimedia Service GmbH</Typography>
            <Typography>Universitätsstr. 133, 44803 Bochum</Typography>
            <Link href="tel:+80028221016">Tel: +49 (0) 800 / 2822 - 1016</Link>
            <Link href="mailto:internet@vonovia.de">internet@vonovia.de</Link>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Konzerndatenschutzbeauftragter</Typography>
            <br />
            <Typography>Bitte richten Sie Ihre Fragen rund um den Datenschutz an</Typography>
            <Typography>Dr. Stefan Drewes</Typography>
            <Typography>c/o Vonovia SE</Typography>
            <Typography>Postfach</Typography>
            <Typography>44784 Bochum</Typography>
            <Link href="mailto:datenschutz@vonovia.de">datenschutz@vonovia.de</Link>
            <br />
            <Typography>
              Wir weisen ausdrücklich darauf hin, dass bei Nutzung dieser E-Mail-Adresse die Inhalte nicht
              ausschließlich von unserem Datenschutzbeauftragten zur Kenntnis genommen werden. Wenn Sie vertrauliche
              Informationen austauschen möchten, bitten wir Sie daher zunächst über diese E-Mail-Adresse um direkte
              Kontaktaufnahme ohne eine nähere Beschreibung des Sachverhaltes.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Definitionen</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Die DSGVO ist die EU-Datenschutzgrundverordnung (Verordnung (EU) 2016/679 des Europäischen Parlaments
                  und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung
                  personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG).
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der
                  personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten
                  handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem
                  Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
                  jedoch nicht als Empfänger; die Verarbeitung dieser Daten durch die genannten Behörden erfolgt im
                  Einklang mit den geltenden Datenschutzvorschriften gemäß den Zwecken der Verarbeitung.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Nutzer sind Sie, wenn Sie sich z.B. auf unserer Webseite über uns und unsere Angebote und Services
                  informieren.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
                  natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder
                  indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
                  Standortdaten zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck
                  der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
                  Identität dieser natürlichen Person sind, identifiziert werden kann (Art. 4 Nr. 1 DSGVO).
                  Personenbezogene Daten können etwa Name und Kontaktdaten, Nutzerverhalten oder Bankdaten sein.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Verantwortlicher ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
                  die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
                  personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das
                  Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise
                  können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der
                  Mitgliedstaaten vorgesehen werden. Für die in dieser Datenschutzerklärung beschriebenen
                  Datenverarbeitungen ist die Deutsche Multimedia Service GmbH Verantwortlicher im Sinne des Art. 4 Nr.
                  7 DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Verarbeitung umfasst das Erheben, Erfassen, die Organisation, das Ordnen, die Speicherung, die
                  Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch
                  Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung,
                  die Einschränkung, das Löschen oder die Vernichtung personenbezogener Daten (Art. 4 Nr. 2 DSGVO).
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Verarbeitung Ihrer Daten bei Besuch unserer Webseiten</Typography>
            <br />
            <Typography>
              Wenn Sie unsere Webseite aufrufen, um sich über unsere Telekommunikationsdienste oder Services zu
              informieren (rein informatorische Nutzung), verarbeiten wir personenbezogene Daten. Die Verarbeitung Ihrer
              personenbezogenen Daten erfolgt zu folgenden Zwecken und auf Basis folgender Rechtsgrundlagen:
            </Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Bei Besuch unserer Webseite verarbeiten wir Ihre personenbezogenen Daten, die für uns technisch
                  erforderlich sind, um Ihnen unsere Webseite bereitzustellen und die Stabilität und Sicherheit bei
                  Besuch unserer Webseite zu gewährleisten. Hierzu verarbeiten wir folgende personenbezogene Daten:
                </Typography>
                <Typography>- IP-Adresse</Typography>
                <Typography>- Browser User Agents</Typography>
                <Typography>- Cookies</Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Wir verarbeiten Ihre personenbezogenen Daten aufgrund unseres berechtigten Interesses, Ihnen auf
                  unserer Webseite Informationen zu unseren Telekommunikationsdiensten und Services bereitzustellen und
                  die IT-Sicherheit Ihnen gegenüber bei Besuch unsere Webseite zu gewährleisten, auf Grundlage von Art.
                  6 Abs. 1 S. 1 lit. f) DSGVO.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Verwendung von Cookies</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Bei der Nutzung unserer Webseite werden Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es
                  sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet und
                  gespeichert werden und durch welche der Stelle, die den Cookie setzt, bestimmte Informationen
                  zufließen. Diese enthalten auch personenbezogene Daten. Cookies können keine Programme ausführen oder
                  Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher
                  und effektiver zu machen.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Für die Nutzung dieser Webseite gilt die Cookie-Datenschutz-Information der Vonovia SE (abrufbar unter
                  [www.vonovia.de/cookie-richtlinien](www.vonovia.de/cookie-richtlinien) entsprechend, wobei hiervon
                  abweichend die DMSG als verantwortliche Stelle gilt.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Registrierung im Online-Kundenportal</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Auf unserer Webseite [www.vonovia.de](https://vonovia.de) können Sie sich in unserem
                  Online-Kundenportal registrieren. Über unser Online-Kundenportal bieten wir Ihnen verschiedene
                  Services an. Die Registrierung und die Nutzung unseres Online-Kundenportals erfordert die Angabe
                  personenbezogener Daten. Pflichtfelder sind in der Eingabemaske entsprechend gekennzeichnet.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Wir verarbeiten Ihre personenbezogenen Daten, um Ihnen die Erstellung und Nutzung Ihres Accounts im
                  Online-Kundenportal zu ermöglichen und Sie aufgrund unseres berechtigten Interesses über unsere
                  Services zu informieren und Ihnen hierzu spezielle Funktionen (etwa Online-Rechnung und E-Mail
                  Service) bereitzustellen. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1
                  lit. b) und lit. f) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Die Verarbeitung der Pflichtangaben dient der Bereitstellung unseres Online-Kundenportals und
                  gegebenenfalls auch der Anbahnung, Vorbereitung, Durchführung und Abwicklung Ihres Vertrags. Die
                  Verarbeitung und Speicherung Ihrer personenbezogenen Daten erfolgt auf Grundlage von Art. 6 Abs. 1 S.
                  1 lit. b) DSGVO.
                </Typography>
              </li>
            </ol>
            <br />
            <Typography>
              Wenn Sie bei der Registrierung freiwillige Angaben machen, willigen Sie in die Verarbeitung dieser Daten
              durch uns ein. Die Verarbeitung Ihrer Angaben erfolgt aufgrund Ihrer Einwilligung i.S.d. Art. 6 Abs. 1 S.
              1 lit. a) DSGVO. Ferner verarbeiten wir Ihre Bestandsdaten und Nutzungsdaten, um Ihnen im
              Online-Kundenportal die Verfügbarkeit von Telekommunikationsdiensten an Ihrem Wohnort anzuzeigen und für
              diese zu werben. Diese Verarbeitungen erfolgen ebenfalls auf Grundlage von Art. 6 Abs. 1 lit. a) DSGVO.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Bearbeitung Ihres Profils im Online-Kundenportal</Typography>
            <br />
            <Typography>
              Nach Registrierung können Sie im Online-Kundenportal unter „Mein Profil“ Ihre Bestandsdaten und bestimmte
              Verkehrsdaten einsehen, Ihre Bestandsdaten ändern sowie Vertragsänderungen oder zusätzliche Leistungen
              beantragen. Diese (und sonstige, von Ihnen im Einzelfall angegebene) Daten verarbeiten wir jeweils soweit
              dies erforderlich ist, um Ihnen die Nutzung der jeweils abgefragten Funktion des Online-Kundenportals zu
              ermöglichen und etwaige Leistungsanfragen zu bearbeiten. Diese Verarbeitungen erfolgen auf Grundlage von
              Art. 6 Abs. 1 S. 1 lit. b) DSGVO soweit sie der Durchführung einer Ihrerseits angefragten Vertragsleistung
              dienen, im Übrigen auf Basis von Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Unser berechtigtes Interesse besteht
              darin, die von Ihnen angefragten Funktionen des Online-Kundenportals bereitzustellen.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Störungsmeldungen</Typography>
            <br />
            <Typography>
              Als Vertragskunde können Sie etwaige Störungen Ihrer Telekommunikationsdienste über unser Kundencenter
              <Link display="inline" href="tel:+80028221016">
                Tel: +49 (0) 800 / 2822 - 1016
              </Link>
              <Link display="inline" href="mailto:internet@vonovia.de">
                internet@vonovia.de
              </Link>
              melden. Wir verarbeiten diese und Ihre sonstigen bei uns gespeicherten Bestandsdaten dann, um
              erforderliche Maßnahmen zur Störungsbeseitigung durchzuführen. Diese Verarbeitungen erfolgt auf Grundlage
              von Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Unser berechtigtes Interesse besteht darin, unsere Vertragspflichten
              zu erfüllen und unsere Telekommunikationsinfrastruktur instand zu halten.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Anfragen über Kundencenter</Typography>
            <br />
            <Typography>
              Wenn Sie Vertragskunde sind/werden, verarbeiten wir Ihre bei uns gespeicherten Bestandsdaten auch, um im
              Falle Ihrer Kontaktaufnahme mit unserem Kundencenter (Webformular/Telefonhotline) Ihr Anliegen zu
              bearbeiten und Maßnahmen der Qualitätssicherung durchführen zu können; dies gilt ebenso für Daten, die Sie
              uns (auch ohne Vertragskunde zu sein) bei Ihrer Kontaktaufnahme mitteilen. Diese Verarbeitung erfolgt auf
              Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Unser berechtigtes Interesse besteht darin, Anfragen
              unserer Kunden effizienter bearbeiten und die Qualität des Kunden-Supports sicherstellen zu können.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>SEPA-Lastschrift</Typography>
            <br />
            <Typography>
              Wenn Sie Vertragskunde werden, können Sie uns zur Bezahlung Ihrer Entgelte für die von Ihnen genutzten
              Telekommunikationsdienste ein SEPA-Lastschriftmandat erteilen. In diesem Fall verarbeiten wir Ihre
              Lastschriftdaten, um die entsprechende Entgelte von Ihrem Konto einzuziehen. Diese Verarbeitung erfolgt
              auf Grundlage von Art. 6 Abs. 1 S. 1 lit. b) DSGVO.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Erfüllung von Vertragspflichten</Typography>
            <br />
            <Typography>
              Wir werden Ihre Bestandsdaten, Nutzungsdaten, Verkehrsdaten und sonstige von Ihnen im Einzelfall
              mitgeteilte personenbezogene Daten verarbeiten, soweit dies erforderlich ist, um unsere vertraglichen
              Pflichten aus dem mit Ihnen geschlossenen Vertrag über Telekommunikationsdienste zu erfüllen, insbesondere
              die hiernach geschuldeten Telekommunikations-, Telemedien- und sonstige Dienste sowie Übertragungs- und
              Telekommunikationsdienste bereitzustellen. Rechtsgrundlage dieser Verarbeitungen ist Art. 6 Abs. 1 S. 1
              lit. b) DSGVO.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Verletzung von Vertragspflichten</Typography>
            <br />
            <Typography>
              Sollten Sie Bestimmungen Ihres Vertrags über Telekommunikationsdienste verletzen, werden wir Ihre
              Bestandsdaten, Nutzungsdaten, Verkehrsdaten und sonstige von Ihnen im Einzelfall mitgeteilte
              personenbezogene Daten auch verarbeiten, um unsere vertraglichen Rechte zu prüfen und ggf. auszuüben. Die
              Rechtsgrundlage für diese Verarbeitung zum Zwecke der Durchführung Ihres Vertrags (z.B. Abmahnung,
              Zahlungsaufforderung, Kündigung) ist Art. 6 Abs. 1 S. 1 lit. b) DSGVO.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Marketingmaßnahmen / Kundenbefragung</Typography>
            <br />
            <Typography>
              Wenn Sie Vertragskunde werden, werden wir die von Ihnen hinterlegte E-Mail-Adresse, Telefonnummer und
              [Datenart-Bezeichnung] verarbeiten, um Ihnen per E-Mail und SMS/MMS regelmäßig allgemeine Informationen zu
              unseren Angeboten und Produkten zukommen zu lassen und Sie zur Teilnahme an Umfragen zur Markt- und
              Meinungsforschung einzuladen. Diese Verarbeitung erfolgt auf Basis von Art. 6 Abs. 1 lit. f) DSGVO,
              solange sie trotz ordnungsgemäßer Belehrung dem Erhalt solcher Kommunikation nicht widersprochen haben.
              Insoweit haben wir ein berechtigtes Interesse, unsere Leistungen auf Basis fundierter Forschungsergebnisse
              zu verbessern und Kunden über Dienstleistungen, die im Rahmen ihrer bestehenden Vertragsbeziehung relevant
              sind, zu informieren.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Abmahnung und Kündigung Ihrer Telekommunikationsdienste</Typography>
            <br />
            <Typography>
              Wenn Sie Ihren Pflichten aus dem Vertrag über die Telekommunikationsdienste nicht oder nicht ausreichend
              nachkommen (etwa bei Zahlungsrückständen), behalten wir uns vor, Maßnahmen gegen Sie einzuleiten (etwa
              Abmahnung oder Kündigung des mit Ihnen geschlossenen Vertrags). Dabei verarbeiten wir Ihre
              personenbezogenen Daten zur Durchführung und gegebenenfalls Abwicklung eines Vertrags auf Grundlage von
              Art. 6 Abs. 1 S. 1 lit. b) DSGVO.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>
              Mahnwesen, Rechtsberatung sowie Durchsetzung und Verteidigung von Rechtsansprüchen
            </Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Bei offenen Forderungen uns gegenüber (etwa Zahlungsrückstände) verfolgen wir diese im
                  Forderungsmanagement, bewerten diese, nehmen telefonisch Kontakt mit Ihnen auf, um Ihnen einen
                  entsprechenden Hinweis zu geben und gegebenenfalls schicken wir Ihnen eine Mahnung.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Sofern Sie Ihren vertraglichen Pflichten nicht nachkommen (etwa Zahlungsrückstände trotz Mahnung) und
                  im Rahmen von Rechtsstreitigkeiten mit Ihnen, beauftragen wir für die Rechtsberatung sowie die
                  Durchsetzung und / oder Verteidigung unserer Rechte externe Rechtsanwälte oder Inkassounternehmen.
                  Unter Umständen verarbeiten wir dabei auch personenbezogene Daten aus anderen öffentlichen Quellen
                  (z.B. Auskunfteien).
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Wir verarbeiten Ihre personenbezogenen Daten zum Zweck, den Vertrag über Telekommunikationsdienste mit
                  Ihnen durchzuführen und gegebenenfalls abzuwickeln, auf Grundlage von Art. 6 Abs. 1 S. 1 lit. b) DSGVO
                  sowie aufgrund unseres berechtigten Interesses, unsere rechtlichen Interessen wahrzunehmen,
                  durchzusetzen und / oder zu verteidigen, auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Bei Pflichtverletzungen unserer Mitarbeiter sowie bei arbeitsbezogenen Strafverfahren gegen unsere
                  Mitarbeiter beauftragen wir gegebenenfalls für die Rechtsberatung sowie die Durchsetzung und / oder
                  Verteidigung unserer Rechte externe Rechtsanwälte. Bei Abwicklung dieser Fälle können unter Umständen
                  auch Ihre personenbezogenen Daten verarbeitet werden. Die Verarbeitung Ihrer personenbezogenen Daten
                  erfolgt aufgrund unseres berechtigten Interesses unsere rechtlichen Interessen wahrzunehmen,
                  durchzusetzen und / oder zu verteidigen, auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Forderungsverkauf</Typography>
            <br />
            <Typography>
              Unter Umständen veräußern wir unsere offenen Forderungen an Factoring-Unternehmen, die anschließend die
              Forderung in eigenem Namen einziehen. Im Rahmen des Verkaufs und dessen Vorbereitung erhalten die
              Inkassounternehmen Zugriff auf Daten, die in Zusammenhang mit den offenen Forderungen stehen. Diese
              enthalten auch personenbezogene Daten. Wenn eine offene Forderung, deren Gläubiger Sie sind, verkauft
              wird, informieren wir Sie hierüber und über den Empfänger, d.h. den neuen Forderungsinhaber. Wir
              verarbeiten Ihre personenbezogenen Daten zum Zweck der Vertragserfüllung mit Ihnen auf Grundlage von Art.
              6 Abs. 1 S. 1 lit. b) DSGVO.
            </Typography>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Compliance-Prüfungen und -Maßnahmen und interne Revision</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Wir dokumentieren Pflichtverletzungen innerhalb der DMSG, um die Sachverhalte aufzuklären, den
                  konkreten Mitarbeiter auf die Pflichtverletzung hinzuweisen, ihn gegebenenfalls abzumahnen und / oder
                  unter Umständen zu kündigen. Die Dokumentation der Pflichtverletzungen kann je nach Einzelfall der
                  Pflichtverletzung unter Umständen auch Ihre personenbezogenen Daten enthalten.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Sofern ein Verdacht auf strafbares Verhalten des Mitarbeiters besteht, führen wir eine Sonderprüfung
                  durch, um den Sachverhalt zu ermitteln, zu prüfen, ob eine Straftat begangen wurde und gegebenenfalls
                  Beweise zu sichern. Dabei können unter Umständen auch Ihre personenbezogenen Daten verarbeitet werden.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Auch im Rahmen von Revisionsprüfungen bei der DMSG können Ihre personenbezogenen Daten verarbeitet
                  werden.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Wir verarbeiten Ihre personenbezogenen Daten, um unseren rechtlichen Verpflichtungen nachzukommen, auf
                  Grundlage von Art. 6 Abs. 1 S. 1 lit. c) DSGVO. Darüber hinaus verarbeiten wir Ihre personenbezogenen
                  Daten aufgrund unseres berechtigten Interesses, die von Aufsichtsorgangen vorgegebenen Prüfungen
                  durchzuführen, um die Abläufe und die Effizienz in der DMSG zu überprüfen, unsere rechtlichen
                  Interessen zu wahren, durchzusetzen und / oder zu verteidigen und der Verhinderung von Betrugs- und
                  Missbrauchsfällen sowie die interne IT- und Netzwerksicherheit zu gewährleisten, auf Grundlage von
                  Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Aufbewahrung und Löschung Ihrer personenbezogenen Daten</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Wir speichern Ihre personenbezogenen Daten solange und soweit, wie es für die Zwecke, für die sie
                  verarbeitet werden, erforderlich ist.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Sofern und soweit Ihre personenbezogenen Daten für die Verarbeitung nicht mehr erforderlich sind,
                  speichern wir Ihre personenbezogenen Daten nur solange Sie Ansprüche gegen uns oder wir gegen Sie
                  geltend machen können (gesetzliche Verjährungsfrist i.d.R. von drei Jahren, beginnend mit Ende des
                  Jahres der Anspruchsentstehung, z.B. §§ 195, 199 Bürgerliches Gesetzbuch).
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Darüber hinaus speichern wir Ihre personenbezogenen Daten, solange und soweit wir hierzu gesetzlich
                  verpflichtet sind. Entsprechende Nachweis- und Aufbewahrungspflichten ergeben sich unter anderem aus
                  dem Handelsgesetz, der Abgabenordnung und dem Geldwäschegesetz (z.B. § 257 HGB; § 147 AO). Die
                  Aufbewahrungspflichten betragen hiernach zehn Jahre.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Datenverarbeitung durch externe Dienstleister – Auftragsverarbeitung</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Im Rahmen der Erbringung der Telekommunikationsdienste und Services bedienen wir uns externer
                  IT-Dienstleister, die uns u.a. Plattformen, Datenbanken oder Tools für unsere Services (z.B. unsere
                  Webseite, Kundenportal) bereitstellen und dabei Ihre personenbezogenen Daten in unserem Auftrag
                  verarbeiten.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Für die Durchführung von Kundenbefragungen bedienen wir uns externer Dienstleister (Agenturen), die
                  Ihre personenbezogenen Daten für uns in unserem Auftrag verarbeiten.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Die von uns beauftragten externen Dienstleister wurden sorgfältig von uns ausgewählt und schriftlich
                  beauftragt. Sie sind an unsere Weisungen gebunden und werden von uns regelmäßig kontrolliert. Alle
                  Systeme, in denen Ihre personenbezogenen Daten gespeichert werden und auf die externe Dienstleister
                  Zugriff haben, sind passwortgeschützt und nur einem bestimmten Personenkreis zugänglich, welcher die
                  Daten zur Bearbeitung der von Ihnen autorisierten Zwecke benötigt. In diesem Zusammenhang stellen wir
                  bei allen Übermittlungen an und Datenverarbeitungen durch externe Dienstleister im Rahmen der
                  gesetzlichen Bestimmungen sicher, dass die Daten in dem hier vereinbarten Sinne verarbeitet, genutzt
                  und gegebenenfalls übermittelt werden.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Übermittlung personenbezogener Daten an Dritter</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Störungsmeldungen: Bei Reparaturmaßnahmen und in Schadensfällen übermitteln wir Ihre personenbezogenen
                  Daten gegebenenfalls an externe Dienstleister, um die Reparaturen durchzuführen. Grundlage ist Art. 6
                  Abs. 1 S. 1 lit. b) DSGVO, soweit dies im Rahmen unserer Instandhaltungspflicht der von Ihnen
                  gemieteten Endgeräte (§ 535 Abs. 1 Bürgerliches Gesetzbuch) erfolgt.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  SEPA-Lastschriftmandat: Wenn Sie uns für den Einzug Ihrer Entgelte für die Telekommunikationsdienste
                  ein SEPA-Lastschriftmandat erteilt haben, übermitteln wir Ihre personenbezogenen Daten an Banken, um
                  diese Entgelte einzuziehen. Rechtsgrundlage der Übermittlung ist Art. 6 Abs. 1 S. 1 lit. b) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Zahlungsverzug: Wenn Sie Ihren Zahlungsverpflichtungen nicht nachkommen, leiten wir ein
                  Inkassoverfahren ein. Für die Durchführung des Inkassoverfahrens übermitteln wir Ihre
                  personenbezogenen Daten an Inkassodienstleister. Dabei verarbeiten wir Ihre personenbezogenen Daten,
                  um den Vertrag mit Ihnen durchzuführen und abzuwickeln, auf Grundlage von Art. 6 Abs. 1 S. 1 lit. b)
                  DSGVO und aufgrund unseres berechtigten Interesses, unsere Rechtsansprüche durchzusetzen,
                  einschließlich Inkasso, auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Verletzung von Vertragspflichten: Sofern Sie Pflichten aus Ihrem Vertrag über
                  Telekommunikationsdienste nicht nachkommen, übermitteln wir Ihre Bestandsdaten und personenbezogene
                  Daten zu offenen Forderungen gegebenenfalls an Auskunfteien und externe Rechtsanwälte, um Forderungen
                  aus Ihrem Vertrag über Telekommunikationsdienste durchzusetzen und den Vertrag ggf. abzuwickeln,
                  jeweils auf Grundlage von Art. 6 Abs. 1 S. 1 lit. b) DSGVO und aufgrund unseres berechtigten
                  Interesses, unsere rechtlichen Interessen wahrzunehmen, durchzusetzen und / oder zu verteidigen, auf
                  Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Factoring: Bei Verkauf offener Forderungen, übermitteln wir personenbezogene Daten an
                  Factoring-Unternehmen zum Zweck der Vertragserfüllung mit Ihnen auf Grundlage von Art. 6 Abs. 1 S. 1
                  lit. b) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Sonderprüfungen: Im Rahmen von Sonderprüfungen im Hinblick auf den Verdacht strafbaren Verhaltens
                  durch Mitarbeiter und bei Revisionsprüfungen bedienen wir uns unterstützender Dienstleister (etwa
                  Wirtschaftsprüfungsgesellschaften). Für die Durchführung der Revisionsprüfung übermitteln wir unter
                  Umständen Ihre personenbezogenen Daten an diese Dienstleister sowie an Ermittlungs- und
                  Strafverfolgungsbehörden sowie Aufsichtsbehörden, um unseren rechtlichen Verpflichtungen nachzukommen,
                  auf Grundlage von Art. 6 Abs. 1 S. 1 lit. c) DSGVO und aufgrund unserer berechtigten Interessen, die
                  vorgegebenen Prüfungen durchzuführen, um die Abläufe und die Effizienz in der DMSG zu überprüfen,
                  unsere rechtlichen Interessen zu wahren, durchzusetzen und / oder zu verteidigen und zur Verhinderung
                  von Betrugs- und Missbrauchsfällen, auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Behördliche Anfragen: Unter Umständen fragen uns Behörden und Netzbetreiber, ob Sie nach wie Kunde bei
                  uns sind. Dabei übermitteln wir Ihre personenbezogenen Daten (Name, Vorname und Verzugsadresse) bei
                  Vorliegen der entsprechenden Voraussetzungen an den Anfragenden aufgrund unseres berechtigten
                  Interesses, die Anfragen zu beantworten; auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Sonstige Fälle: Darüber hinaus übermitteln wir Ihre personenbezogenen Daten, soweit eine gesetzliche
                  Verpflichtung unsererseits zur Weitergabe besteht. Die Übermittlung erfolgt auf Grundlage von Art. 6
                  Abs. 1 S. 1 lit. c) DSGVO.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Berechtigte Interessen und Widerspruch</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Wir verarbeiten Ihre personenbezogenen Daten aufgrund unserer berechtigten Interessen, Ihnen auf
                  unserer Webseite Informationen zu unseren Telekommunikationsdiensten und Services bereitzustellen und
                  die IT-Sicherheit Ihnen gegenüber bei Besuch unserer Webseite zu gewährleisten, Analysen durchzuführen
                  und auf Basis dieser Analysen unseren Internetauftritt sowie unsere Telekommunikationsdienste und
                  Services zu verbessern, an der Betrugsprävention, um Sie über unsere Telekommunikationsdienste und
                  Services zu informieren und Ihnen hierzu spezielle Funktionen (etwa Abruf von Favoriten beim nächsten
                  Login) bereitzustellen, einen Zahlungsausfall Ihrerseits zu vermeiden, rechtliche Ansprüche geltend zu
                  machen und / oder durchzusetzen, effizient Rechnungen und Zahlungen zu bearbeiten, für unsere Produkte
                  und Services zu werben, unsere Produkte und Services entsprechend Ihren Bedürfnissen und Wünschen zu
                  verbessern, Ihre Anfrage zu beantworten, Ihre Kommentare / Fragen / Anregungen zu beantworten, der
                  Verhinderung von Betrugs- und Missbrauchsfällen im Unternehmen, die IT- und Netzwerksicherheit zu
                  gewährleisten sowie um interne Verwaltungstätigkeiten effizient und arbeitsteilig durchzuführen.
                </Typography>
                <br />
                <Typography>Bei Fragen dazu wenden Sie sich an</Typography>
                <Typography>Deutsche Multimedia Service GmbH</Typography>
                <Typography>Universitätsstr. 133, 44803 Bochum</Typography>
                <Link href="tel:+4980028221016">Tel: +49 (0) 800 28221016</Link>
                <Link href="mailto:internet@vonovia.de">internet@vonovia.de</Link>
                <br />
              </li>
              <li className="ListContent">
                <Typography>
                  Soweit wir Ihre personenbezogenen Daten auf Grundlage dieser berechtigten Interessen (Art. 6 Abs. 1 S.
                  1 lit. f) DSGVO) verarbeiten, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen
                  Situation ergeben, jederzeit gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch
                  einzulegen. Wir werden Ihre Daten dann für diese(n) Zweck(e) nicht mehr verarbeiten, sofern nicht
                  unsere schutzwürdigen Interessen überwiegen oder die Verarbeitung der Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen dient. Unbeschadet dessen, können Sie bei Direktwerbung (etwa
                  Newsletter) jederzeit ohne Angabe von Gründen der Verarbeitung Ihrer personenbezogenen Daten
                  widersprechen. Bitte richten Sie Ihre Anfrage an
                </Typography>
                <br />
                <Typography>Deutsche Multimedia Service GmbH</Typography>
                <Typography>Universitätsstr. 133, 44803 Bochum</Typography>
                <Link href="tel:+4980028221016">Tel: +49 (0) 800 28221016</Link>
                <Link href="mailto:internet@vonovia.de">internet@vonovia.de</Link>
                <br />
              </li>
              <li className="ListContent">
                <Typography>
                  Wenn Sie der Datenverarbeitung widersprechen, verarbeiten wir Ihre in diesem Zusammenhang erhobenen
                  personenbezogenen Daten zur Beantwortung Ihrer Anfrage. Die Verarbeitung Ihrer personenbezogenen Daten
                  erfolgt zur Erfüllung einer rechtlichen Verpflichtung auf Grundlage von Art. 6 Abs. 1 S. 1 lit. c)
                  DSGVO.
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Einwilligung und Widerruf Ihrer Einwilligung</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Sofern Sie uns eine Einwilligung für die Verarbeitung ihrer personenbezogenen Daten erteilt haben,
                  können Sie diese jederzeit widerrufen. Der Widerruf Ihrer Einwilligung wirkt für die Zukunft. Die
                  Rechtmäßigkeit der Verarbeitung Ihrer personenbezogenen Daten bis zum Zeitpunkt des Widerrufs bleibt
                  unberührt. Richten Sie bitte Ihren Widerruf schriftlich, telefonisch oder per E-Mail an
                </Typography>
                <br />
                <Typography>Deutsche Multimedia Service GmbH</Typography>
                <Typography>Universitätsstr. 133, 44803 Bochum</Typography>
                <Link href="tel:+4980028221016">Tel: +49 (0) 800 28221016</Link>
                <Link href="mailto:internet@vonovia.de">internet@vonovia.de</Link>
                <br />
              </li>
              <li className="ListContent">
                <Typography>
                  Sofern Sie Ihre Einwilligung widerrufen, verarbeiten wir Ihre in diesem Zusammenhang erhobenen
                  personenbezogenen Daten zur Beantwortung Ihrer Anfrage. Die Verarbeitung Ihrer personenbezogenen Daten
                  erfolgt zur Erfüllung einer rechtlichen Verpflichtung auf Grundlage von Art. 6 Abs. 1 S. 1 lit. c)
                  DSGVO.
                </Typography>
                <br />
              </li>
            </ol>
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Ihre Rechte</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography> Sie können jederzeit von uns nach Maßgabe der DSGVO verlangen, dass wir</Typography>
                <Typography>
                  {' '}
                  - Ihnen Auskunft über die Sie betreffenden personenbezogenen Daten, die wir verarbeiten, erteilen
                  (Art. 15 DSGVO),
                </Typography>
                <Typography>
                  {' '}
                  - personenbezogene Daten, die Sie betreffen und unrichtig sind, berichtigen (Art. 16 DSGVO) und / oder
                </Typography>
                <Typography>
                  {' '}
                  - Ihre bei uns gespeicherten personenbezogenen Daten löschen (Art. 17 DSGVO), sperren (Art. 18 DSGVO)
                  und / oder herausgeben (Art. 20 DSGVO).
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>Richten Sie bitte Ihre entsprechende Anfrage an</Typography>
                <br />
                <Typography>Deutsche Multimedia Service GmbH</Typography>
                <Typography>Universitätsstr. 133, 44803 Bochum</Typography>
                <Link href="tel:+4980028221016">Tel: +49 (0) 800 28221016</Link>
                <Link href="mailto:internet@vonovia.de">internet@vonovia.de</Link>
                <br />
              </li>
              <li className="ListContent">
                <Typography>
                  Wenn Sie uns gegenüber Ihre Rechte geltend machen, verarbeiten wir Ihre in diesem Zusammenhang
                  erhobenen personenbezogenen Daten zur Beantwortung Ihrer Anfrage. Die Verarbeitung Ihrer
                  personenbezogenen Daten erfolgt zur Erfüllung einer rechtlichen Verpflichtung auf Grundlage von Art. 6
                  Abs. 1 S. 1 lit. c) DSGVO.
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Unbeschadet der o.g. Rechte können Sie sich bei einer Aufsichtsbehörde für Datenschutz beschweren,
                  wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten durch
                  uns gegen die DSGVO verstößt (Art. 77 DSGVO).
                </Typography>
              </li>
            </ol>
            <br />
          </li>
          <li className="ListHeadline">
            <Typography variant={'h2'}>Änderungen dieser Datenschutzinformation</Typography>
            <br />
            <ol>
              <li className="ListContent">
                <Typography>
                  Es gelten die Bestimmungen dieser Datenschutzinformation (kostenlos abrufbar unter /Datenschutz) in
                  ihrer zum Zeitpunkt gültigen Fassung (vgl. Stand und Version der Datenschutzinformation).
                </Typography>
              </li>
              <li className="ListContent">
                <Typography>
                  Wir behalten uns vor, den Inhalt dieser Datenschutzinformation zu ergänzen und zu ändern. Die
                  aktualisierte Datenschutzinformation gilt ab ihrer Gültigkeit (vgl. Stand und Version der
                  Datenschutzinformation).
                </Typography>
              </li>
            </ol>
            <br />
          </li>
        </ol>
        {/* <Typography className="ListHeadline">Rechtswirksamkeit dieses Haftungsausschlusses:</Typography> */}
      </Container>
    </Paper>
  )
}

export default Dataprivacy
