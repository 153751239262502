import { FetchResult, useMutation } from '@apollo/client'
import { Mutation } from 'graphql/types'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, { AvailabilityCheckAction } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import CheckoutActions, { CheckoutAction } from 'store/Checkout/Checkout.actions'
import SaveOrderMutation from '../../graphql/mutations/SaveOrderMutation'
import { ServerResponse } from './saveOrder'

interface ConfirmReducerReturn {
  sendingDataInProgress: boolean
  setSendingDataInProgress: (bool: boolean) => void
  initAvailabilityState: () => void
  initCheckoutState: () => void
  saveOrderMutation: (options?: any) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
}

export default (): ConfirmReducerReturn => {
  const dispatchAvailabilityAction = useDispatch<Dispatch<AvailabilityCheckActions>>()
  const dispatchCheckoutAction = useDispatch<Dispatch<CheckoutActions>>()

  const initAvailabilityState = useCallback(
    () => dispatchAvailabilityAction({ type: AvailabilityCheckAction.SET_TO_INIT_STATE, payload: null }),
    [dispatchAvailabilityAction],
  )

  const initCheckoutState = useCallback(
    () => dispatchCheckoutAction({ type: CheckoutAction.SET_TO_INIT_STATE }),
    [dispatchCheckoutAction],
  )
  const navigate = useNavigate()
  const [sendingDataInProgress, setSendingDataInProgress] = useState<boolean>(false)

  const confirmCallback = (response: ServerResponse): void => {
    if (response.Code !== -1) {
      setSendingDataInProgress(false)
      initAvailabilityState()
      initCheckoutState()
      navigate('/erfolgreiche-bestellung')
    }
  }
  const [saveOrderMutation] = useMutation<Mutation>(SaveOrderMutation, {
    onCompleted: (): void => {
      confirmCallback({ OK: true, Code: 200 })
    },
    onError: (error: any): void => {
      confirmCallback({ OK: false, Code: -1, Error: error })
    },
  })

  return {
    sendingDataInProgress,
    setSendingDataInProgress,
    initAvailabilityState,
    initCheckoutState,
    saveOrderMutation,
  }
}
