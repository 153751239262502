import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import AvailabilityCheckInput from 'components/AvailabilityCheckInput'
import CheckboxIcon from 'components/CheckboxIcon'
import CheckIcon from 'components/CheckIcon'
import ChevronRightIcon from 'components/ChevronRightIcon'
import ContractSummaryInput from 'components/ContractSummaryInput'
import LoadingSpinner from 'components/LoadingSpinner'
import PriceBubble from 'components/PriceBubble'
import { LegalNoticeCategory, Product, ProductCategory, ProductOption, ProductOptionCategory } from 'graphql/types'
import { useAvailabilityCheckState } from 'pages/AvailabilityCheck/useAvailabilityCheckState'
import { useLandingPageState } from 'pages/LandingPage/useLandingPageState'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AvailabilityCheckField } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import { replaceLiToSpanTags, replacePToSpanTags } from 'utils/function'
import TestIDs from 'utils/TestIDs'
import { ModifiedTheme } from 'utils/theme'
import _ from 'lodash'

const useStyles = makeStyles((theme: Theme) => ({
  productSelection: {
    background: '#338095',
    color: ModifiedTheme.palette.primary.contrastText,
    paddingBottom: '110px',
  },

  availabilityCheck: {
    background: '#E9EDEE',
    padding: '120px 0',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '66px',
      paddingBottom: '44px',
    },
  },

  submitButton: {
    width: '100%',
    '& .MuiButton-startIcon': {
      marginRight: '18px',
    },
  },

  footnotes: {
    padding: '120px 0',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '66px',
      paddingBottom: '44px',
    },
    '& p': {
      textAlign: 'left',
      marginBottom: '1em',
      fontSize: 14,
      fontFamily: 'Whitney-normal',
    },
    '& a': {
      color: '#009aa8',
      display: 'inline',
    },
  },

  selectWrapper: {
    display: 'block',
  },

  radioWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& .MuiTypography-root': {
        textAlign: 'center',
      },
    },
  },

  speedInfo2: {
    '& h3': {
      textAlign: 'left',
    },
  },
  speedInfo1: {
    '& h3': {
      textAlign: 'left',
    },
  },

  selectItem: {
    border: '2px solid red',
  },

  productFeatures: {
    '& span': {
      fontSize: 24,
      fontFamily: 'Whitney-normal',
      marginBottom: 15,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
  },

  speedSpacer: {
    '& hr': {
      height: '1px',
      background: 'white',
      display: 'block',
      width: '100%',
      border: 'none',
      marginBottom: '40px',
      marginTop: '20px',
    },
    '& hr.onlySmall': {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  },

  featureList: {
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      margin: 'auto',
    },
  },

  smallListitem: {
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'Whitney-normal',
    '& .MuiListItemIcon-root': {
      minWidth: '26px',
    },
  },

  headlineMarginBottom: {
    marginBottom: '100px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '51px',
    },
  },

  productFeatureListItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

const LandingPage = (): JSX.Element => {
  const { path } = useParams()

  const [previousPath, setPreviousPath] = useState<any>(path)

  const {
    legalNotices,
    loading,
    productOptions,
    selectedOptions,
    selectedProduct,
    products,
    setSelectedAddress,
    setSelectedProduct,
    toggleProductOption,
    landingPageProducts,
    lps,
  } = useLandingPageState(path)
  const navigate = useNavigate()
  const classes = useStyles()

  const {
    additionalInfos,
    cities,
    focusedFieldId,
    handleCheckVZF,
    numbers,
    selectedAdditionalInfo,
    selectedCity,
    selectedNumber,
    selectedStreet,
    setSelectedAdditionalInfo,
    setSelectedCity,
    setSelectedNumber,
    setSelectedStreet,
    setZip,
    streets,
    vzfError,
    zip,
  } = useAvailabilityCheckState()

  useEffect(() => {
    // clean up the state if the user changes the params otherwise the state would contain the wrong data
    if (!_.isEqual(path, previousPath)) {
      setPreviousPath(path)
      document.location.reload()
    }
  }, [path, previousPath])

  const refSubmit = useRef<HTMLButtonElement>(null)

  const handleSubmit = (): void => {
    navigate('/verfuegbarkeitspruefung')
  }

  const renderProductInfo = (): JSX.Element => {
    return (
      <Grid item md={5} xs={12} {...TestIDs.GET_PRODUCTS('FRONT_PAGE_FORM', 'DESCRIPTION')}>
        <Box marginBottom={{ xs: 4, md: 0 }}>
          {selectedProduct && (
            <>
              <Typography variant="h3" component="h3">
                {selectedProduct.landingPage.title}
              </Typography>
              {selectedProduct.landingPage.featureText && (
                <div
                  className={'ProductInfoList'}
                  dangerouslySetInnerHTML={{
                    __html: replaceLiToSpanTags(selectedProduct.landingPage.featureText),
                  }}
                  style={{ paddingTop: '16px' }}
                />
              )}
            </>
          )}
        </Box>
      </Grid>
    )
  }
  const getSelectedProduct = (value: string): Product | undefined => products.find((p) => p.id === value)
  const handleProductsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedProduct = getSelectedProduct(event.target.value)

    if (selectedProduct) {
      setSelectedProduct(selectedProduct)
    }
  }
  const getProductOption = (value: string): ProductOption | undefined => productOptions.find((po) => po.id === value)
  const handleProductOptionsToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const toggledProductOption = getProductOption(event.target.value)
    if (toggledProductOption) {
      toggleProductOption(toggledProductOption)
    }
  }
  const renderCheckboxForm = (option: ProductOption): JSX.Element => {
    return (
      <FormControlLabel
        key={option.name + '_' + selectedOptions.includes(option)}
        control={
          <Checkbox
            checkedIcon={<CheckboxIcon checked={true} inverted={true} error={false} />}
            checked={selectedOptions.includes(option)}
            color="secondary"
            onChange={handleProductOptionsToggle}
            inputProps={{
              ...TestIDs.GET_PRODUCTS('FRONT_PAGE_FORM', option + '_SELECT'),
            }}
            className={'LandingCheckbox'}
            value={option.id}
          />
        }
        label={option.name}
        className={'selectItem'}
        {...TestIDs.GET_PRODUCTS('FRONT_PAGE_FORM', option.name)}
      />
    )
  }
  const renderRadioForm = (product: Product): JSX.Element => {
    return (
      <FormControlLabel
        key={product.name + '_' + (selectedProduct && selectedProduct.id === product.id)}
        value={product.id}
        label={product.landingPage.title}
        control={
          <Radio
            checked={selectedProduct && selectedProduct.id === product.id}
            inputProps={{
              ...TestIDs.GET_PRODUCTS('FRONT_PAGE_FORM', product.id + '_SELECT'),
            }}
          />
        }
        {...TestIDs.GET_PRODUCTS('FRONT_PAGE_FORM', product.id)}
        className={clsx('selectItem', 'selectItemNarrow')}
        style={{ backgroundColor: product.color }}
      />
    )
  }
  const renderProductsForm = (): JSX.Element => {
    return (
      <FormControl component="fieldset" className={classes.selectWrapper}>
        <RadioGroup
          aria-label="Products"
          name="Products"
          value={selectedProduct ? selectedProduct.id : null}
          onChange={handleProductsChange}
          className={classes.radioWrapper}
        >
          {[...landingPageProducts]
            .filter((p) => p.landingPage.displayed && p.category !== ProductCategory.BUNDLE)
            .map(renderRadioForm)}
        </RadioGroup>
      </FormControl>
    )
  }
  const renderProductOptionForm = (): JSX.Element | null => {
    if (!selectedProduct || productOptions.length === 0) return null

    const optionsToShow = productOptions.filter(
      (po) =>
        selectedProduct.options &&
        selectedProduct.options.map((o) => o.productOption).includes(po.id) &&
        po.landingPage.displayed &&
        po.category !== ProductOptionCategory.HARDWARE,
    )

    if (optionsToShow.length === 0) return null

    return (
      <>
        <Grid container item xs={12} className={classes.speedSpacer}>
          <hr />
        </Grid>
        <Grid container item xs={12} className={classes.speedInfo2}>
          <Grid item md={5} xs={12}>
            {[...optionsToShow].sort((a, b) => a.renderIndex - b.renderIndex).filter((o) => o.landingPage.featureText)
              .length > 0 && (
              <>
                <Typography variant="h3" component="h3" {...TestIDs.FRONTPAGE_FLATRATE_HEADLINE_TYPOGRAPHY}>
                  Zusatz-Option
                </Typography>
                <List>
                  {optionsToShow.map((o) =>
                    o.landingPage.featureText ? (
                      <ListItem key={o.id} alignItems="flex-start" className={classes.productFeatureListItem}>
                        <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography className={'ProductOptionFeatureText'}>{o.landingPage.featureText}</Typography>
                        </ListItemText>
                      </ListItem>
                    ) : null,
                  )}
                </List>
              </>
            )}
          </Grid>
          <Grid item md={1} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <FormControl component="fieldset" className={classes.selectWrapper}>
              {optionsToShow.map(renderCheckboxForm)}
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  }

  //NOTE: this is for debugging the current selected items and vouchers
  //console.log('selectedProduct:', selectedProduct)
  //console.log('selectedOptions:', selectedOptions)
  //console.log('appliedVouchers:', appliedVouchers)
  const [landingPageLegalNoticeIDs, setlandingPageLegalNoticeIDs] = useState<string[]>([])

  //NOTE: this will create an array of legalNoticeIDs for the basket only
  useEffect(() => {
    const legalNoticeBasketIDS: string[] = []
    if (lps) {
      for (let i = 0; i < lps.products.length; i++) {
        const id = lps.products[i].landingPage.legalNotice
        if (id) {
          legalNoticeBasketIDS.push(id)
        }
        if (lps.products[i].options) {
          for (let j = 0; j < lps.products[i].options.length; j++) {
            const optID = lps.products[i].options[j].productOption
            const option = productOptions.find((opt) => opt.id === optID)
            if (option && option.landingPage.legalNotice) {
              legalNoticeBasketIDS.push(option.landingPage.legalNotice)
            }
          }
        }
      }
    }
    setlandingPageLegalNoticeIDs(legalNoticeBasketIDS)
  }, [lps, legalNotices, productOptions])

  return (
    <>
      <Box className={classes.productSelection} {...TestIDs.FRONTPAGE_PRODUCT_SELECTOR}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} className={'h1Container'}>
              <Typography variant="h1" component="h2" align={'center'}>
                {lps ? lps.title : ''}
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid container item xs={12} className={classes.speedInfo1}>
                {renderProductInfo()}
                <Grid item md={1} xs={12}></Grid>
                <Grid item md={6} xs={12}>
                  {renderProductsForm()}
                </Grid>
              </Grid>
              {renderProductOptionForm()}
            </Grid>
            <Grid item xs={12} md={1} className={classes.speedSpacer}>
              <hr className={'onlySmall'} />
            </Grid>
            <Box flex={1} display={'flex'} flexDirection="column" alignItems={{ xs: 'center', md: 'flexStart' }}>
              {selectedProduct && (
                <>
                  <PriceBubble
                    legalNotices={legalNotices}
                    selectedProduct={selectedProduct}
                    selectedProductOptions={selectedOptions}
                    {...TestIDs.FRONTPAGE_PRICE_BUBBLE}
                  />
                  {selectedProduct.landingPage.priceInfoText && (
                    <div
                      className={'ProductPriceInfoText'}
                      dangerouslySetInnerHTML={{
                        __html: replaceLiToSpanTags(selectedProduct.landingPage.priceInfoText),
                      }}
                      style={{ paddingTop: '20px' }}
                    />
                  )}
                  <Box display={'flex'} mt={3}>
                    <Button
                      ref={refSubmit}
                      focusRipple={true}
                      color={'secondary'}
                      startIcon={<ChevronRightIcon />}
                      onClick={handleSubmit}
                      {...TestIDs.FRONTPAGE_ORDER_BUTTON}
                    >
                      Jetzt Bestellen
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.availabilityCheck}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <Typography
                {...TestIDs.AVAILABILITY_CHECK_HEADER_TYPOGRAPHY}
                variant={'h1'}
                component={'h2'}
                align={'center'}
                className={clsx(
                  classes.headlineMarginBottom,
                  zip.length === 5 && cities.length === 0 ? 'TextError' : '',
                )}
              >
                {zip.length === 5 && cities.length === 0 ? 'Es tut uns leid.' : 'Jetzt Verfügbarkeit prüfen'}
              </Typography>
              <Box width={'55%'} mt={2} mr={'auto'} mb={0} ml={'auto'}>
                <Typography
                  {...TestIDs.AVAILABILITY_CHECK_SUBHEADER_TYPOGRAPHY}
                  className={zip.length === 5 && cities.length === 0 ? 'TextError TextBig' : 'TextBig'}
                  align={'center'}
                >
                  {zip.length === 5 && cities.length === 0
                    ? 'Leider bieten wir unseren Service an der von Ihnen gewünschten Postleitzahl noch nicht an.'
                    : null}
                </Typography>
              </Box>
              <AvailabilityCheckInput
                buttonText={'Verfügbarkeit prüfen'}
                disabledAdditionalInfo={additionalInfos.length < 2}
                disabledCity={cities.length < 2}
                disabledNumber={numbers.length < 2}
                disabledStreet={streets.length < 2}
                disabledZip={false}
                disabledSubmit={focusedFieldId !== AvailabilityCheckField.SUBMIT}
                additionalInfos={additionalInfos}
                cities={cities}
                focusedFieldId={focusedFieldId}
                numbers={numbers}
                onSubmit={(): void => {
                  setSelectedAddress()
                  navigate('/auschecken')
                }}
                selectedAdditionalInfo={selectedAdditionalInfo}
                selectedCity={selectedCity}
                selectedNumber={selectedNumber}
                selectedStreet={selectedStreet}
                setSelectedAdditionalInfo={setSelectedAdditionalInfo}
                setSelectedCity={setSelectedCity}
                setSelectedNumber={setSelectedNumber}
                setSelectedStreet={setSelectedStreet}
                setZip={setZip}
                streets={streets}
                zip={zip}
                autoFocusOnMount={false}
              />
              <ContractSummaryInput onClick={handleCheckVZF} error={vzfError} disabledSubmit={false} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.footnotes} {...TestIDs.FRONTPAGE_LEGAL_NOTES}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant={'h1'} component={'h2'} className={classes.headlineMarginBottom} align={'center'}>
                Rechtliche Hinweise
              </Typography>
              <Box className={'LegalNoticeText'}>
                {legalNotices.length > 0 &&
                  [...legalNotices]
                    .sort((a, b) => a.renderIndex - b.renderIndex)
                    .map((ln, index) =>
                      ln.category === LegalNoticeCategory.LANDINGPAGE &&
                      landingPageLegalNoticeIDs.includes(ln.id) &&
                      ln.text ? (
                        <div
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: `${ln.renderIndex}) ${replacePToSpanTags(ln.text)}`,
                          }}
                        />
                      ) : null,
                    )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <LoadingSpinner loading={loading} />
    </>
  )
}

export default LandingPage
