import gql from 'graphql-tag'

export const VALIDATE_VOUCHER = gql`
  query validateVoucherCode($code: String!, $selectedAddressID: ID!) {
    validateVoucherCode(code: $code, selectedAddressID: $selectedAddressID) {
      isValid
      voucher {
        availableFor
        availableType
        basketSubtitle
        basketTitle
        code
        discountType
        legalNotice
        name
        validityType
        value
        valueType
      }
    }
  }
`
