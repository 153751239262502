export interface GetTestIDReturn {
  [key: string]: string | undefined
}

export const getTestID = (id: string): GetTestIDReturn => {
  return process.env.NODE_ENV === 'production'
    ? {}
    : {
        'data-testid': id,
      }
}
type PRODUCT_BASE = 'CHECKOUT_FORM' | 'FRONT_PAGE_FORM'
const TestIDs = {
  GET_PRODUCTS: (input: PRODUCT_BASE, category?: string): GetTestIDReturn =>
    getTestID('PRODUCTS_' + input + (category ? '_' + category : '')),
  GET_PRODUCT_OPTION: (input: PRODUCT_BASE, category?: string): GetTestIDReturn =>
    getTestID('PRODUCT_OPTION_' + input + (category ? '_' + category : '')),
  APPARTMENT_CONTRACT_NUMBER_INPUT: getTestID('appartmentFields_vertragsnummer,Input'),
  APPARTMENT_FIELDS_FLOOR_INPUT: getTestID('appartmentFields_etageInput'),
  APPARTMENT_FIELDS_FLOOR_POSITION_INPUT: getTestID('appartmentFields_etagePositionInput'),
  APPARTMENT_FIELDS_FLOOR_POSITION_TOOLTIP: getTestID('appartmentFields_etagePositionTooltip'),
  APPARTMENT_FIELDS_FLOOR_TOOLTIP: getTestID('appartmentFields_etageTooltip'),
  APPARTMENT_FIELDS_HEADER: getTestID('appartmentFields_headerTypography'),
  AVAILABILITY_CHECK_ADDITIONAL_SELECT: getTestID('dropdown-Zusatz-select'),
  AVAILABILITY_CHECK_CITY_INPUT: getTestID('availabilityCheck_cityInput'),
  AVAILABILITY_CHECK_CITY_SELECT: getTestID('dropdown-Ort-select'),
  AVAILABILITY_CHECK_FORM: getTestID('availabilityCheck_form'),
  AVAILABILITY_CHECK_HEADER_TYPOGRAPHY: getTestID('availabilityCheck_headerTypography'),
  AVAILABILITY_CHECK_NUMBER_SELECT: getTestID('dropdown-Nr\\.-select'),
  AVAILABILITY_CHECK_STREET_SELECT: getTestID('dropdown-Straße-select'),
  AVAILABILITY_CHECK_SUBHEADER_TYPOGRAPHY: getTestID('availabilityCheck_subheaderTypography'),
  AVAILABILITY_CHECK_SUBMIT_BUTTON: getTestID('availabilityCheck_submitButton'),
  AVAILABILITY_CHECK_ZIP_INPUT: getTestID('availabilityCheck_zipInput'),
  BANK_FIELDS_CHECKBOX: getTestID('bankFields_checkbox'),
  BANK_FIELDS_HEADER: getTestID('bankFields_headerTypography'),
  BASKET_CONTAINER: getTestID('basket'),
  BASKET_FLATRATE: getTestID('basket_flatrate'),
  BASKET_HEADER_TYPOGRAPHY: getTestID('basket_headerTypography'),
  BASKET_PRICE_MONTHLY: getTestID('basket_priceMonthly'),
  BASKET_PRICE_ONCE: getTestID('basket_priceOnce'),
  BASKET_OPTION: getTestID('basket_option'),
  BASKET_PRODUCT: getTestID('basket_product'),
  BASKET_ROUTER: getTestID('basket_router'),
  BASKET_SUM_MONTHLY: getTestID('basket_sumMonthly'),
  BASKET_SUM_ONCE: getTestID('basket_sumOnce'),
  BUNDLE_PRODUCT_RADIO_YES: getTestID('BundleProductRadio_yes'),
  BUNDLE_PRODUCT_RADIO_NO: getTestID('BundleProductRadio_no'),
  BUNDLE_PRODUCT_FIELDS_CHECKBOX: getTestID('BundleProductFields_checkbox'),
  BUNDLE_PRODUCT_FIELDS_HEADER: getTestID('BundleProductFields_headerTypography'),
  BUNDLE_PRODUCT_FIELDS_SUBHEADER: getTestID('BundleProductFields_subheaderTypography'),
  CHECKOUT_FIELDS_ACCOUNT_HOLDER_HEADER: getTestID('checkoutFields_accountHolderHeader'),
  CHECKOUT_FIELDS_ACCOUNT_SALUTATION_SELECT: getTestID('checkoutFields_salutationSelect'),
  CHECKOUT_FLATRATE_SELECT: getTestID('checkout_flatrateSelect'),
  CHECKOUT_FLATRATE_SELECT_HEADER_TYPOGRAPHY: getTestID('checkout_flatrateSelectHeaderTypography'),
  CHECKOUT_IBAN_INPUT: getTestID('ibanInput_input'),
  CHECKOUT_INVALID_FIELD_LIST: getTestID('checkout_invalidFieldList'),
  CHECKOUT_MAINDATA_BIRTHDAY_INPUT: getTestID('checkout_maindataBirthdayInput'),
  CHECKOUT_MAINDATA_EMAIL_INPUT: getTestID('checkout_maindataEmailInput'),
  CHECKOUT_MAINDATA_LASTNAME_INPUT: getTestID('checkout_maindataLastnameInput'),
  CHECKOUT_MAINDATA_NAME_INPUT: getTestID('checkout_maindataNameInput'),
  CHECKOUT_MAINDATA_PHONE_INPUT: getTestID('checkout_maindataPhoneInput'),
  CHECKOUT_MAINDATA_SALUTATION_SELECT: getTestID('checkout_maindataSalutationSelect'),
  CHECKOUT_MAINDATA_TITLE_INPUT: getTestID('checkout_maindataTitleInput'),
  CHECKOUT_NAME_INPUT: getTestID('checkout_nameInput'),
  CHECKOUT_NOPHONE_SELECT: getTestID('checkout_nophoneSelect'),
  CHECKOUT_SPEED_1000_SELECT: getTestID('checkout_speed1000Select'),
  CHECKOUT_NO_SPEED_SELECT: getTestID('checkout_noSpeedSelect'),
  CHECKOUT_SPEED_SELECT: getTestID('checkout_speed1000Select'),
  CHECKOUT_SPEED_100_SELECT: getTestID('checkout_speed100Select'),
  CHECKOUT_SPEED_500_SELECT: getTestID('checkout_speed500Select'),
  CHECKOUT_SPEED_SELECT_HEADER_TYPOGRAPHY: getTestID('checkout_speedSelectHeaderTypography'),
  CHECKOUT_SUBMIT_BUTTON: getTestID('checkout_submitButton'),
  CONFIRMPAGE_HEADER_SUBLINE: getTestID('confirmpage_header_subline'),
  CONFIRMPAGE_LEGAL_CHECKBOX: getTestID('confirmpage_legalCheckbox'),
  CONFIRMPAGE_INFOSHEET_CHECKBOX: getTestID('confirmpage_infosheetCheckbox'),
  CONFIRMPAGE_HEADER_TITLE: getTestID('confirmpage_header_title'),
  CONFIRMPAGE_ORDER_BUTTON: getTestID('confirmpage_order_button'),
  COOKIELEGALS_CONTENT: getTestID('cookieLegals_content'),
  DATAPRIVACY_CONTENT: getTestID('dataprivacy_content'),
  DATA_TABLE_HEADER: getTestID('dataTable_headerTypography'),
  DATA_TABLE_KEY: getTestID('dataTable_keyTypography'),
  DATA_TABLE_VALUE: getTestID('dataTable_valueTypography'),
  DESIRE_INSTALLATION_FIELDS_DATE_PICKER: getTestID('desiredInstallation_datePicker'),
  DESIRE_INSTALLATION_FIELDS_DATE_PICKER_TOOLTIP: getTestID('desiredInstallation_datePickerTooltip'),
  DESIRE_INSTALLATION_FIELDS_HEADER: getTestID('desiredInstallation_headerTypography'),
  ERROR_PAGE_BODY_TYPOGRAPHY: getTestID('errorPage_bodyTypography'),
  ERROR_PAGE_HEADER_TYPOGRAPHY: getTestID('errorPage_headerTypography'),
  ERROR_PAGE_RETURN_BUTTON: getTestID('errorPage_returnButton'),
  FRONTPAGE_FLATRATE: getTestID('frontpage_flatrateCheckbox'),
  FRONTPAGE_FLATRATE_HEADLINE_TYPOGRAPHY: getTestID('frontpage_flatrateHeadlineTypography'),
  FRONTPAGE_LEGAL_NOTES: getTestID('frontpage_legalNotes'),
  FRONTPAGE_ORDER_BUTTON: getTestID('frontpage_orderButton'),
  FRONTPAGE_PRICE_BUBBLE: getTestID('frontpage_priceBubble'),
  FRONTPAGE_PRODUCT_DESCRIPTION: getTestID('frontpage_productDescription'),
  FRONTPAGE_PRODUCT_SELECTOR: getTestID('frontpage_productSelector'),
  FRONTPAGE_SPEED_100: getTestID('frontpage_speed100'),
  FRONTPAGE_SPEED_1000: getTestID('frontpage_speed1000'),
  FRONTPAGE_SPEED_1000_SELECT: getTestID('frontpage_speed1000Select'),
  FRONTPAGE_NO_SPEED: getTestID('frontpage_noSpeed'),
  FRONTPAGE_NO_SPEED_SELECT: getTestID('frontpage_noSpeedSelect'),
  FRONTPAGE_SPEED_100_SELECT: getTestID('frontpage_speed100Select'),
  FRONTPAGE_SPEED_500: getTestID('frontpage_speed500'),
  FRONTPAGE_SPEED_500_SELECT: getTestID('frontpage_speed500Select'),
  IBAN_INPUT_TEXTFIELD: getTestID('ibanInput_input'),
  IBAN_INPUT_TOOLTIP: getTestID('ibanInput_inputTooltip'),
  IMPRINT_CONTENT: getTestID('imprint_content'),
  MAIN_FIELDS_ADDITIONAL_INFO_INPUT: getTestID('mainFields_zusatzInput'),
  MAIN_FIELDS_ADRESS_NUMBER_INPUT: getTestID('mainFields_huseNummerInput'),
  MAIN_FIELDS_AGE_INPUT: getTestID('mainFields_alteInput'),
  MAIN_FIELDS_AGE_INPUT_TOOLTIP: getTestID('mainFields_alteTooltip'),
  MAIN_FIELDS_CITY_INPUT: getTestID('mainFields_ortInput'),
  MAIN_FIELDS_EMAIL_INPUT: getTestID('mainFields_emailInput'),
  MAIN_FIELDS_EMAIL_INPUT_TOOLTIP: getTestID('mainFields_emailTooltip'),
  MAIN_FIELDS_HEADER: getTestID('mainFields_headerTypography'),
  MAIN_FIELDS_NAME_INPUT: getTestID('mainFields_nameInput'),
  MAIN_FIELDS_NAME_INPUT_TOOLTIP: getTestID('mainFields_nameTooltip'),
  MAIN_FIELDS_SALUTATION_SELECT: getTestID('mainFields_anredeSelect'),
  MAIN_FIELDS_STREET_INPUT: getTestID('mainFields_strasseInput'),
  MAIN_FIELDS_SURNAME_INPUT: getTestID('mainFields_nachnameInput'),
  MAIN_FIELDS_SURNAME_INPUT_TOOLTIP: getTestID('mainFields_nachnameTooltip'),
  MAIN_FIELDS_TELEPHONE_INPUT: getTestID('mainFields_telInput'),
  MAIN_FIELDS_TELEPHONE_INPUT_TOOLTIP: getTestID('mainFields_telTooltip'),
  MAIN_FIELDS_TITLE_INPUT: getTestID('mainFields_titleInput'),
  MAIN_FIELDS_ZIPCODE_INPUT: getTestID('mainFields_plzInput'),
  MAIN_TENANT_HEADER: getTestID('mainTenantFields_headerTypography'),
  MAIN_TENANT_NAME_INPUT: getTestID('mainTenantFields_nameInput'),
  MAIN_TENANT_NAME_INPUT_TOOLTIP: getTestID('mainTenantFields_nameInputTooltip'),
  MAIN_TENANT_SURNAME_INPUT: getTestID('mainTenantFields_surnameInput'),
  MAIN_TENANT_SURNAME_INPUT_TOOLTIP: getTestID('mainTenantFields_surnameInputTooltip'),
  OPTION_ITEM_COMPOMEMT_CLICK: getTestID('optionItemComponent_clickGrid'),
  PAGE_FOOTER: getTestID('page_footer'),
  PAGE_FOOTER_LINK: getTestID('page_footerLink'),
  PAGE_HEADER: getTestID('page_header'),
  PAGE_HEADER_HELP_BUTTON: getTestID('page_headerHelpButton'),
  PAGE_HEADER_HELP_POPUP: getTestID('page_headerHelpPopup'),
  PAGE_HEADER_LOGO: getTestID('page_headerLogo'),
  PAGE_HEADER_LOGO_ICON_BUTTON: getTestID('page_headerLogoIconButton'),
  PAYMENT_FIELDS_HEADER: getTestID('paymentFields_headerTypography'),
  PORTABILITY_CONTRACT_HOLDER_ADD_NEW_FIELD: getTestID('portabilityFields_vertragsinhaberAddButton'),
  PORTABILITY_CONTRACT_HOLDER_ADD_NEW_FIELD_LABEL: getTestID('portabilityFields_vertragsinhaberAddButtonLabel'),
  PORTABILITY_CONTRACT_HOLDER_HEADER: getTestID('portabilityFields_vertragsinhaberHeaderTypography'),
  PORTABILITY_CURRENT_ADRESS_HEADER: getTestID('portabilityFields_bisherigeAdresseHeaderTypography'),
  PORTABILITY_CURRENT_ADRESS_SUBTITLE: getTestID('portabilityFields_bisherigeAdresseSubtitleTypography'),
  PORTABILITY_TAKE_PHONE_NUMBER_ADD_NEW_FIELD: getTestID('portabilityFields_rufnummernmitnahmeAddButton'),
  PORTABILITY_TAKE_PHONE_NUMBER_ADD_NEW_FIELD_LABEL: getTestID('portabilityFields_rufnummernmitnahmeAddButtonLabel'),
  PORTABILITY_TAKE_PHONE_NUMBER_HEADER: getTestID('portabilityFields_rufnummernmitnahmeHeaderTypography'),
  PORTABILITY_TAKE_PHONE_NUMBER_SUBHEADER: getTestID('portabilityFields_rufnummernmitnahmeSubheaderTypography'),
  SUCCEEDED_PAGE_BODY_TYPOGRAPHY: getTestID('succeededPage_bodyTypography'),
  SUCCEEDED_PAGE_HEADER_TYPOGRAPHY: getTestID('succeededPage_headerTypography'),
  SUCCEEDED_PAGE_RETURN_BUTTON: getTestID('succeededPage_returnButton'),
  TEXT_FIELD_RENDERER_COMPONENT_INPUT: getTestID('textFieldRendererComponent_textFieldInput'),
  TEXT_FIELD_RENDERER_COMPONENT_TOOLTIP: getTestID('textFieldRendererComponent_toolTip'),
  UPLOAD_FILE_DELETE: getTestID('uploadFile_deleteIconButton'),
  UPLOAD_FILE_INPUT: getTestID('uploadFile_input'),
  UPLOAD_FILE_NAME: getTestID('uploadFile_nameTypography'),
  UPLOAD_FILE_TEXT: getTestID('uploadFile_textTypography'),
}

export default TestIDs
