import { Box, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { LegalNotice, Product, ProductOption, PriceInterval } from 'graphql/types'
import React, { useMemo } from 'react'
import { priseTofixed } from 'utils/checkoutFunctions'
import TestIDs from 'utils/TestIDs'

const useStyles = makeStyles(() => ({
  bubble: {
    display: 'inline-block',
    alignContent: 'centered',
    borderRadius: '50%',
    height: '222px',
    width: '222px',
    textAlign: 'center',
    color: 'white',
    transition: 'background-color 300ms',

    '& .wrapper': {
      display: 'grid',
      alignContent: 'center',
      height: '100%',
    },

    '& h3': {
      borderBottom: '2px solid white',
      margin: '0 19px 3px 19px',
      paddingBottom: '3px',
      lineHeight: 1,
      alignSelf: 'end',
      fontFamily: 'Whitney-normal',
      fontWeight: 'normal',
      fontSize: 90,
    },

    '& h4': {
      borderBottom: '2px solid white',
      margin: '0 19px 3px 19px',
      paddingBottom: '3px',
      lineHeight: 1,
      alignSelf: 'end',
      fontFamily: 'Whitney-normal',
      fontWeight: 'normal',
      fontSize: 60,
    },

    '& h6': {
      borderBottom: '2px solid white',
      margin: '0 19px 3px 19px',
      paddingBottom: '3px',
      lineHeight: 1,
      alignSelf: 'end',
      fontFamily: 'Whitney-normal',
      fontWeight: 'normal',
      fontSize: 50,
    },
    '& p': {
      fontFamily: 'Whitney-normal',
      fontSize: 24,
    },
    '& sup': {
      fontSize: 12,
    },
  },
}))
export interface PriceBubbleProps {
  selectedProduct: Product
  selectedProductOptions: ProductOption[]
  legalNotices: LegalNotice[]
}

const PriceBubble = (props: PriceBubbleProps): JSX.Element => {
  const classes = useStyles()
  const { selectedProduct, selectedProductOptions, legalNotices } = props

  const legalNoticeNumbers = useMemo(() => {
    let legalNoticeIDs: string[] = []

    if (selectedProduct.landingPage.legalNotice) {
      legalNoticeIDs.push(selectedProduct.landingPage.legalNotice)
    }

    for (let i = 0; i < selectedProductOptions.length; i++) {
      const option = selectedProductOptions[i]
      if (option.landingPage.legalNotice) {
        legalNoticeIDs.push(option.landingPage.legalNotice)
      }
    }

    legalNoticeIDs = [...new Set(legalNoticeIDs)]

    const legalNoticeNumbers: number[] = []

    for (let i = 0; i < legalNoticeIDs.length; i++) {
      const legalNoticeID = legalNoticeIDs[i]
      const legalNotice = legalNotices.find((ln) => ln.id === legalNoticeID)
      if (legalNotice) {
        legalNoticeNumbers.push(legalNotice.renderIndex)
      }
    }

    return legalNoticeNumbers
  }, [selectedProduct, selectedProductOptions, legalNotices])

  const price = useMemo(() => {
    let total = 0
    for (let i = 0; i < selectedProductOptions.length; i++) {
      if (selectedProductOptions[i].price.interval === PriceInterval.MONTHLY) {
        total += selectedProductOptions[i].price.value
      }
    }
    if (selectedProduct.price.interval === PriceInterval.MONTHLY) {
      total += selectedProduct.price.value
    }
    return total
  }, [selectedProduct, selectedProductOptions])

  const textComponent = price.toString().length > 4 ? 'h6' : price.toString().length > 2 ? 'h4' : 'h3'

  return (
    <Box className={clsx(classes.bubble)} style={{ backgroundColor: selectedProduct.color }}>
      <Box className="wrapper" {...TestIDs.FRONTPAGE_PRICE_BUBBLE}>
        <Typography variant="h1" component={textComponent} align={'center'}>
          {priseTofixed(price)} &euro;
        </Typography>
        <Box display={'flex'} justifyContent={'center'} marginTop={1}>
          <Typography component="p" align={'center'}>
            pro Monat
          </Typography>
          <Typography component={'sup'} align={'center'}>
            {legalNoticeNumbers.map((ln) => `${ln}) `)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PriceBubble
