import { Button, Grid, Typography } from '@material-ui/core'
import ChevronRightIcon from 'components/ChevronRightIcon'
import { Product, ProductCategory } from 'graphql/types'
import * as React from 'react'
import BundleProductFields from './BundleProductFields'

export interface ContractSummaryProps {
  bundleAvailable: Product | undefined
  selectedOption?: boolean
  legalAccepted: boolean
  toggleLegalAccepted: (payload: boolean) => void
  onClickVZF: () => void
  setSelectedOption: (payload: boolean) => void
  vzfID?: string
  selectedProduct: Product | undefined
}

const ContractSummary = (props: ContractSummaryProps): JSX.Element => {
  const {
    bundleAvailable,
    legalAccepted,
    onClickVZF,
    setSelectedOption,
    toggleLegalAccepted,
    selectedOption,
    vzfID,
    selectedProduct,
  } = props
  const [bundleError, setBundleError] = React.useState<string[]>([])
  const clearInvalidField = (p: string): void => {
    if (p === 'bundleProduct') setBundleError([])
  }

  const renderProducts = (): JSX.Element => (
    <>
      {selectedProduct?.category === ProductCategory.INTERNET && (
        <BundleProductFields
          disable={!!vzfID}
          bundleProduct={bundleAvailable}
          bundleProductState={selectedOption}
          hasBundleProduct={legalAccepted}
          clearInvalidField={clearInvalidField}
          invalidFields={bundleError}
          setBundleProductState={setSelectedOption}
          toggleHasBundleProduct={toggleLegalAccepted}
        />
      )}
    </>
  )
  const handleOnVZFClick = (): void => {
    if (bundleAvailable === undefined) {
      onClickVZF()
    } else if (!!bundleAvailable.orderPage.renderDetails) {
      if (selectedOption === undefined && selectedProduct?.category === ProductCategory.INTERNET) {
        setBundleError(['bundleProduct'])
      } else if (selectedOption === true && !legalAccepted) {
        setBundleError(['bundleProduct'])
      } else {
        onClickVZF()
      }
    } else {
      onClickVZF()
    }
  }
  return (
    <>
      {vzfID !== undefined ? (
        <>
          {bundleAvailable && (
            <Grid item container spacing={2}>
              <Grid item xs style={{ marginTop: '15px', marginBottom: '15px' }}>
                {renderProducts()}
              </Grid>
            </Grid>
          )}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h3'} className={'sectionH3'}>
                Vertragszusammenfassung
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h6'}>Ihre Vertragszusammenfassungsnummer (VZF NR.) lautet: {vzfID}</Typography>
            </Grid>
            <Grid item xs={12} lg={7} />
            <Grid item xs={12} lg={5}>
              <Button
                focusRipple={true}
                color={'secondary'}
                onClick={handleOnVZFClick}
                startIcon={<ChevronRightIcon />}
              >
                VZF herunterladen
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {bundleAvailable && renderProducts()}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h3'} className={'sectionH3'}>
                Vertragszusammenfassung
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h6'}>
                Sie müssen die Vertragszusammenfassung herunterladen um mit der Bearbeitung weiter fortzufahren. Möchten
                Sie die Bestellung an anderer Stelle fortsetzen, dann haben Sie die Möglichkeit direkt mit Ihrer
                Vertragszusammenfassungsnummer wieder zur Bestellung zurück zu kehren.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={7} />
            <Grid item xs={12} lg={5}>
              <Button
                disabled={bundleError.length > 0}
                focusRipple={true}
                color={'secondary'}
                onClick={handleOnVZFClick}
                startIcon={<ChevronRightIcon />}
              >
                VZF herunterladen
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
export default ContractSummary
