import { Checkbox, FormControl, Grid, Radio, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import CheckboxIcon from 'components/CheckboxIcon'
import * as React from 'react'
import TestIDs, { GetTestIDReturn } from 'utils/TestIDs'

export interface OptionItemProps {
  onClick: () => void
  title: string
  subtitle?: JSX.Element
  price?: number
  selected: boolean
  variant: 'h3' | 'h4'
  disable?: boolean
  checkBox?: boolean
  testId?: GetTestIDReturn
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: 8,
    padding: '20px 20px 20px 20px',
    '&:hover': {
      cursor: 'pointer',
    },
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 25,
    },
    '& .MuiGrid-root': {
      [theme.breakpoints.down('xs')]: {
        flexBasis: 'auto',
      },
    },
  },
  disable: {
    borderRadius: 8,
    padding: '20px 20px 20px 20px',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 25,
    },
    '& .MuiGrid-root': {
      [theme.breakpoints.down('xs')]: {
        flexBasis: 'auto',
      },
    },
  },
  radioContainer: {
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
    },
  },

  title: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      '& h3': {
        textAlign: 'center',
        fontSize: '32px',
      },
      '& p': {
        textAlign: 'center',
        paddingBottom: '15px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
  },
  priceInfo: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'flex-end',
      '& h3': {
        paddingRight: '10px',
        fontSize: '32px',
        marginBottom: 0,
      },
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      flexDirection: 'column',
      '& h3, & p': {
        textAlign: 'right',
      },
    },
  },
  isSelected: {
    backgroundColor: '#009AA8',
    color: '#FFF',
    marginBottom: 10,
  },
  isDeselected: {
    backgroundColor: '#DDE2E5',
    color: '#1A1A1A',
    marginBottom: 10,
  },
}))

const OptionItem = (props: OptionItemProps): JSX.Element => {
  const classes = useStyles()
  const wrapperClasses = [classes.wrapper, props.selected ? classes.isSelected : classes.isDeselected]
  const disableClasses = [classes.disable, props.selected ? classes.isSelected : classes.isDeselected]
  const newPrice = props.price && props.price % 1 !== 0 ? props.price.toFixed(2).replace('.', ',') : props.price
  return (
    <Grid
      container
      onClick={props.disable ? undefined : props.onClick}
      className={props.disable ? clsx(disableClasses) : clsx(wrapperClasses)}
      direction={'row'}
      {...TestIDs.OPTION_ITEM_COMPOMEMT_CLICK}
    >
      <Grid item xs sm={1} container justifyContent={'center'} alignItems={'center'} className={classes.radioContainer}>
        <FormControl style={{ height: props.checkBox ? 30 : undefined }}>
          {props.checkBox ? (
            <Checkbox
              checkedIcon={<CheckboxIcon checked={props.selected} inverted={true} error={false} />}
              checked={props.selected}
              value={props.price}
              name={props.title}
              inputProps={{ 'aria-label': props.title }}
              className={props.selected ? classes.isSelected : undefined}
            />
          ) : (
            <Radio
              checked={props.selected}
              value={props.price}
              name={props.title}
              inputProps={{ 'aria-label': props.title }}
            />
          )}
        </FormControl>
      </Grid>
      <Grid container item xs className={classes.title}>
        <Grid item className={props.variant === 'h4' ? 'OptionItemSingleLine' : ''}>
          <Typography variant={props.variant} className={props.variant === 'h4' ? 'OptionItemSingleLine' : ''}>
            {props.title}
          </Typography>
        </Grid>
        {props.subtitle && (
          <Grid item>
            <Typography variant="h6">{props.subtitle}</Typography>
          </Grid>
        )}
      </Grid>
      {props.price && (
        <Grid
          container
          item
          xs={12}
          sm={props.price % 1 !== 0 ? 3 : 2}
          className={classes.priceInfo}
          justifyContent={'center'}
        >
          <Grid item>
            <Typography variant={'h3'} component={'h3'}>
              {newPrice} €
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">pro Monat</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default OptionItem
