import * as React from 'react'

const HelpIcon = (): JSX.Element => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C0 2.23712 2.23712 0 5 0H21.6667C22.5871 0 23.3333 0.746192 23.3333 1.66667C23.3333 2.58714 22.5871 3.33333 21.6667 3.33333H5C4.07807 3.33333 3.33333 4.07807 3.33333 5V35C3.33333 35.9219 4.07807 36.6667 5 36.6667H35C35.9218 36.6667 36.6667 35.9219 36.6667 35V18.3333C36.6667 17.4129 37.4129 16.6667 38.3333 16.6667C39.2538 16.6667 40 17.4129 40 18.3333V35C40 37.7629 37.7626 40 35 40H5C2.23712 40 0 37.7629 0 35V5ZM30.4882 0.488155C31.139 -0.162718 32.1943 -0.162718 32.8452 0.488155L39.5118 7.15482C40.1627 7.8057 40.1627 8.86097 39.5118 9.51184L19.5118 29.5118C19.3289 29.6948 19.1058 29.8326 18.8604 29.9145L8.86038 33.2478C8.26149 33.4474 7.60121 33.2916 7.15482 32.8452C6.70843 32.3988 6.55256 31.7385 6.75219 31.1396L10.0855 21.1396C10.1673 20.8942 10.3052 20.6711 10.4882 20.4882L25.4876 5.48867C25.4878 5.4885 25.488 5.48833 25.4882 5.48816C25.4883 5.48798 25.4885 5.48781 25.4887 5.48764L30.4882 0.488155ZM26.6667 9.02369L13.1234 22.567L10.9686 29.0314L17.433 26.8766L30.9763 13.3333L26.6667 9.02369ZM33.3333 10.9763L29.0237 6.66667L31.6667 4.02369L35.9763 8.33333L33.3333 10.9763Z"
        fill="#878787"
      />
    </svg>
  )
}

export default HelpIcon
