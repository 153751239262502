import { Fade, TextField, Tooltip } from '@material-ui/core'
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input'
import clsx from 'clsx'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'
import ErrorList from '../../utils/ErrorList'
import formatIban from './formatIban'

interface IbanProps {
  onChange?: (iban: string) => void
  value?: string
  initialValue?: string
  inputProps?: StandardInputProps['inputProps']
  // eslint-disable-next-line
  inputRef?: React.Ref<any>
  invalidFields: string[]
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
  label: string
}

interface IbanState {
  iban: string
  formattedIban: string
}

export class IbanInput extends React.Component<IbanProps, IbanState> {
  constructor(props: IbanProps) {
    super(props)
    this.state = this.getIbanStateByInput(props.initialValue || '')
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const ibanInput = event.target.value || ''
    const newState = this.getIbanStateByInput(ibanInput)

    this.setState(newState, () => {
      this.propUpdate(this.state.iban)
    })
  }

  getIbanStateByInput = (input: string): IbanState => {
    const iban = input.replace(/[^0-9a-zA-Z]/gi, '').toUpperCase()

    return {
      iban,
      formattedIban: formatIban(iban),
    }
  }

  propUpdate = (iban: string): void => {
    if (!this.props.onChange) {
      return
    }

    this.props.onChange(iban)
  }

  render(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { invalidFields, onChange, focusedField, setFocusedField, label, initialValue, ...restProps } = this.props
    return (
      <Tooltip
        {...TestIDs.IBAN_INPUT_TOOLTIP}
        open={invalidFields.indexOf('accountData-iban') !== -1 && focusedField === 'accountData-iban'}
        placement={'top-start'}
        PopperProps={{
          className:
            ErrorList.PaymentDetailsIban.length > 50
              ? clsx('BigErrorMessage', 'MuiTooltip-popper')
              : ErrorList.PaymentDetailsIban.length > 40
              ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
              : 'MuiTooltip-popper',
        }}
        title={ErrorList.PaymentDetailsIban}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 100 }}
      >
        <TextField
          inputProps={{
            maxLength: 100,
          }}
          {...TestIDs.IBAN_INPUT_TEXTFIELD}
          onClick={(): void => setFocusedField('accountData-iban')}
          onBlur={(): void => setFocusedField('')}
          error={invalidFields.indexOf('accountData-iban') !== -1}
          variant={'filled'}
          type="text"
          fullWidth={true}
          onChange={this.handleOnChange}
          value={this.state.formattedIban}
          label={label}
          InputProps={{
            className: 'fallbackLightBackground',
          }}
          {...restProps}
        />
      </Tooltip>
    )
  }
}

export default IbanInput
