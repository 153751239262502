import { Box, Container } from '@material-ui/core'
import React from 'react'
import TestIDs from 'utils/TestIDs'
import FooterRowLinks from './FooterRowLinks'

const Footer = (): JSX.Element => {
  return (
    <Box
      bgcolor={'primary.main'}
      pt={3}
      pb={3}
      pl={{ xs: 0, sm: 3 }}
      pr={{ xs: 0, sm: 3 }}
      {...TestIDs.PAGE_FOOTER}
      height={55}
      minHeight={55}
    >
      <Container maxWidth="lg">
        <FooterRowLinks
          links={[
            { name: `© Vonovia SE ${new Date().getFullYear()}`, url: '' },
            { name: 'Impressum', url: '/impressum' },
            { name: 'Datenschutz', url: '/datenschutz' },
            { name: 'Cookie-Richtlinien', url: '/cookie-richtlinien' },
          ]}
        />
      </Container>
    </Box>
  )
}

export default Footer
