import { Grid } from '@material-ui/core'
import OptionItem from 'components/OptionItem'
import { isEqual } from 'lodash'
import * as React from 'react'

interface OptionItemProps {
  selectionValue: boolean
  title: string
}

export interface OptionItemRendererProps {
  values: OptionItemProps[]
  selectedValue: boolean
  editOptionItem: (value: boolean) => void
}

const OptionItemRenderer = (props: OptionItemRendererProps): JSX.Element => {
  const { editOptionItem, selectedValue, values } = props

  return (
    <Grid container item spacing={2}>
      {values.map((value, index) => (
        <Grid item xs={12} key={value.title + index}>
          <OptionItem
            onClick={(): void => editOptionItem(value.selectionValue)}
            selected={selectedValue === value.selectionValue}
            title={value.title}
            variant={'h4'}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default React.memo(OptionItemRenderer, (prevProps, nextProps) => isEqual(prevProps, nextProps))
