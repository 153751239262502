import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import CheckboxIcon from 'components/CheckboxIcon'
import IbanInput from 'components/IbanInput'
import { isEqual } from 'lodash'
import * as React from 'react'
import { AccountData, AccountType } from 'store/Checkout/Checkout.reducer'
import TestIDs from 'utils/TestIDs'
import { OptionItemRenderer, TextFieldRenderer } from '.'
import ErrorList from '../../utils/ErrorList'

interface BankDetailsFieldsProps {
  accountType: AccountType
  iban: string
  accountData: AccountData
  paymentAuthorization: boolean
  toggleAccountType: (payload: AccountType) => void
  editIban: (payload: string) => void
  editAccountData: (payload: AccountData) => void
  togglePaymentAuthorization: (payload: boolean) => void
  invalidFields: string[]
  clearInvalidField: (field: string) => void
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
  isSameAccountHolder: boolean
}

const BankDetailsFields = (props: BankDetailsFieldsProps): JSX.Element => {
  const {
    accountData,
    accountType,
    editAccountData,
    editIban,
    iban,
    isSameAccountHolder,
    paymentAuthorization,
    toggleAccountType,
    togglePaymentAuthorization,
    clearInvalidField,
    invalidFields,
    focusedField,
    setFocusedField,
  } = props

  const toggleBoolToAccountType = (payload: boolean): void => {
    if (payload) {
      // Account number = TRUE
      clearInvalidField('accountData-iban')
      toggleAccountType(AccountType.ACCOUNT_NUMBER)
    } else {
      // IBAN = false
      clearInvalidField('accountData-accountNumber')
      clearInvalidField('accountData-bankCode')
      toggleAccountType(AccountType.IBAN)
    }
  }

  const combineAndEditAccountData = React.useCallback(
    // eslint-disable-next-line
    (field: string, index: number, value: any, errorField: string) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        clearInvalidField(errorField)
      }
      switch (field) {
        case 'AccountNumber':
          editAccountData({ ...accountData, accountNumber: value })
          break
        case 'BankCode':
          editAccountData({ ...accountData, bankCode: value })
          break
      }
    },
    [editAccountData, accountData, invalidFields, clearInvalidField],
  )

  const clearAndEditIban = React.useCallback(
    (payload: string) => {
      if (invalidFields.indexOf('accountData-iban') !== -1) {
        clearInvalidField('accountData-iban')
      }
      editIban(payload)
    },
    [invalidFields, editIban, clearInvalidField],
  )

  return (
    <Grid container item spacing={3}>
      <Grid item xs={12}>
        <Typography className={'centerWhenSmall'} {...TestIDs.BANK_FIELDS_HEADER} variant={'h4'}>
          Bankverbindung
        </Typography>
      </Grid>
      <OptionItemRenderer
        editOptionItem={toggleBoolToAccountType}
        selectedValue={accountType === AccountType.ACCOUNT_NUMBER}
        values={[
          { selectionValue: false, title: 'IBAN' },
          { selectionValue: true, title: 'Kontonummer und Bankleitzahl' },
        ]}
      />
      {accountType === AccountType.ACCOUNT_NUMBER && (
        <Grid container item spacing={2}>
          <TextFieldRenderer
            setFocusedField={setFocusedField}
            combineAndEdit={combineAndEditAccountData}
            invalidFields={invalidFields}
            index={0}
            focusedField={focusedField}
            values={[
              {
                columnValue: 6,
                mobileColumnValue: 12,
                editField: 'AccountNumber',
                label: `Kontonummer (max. 10-stellig) ${isSameAccountHolder ? '*' : ''}`,
                value: accountData.accountNumber,
                maxLength: 10,
                errorField: 'accountData-accountNumber',
                tooltipTitle: ErrorList.AccountDataNumber,
              },
              {
                columnValue: 6,
                mobileColumnValue: 12,
                editField: 'BankCode',
                label: `Bankleitzahl (8-stellig) ${isSameAccountHolder ? '*' : ''}`,
                value: accountData.bankCode,
                maxLength: 8,
                errorField: 'accountData-bankCode',
                tooltipTitle: ErrorList.AccountDataBankCode,
              },
            ]}
          />
        </Grid>
      )}
      {accountType === AccountType.IBAN && (
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <IbanInput
              focusedField={focusedField}
              setFocusedField={setFocusedField}
              value={iban}
              onChange={clearAndEditIban}
              initialValue={iban}
              invalidFields={invalidFields}
              label={`IBAN ${isSameAccountHolder ? '*' : ''}`}
            />
          </Grid>
        </Grid>
      )}
      <Grid container item spacing={2}>
        <Grid item {...TestIDs.BANK_FIELDS_CHECKBOX}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckboxIcon checked={false} error={invalidFields.indexOf('paymentAuthorization') !== -1} />}
                checkedIcon={
                  <CheckboxIcon checked={true} error={invalidFields.indexOf('paymentAuthorization') !== -1} />
                }
                onClick={(): void => {
                  if (invalidFields.indexOf('paymentAuthorization') !== -1) {
                    clearInvalidField('paymentAuthorization')
                  }
                  togglePaymentAuthorization(!paymentAuthorization)
                }}
                style={invalidFields.indexOf('paymentAuthorization') !== -1 ? { color: 'red' } : undefined}
              />
            }
            style={{ alignItems: 'flex-start' }}
            checked={paymentAuthorization}
            label={
              <>
                <Box marginBottom={2} marginLeft={2}>
                  <Typography className={invalidFields.indexOf('paymentAuthorization') !== -1 ? 'TextError' : ''}>
                    Ich ermächtige die Deutsche Multimedia Service GmbH, Zahlungen von meinem Konto mittels Lastschrift
                    (Gläubiger ID: DE40ZZZ00000207023) einzuziehen.
                  </Typography>
                </Box>
                <Box marginBottom={2} marginLeft={2}>
                  <Typography className={invalidFields.indexOf('paymentAuthorization') !== -1 ? 'TextError' : ''}>
                    Zugleich weise ich mein Kreditinstitut an, die von der Deutschen Multimedia Service GmbH auf mein
                    Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit
                    dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meinem
                    Kreditinstitut vereinbarten Bedingungen.
                  </Typography>
                </Box>
                <Box marginLeft={2}>
                  <Typography className={invalidFields.indexOf('paymentAuthorization') !== -1 ? 'TextError' : ''}>
                    Die Mandatsreferenz wird mir separat mitgeteilt.
                  </Typography>
                </Box>
              </>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(BankDetailsFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
