export const downloadBlob = (fileName: string, file: Blob): void => {
  const element = document.createElement('a')
  element.href = URL.createObjectURL(file)
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  if (element.parentElement) {
    element.parentElement.removeChild(element)
  }
}
export const dlFile = async (URL: string, fileName: string): Promise<void> => {
  const res = await fetch(URL, {
    method: 'GET',
    credentials: 'same-origin',
    //headers: {
    //authorization: `Bearer ${token}`,
    //},
  })
  if (res.status === 200) {
    const blob = await res.blob()
    downloadBlob(fileName, blob)
  } else {
    console.log(res)
  }
}
