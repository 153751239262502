import { Fade, Grid, TextField, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Dropdown from 'components/Dropdown'
import { isEqual } from 'lodash'
import * as React from 'react'
import InputMask from 'react-input-mask'
import { MainData, PersonSalutation } from 'store/Checkout/Checkout.reducer'
import TestIDs from 'utils/TestIDs'
import ErrorList from '../../utils/ErrorList'

export interface MainDataFieldsProps {
  mainData: MainData
  editMainData: (payload: MainData) => void
  invalidFields: string[]
  clearInvalidField: (field: string) => void
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
}

const MainDataFields = (props: MainDataFieldsProps): JSX.Element => {
  const { mainData, editMainData, invalidFields, clearInvalidField, focusedField, setFocusedField } = props

  const combineAndEditMainData = React.useCallback(
    // eslint-disable-next-line
    (field: string, value: any, errorField: string) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        if (
          errorField !== 'basicData-date' ||
          (errorField === 'basicData-date' && value !== '__.__.____' && value !== '')
        ) {
          clearInvalidField(errorField)
        }
      }
      switch (field) {
        case 'Salutation':
          editMainData({
            ...mainData,
            basicData: {
              ...mainData.basicData,
              salutation: value.target.value as PersonSalutation,
            },
          })
          break
        case 'Title':
          editMainData({ ...mainData, basicData: { ...mainData.basicData, title: value } })
          break
        case 'Date':
          editMainData({ ...mainData, date: value })
          break
        case 'Name':
          editMainData({ ...mainData, basicData: { ...mainData.basicData, name: value } })
          break
        case 'LastName':
          editMainData({ ...mainData, basicData: { ...mainData.basicData, lastName: value } })
          break
        case 'Zip':
          editMainData({ ...mainData, address: { ...mainData.address, zip: value } })
          break
        case 'City':
          editMainData({ ...mainData, address: { ...mainData.address, city: value } })
          break
        case 'Street':
          editMainData({ ...mainData, address: { ...mainData.address, street: value } })
          break
        case 'Number':
          editMainData({ ...mainData, address: { ...mainData.address, number: value } })
          break
        case 'AdditionalInfo':
          editMainData({ ...mainData, address: { ...mainData.address, additionalInfo: value } })
          break
        case 'Email':
          editMainData({ ...mainData, email: value })
          break
        case 'Telephone':
          editMainData({ ...mainData, telephoneNr: value })
      }
    },
    [editMainData, mainData, invalidFields, clearInvalidField],
  )

  return (
    <Grid id={'MeineDaten'} container item spacing={2}>
      <Grid item xs={12}>
        <Typography {...TestIDs.MAIN_FIELDS_HEADER} variant={'h3'} className={'sectionH3'}>
          Meine Daten
        </Typography>
      </Grid>
      <Grid container item spacing={2} className={'noPaddingTop'}>
        <Grid item md={4} xs={6}>
          <Dropdown
            {...TestIDs.MAIN_FIELDS_SALUTATION_SELECT}
            label={'Anrede'}
            options={['Herr', 'Frau', 'Familie', 'Divers']}
            value={mainData.basicData.salutation}
            onChange={(e): void => combineAndEditMainData('Salutation', e, '')}
            className={'fallbackLightBackground'}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <TextField
            inputProps={{
              maxLength: 100,
            }}
            {...TestIDs.MAIN_FIELDS_TITLE_INPUT}
            value={mainData.basicData.title}
            onChange={(e): void => combineAndEditMainData('Title', e.target.value, '')}
            label={'Titel'}
            variant={'filled'}
            fullWidth={true}
            InputProps={{
              className: 'fallbackLightBackground',
            }}
          />
        </Grid>
        <Grid {...TestIDs.MAIN_FIELDS_AGE_INPUT} item md={4} xs={12}>
          <Tooltip
            {...TestIDs.MAIN_FIELDS_AGE_INPUT_TOOLTIP}
            open={invalidFields.indexOf('basicData-date') !== -1 && focusedField === 'basicData-date'}
            placement={'top-start'}
            title={ErrorList.MainFieldsAge}
            PopperProps={{
              className:
                ErrorList.MainFieldsAge.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : ErrorList.MainFieldsAge.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <InputMask
              onClick={(): void => setFocusedField('basicData-date')}
              onBlur={(): void => setFocusedField('')}
              mask={'99.99.9999'}
              alwaysShowMask={false}
              placeholder={''}
              value={mainData.date}
              onChange={(e): void => combineAndEditMainData('Date', e.target.value, 'basicData-date')}
            >
              <TextField
                inputProps={{
                  maxLength: 100,
                }}
                label={'Geburtsdatum *'}
                variant={'filled'}
                fullWidth={true}
                error={invalidFields.indexOf('basicData-date') !== -1}
                InputProps={{
                  className: 'fallbackLightBackground',
                }}
              />
            </InputMask>
          </Tooltip>
        </Grid>
        <Grid item md={6} xs={12}>
          <Tooltip
            {...TestIDs.MAIN_FIELDS_NAME_INPUT_TOOLTIP}
            open={invalidFields.indexOf('basicData-name') !== -1 && focusedField === 'basicData-name'}
            PopperProps={{
              className:
                ErrorList.MainFieldsName.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : ErrorList.MainFieldsName.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            placement={'top-start'}
            title={ErrorList.MainFieldsName}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <TextField
              inputProps={{
                maxLength: 100,
              }}
              {...TestIDs.MAIN_FIELDS_NAME_INPUT}
              onClick={(): void => setFocusedField('basicData-name')}
              onBlur={(): void => setFocusedField('')}
              error={invalidFields.indexOf('basicData-name') !== -1}
              label={'Vorname *'}
              variant={'filled'}
              fullWidth={true}
              value={mainData.basicData.name}
              onChange={(e): void => combineAndEditMainData('Name', e.target.value, 'basicData-name')}
              InputProps={{
                className: 'fallbackLightBackground',
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item md={6} xs={12}>
          <Tooltip
            {...TestIDs.MAIN_FIELDS_SURNAME_INPUT_TOOLTIP}
            open={invalidFields.indexOf('basicData-lastName') !== -1 && focusedField === 'basicData-lastName'}
            PopperProps={{
              className:
                ErrorList.MainFieldsLastName.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : ErrorList.MainFieldsLastName.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            placement={'top-start'}
            title={ErrorList.MainFieldsLastName}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <TextField
              inputProps={{
                maxLength: 100,
              }}
              {...TestIDs.MAIN_FIELDS_SURNAME_INPUT}
              onClick={(): void => setFocusedField('basicData-lastName')}
              onBlur={(): void => setFocusedField('')}
              error={invalidFields.indexOf('basicData-lastName') !== -1}
              label={'Nachname *'}
              variant={'filled'}
              fullWidth={true}
              value={mainData.basicData.lastName}
              onChange={(e): void => combineAndEditMainData('LastName', e.target.value, 'basicData-lastName')}
              InputProps={{
                className: 'fallbackLightBackground',
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item md={3} xs={12}>
          <TextField
            inputProps={{
              maxLength: 100,
            }}
            {...TestIDs.MAIN_FIELDS_ZIPCODE_INPUT}
            label={'Postleitzahl'}
            variant={'filled'}
            fullWidth={true}
            value={props.mainData.address.zip}
            disabled={true}
            InputProps={{
              className: 'fallbackLightBackground',
            }}
          />
        </Grid>
        <Grid item md={9} xs={12}>
          <TextField
            inputProps={{
              maxLength: 100,
            }}
            {...TestIDs.MAIN_FIELDS_CITY_INPUT}
            label={'Ort'}
            variant={'filled'}
            fullWidth={true}
            value={props.mainData.address.city}
            disabled={true}
            className={'fallbackLightBackground'}
            InputProps={{
              className: 'fallbackLightBackground',
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            inputProps={{
              maxLength: 100,
            }}
            {...TestIDs.MAIN_FIELDS_STREET_INPUT}
            label={'Straße'}
            variant={'filled'}
            fullWidth={true}
            value={props.mainData.address.street}
            disabled={true}
            className={'fallbackLightBackground'}
            InputProps={{
              className: 'fallbackLightBackground',
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <TextField
            inputProps={{
              maxLength: 100,
            }}
            {...TestIDs.MAIN_FIELDS_ADRESS_NUMBER_INPUT}
            label={'Nr.'}
            variant={'filled'}
            fullWidth={true}
            value={props.mainData.address.number}
            disabled={true}
            className={'fallbackLightBackground'}
            InputProps={{
              className: 'fallbackLightBackground',
            }}
          />
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            inputProps={{
              maxLength: 100,
            }}
            {...TestIDs.MAIN_FIELDS_ADDITIONAL_INFO_INPUT}
            label={'Zusatz'}
            variant={'filled'}
            fullWidth={true}
            value={props.mainData.address.additionalInfo}
            disabled={true}
            className={'fallbackLightBackground'}
            InputProps={{
              className: 'fallbackLightBackground',
            }}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item md={6} xs={12}>
          <Tooltip
            {...TestIDs.MAIN_FIELDS_EMAIL_INPUT_TOOLTIP}
            open={invalidFields.indexOf('basicData-email') !== -1 && focusedField === 'basicData-email'}
            placement={'top-start'}
            PopperProps={{
              className:
                ErrorList.MainFieldsEmail.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : ErrorList.MainFieldsEmail.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            title={ErrorList.MainFieldsEmail}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <TextField
              inputProps={{
                maxLength: 100,
              }}
              {...TestIDs.MAIN_FIELDS_EMAIL_INPUT}
              onClick={(): void => setFocusedField('basicData-email')}
              onBlur={(): void => setFocusedField('')}
              error={invalidFields.indexOf('basicData-email') !== -1}
              label={'E-Mail *'}
              variant={'filled'}
              fullWidth={true}
              value={props.mainData.email}
              onChange={(e): void => combineAndEditMainData('Email', e.target.value, 'basicData-email')}
              InputProps={{
                className: 'fallbackLightBackground',
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item md={6} xs={12}>
          <Tooltip
            {...TestIDs.MAIN_FIELDS_TELEPHONE_INPUT_TOOLTIP}
            open={invalidFields.indexOf('basicData-telephoneNr') !== -1 && focusedField === 'basicData-telephoneNr'}
            placement={'top-start'}
            PopperProps={{
              className:
                ErrorList.MainFieldsTelephone.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : ErrorList.MainFieldsTelephone.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            title={ErrorList.MainFieldsTelephone}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <TextField
              inputProps={{
                maxLength: 100,
              }}
              {...TestIDs.MAIN_FIELDS_TELEPHONE_INPUT}
              onClick={(): void => setFocusedField('basicData-telephoneNr')}
              onBlur={(): void => setFocusedField('')}
              error={invalidFields.indexOf('basicData-telephoneNr') !== -1}
              label={'Telefon-Nr. *'}
              variant={'filled'}
              fullWidth={true}
              value={props.mainData.telephoneNr}
              onChange={(e): void => combineAndEditMainData('Telephone', e.target.value, 'basicData-telephoneNr')}
              InputProps={{
                className: 'fallbackLightBackground',
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(MainDataFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
