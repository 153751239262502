import gql from 'graphql-tag'

export default gql`
  query Products {
    products {
      basket {
        dependentOn {
          options {
            id
            oneTimeCut
          }
          products {
            id
            oneTimeCut
          }
        }
        displayed
        featureText
        legalNotice
        priceInfoText
        renderDetails {
          defaultOption
          options
          renderSelected
          subTitle
          title
        }
        subTitle
        title
      }
      category
      color
      id
      landingPage {
        dependentOn {
          options {
            id
            oneTimeCut
          }
          products {
            id
            oneTimeCut
          }
        }
        displayed
        featureText
        legalNotice
        priceInfoText
        renderDetails {
          defaultOption
          options
          renderSelected
          subTitle
          title
        }
        subTitle
        title
      }
      name
      options {
        availability
        dependent {
          dependency
          productOption
        }
        productOption
      }
      orderPage {
        dependentOn {
          options {
            id
            oneTimeCut
          }
          products {
            id
            oneTimeCut
          }
        }
        displayed
        featureText
        legalNotice
        priceInfoText
        renderDetails {
          defaultOption
          options
          renderSelected
          subTitle
          title
        }
        subTitle
        title
      }
      price {
        interval
        market
        value
        vat
      }
      preSelected
      renderIndex
    }
  }
`
