import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import DeletIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import * as React from 'react'
import Dropzone, { FileError, FileRejection } from 'react-dropzone'
import TestIDs from 'utils/TestIDs'

export interface UploadFileProps {
  files: File[]
  onFileRemove: (file: File) => void
  onFileChosen: (files: File[]) => void
}

enum UPLOAD_ERROR {
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large',
  TOO_MANY_FILES = 'too-many-files',
}

interface UploadFileError {
  name: string
  errors: UPLOAD_ERROR[]
}

const UploadFile = (props: UploadFileProps): JSX.Element => {
  const { files, onFileChosen, onFileRemove } = props
  const [errors, setErrors] = React.useState<UploadFileError[]>([])
  const [dragOver, setDragOver] = React.useState(false)

  const addErrors = (fileRejection: FileRejection): UploadFileError => {
    const currentErrors: UploadFileError = {
      name: fileRejection.file.name,
      errors: [],
    }
    fileRejection.errors.forEach((fileRejectionError: FileError) => {
      switch (fileRejectionError.code) {
        case UPLOAD_ERROR.FILE_TOO_LARGE:
          currentErrors.errors.push(UPLOAD_ERROR.FILE_TOO_LARGE)
          break
        case UPLOAD_ERROR.FILE_INVALID_TYPE:
          currentErrors.errors.push(UPLOAD_ERROR.FILE_INVALID_TYPE)
          break
      }
    })
    return currentErrors
  }

  const onFileReject = (rej: FileRejection[]): void => {
    const errorsToState: UploadFileError[] = []
    rej.forEach((fileRejection: FileRejection) => {
      errorsToState.push(addErrors(fileRejection))
    })
    setErrors(errorsToState)
  }

  const getMessage = (error: UPLOAD_ERROR): string => {
    switch (error) {
      case UPLOAD_ERROR.FILE_INVALID_TYPE:
        return 'Dateiformat nicht erlaubt'
      case UPLOAD_ERROR.FILE_TOO_LARGE:
        return 'Datei zu groß'
      default:
        return 'Zu viele Dateien'
    }
  }

  const cssClasses = ['uploadFile']
  if (dragOver) cssClasses.push('hover')
  if (errors.length !== 0) cssClasses.push('uploadError')

  return (
    <Grid container item xs={12}>
      {files.length > 0 &&
        files.map((file: File, index: number): JSX.Element => {
          return (
            <Grid
              key={file.name + index}
              container
              item
              xs={12}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
              spacing={2}
            >
              <Grid item xs={9} className={'forceWrap'}>
                <Typography {...TestIDs.UPLOAD_FILE_NAME} variant={'h6'} className={'forceWrap'}>
                  {file.name}
                </Typography>
              </Grid>
              <Grid container item xs={2} justifyContent={'center'}>
                <Typography variant={'h6'} className={'AlignCenter'}>{`${Math.round(file.size / 1024)} kb`}</Typography>
              </Grid>
              <Grid container item xs={1} justifyContent={'flex-end'}>
                <IconButton {...TestIDs.UPLOAD_FILE_DELETE} onClick={(): void => onFileRemove(file)}>
                  <DeletIcon />
                </IconButton>
              </Grid>
            </Grid>
          )
        })}
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Dropzone
            accept={{ 'image/*': ['.jpg', '.png'], 'application/pdf': ['.pdf'] }}
            maxSize={2097152}
            onDragEnter={(): void => setDragOver(true)}
            onDragLeave={(): void => setDragOver(false)}
            onDrop={(acc, rej): void => {
              setErrors([])
              setDragOver(false)
              if (files.length + acc.length > 20) {
                const errors2 = []
                errors2.push({
                  name: '',
                  errors: [UPLOAD_ERROR.TOO_MANY_FILES],
                })
                setErrors(errors2)
              } else {
                if (rej.length > 0) {
                  onFileReject(rej)
                }
                if (acc.length > 0) {
                  onFileChosen(acc)
                }
              }
            }}
          >
            {({ getRootProps, getInputProps }): JSX.Element => (
              <Grid container item xs={12} {...getRootProps({ className: 'dropzone' })}>
                <input {...TestIDs.UPLOAD_FILE_INPUT} {...getInputProps()} />
                <Grid container item xs={12}>
                  <Typography {...TestIDs.UPLOAD_FILE_TEXT} className={clsx([...cssClasses])} variant={'h6'}>
                    {dragOver && <>Datei(en) jetzt loslassen.</>}
                    {!dragOver && <>Klicken Sie um eine Datei auszuwählen oder ziehen Sie eine Datei hier hinein</>}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Dropzone>
        </Grid>
        {errors.length > 0 && (
          <Box width={1} marginTop={2}>
            <Grid item xs={12}>
              <Alert severity="error">
                <Grid container direction={'column'} spacing={2}>
                  {errors.map((uploadError, index) => (
                    <Grid key={uploadError.name + index} container item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant={'body1'}>{uploadError.name}</Typography>
                      </Grid>
                      {uploadError.errors.map((error) => (
                        <Grid item xs={12} container key={uploadError.name + error}>
                          <Typography variant={'body2'}>{getMessage(error)}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Alert>
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default UploadFile
