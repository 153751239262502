import { Container, Link, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'
import useTagNumber from 'utils/useTagNumber'

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    margin: theme.spacing(6),
    textAlign: 'left',
  },
}))

const Imprint = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Paper className={classes.contentContainer} elevation={0} square {...TestIDs.IMPRINT_CONTENT}>
      <Container maxWidth={'lg'} className={'LegalNotes'}>
        <Typography variant={'h1'}>Impressum</Typography>
        <br />
        <Typography variant={'h2'}>Seiteninhaber:</Typography>
        <Typography>Deutsche Multimedia Service GmbH</Typography>
        <Typography>Universitätsstraße 133</Typography>
        <Typography>44803 Bochum</Typography>
        <Link href="tel:+80028221016">Tel: +49 (0) 800 / 2822 - 1016</Link>
        <Link href="mailto:internet@vonovia.de">internet@vonovia.de</Link>
        <Link href="https://www.vonovia.de/multimedia">www.vonovia.de/multimedia</Link>
        <br />
        <Typography variant={'h2'}>Verantwortlich für die Inhalte:</Typography>
        <Typography>Deutsche Multimedia Service GmbH</Typography>
        <Typography>Universitätsstraße 133</Typography>
        <Typography>44803 Bochum</Typography>
        <Link href="tel:+80028221016">Tel: +49 (0) 800 / 2822 - 1016</Link>
        <Link href="mailto:internet@vonovia.de">internet@vonovia.de</Link>
        <Link href="https://www.vonovia.de/multimedia">www.vonovia.de/multimedia</Link>
        <br />
        <Typography variant={'h2'}>Geschäftsführer:</Typography>
        <Typography>Stephen Guhr</Typography>
        <Typography>Arnd Fittkau</Typography>
        <Typography>Philip Grosse</Typography>
        <br />
        <Typography variant={'h2'}>Registernummer:</Typography>
        <Typography>HRB66763</Typography>
        <br />
        <Typography variant={'h2'}>Registergericht / Gerichtsstand:</Typography>
        <Typography>Sitz der Gesellschaft: Düsseldorf</Typography>
        <Typography>Amtsgericht Düsseldorf</Typography>
        <Typography>USt-IdNr.: DE280463854</Typography>
        <br />
        {/* <Typography variant={'h2'}>Technische Umsetzung und Design:</Typography>
            <br />
            <Typography>Qubidu GmbH</Typography>
            <Typography>Auf dem Sande 1</Typography>
            <Typography>20457 Hamburg</Typography>
            <Typography>Deutschland</Typography>
            <Link href="tel:+4940609457970">Tel: +49 40 609457970</Link>
            <Link href="mailto:info@qubidu.com">info@qubidu.com</Link>
        <br /> */}
        <Typography variant={'h2'}>Aufsichtsbehörde:</Typography>
        <Typography>Bundesnetzagentur für Elektrizität, Gas,</Typography>
        <Typography>Telekommunikation, Post und Eisenbahnen</Typography>
        <Typography>Tulpenfeld 4</Typography>
        <Typography>53113 Bonn</Typography>
        <br />
        <Typography variant={'h2'}>Haftungsausschluss</Typography>
        <ol>
          <li className="ListContent">
            <Typography className="ListHeadline">Inhalt des Onlineangebotes:</Typography>
            <br />
            <Typography>
              Der Seiteninhaber übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder
              Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Seiteninhaber, welche sich auf
              Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen
              Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden,
              sind grundsätzlich ausgeschlossen, sofern seitens des Seiteninhabers, kein nachweislich vorsätzliches oder
              grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der
              Seiteninhaber behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte
              Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig
              einzustellen.
            </Typography>
            <br />
          </li>
          <li className="ListContent">
            <Typography className="ListHeadline">Verweise und Links:</Typography>
            <br />
            <Typography>
              Bei direkten oder indirekten Verweisen auf fremde Webseiten (“Hyperlinks”), die außerhalb des
              Verantwortungsbereiches des Seiteninhabers liegen, würde eine Haftungsverpflichtung ausschließlich in dem
              Fall in Kraft treten, in dem der Seiteninhaber von den Inhalten Kenntnis hat und es ihm technisch möglich
              und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Seiteninhaber erklärt
              hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden
              Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der
              gelinkten/verknüpften Seiten hat der Seiteninhaber keinerlei Einfluss. Deshalb distanziert er sich hiermit
              ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung verändert
              wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und
              Verweise sowie für Fremdeinträge in von dem Seiteninhaber eingerichteten Gästebüchern, Diskussionsforen,
              Mailinglisten und Intranetbereiche. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere
              für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet
              allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die
              jeweilige Veröffentlichung lediglich verweist.
            </Typography>
            <br />
          </li>
          <li className="ListContent">
            <Typography className="ListHeadline">Urheber- und Kennzeichenrecht:</Typography>
            <br />
            <Typography>
              Der Seiteninhaber ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken,
              Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente,
              Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte
              zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken-
              und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und
              den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht
              der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für
              veröffentlichte, von dem Seiteninhaber selbst erstellte Objekte bleibt allein beim Seiteninhaber. Eine
              Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen
              elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Seiteninhabers nicht
              gestattet.
            </Typography>
            <br />
          </li>
          <li className="ListContent">
            <Typography className="ListHeadline">Datenschutz:</Typography>
            <br />
            <Typography>
              Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten
              (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf
              ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist – soweit
              technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten
              oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben
              veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch
              Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche
              Schritte gegen die Versender von so genannten Spam-Mails bei Verstößen gegen dieses Verbot sind
              ausdrücklich vorbehalten.
            </Typography>
            <br />
          </li>
          <li className="ListContent">
            <Typography className="ListHeadline">Rechtswirksamkeit dieses Haftungsausschlusses:</Typography>
            <br />
            <Typography>
              Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite
              verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht,
              nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem
              Inhalt und ihrer Gültigkeit davon unberührt.
            </Typography>
          </li>
        </ol>
        <br />
        <Typography align={'right'} variant={'subtitle1'}>
          {useTagNumber()}
        </Typography>
      </Container>
    </Paper>
  )
}

export default Imprint
