import { format, parse } from 'date-fns'

// create a function to replace "<li>" with "<li><span>" and "</li>" with "</span></li>" from string

export const replaceLiToSpanTags = (str: string): string => {
  return str.replace(/<li>/g, '<li><span>').replace(/<\/li>/g, '</span></li>')
}

export const replacePToSpanTags = (str: string): string => {
  return str.replace(/<p>/g, '<span>').replace(/<\/p>/g, '</span>').replace(/\n/g, '<br />')
}

export const formatStringToDate = (date: string): Date => {
  const formatDate = new Date('2000-01-01T00:00:00.000Z')
  formatDate.setHours(0)
  formatDate.setMinutes(0)
  formatDate.setSeconds(0)
  return parse(date, 'dd.MM.yyyy', formatDate)
}
export const formatDateToString = (date: Date): string => format(new Date(date), 'dd.MM.yyyy')

// export const getProductSheetLink = (selectedOptions: ProductOption[], selectedProduct?: Product): string => {
//     let productLink = 'produktinformationsblatt'
//     if (selectedProduct === undefined || selectedProduct.category !== ProductCategory.INTERNET) {
//         productLink += '_telefon_pur'
//         return `/assets/docs/${productLink}.pdf`
//     }

//     if (selectedProduct.name === 'Internet 100') {
//         productLink += '_internet_100'
//     }
//     if (selectedProduct.name === 'Internet 500') {
//         productLink += '_internet_500'
//     }
//     if (selectedProduct.name === 'Internet 1000') {
//         productLink += '_internet_1000'
//     }
//     selectedOptions.forEach((option) => {
//         if (option.category !== ProductOptionCategory.HARDWARE) {
//             if (option.name === 'Telefon Flatrate') {
//                 productLink += '_mit_telefon_flatrate'
//             }
//             if (option.name === 'TV') {
//                 productLink += '_und_tv'
//             }
//         }
//     })
//     return `/assets/docs/${productLink}.pdf`
// }
