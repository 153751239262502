import { createTheme, Theme } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})
export const smallMobile = 385
export const smallestMobile = 330

export const ModifiedTheme: Theme = createTheme({
  overrides: {
    MuiAppBar: {
      root: {
        height: '122px',
      },
      positionStatic: {
        flexDirection: 'row',
        fontSize: 'calc(10px + 2vmin)',
        padding: 24,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        '&$disabled': {
          backgroundColor: '#DDE2E5',
        },
      },
      textSecondary: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 11,
        paddingBottom: 11,
        '& span': {
          fontSize: '28px !important',
          fontWeight: 'normal',
        },

        backgroundColor: '#009AA8',
        '&:hover': {
          backgroundColor: '#004356',
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: '#004356 !important',
          },
        },
        '&.AvailabilityCheck': {
          marginTop: 43,
          [breakpoints.down('sm')]: {
            marginTop: 40,
            width: '100%',
          },
        },
      },
      label: {
        fontFamily: 'Whitney-normal',
        fontWeight: 'normal',
        fontStyle: 'italic',
        textTransform: 'none',
        fontSize: '28px',
      },
      startIcon: {
        marginRight: 16,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        backgroundColor: 'white',
        borderRadius: 3,
        padding: '2px',
        marginLeft: 8,
        marginTop: 2,
        [breakpoints.down('sm')]: {
          marginRight: '12px',
        },
        '&:hover': {
          backgroundColor: 'white',
        },
        '&>span:first-of-type': {
          transform: 'scale(1.3) translate(0, 1px)',
        },
        '&.LandingCheckbox': {
          '&$checked': {
            backgroundColor: 'transparent',
            marginLeft: 10,
            marginTop: -1,
          },
        },
      },
    },
    MuiContainer: {
      root: {
        [breakpoints.down('sm')]: {
          paddingLeft: 20,
          paddingRight: 20,
        },
        '&.ContentContainer': {
          marginTop: 40,
          textAlign: 'right',
          [breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        '&.LeftAlignedText': {
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
        },
        '&.LegalNotes': {
          [breakpoints.down('sm')]: {
            paddingTop: '66px',
            paddingBottom: '44px',
          },
          '& p': {
            textAlign: 'left',
            fontSize: 14,
            fontFamily: 'Whitney',
          },
          '& a': {
            color: '#009aa8',
            fontSize: 14,
            fontFamily: 'Whitney',
          },
          '& h1': {
            fontSize: 19,
            fontFamily: 'Whitney-normal',
          },
          '& h2': {
            fontSize: 19,
            fontFamily: 'Whitney-normal',
          },
          '& li': {
            '&.ListHeadline': {
              fontSize: 19,
              fontFamily: 'Whitney-normal',
            },
            '&.ListContent': {
              fontSize: 14,
              fontFamily: 'Whitney',
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      root: {
        '&.InfoBarDialogTitle h2': {
          color: '#085368',
          wordBreak: 'break-all',
          fontSize: '46px',
          [breakpoints.down('md')]: {
            marginBottom: 18,
          },
          [breakpoints.down(smallMobile)]: {
            fontSize: '26px',
          },
        },
      },
    },
    MuiDialogContentText: {
      root: {
        '&.InfoBarDialogContentText': {
          fontSize: '24px',
          fontFamily: 'Whitney-normal',
          lineHeight: '29px',
          color: '#085368',
          [breakpoints.down(smallMobile)]: {
            fontSize: '18px',
            lineHeight: '20px',
          },
        },
      },
    },
    MuiDivider: {
      root: {
        marginTop: 60,
        marginBottom: 40,
        '&.LessMarginTop': {
          marginTop: 48,
        },
      },
      middle: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#FFF',
        border: '2px solid transparent ',
        borderRadius: '5px',
        '&.fallbackDarkBackground': {
          backgroundColor: 'white',
          '&$disabled': {
            backgroundColor: '#F6F6F6',
          },
          '&:hover': {
            backgroundColor: '#F6F6F6',
          },
        },
        '&.fallbackLightBackground': {
          backgroundColor: '#F6F6F6',
          '&$disabled': {
            '&:hover': {
              backgroundColor: '#F6F6F6',
            },
          },
          '&:hover': {
            backgroundColor: 'white',
          },
        },
        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        },
        '&$disabled': {
          backgroundColor: '#F6F6F6',
        },
        '&$error': {
          border: '2px solid #DE4A3A ',
        },
        '&$focused': {
          backgroundColor: '#FFF',
        },
        '&:hover': {
          backgroundColor: '#F6F6F6',
        },
      },
      input: {
        fontFamily: 'Whitney-lighter',
        fontSize: '20px',
        fontWeight: 'lighter',
      },
      underline: {},
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        '&.error *': {
          color: '#DE4A3A',
        },
        '&.selectItem': {
          background: '#085368',
          borderRadius: '8px',
          padding: '20px',
          paddingLeft: '20px',
          marginBottom: '20px',
          marginRight: '0',
          textAlign: 'left',
          lineHeight: 1,
          display: 'flex',

          '& > span': {
            [breakpoints.up('md')]: {
              marginRight: '13px',
            },
          },
          '& .Mui-checked': {
            color: 'white',
          },
        },
        '&.selectItemNarrow': {
          [breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: '20px 10px',
            width: '23%',
          },
        },
        '&.mint': {
          background: '#009860',
          fontFamily: 'Mercury',
          fontSize: '32px',
        },
        '&.blue': {
          background: '#009AA8',
          fontFamily: 'Mercury',
          fontSize: '32px',
        },
        '&.darkBlue': {
          background: '#085368',
          fontFamily: 'Mercury',
          fontSize: '32px',
        },
        '&.darkGreen': {
          background: '#0f744e',
          fontFamily: 'Mercury',
          fontSize: '32px',
        },
        '&.green': {
          background: '#6AAF23',
        },

        '& .MuiFormControlLabel-label': {
          fontFamily: 'Whitney-normal',
          fontSize: '32px',
        },
      },
      label: {
        '& p': {
          color: '#085368',
          '&.error': {
            color: '#DE4A3A',
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#222',
        fontSize: '12px',
        fontFamily: 'Whitney-normal',
        fontWeight: 'normal',
        marginTop: 8,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormLabel: {
      root: {
        '&$error': {
          color: '#DE4A3A',
        },
      },
    },
    MuiGrid: {
      root: {
        '&.AppBarIconButton': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
          textAlign: 'end',
        },
        '&.GridSubcontainer': {
          [breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          },
        },
        '&.AvailabilityInputRight': {
          [breakpoints.up('lg')]: {
            paddingRight: '0 !important',
          },
        },
        '&.noPaddingTop': {
          paddingTop: '0 !important',
        },
        '&.OptionItemSingleLine': { display: 'flex' },
        '&.SubmitButtonContainer': { marginTop: 40 },
        '&.h1Container': {
          marginBottom: '85px',
          marginTop: '110px',
          [breakpoints.down('sm')]: {
            marginTop: '66px',
          },
        },
        '&.forceWrap': {
          overflow: 'hidden',
        },
        '&.PaddingTop': {
          paddingTop: '32px !important',
        },
        '&.InfoBar': {
          backgroundColor: '#6AAF23',
          '&.Darker': {
            backgroundColor: '#009860',
          },
          cursor: 'pointer',
          padding: '8px 0px',
        },
        '& .ProductInfoList': {
          '& ul': {
            paddingInlineStart: 0,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '15px',
            marginBlockStart: 0,
            marginBlockEnd: 0,
            '& li': {
              marginLeft: '0px',
              paddingLeft: '49px',
              listStyleType: 'none',
              backgroundImage:
                'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOSAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik04LjcwNjMzIDI3LjY4MzFDOS4wMTI2NyAyNy44OTczIDkuMzYzNzQgMjggOS43MTE0NSAyOEMxMC4yNjQ2IDI4IDEwLjgwOTIgMjcuNzM5NiAxMS4xNTA2IDI3LjI1MjdMMjguNjgyNSAyLjc1NzdDMjkuMjM4NCAxLjk2NDk0IDI5LjA0NTQgMC44NzIyMjUgMjguMjUxNSAwLjMxNzE3NEMyNy40NTc1IC0wLjIzODA3NSAyNi4zNjMxIC0wLjA0NTQxNzkgMjUuODA3MiAwLjc0NzM0NEw4LjI3NTMxIDI1LjI0MjVDNy43MTk0MSAyNi4wMzUzIDcuOTEyMzYgMjcuMTI4IDguNzA2MzMgMjcuNjgzMVoiCiAgICAgICAgICAgICAgICBmaWxsPSJ3aGl0ZSIKICAgICAgICAgICAgLz4KICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik02LjAyNzIxIDI0Ljg0MDhDNy4zODM2IDIzLjQ4NjUgNy4zOTM1IDIxLjI5NzUgNi4wNTc4OSAxOS45MzAyTDIuOTk2MDIgMTYuODM4N0MyLjMxMDcgMTYuMTU0NyAxLjE5OTMxIDE2LjE1NDcgMC41MTM5ODkgMTYuODM4N0MtMC4xNzEzMyAxNy41MjMyIC0wLjE3MTMzIDE4LjYzMjcgMC41MTM5ODkgMTkuMzE3TDYuMDI3MjEgMjQuODQwOFoiCiAgICAgICAgICAgICAgICBmaWxsPSJ3aGl0ZSIKICAgICAgICAgICAgLz4KICAgICAgICA8L3N2Zz4=")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0px 0px',
              backgroundSize: '28px',
              minHeight: '28px',
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Whitney-normal',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '24px',
              lineHeight: '30px',
              '& span': {
                width: '100%',
                textAlign: 'left',
              },
            },
          },
        },
        '& .ProductPriceInfoText': {
          '& ul': {
            paddingInlineStart: 0,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            marginBlockStart: 0,
            marginBlockEnd: 0,
            '& li': {
              marginLeft: '0px',
              paddingLeft: '25px',
              listStyleType: 'none',
              backgroundImage:
                'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOSAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik04LjcwNjMzIDI3LjY4MzFDOS4wMTI2NyAyNy44OTczIDkuMzYzNzQgMjggOS43MTE0NSAyOEMxMC4yNjQ2IDI4IDEwLjgwOTIgMjcuNzM5NiAxMS4xNTA2IDI3LjI1MjdMMjguNjgyNSAyLjc1NzdDMjkuMjM4NCAxLjk2NDk0IDI5LjA0NTQgMC44NzIyMjUgMjguMjUxNSAwLjMxNzE3NEMyNy40NTc1IC0wLjIzODA3NSAyNi4zNjMxIC0wLjA0NTQxNzkgMjUuODA3MiAwLjc0NzM0NEw4LjI3NTMxIDI1LjI0MjVDNy43MTk0MSAyNi4wMzUzIDcuOTEyMzYgMjcuMTI4IDguNzA2MzMgMjcuNjgzMVoiCiAgICAgICAgICAgICAgICBmaWxsPSJ3aGl0ZSIKICAgICAgICAgICAgLz4KICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik02LjAyNzIxIDI0Ljg0MDhDNy4zODM2IDIzLjQ4NjUgNy4zOTM1IDIxLjI5NzUgNi4wNTc4OSAxOS45MzAyTDIuOTk2MDIgMTYuODM4N0MyLjMxMDcgMTYuMTU0NyAxLjE5OTMxIDE2LjE1NDcgMC41MTM5ODkgMTYuODM4N0MtMC4xNzEzMyAxNy41MjMyIC0wLjE3MTMzIDE4LjYzMjcgMC41MTM5ODkgMTkuMzE3TDYuMDI3MjEgMjQuODQwOFoiCiAgICAgICAgICAgICAgICBmaWxsPSJ3aGl0ZSIKICAgICAgICAgICAgLz4KICAgICAgICA8L3N2Zz4=")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0px 0px',
              backgroundSize: '16px',
              minHeight: '16px',
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Whitney-normal',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '16px',
              lineHeight: '24px',
              '& span': {
                width: '100%',
                textAlign: 'left',
              },
            },
          },
        },
        '& .LegalNoticeText': {
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '36px',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        '& p': {
          fontFamily: 'Whitney-lighter',
          fontSize: '20px',
          fontWeight: 'lighter',
          paddingTop: '3px',
        },
      },
    },
    MuiIconButton: {
      root: {
        width: 'fit-content',
      },
    },
    MuiInputBase: {
      root: {
        '&.TextError': {
          color: '#DE4A3A',
        },
      },
    },
    MuiInputLabel: {
      filled: {
        color: '#00607B !important',
        fontFamily: 'Whitney-lighter',
        fontSize: '20px',
        fontWeight: 'lighter',
        transform: 'translate(12px, 23px) scale(1)',
      },
      shrink: {
        fontSize: '12px !important',
      },
    },
    MuiListItem: {
      root: {
        '& p': {
          lineHeight: '29px',
          marginTop: 0,
        },
        '& .MuiTypography-body1': {
          lineHeight: '29px',
        },
      },
    },
    MuiLink: {
      root: {
        display: 'block',
        wordBreak: 'break-word',
        '&.FooterLink': {
          fontFamily: 'Whitney-lighter',
          fontWeight: 'lighter',
          fontStyle: 'italic',
          fontSize: '16px',
        },
        '&.FooterRowLink': {
          lineHeight: '32px',
          opacity: '0.6',
          '&:hover': { opacity: '1' },
          paddingLeft: 16,
          paddingRight: 16,
        },
        '&.FooterColumnLink': {
          marginTop: 16,
        },
        '&.AccountHolderLink': {
          fontWeight: 'bold',
          textDecoration: 'underline',
          color: '#1A1A1A',
        },
        '&.InlineLink': {
          display: 'inline',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Whitney-lighter',
        fontSize: '20px',
        fontWeight: 'lighter',
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 5,
        '&.DropdownPaper': {
          boxShadow:
            '0px 3px 2px -2px rgba(0,0,0,0.2), 0px 0px 5px 1px rgba(0,0,0,0.14), 0px 3px 7px 0px rgba(0,0,0,0.12)',
        },
      },
    },
    MuiPaper: {
      elevation0: {
        paddingTop: 64,
        paddingBottom: 64,
        '&.Checkout': {
          backgroundColor: '#00607b',
          paddingTop: 110,
          paddingBottom: 120,
          [breakpoints.down('md')]: {
            paddingBottom: 80,
            paddingTop: 80,
          },
        },
        [breakpoints.down('md')]: {
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 29,
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&>span>div>svg+svg': {
          color: '#085368',
        },
        '&>span:first-of-type': {
          transform: 'scale(1.7) translate(0, 1px)',
          '&::before': {
            content: '" "',
            position: 'absolute',
            width: 20,
            height: 20,
            backgroundColor: 'white',
            borderRadius: 20,
          },
        },
      },
    },
    MuiSelect: {
      root: {
        textAlign: 'start',
      },
      filled: {
        '&.MuiSelect-filled': {
          paddingRight: 40,
        },
      },
      select: {
        '&:focus': {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        '&.helpPopover': {
          position: 'absolute',
          top: 13,
          right: 13,
          color: '#6AAF23',
        },
        '&.AddFieldButton': {
          fontSize: '20px !important',
          color: '#878787',
        },
        '&.AppBarIcon': {
          fontSize: '40px !important',
        },
      },
    },
    MuiTableCell: {
      root: {
        verticalAlign: 'top',
      },
    },
    MuiTextField: {
      root: {
        borderRadius: '5px',
        '&.fallbackDarkBackground': {
          backgroundColor: 'white',
          '&$disabled': {
            backgroundColor: '#F6F6F6',
          },
          '&:hover': {
            backgroundColor: '#F6F6F6',
          },
        },
        '&.fallbackLightBackground': {
          backgroundColor: '#F6F6F6',
          '&:hover': {
            backgroundColor: 'white',
          },
        },
        '& .MuiFilledInput-root.availabilityCheck input': {
          '&[type=number]': {
            '-moz-appearance': 'textfield',
          },
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
      },
    },
    MuiTooltip: {
      popper: {
        '&.BigErrorMessage': {
          [breakpoints.down('xs')]: {
            marginRight: '4vw',
            maxWidth: '90vw',
          },
          [breakpoints.down(smallMobile)]: {
            marginLeft: '7vw',
            marginRight: '5vw',
            maxWidth: '88vw',
          },
          [breakpoints.down(smallestMobile)]: {
            marginLeft: '8vw',
            marginRight: '4vw',
            maxWidth: '88vw',
          },
        },
        '&.MediumErrorMessage': {
          [breakpoints.down(smallMobile)]: {
            maxWidth: '90vw',
          },
          [breakpoints.down(smallestMobile)]: {
            maxWidth: '87.5vw',
          },
        },
      },
      tooltip: {
        backgroundColor: '#DE4A3A',
        fontFamily: 'Whitney-normal',
        // maxWidth: 'none',
        padding: '8px 16px',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.14286em',
        '&::before': {
          backgroundColor: '#DE4A3A',
          content: '" "',
          fontSize: 0,
          height: '24px',
          left: '24.5px',
          position: 'absolute',
          bottom: '0',
          width: '3px',
        },
        '&::after': {
          backgroundColor: '#DE4A3A',
          borderRadius: '6px',
          content: '" "',
          fontSize: 0,
          height: '12px',
          left: '20px',
          position: 'absolute',
          bottom: '-6px',
          width: '12px',
        },
      },
      tooltipPlacementBottom: {
        '&::before': {
          backgroundColor: '#DE4A3A',
          content: '" "',
          fontSize: 0,
          height: '24px',
          left: '24.5px',
          position: 'absolute',
          top: '0px',
          width: '3px',
        },
        '&::after': {
          backgroundColor: '#DE4A3A',
          borderRadius: '6px',
          content: '" "',
          fontSize: 0,
          height: '12px',
          left: '20px',
          position: 'absolute',
          top: '-6px',
          width: '12px',
        },
      },
    },
    MuiTypography: {
      root: {
        textAlign: 'left',
        '&.ProductOptionFeatureText': {
          fontFamily: 'Whitney-normal',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '24px',
          lineHeight: '30px',
        },
        '&.centerWhenSmall': {
          [breakpoints.down('md')]: {
            textAlign: 'center',
          },
        },
        '&.marginTopWhenSmall': {
          [breakpoints.down('md')]: {
            maginTop: '2px !important',
          },
        },
        '&.FooterTypography': {
          fontFamily: 'Whitney-lighter',
          fontWeight: 'lighter',
          fontStyle: 'italic',
          fontSize: '16px',
          opacity: 0.6,
          paddingRight: 16,
        },
        '&.TextError': {
          color: '#DE4A3A',
        },
        '&.vzfError': {
          margin: 8,
          color: '#DE4A3A',
        },
        '&.TextBig': {
          fontFamily: 'Whitney-lighter',
          fontSize: '24px',
          fontWeight: 'lighter',
          marginBottom: '1em',
        },
        '&.TextNormal': {
          fontFamily: 'Whitney-lighter',
          fontSize: '16px',
          fontWeight: 'lighter',
        },
        '&.TextBold': {
          fontFamily: 'Whitney-bold',
          fontSize: '16px',
          fontWeight: 'bold',
        },
        '&.BasketText': {
          fontFamily: 'Whitney-lighter',
          fontSize: '16px',
          fontWeight: 'lighter',
        },
        '&.BasketBold': {
          fontFamily: 'Whitney-bold',
          fontSize: '16px',
          fontWeight: 'bold',
        },
        '&.helpPopover': {
          fontFamily: 'Whitney-bold',
          fontSize: '15px',
          fontWeight: 'bold',
        },
        '&.succeededPage_bodyTypography': {
          fontFamily: 'Whitney-lighter',
          marginTop: 135,
        },
        '&.errorPage_bodyTypography': {
          fontFamily: 'Whitney-lighter',
          marginTop: 61,
        },
        '&.OptionItemSingleLine': {
          alignSelf: 'center',
          [breakpoints.down('xs')]: {
            textAlign: 'center',
          },
        },
        '&.AddFieldButton': {
          fontFamily: 'Whitney-bold',
          fontSize: '16px',
          fontWeight: 'bold',
          fontStyle: 'italic',
          color: '#878787',
        },
        '&.uploadFile': {
          justifyContent: 'center',
          alignItems: 'center',
          padding: 25,
          borderRadius: 8,
          border: '2px dotted #878787',
          backgroundColor: '#F6F6F6',
          width: '100%',
          textAlign: 'center',
        },
        '&.uploadFile:hover': {
          color: '#009AA8',
          border: '2px dotted #009AA8',
          cursor: 'pointer',
        },
        '&.uploadFile.hover': {
          color: '#fff',
          backgroundColor: '#009AA8',
          border: '2px dotted #fff',
        },

        '&.narrower': {
          padding: '0 40px',
        },
        '&.uploadError': {
          border: '2px dotted #DE4A3A',
        },
        '&.VoucherRemind': {
          marginTop: '48px',
        },
      },
      h1: {
        fontFamily: 'Mercury',
        fontSize: '62px',
        [breakpoints.down('md')]: {
          fontSize: '46px',
          lineHeight: '55px',
        },
      },
      h2: {
        fontFamily: 'Mercury',
        fontSize: '54px',
      },
      h3: {
        fontFamily: 'Mercury',
        fontSize: '46px',
        [breakpoints.down('md')]: {
          fontSize: '32px',
          marginBottom: 18,
        },
        '&.sectionH3': {
          marginBottom: 16,
          [breakpoints.down('md')]: {
            fontSize: '46px',
            textAlign: 'center',
            width: '100%',
          },
        },
      },
      h4: {
        fontFamily: 'Mercury',
        fontSize: '32px',
      },
      h5: {
        fontFamily: 'Whitney-normal',
        fontSize: '24px',
      },
      h6: {
        fontFamily: 'Whitney-normal',
        fontSize: '16px',
        '&.forceWrap': {
          overflow: 'hidden',
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
        },
        '&.AlignCenter': {
          textAlign: 'center',
        },
        '& .ProductSelectorSubtitle': {
          '& ul': {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        },
      },
      colorTextSecondary: {
        '&.FooterColumnHeader': {
          fontFamily: 'Mercury',
          fontWeight: 'normal',
          fontSize: '24px',
        },
      },
      subtitle1: {
        fontFamily: 'Whitney-normal',
        fontSize: '16px',
        color: '#878787',
      },
      subtitle2: {
        fontFamily: 'Whitney-normal',
        fontSize: '12px',
        fontWeight: 'normal',
      },
      body1: {
        fontFamily: 'Whitney-normal',
      },
      body2: {
        fontFamily: 'Whitney-normal',
        fontSize: '20px',
      },
    },
  },
  palette: {
    primary: {
      light: '#4C8FA2',
      main: '#085368',
      dark: '#004356',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#fff',
    },
    error: {
      main: '#DE4A3A',
    },
  },
})

// export const useStyles = makeStyles((theme) => ({
//     app: {
//         textAlign: 'center',
//     },
//     appHeader: {
//         backgroundColor: theme.palette.primary.main,
//         flexDirection: 'row',
//         fontSize: 'calc(10px + 2vmin)',
//         color: theme.palette.primary.contrastText,
//         padding: theme.spacing(3),
//         marginBottom: theme.spacing(3),
//     },
//     footerContainer: {
//         backgroundColor: theme.palette.primary.main,
//         paddingTop: theme.spacing(3),
//         paddingBottom: theme.spacing(3),
//         paddingLeft: theme.spacing(5),
//         paddingRight: theme.spacing(5),
//     },
//     menuButton: {
//         marginRight: theme.spacing(2),
//     },
//     title: {
//         flexGrow: 1,
//     },
// }))
