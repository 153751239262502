import { Box, Button, Container, Grid, TextField, Typography } from '@material-ui/core'
import ChevronRightIcon from 'components/ChevronRightIcon'
import * as React from 'react'

export interface ContractSummaryInputProps {
  onClick: (vzf: string) => void
  error: boolean
  disabledSubmit: boolean
}

const ContractSummaryInput = (props: ContractSummaryInputProps): JSX.Element => {
  const { onClick, error, disabledSubmit } = props
  const [vzf, setVzf] = React.useState('')
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setVzf(e.target.value)
  }
  return (
    <Container className={'ContentContainer'} maxWidth={'lg'}>
      <Box display={'flex'} flexDirection={'column'} style={{ rowGap: '64px' }}>
        <Box>
          <Typography variant={'h4'} align={'center'} className={'ContractSummary'} style={{ wordBreak: 'break-all' }}>
            Sie haben bereits eine Vertragszusammenfassung vorliegen?
          </Typography>
        </Box>
        <Grid container justifyContent={'space-between'} style={{ rowGap: '32px' }}>
          <Grid item xs={12} lg={4}>
            <TextField
              error={error}
              InputProps={{
                className: error
                  ? 'TextError fallbackDarkBackground availabilityCheck'
                  : 'fallbackDarkBackground availabilityCheck',
              }}
              inputProps={{
                maxLength: 9,
              }}
              fullWidth={true}
              label={'Vertragszusammenfassung-Nummer'}
              variant={'filled'}
              value={vzf}
              onChange={(event): void => {
                if (!/[^0-9]+/g.test(event.target.value) && onChange) onChange(event)
              }}
              onKeyDown={(e): void => {
                if (e.key === 'Enter') onClick(vzf)
              }}
              className={'fallbackDarkBackground'}
              type={'text'}
              InputLabelProps={{
                style: {
                  wordBreak: 'break-all',
                },
              }}
            />
            {error && (
              <Typography variant={'subtitle2'} className={'vzfError'}>
                Die Vertragszusammenfassung-Nummer ist falsch oder nicht existieren
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Button
              focusRipple={true}
              disabled={disabledSubmit || vzf.length < 6}
              color={'secondary'}
              onClick={(): void => onClick(vzf)}
              startIcon={<ChevronRightIcon />}
            >
              Zur Bestellung
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default ContractSummaryInput
