import gql from 'graphql-tag'

export default gql`
  query CheckVZF($vzfID: String!) {
    checkVZF(vzfID: $vzfID) {
      vzfID
      vzfData {
        products
        productOptions
        voucher {
          amount
          availableFor
          availableType
          basketSubtitle
          basketTitle
          code
          dateFrom
          dateTo
          discountType
          id
          legalNotice
          name
          numberOfMonth
          redeemed
          type
          validityType
          value
          valueType
        }
        address {
          zipcode
          city
          street
          houseNumber
          additionalInfo
        }
      }
    }
  }
`
