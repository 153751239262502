import { LegalNotice, OptionAvailability, Product, ProductOption, Voucher } from 'graphql/types'
import CheckoutActions, { CheckoutAction } from './Checkout.actions'

export interface AccountData {
  accountNumber: string
  bankCode: string
}

export interface AccountHolder {
  lastName: string
  name: string
}

export interface AppartmentData {
  flat: string
  floor: string
  rentalAgreementNumber: string
}

export enum PersonSalutation {
  DIVERSE = 'Divers',
  FAMILY = 'Familie',
  MR = 'Herr',
  MRS = 'Frau',
}

export interface DeliveryAddressData {
  salutation: string
  title: string
  firstName: string
  lastName: string
  additionalInfo: string
  city: string
  number: string
  street: string
  zip: string
}

export interface Address {
  additionalInfo: string
  city: string
  number: string
  street: string
  zip: string
}

export interface BasicData {
  lastName: string
  name: string
  salutation: PersonSalutation
  title: string
}

export interface MainData {
  address: Address
  basicData: BasicData
  date: string
  email: string
  telephoneNr: string
}

export interface MainTenantData {
  lastName: string
  name: string
}

export interface MainTenantData {
  lastName: string
  name: string
}

export interface PortabilityNumber {
  areaCode: string
  number: string
}

export interface PreviousContractor {
  lastName: string
  name: string
}

export enum AccountType {
  IBAN,
  ACCOUNT_NUMBER,
}

export interface Offer {
  bannerText: string
  color: string
  endDate: string | undefined
  initialDate: string
  overlay:
    | {
        message: string[]
        title: string | undefined
      }
    | undefined
}

export interface CheckoutState {
  accountData: AccountData
  accountHolder: boolean
  accountHolderData: AccountHolder
  accountType: AccountType
  userHasPreviousContract?: boolean
  userVoucher: string
  appartmentData: AppartmentData
  bundleLegalRequirementAccepted: boolean
  deliveryAddressData: DeliveryAddressData
  desiredInstallationDate: boolean
  desiredInstallationDateData: string | null
  filesForUploading: File[]
  iban: string
  invalidFields: string[]
  isLandlineContractCancelled: boolean
  landlineContractCancelDate: string
  legalNotices: LegalNotice[]
  mainData: MainData
  mainTenant: boolean
  mainTenantData: MainTenantData
  offers: Offer[]
  paymentAuthorization: boolean
  portability: boolean
  portabilityNumbers: PortabilityNumber[]
  previousConnectionAddress: boolean
  previousConnectionAddressData: Address
  previousConnectionTermination: boolean
  previousConnectionTerminationData: string
  previousContractor: boolean
  previousContractorData: PreviousContractor[]
  previousProvider: string
  productOptions: ProductOption[]
  products: Product[]
  recurringPaymentAuthorization: boolean
  selectedOptions: ProductOption[]
  selectedProduct: Product | undefined
  appliedVoucher: Voucher | undefined
  vzfID: string | undefined
}

export const initialCheckoutState: CheckoutState = {
  accountData: { accountNumber: '', bankCode: '' },
  accountHolder: false,
  userVoucher: '',
  appliedVoucher: undefined,
  accountHolderData: {
    name: '',
    lastName: '',
  },
  accountType: AccountType.IBAN,
  appartmentData: {
    flat: '',
    floor: '',
    rentalAgreementNumber: '',
  },
  bundleLegalRequirementAccepted: false,
  deliveryAddressData: {
    title: '',
    salutation: '',
    firstName: '',
    lastName: '',
    additionalInfo: '',
    city: '',
    number: '',
    street: '',
    zip: '',
  },
  desiredInstallationDate: false,
  desiredInstallationDateData: null,
  filesForUploading: [],
  iban: '',
  invalidFields: [],
  isLandlineContractCancelled: false,
  landlineContractCancelDate: '',
  mainData: {
    basicData: {
      name: '',
      salutation: PersonSalutation.MR,
      lastName: '',
      title: '',
    },
    address: {
      additionalInfo: '',
      city: '',
      number: '',
      street: '',
      zip: '',
    },
    date: '',
    email: '',
    telephoneNr: '',
  },
  mainTenant: false,
  mainTenantData: {
    lastName: '',
    name: '',
  },
  paymentAuthorization: false,
  portability: false,
  portabilityNumbers: [
    {
      areaCode: '',
      number: '',
    },
  ],
  previousConnectionAddress: false,
  previousConnectionAddressData: {
    additionalInfo: '',
    city: '',
    number: '',
    street: '',
    zip: '',
  },
  previousConnectionTermination: false,
  previousConnectionTerminationData: '',
  previousContractor: false,
  previousContractorData: [
    {
      name: '',
      lastName: '',
    },
  ],
  previousProvider: '',
  recurringPaymentAuthorization: false,
  legalNotices: [],
  offers: [],
  productOptions: [],
  products: [],
  selectedOptions: [],
  selectedProduct: undefined,
  vzfID: undefined,
  userHasPreviousContract: undefined,
}

function CheckoutReducer(state: CheckoutState = initialCheckoutState, action: CheckoutActions): CheckoutState {
  let portabilityNumbersToEdit
  let previousContractorsToEdit

  switch (action.type) {
    case CheckoutAction.ADD_PORTABILITY_NUMBER:
      portabilityNumbersToEdit = [...state.portabilityNumbers]
      portabilityNumbersToEdit.push({
        areaCode: '',
        number: '',
      })
      return { ...state, portabilityNumbers: portabilityNumbersToEdit }
    case CheckoutAction.ADD_PREVIOUS_CONTRACTOR:
      previousContractorsToEdit = [...state.previousContractorData]
      previousContractorsToEdit.push({
        name: '',
        lastName: '',
      })
      return { ...state, previousContractorData: previousContractorsToEdit }
    case CheckoutAction.ADD_UPLOADED_FILES:
      return { ...state, filesForUploading: [...state.filesForUploading, ...action.payload] }
    case CheckoutAction.CLEAR_INVALID_FIELD:
      const indexToRemove = state.invalidFields.indexOf(action.payload)
      const newInvalidFields = [...state.invalidFields]
      if (indexToRemove > -1) {
        newInvalidFields.splice(indexToRemove, 1)
      }
      return { ...state, invalidFields: newInvalidFields }
    case CheckoutAction.CLEAR_SELECTED_PRODUCT_OPTIONS:
      return { ...state, selectedOptions: [] }
    case CheckoutAction.DELETE_UPLOADED_FILE:
      const deletedFileIndex = state.filesForUploading.findIndex(
        (file) => action.payload.name === file.name && action.payload.size === file.size,
      )
      if (deletedFileIndex !== -1) state.filesForUploading.splice(deletedFileIndex, 1)
      return { ...state }
    case CheckoutAction.EDIT_ACCOUNT_DATA:
      return { ...state, accountData: action.payload }
    case CheckoutAction.EDIT_ACCOUNT_HOLDER:
      return { ...state, accountHolderData: action.payload }
    case CheckoutAction.EDIT_APPARTMENT_DATA:
      return { ...state, appartmentData: action.payload }
    case CheckoutAction.EDIT_DESIRED_INSTALLATION_DATE:
      return { ...state, desiredInstallationDateData: action.payload }
    case CheckoutAction.EDIT_IBAN:
      return { ...state, iban: action.payload }
    case CheckoutAction.EDIT_MAIN_DATA:
      return { ...state, mainData: action.payload }
    case CheckoutAction.EDIT_MAIN_TENANT:
      return { ...state, mainTenantData: action.payload }
    case CheckoutAction.EDIT_PORTABILITY_NUMBER:
      portabilityNumbersToEdit = [...state.portabilityNumbers]
      portabilityNumbersToEdit[action.payload.index] = action.payload.portabilityNumber
      return { ...state, portabilityNumbers: portabilityNumbersToEdit }
    case CheckoutAction.EDIT_PREVIOUS_CONNECTION_ADDRESS:
      return { ...state, previousConnectionAddressData: action.payload }
    case CheckoutAction.EDIT_PREVIOUS_CONNECTION_TERMINATION:
      return { ...state, previousConnectionTerminationData: action.payload }
    case CheckoutAction.EDIT_PREVIOUS_CONTRACTOR:
      previousContractorsToEdit = [...state.previousContractorData]
      previousContractorsToEdit[action.payload.index] = action.payload.previousContractor
      return { ...state, previousContractorData: previousContractorsToEdit }
    case CheckoutAction.REMOVE_PORTABILITY_NUMBER:
      portabilityNumbersToEdit = [...state.portabilityNumbers]
      portabilityNumbersToEdit.splice(action.payload, 1)
      return { ...state, portabilityNumbers: portabilityNumbersToEdit }
    case CheckoutAction.REMOVE_PREVIOUS_CONTRACTOR:
      previousContractorsToEdit = [...state.previousContractorData]
      previousContractorsToEdit.splice(action.payload, 1)
      return { ...state, previousContractorData: previousContractorsToEdit }
    case CheckoutAction.SET_INVALID_FIELDS:
      return { ...state, invalidFields: action.payload }
    case CheckoutAction.SET_LANDLINE_CONTRACT_CANCEL_DATE:
      return { ...state, landlineContractCancelDate: action.payload }
    case CheckoutAction.SET_OFFERS:
      return { ...state, offers: [...action.payload] }
    case CheckoutAction.SET_PREVIOUS_PROVIDER:
      return { ...state, previousProvider: action.payload }
    case CheckoutAction.SET_SELECTED_PRODUCT:
      const selectedProduct = action.payload
      if (!selectedProduct) {
        return { ...state, selectedProduct: undefined, selectedOptions: [], appliedVoucher: undefined }
      }

      const optionsToRender = selectedProduct.options
      const selectedOptions: ProductOption[] = []
      if (optionsToRender) {
        for (let i = 0; i < optionsToRender.length; i++) {
          const o = optionsToRender[i]
          if (o.availability === OptionAvailability.PRESELECTED) {
            const productOptionToAdd = state.productOptions.find((po) => po.id === o.productOption)
            if (productOptionToAdd) {
              selectedOptions.push(productOptionToAdd)
            }
          }
        }
      }
      return {
        ...state,
        selectedProduct: action.payload,
        selectedOptions,
        appliedVoucher: undefined,
      }
    case CheckoutAction.SET_STATE:
      return { ...state, ...action.payload }
    case CheckoutAction.SET_TO_INIT_STATE:
      return { ...initialCheckoutState }
    case CheckoutAction.SET_TO_INIT_STATE_EXCEPT_QUERY:
      return {
        ...initialCheckoutState,
        legalNotices: state.legalNotices,
        offers: state.offers,
        productOptions: state.productOptions,
        products: state.products,
      }
    case CheckoutAction.TOGGLE_ACCOUNT_HOLDER:
      return { ...state, accountHolder: action.payload }
    case CheckoutAction.TOGGLE_ACCOUNT_TYPE:
      return { ...state, accountType: action.payload }
    case CheckoutAction.TOGGLE_DESIRED_INSTALLATION_DATE:
      return { ...state, desiredInstallationDate: action.payload }
    case CheckoutAction.TOGGLE_IS_LANDLINE_CONTRACT_CANCELLED:
      return { ...state, isLandlineContractCancelled: action.payload }
    case CheckoutAction.TOGGLE_MAIN_TENANT:
      return { ...state, mainTenant: action.payload }
    case CheckoutAction.TOGGLE_PAYMENT_AUTHORIZATION:
      return { ...state, paymentAuthorization: action.payload }
    case CheckoutAction.TOGGLE_PORTABILITY:
      return { ...state, portability: action.payload }
    case CheckoutAction.TOGGLE_PREVIOUS_CONNECTION_ADDRESS:
      return { ...state, previousConnectionAddress: action.payload }
    case CheckoutAction.TOGGLE_PREVIOUS_CONNECTION_TERMINATION:
      return { ...state, previousConnectionTermination: action.payload }
    case CheckoutAction.TOGGLE_PREVIOUS_CONTRACTOR:
      return { ...state, previousContractor: action.payload }
    case CheckoutAction.TOGGLE_PRODUCT_OPTION:
      const editedOptions = [...state.selectedOptions]
      if (editedOptions.includes(action.payload)) {
        return {
          ...state,
          selectedOptions: editedOptions.filter((o) => o.id !== action.payload.id),
        }
      }
      return {
        ...state,
        selectedOptions: [...editedOptions, action.payload],
      }
    case CheckoutAction.TOGGLE_RECURRING_PAYMENT_AUTHORIZATION:
      return { ...state, recurringPaymentAuthorization: action.payload }
    case CheckoutAction.SET_VZF_ID:
      return { ...state, vzfID: action.payload }
    case CheckoutAction.TOGGLE_BUNDLE_LEGAL_REQUIREMENT:
      return { ...state, bundleLegalRequirementAccepted: action.payload }
    case CheckoutAction.SET_USER_PREVIOUS_CONTRACT:
      return { ...state, userHasPreviousContract: action.payload }
    case CheckoutAction.SET_CHECKOUT_PARTIAL:
      return { ...state, ...action.payload }
    case CheckoutAction.SET_USER_VOUCHER:
      return { ...state, userVoucher: action.payload }
    case CheckoutAction.SET_APPLIED_VOUCHER:
      return { ...state, appliedVoucher: action.payload }
    case CheckoutAction.EDIT_DELIVERY_ADDRESS:
      return { ...state, deliveryAddressData: action.payload }
    default:
      return state
  }
}

export default CheckoutReducer
