import { Fade, Grid, TextField, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import * as React from 'react'
import { AppartmentData } from 'store/Checkout/Checkout.reducer'
import TestIDs from 'utils/TestIDs'
import ErrorList from '../../utils/ErrorList'

export interface AppartmentDataFieldsProps {
  appartmentData: AppartmentData
  editAppartmentData: (payload: AppartmentData) => void
  invalidFields: string[]
  clearInvalidField: (field: string) => void
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
}

const AppartmentDataFields = (props: AppartmentDataFieldsProps): JSX.Element => {
  const { appartmentData, editAppartmentData, clearInvalidField, invalidFields, focusedField, setFocusedField } = props

  const combineAndEditAppartmentData = React.useCallback(
    // eslint-disable-next-line
    (field: string, value: any, errorField: string) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        clearInvalidField(errorField)
      }
      switch (field) {
        case 'Floor':
          editAppartmentData({
            ...appartmentData,
            floor: value,
          })
          break
        case 'Flat':
          editAppartmentData({
            ...appartmentData,
            flat: value,
          })
          break
        case 'RentalAgreementNumber':
          editAppartmentData({
            ...appartmentData,
            rentalAgreementNumber: value,
          })
          break
      }
    },
    [editAppartmentData, appartmentData, invalidFields, clearInvalidField],
  )

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Typography {...TestIDs.APPARTMENT_FIELDS_HEADER} variant={'h3'} className={'sectionH3'}>
          Wohnung
        </Typography>
      </Grid>
      <Grid container item spacing={2} className={'noPaddingTop'}>
        <Grid item md={6} xs={12}>
          <Tooltip
            {...TestIDs.APPARTMENT_FIELDS_FLOOR_TOOLTIP}
            open={invalidFields.indexOf('appartmentData-floor') !== -1 && focusedField === 'appartmentData-floor'}
            PopperProps={{
              className:
                ErrorList.AppartmentDataFloor.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : ErrorList.AppartmentDataFloor.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            placement={'top-start'}
            title={ErrorList.AppartmentDataFloor}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <TextField
              {...TestIDs.APPARTMENT_FIELDS_FLOOR_INPUT}
              onClick={(): void => setFocusedField('appartmentData-floor')}
              onBlur={(): void => setFocusedField('')}
              error={invalidFields.indexOf('appartmentData-floor') !== -1}
              label={'Etage *'}
              variant={'filled'}
              fullWidth={true}
              value={appartmentData.floor}
              onChange={(e): void => combineAndEditAppartmentData('Floor', e.target.value, 'appartmentData-floor')}
              helperText={'Bitte zählen Sie die Etagen bis zu Ihrer Wohnung. Das Erdgeschoss ist die 1. Etage.'}
              InputProps={{
                className: 'fallbackLightBackground',
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item md={6} xs={12}>
          <Tooltip
            {...TestIDs.APPARTMENT_FIELDS_FLOOR_POSITION_TOOLTIP}
            open={invalidFields.indexOf('appartmentData-flat') !== -1 && focusedField === 'appartmentData-flat'}
            PopperProps={{
              className:
                ErrorList.AppartmentDataFlat.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : ErrorList.AppartmentDataFlat.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            placement={'top-start'}
            title={ErrorList.AppartmentDataFlat}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <TextField
              inputProps={{
                maxLength: 100,
              }}
              {...TestIDs.APPARTMENT_FIELDS_FLOOR_POSITION_INPUT}
              onClick={(): void => setFocusedField('appartmentData-flat')}
              onBlur={(): void => setFocusedField('')}
              error={invalidFields.indexOf('appartmentData-flat') !== -1}
              label={'Wohnung *'}
              variant={'filled'}
              fullWidth={true}
              value={appartmentData.flat}
              onChange={(e): void => combineAndEditAppartmentData('Flat', e.target.value, 'appartmentData-flat')}
              helperText={
                'Bitte zählen Sie die Wohnungen auf Ihrer Etage von links beginnend bis zu Ihrer Wohnung. Die Treppe befindet sich hinter Ihnen.'
              }
              InputProps={{
                className: 'fallbackLightBackground',
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{
              maxLength: 100,
            }}
            {...TestIDs.APPARTMENT_CONTRACT_NUMBER_INPUT}
            label={'Mietvertragsnummer'}
            variant={'filled'}
            fullWidth={true}
            value={appartmentData.rentalAgreementNumber}
            onChange={(e): void => combineAndEditAppartmentData('RentalAgreementNumber', e.target.value, '')}
            helperText={
              'Sind Sie Mieter bei Vonovia? Dann nennen Sie uns bitte Ihre Mietvertragsnummer, falls Sie diese zur Hand haben.'
            }
            InputProps={{
              className: 'fallbackLightBackground',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(AppartmentDataFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
