import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import * as React from 'react'

export interface LoadingSpinnerProps {
  loading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    circularProgress: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
)

const LoadingSpinner = (props: LoadingSpinnerProps): JSX.Element => {
  const classes = useStyles()
  const { loading } = props

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress className={classes.circularProgress} />
    </Backdrop>
  )
}

export default LoadingSpinner
