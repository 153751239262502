import { makeStyles, Paper, Theme } from '@material-ui/core'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    margin: theme.spacing(6),
    textAlign: 'left',
  },
}))

const CookieLegals = (): JSX.Element => {
  const classes = useStyles()
  const divRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const scriptTag = document.createElement('script')

    scriptTag.id = 'CookieDeclaration'
    scriptTag.src = 'https://consent.cookiebot.com/a884ea8b-b624-4330-89b0-8be129d01c12/cd.js'
    scriptTag.async = true

    divRef.current?.appendChild(scriptTag)
  }, [])

  return (
    <Paper className={classes.contentContainer} elevation={0} square {...TestIDs.COOKIELEGALS_CONTENT}>
      <div ref={divRef} />
    </Paper>
  )
}

export default CookieLegals
