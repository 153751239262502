import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, { AvailabilityCheckAction } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import CheckoutActions, { CheckoutAction } from 'store/Checkout/Checkout.actions'

export interface AppStateReturn {
  logoClick: () => void
}
export const useAppState = (): AppStateReturn => {
  const dispatch = useDispatch<Dispatch<AvailabilityCheckActions | CheckoutActions>>()
  const resetStateNotQueriesAvaCheck = React.useCallback(() => {
    dispatch({ type: AvailabilityCheckAction.SET_TO_INIT_STATE_EXCEPT_QUERY })
  }, [dispatch])

  const resetStateNotQueriesCheckout = React.useCallback(() => {
    dispatch({ type: CheckoutAction.SET_TO_INIT_STATE_EXCEPT_QUERY })
  }, [dispatch])
  const navigate = useNavigate()

  const logoClick = (): void => {
    resetStateNotQueriesAvaCheck()
    resetStateNotQueriesCheckout()
    navigate('/')
  }
  return { logoClick }
}
