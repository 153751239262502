import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Dropdown from 'components/Dropdown'
import { isEqual } from 'lodash'
import React from 'react'
import { DeliveryAddressData } from 'store/Checkout/Checkout.reducer'
import ErrorList from 'utils/ErrorList'
import TestIDs from 'utils/TestIDs'
import { TextFieldRenderer } from '.'

interface DeliverAddressProps {
  deliverAddressData: DeliveryAddressData
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
  invalidFields: string[]
  focusedField: string
  editDeliveryAddress: (payload: DeliveryAddressData) => void
  clearInvalidField: (field: string) => void
}

const DeliveryAddress = (props: DeliverAddressProps): JSX.Element => {
  const { deliverAddressData, setFocusedField, focusedField, invalidFields, editDeliveryAddress, clearInvalidField } =
    props

  const combineAndEditDeliveryAddress = React.useCallback(
    (field: string, index: number, value: any, errorField: any) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        clearInvalidField(errorField)
      }
      switch (field) {
        case 'Zip':
          editDeliveryAddress({ ...deliverAddressData, zip: value })
          break
        case 'City':
          editDeliveryAddress({ ...deliverAddressData, city: value })
          break
        case 'Street':
          editDeliveryAddress({ ...deliverAddressData, street: value })
          break
        case 'Number':
          editDeliveryAddress({ ...deliverAddressData, number: value })
          break
        case 'AdditionalInfo':
          editDeliveryAddress({ ...deliverAddressData, additionalInfo: value })
          break
        case 'Title':
          editDeliveryAddress({ ...deliverAddressData, title: value })
          break
        case 'FirstName':
          editDeliveryAddress({ ...deliverAddressData, firstName: value })
          break
        case 'LastName':
          editDeliveryAddress({ ...deliverAddressData, lastName: value })
          break
      }
    },
    [editDeliveryAddress, deliverAddressData, invalidFields, clearInvalidField],
  )

  return (
    <>
      <Grid id={'Versandadresse'} container item spacing={2} className={'PaddingTop'}>
        <Grid item xs={12}>
          <Typography {...TestIDs.PORTABILITY_TAKE_PHONE_NUMBER_SUBHEADER} variant={'h3'} className={'centerWhenSmall'}>
            Abweichende Lieferadresse
          </Typography>
          <Typography
            {...TestIDs.PORTABILITY_CURRENT_ADRESS_SUBTITLE}
            variant={'subtitle2'}
            className={clsx(['marginTopWhenSmall', 'centerWhenSmall'])}
          >
            Falls Sie eine andere Lieferadresse für die Lieferung Ihrer Hardware angeben möchten, so können Sie das hier
            tun.
          </Typography>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              label={'Anrede'}
              options={['', 'Herr', 'Frau', 'Familie', 'Divers']}
              value={deliverAddressData.salutation}
              onChange={(e): void =>
                editDeliveryAddress({ ...deliverAddressData, salutation: e.target.value as string })
              }
              className={'fallbackLightBackground'}
            />
          </Grid>
          <TextFieldRenderer
            combineAndEdit={combineAndEditDeliveryAddress}
            index={0}
            invalidFields={invalidFields}
            focusedField={focusedField}
            setFocusedField={setFocusedField}
            values={[
              {
                columnValue: 6,
                mobileColumnValue: 12,
                editField: 'Title',
                label: 'Titel',
                value: deliverAddressData.title,
                errorField: '',
                tooltipTitle: '',
              },
              {
                columnValue: 6,
                mobileColumnValue: 12,
                editField: 'FirstName',
                label: 'Vorname',
                value: deliverAddressData.firstName,
                errorField: 'deliverAddress-firstName',
                tooltipTitle: ErrorList.DeliveryAddressFirstname,
              },
              {
                columnValue: 6,
                mobileColumnValue: 12,
                editField: 'LastName',
                label: 'Nachname',
                value: deliverAddressData.lastName,
                errorField: 'deliverAddress-lastName',
                tooltipTitle: ErrorList.DeliveryAddressLastname,
              },
              {
                columnValue: 3,
                mobileColumnValue: 12,
                editField: 'Zip',
                label: 'Postleitzahl',
                value: deliverAddressData.zip,
                errorField: 'deliverAddress-zip',
                tooltipTitle: ErrorList.DeliveryAddressZip,
              },
              {
                columnValue: 9,
                mobileColumnValue: 12,
                editField: 'City',
                label: 'Ort',
                value: deliverAddressData.city,
                errorField: 'deliverAddress-city',
                tooltipTitle: ErrorList.DeliveryAddressCity,
              },
              {
                columnValue: 6,
                mobileColumnValue: 12,
                editField: 'Street',
                label: 'Straße',
                value: deliverAddressData.street,
                errorField: 'deliverAddress-street',
                tooltipTitle: ErrorList.DeliveryAddressStreet,
              },
              {
                columnValue: 2,
                mobileColumnValue: 4,
                editField: 'Number',
                label: 'Nr.',
                value: deliverAddressData.number,
                errorField: 'deliverAddress-number',
                tooltipTitle: ErrorList.DeliveryAddressNumber,
              },
              {
                columnValue: 4,
                mobileColumnValue: 8,
                editField: 'AdditionalInfo',
                label: 'Zusatz',
                value: deliverAddressData.additionalInfo,
                errorField: '',
                tooltipTitle: '',
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default React.memo(DeliveryAddress, (prevProps, nextProps) => isEqual(prevProps, nextProps))
