import { Box, Fade, Grid, Tooltip, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import * as DateFns from 'date-fns'
import { isEqual } from 'lodash'
import * as React from 'react'
import { formatDateToString } from 'utils/function'
import TestIDs from 'utils/TestIDs'
import { OptionItemRenderer } from '.'
import ErrorList from '../../utils/ErrorList'

export interface DesiredInstallationDateProps {
  desiredInstallationDate: boolean
  toggleDesiredInstallationDate: (payload: boolean) => void
  editDesiredInstallationDate: (payload: string) => void
  invalidFields: string[]
  clearInvalidField: (field: string) => void
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
  minDate: () => Date
}

const DesiredInstallationDateFields = (props: DesiredInstallationDateProps): JSX.Element => {
  const {
    desiredInstallationDate,
    editDesiredInstallationDate,
    toggleDesiredInstallationDate,
    clearInvalidField,
    focusedField,
    invalidFields,
    setFocusedField,
    minDate,
  } = props

  const [dateValue, setDateValue] = React.useState<Date | null>(null)

  const shouldDisableDate = (day: Date | null): boolean => {
    if (day && DateFns.isWeekend(day)) {
      return true
    }
    return false
  }

  React.useEffect(
    () => {
      if (dateValue && !isNaN(new Date(dateValue).getTime())) {
        if (invalidFields.indexOf('installationDate') !== -1) {
          clearInvalidField('installationDate')
        }
        editDesiredInstallationDate(formatDateToString(dateValue))
      }
    },
    // eslint-disable-next-line
    [dateValue],
  )

  React.useEffect(
    () => {
      if (desiredInstallationDate) {
        setDateValue(null)
      }
    },
    // eslint-disable-next-line
    [desiredInstallationDate],
  )

  return (
    <Grid id={'Wunschtermin'} container item spacing={2}>
      <Grid item xs={12}>
        <Typography {...TestIDs.DESIRE_INSTALLATION_FIELDS_HEADER} variant={'h3'} className={'sectionH3'}>
          Wunsch&shy;termin
        </Typography>
      </Grid>
      <OptionItemRenderer
        editOptionItem={toggleDesiredInstallationDate}
        selectedValue={desiredInstallationDate}
        values={[
          { selectionValue: false, title: 'so schnell wie möglich' },
          { selectionValue: true, title: 'Wunschtermin' },
        ]}
      />
      {desiredInstallationDate && (
        <Box marginTop={5} flex={1} padding={1}>
          <Tooltip
            {...TestIDs.DESIRE_INSTALLATION_FIELDS_DATE_PICKER_TOOLTIP}
            open={invalidFields.indexOf('installationDate') !== -1 && focusedField === 'installationDate'}
            placement={'top-start'}
            PopperProps={{
              className:
                ErrorList.DesiredInstallationDate.length > 50
                  ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                  : ErrorList.DesiredInstallationDate.length > 40
                  ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                  : 'MuiTooltip-popper',
            }}
            title={ErrorList.DesiredInstallationDate}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <Grid item xs={12}>
              <KeyboardDatePicker
                {...TestIDs.DESIRE_INSTALLATION_FIELDS_DATE_PICKER}
                onClick={(): void => setFocusedField('installationDate')}
                onBlur={(): void => setFocusedField('')}
                error={invalidFields.indexOf('installationDate') !== -1}
                InputAdornmentProps={{ position: 'start' }}
                label={`Frühestens ab dem ${formatDateToString(minDate())} *`}
                variant={'dialog'}
                inputVariant="filled"
                fullWidth={true}
                minDate={minDate()}
                placeholder={formatDateToString(minDate())}
                shouldDisableDate={shouldDisableDate}
                helperText={`nur werktags, frühestens ab dem ${formatDateToString(minDate())}`}
                value={dateValue}
                onChange={(date): void => setDateValue(date)}
                format="dd.MM.yyyy"
                InputProps={{
                  className: 'fallbackLightBackground',
                }}
                cancelLabel={<Typography variant={'h5'}>Abbrechen</Typography>}
                okLabel={<Typography variant={'h5'}>Ok</Typography>}
              />
            </Grid>
          </Tooltip>
        </Box>
      )}
    </Grid>
  )
}

export default React.memo(DesiredInstallationDateFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
