import { Grid, Typography } from '@material-ui/core'
import { isEqual } from 'lodash'
import * as React from 'react'
import { AccountData, AccountHolder, AccountType } from 'store/Checkout/Checkout.reducer'
import TestIDs from 'utils/TestIDs'
import { AccountHolderFields } from '.'
import BankDetailsFields from './BankDetailsFields'

export interface PaymentDetailFieldsProps {
  accountHolder: boolean
  accountHolderData: AccountHolder
  toggleAccountHolder: (payload: boolean) => void
  editAccountHolderData: (payload: AccountHolder) => void
  accountType: AccountType
  iban: string
  accountData: AccountData
  paymentAuthorization: boolean
  toggleAccountType: (payload: AccountType) => void
  editIban: (payload: string) => void
  editAccountData: (payload: AccountData) => void
  togglePaymentAuthorization: (payload: boolean) => void
  invalidFields: string[]
  clearInvalidField: (field: string) => void
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
}

const PaymentDetailFields = (props: PaymentDetailFieldsProps): JSX.Element => {
  const {
    accountHolder,
    accountHolderData,
    toggleAccountHolder,
    editAccountHolderData,
    accountData,
    accountType,
    editAccountData,
    editIban,
    iban,
    paymentAuthorization,
    toggleAccountType,
    togglePaymentAuthorization,
    clearInvalidField,
    invalidFields,
    focusedField,
    setFocusedField,
  } = props

  return (
    <>
      <Grid id={'Zahlungsdaten'} container item spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h3'} className={'sectionH3'} {...TestIDs.PAYMENT_FIELDS_HEADER}>
            Zahlungs&shy;daten
          </Typography>
        </Grid>
        <AccountHolderFields
          accountHolder={accountHolder}
          accountHolderData={accountHolderData}
          editAccountHolderData={editAccountHolderData}
          toggleAccountHolder={toggleAccountHolder}
          invalidFields={invalidFields}
          clearInvalidField={clearInvalidField}
          focusedField={focusedField}
          setFocusedField={setFocusedField}
        />
        <BankDetailsFields
          accountData={accountData}
          accountType={accountType}
          editAccountData={editAccountData}
          editIban={editIban}
          iban={iban}
          paymentAuthorization={paymentAuthorization}
          toggleAccountType={toggleAccountType}
          togglePaymentAuthorization={togglePaymentAuthorization}
          invalidFields={invalidFields}
          clearInvalidField={clearInvalidField}
          focusedField={focusedField}
          setFocusedField={setFocusedField}
          isSameAccountHolder={!accountHolder}
        />
      </Grid>
    </>
  )
}

export default React.memo(PaymentDetailFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
