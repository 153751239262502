import { combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import AvailabilityCheckReducer from './AvailabilityCheck/AvailabilityCheck.reducer'
import CheckoutReducer from './Checkout/Checkout.reducer'

export const rootReducer = combineReducers({
  availabilityCheck: AvailabilityCheckReducer,
  checkout: CheckoutReducer,
})

const store = createStore(rootReducer, composeWithDevTools())
export default store
export type AppState = ReturnType<typeof rootReducer>
