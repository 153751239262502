import { compareAsc, isAfter, isFuture, sub } from 'date-fns'
import {
  AddressData,
  PriceInterval,
  Product,
  ProductOption,
  Voucher,
  VoucherAvailableType,
  VoucherDiscountType,
  VoucherType,
  VoucherValidityType,
  VoucherValueType,
} from 'graphql/types'
import IBAN from 'iban'
import {
  AccountData,
  AccountHolder,
  AccountType,
  Address,
  AppartmentData,
  DeliveryAddressData,
  MainData,
  MainTenantData,
  PortabilityNumber,
  PreviousContractor,
} from 'store/Checkout/Checkout.reducer'
import { formatDateToString, formatStringToDate } from 'utils/function'

export const getInvalidFormFields = (
  mainData: MainData,
  appartmentData: AppartmentData,
  mainTenant: boolean,
  mainTenantData: MainTenantData,
  portability: boolean,
  portabilityNumbers: PortabilityNumber[],
  previousContractor: boolean,
  previousContractorData: PreviousContractor[],
  previousConnectionAddress: boolean,
  previousConnectionAddressData: Address,
  deliveryAddressData: DeliveryAddressData,
  desiredInstallationDate: boolean,
  desiredInstallationDateData: string | null,
  minDate: () => Date,
  accountHolder: boolean,
  accountHolderData: AccountHolder,
  accountType: AccountType,
  iban: string,
  accountData: AccountData,
  paymentAuthorization: boolean,
  previousProviderDropdownValue: string,
  previousProviderTextFieldValue: string,
  setInvalidFields: (payload: string[]) => void,
): number => {
  const invalidFields: string[] = []

  if (mainData.basicData.name.length < 1) {
    invalidFields.push('basicData-name')
  }

  if (mainData.basicData.lastName.length < 1) {
    invalidFields.push('basicData-lastName')
  }

  const date = formatStringToDate(mainData.date)
  if (isNaN(date.getTime())) {
    invalidFields.push('basicData-date')
  } else {
    const maxDate = sub(new Date(), {
      years: 18,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    })

    if (isAfter(date, maxDate)) {
      invalidFields.push('basicData-date')
    }
  }

  // eslint-disable-next-line
  const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!emailRegExp.test(mainData.email)) {
    invalidFields.push('basicData-email')
  }

  const telephoneRegExp = /^[+]*[0-9\s]+$/
  if (!telephoneRegExp.test(mainData.telephoneNr)) {
    invalidFields.push('basicData-telephoneNr')
  }

  if (appartmentData.flat.length < 1) {
    invalidFields.push('appartmentData-flat')
  }

  if (appartmentData.floor.length < 1) {
    invalidFields.push('appartmentData-floor')
  }

  if (mainTenant) {
    if (mainTenantData.name.length < 1) {
      invalidFields.push('mainTenant-name')
    }
    if (mainTenantData.lastName.length < 1) {
      invalidFields.push('mainTenant-lastName')
    }
  }

  if (portability) {
    if (portabilityNumbers[0].areaCode.length < 1) {
      invalidFields.push('portabilityNumber-areaCode-0')
    }
    if (previousProviderDropdownValue === '') {
      invalidFields.push('previousProvider-0')
    }
    if (previousProviderDropdownValue === 'Sonstiger Anbieter' && previousProviderTextFieldValue.length < 1) {
      invalidFields.push('previousProvider-1')
    }

    if (portabilityNumbers[0].number.length < 1) {
      invalidFields.push('portabilityNumber-number-0')
    }
    if (portabilityNumbers.length > 1) {
      if (
        portabilityNumbers[0].areaCode.length > 0 &&
        portabilityNumbers[1].areaCode !== portabilityNumbers[0].areaCode
      ) {
        invalidFields.push('portabilityNumber-areaCode-1')
      }
      if (portabilityNumbers.length > 2) {
        if (portabilityNumbers[2].areaCode !== portabilityNumbers[0].areaCode) {
          invalidFields.push('portabilityNumber-areaCode-2')
        }
      }
    }

    if (previousContractor) {
      if (previousContractorData[0].name.length < 1) {
        invalidFields.push('previousContractor-name')
      }

      if (previousContractorData[0].lastName.length < 1) {
        invalidFields.push('previousContractor-lastName')
      }
    }

    if (previousConnectionAddress) {
      if (previousConnectionAddressData.zip.length < 5) {
        invalidFields.push('previousConnectionAddress-zip')
      }

      if (previousConnectionAddressData.city.length < 1) {
        invalidFields.push('previousConnectionAddress-city')
      }

      if (previousConnectionAddressData.street.length < 1) {
        invalidFields.push('previousConnectionAddress-street')
      }

      if (previousConnectionAddressData.number.length < 1) {
        invalidFields.push('previousConnectionAddress-number')
      }
    }
  }

  if (deliveryAddressData) {
    if (
      deliveryAddressData.zip != '' ||
      deliveryAddressData.city != '' ||
      deliveryAddressData.street != '' ||
      deliveryAddressData.number != '' ||
      deliveryAddressData.firstName != '' ||
      deliveryAddressData.lastName != ''
    ) {
      if (deliveryAddressData.zip.length < 5) {
        invalidFields.push('deliverAddress-zip')
      }

      if (deliveryAddressData.firstName.length < 2) {
        invalidFields.push('deliverAddress-firstName')
      }
      if (deliveryAddressData.lastName.length < 2) {
        invalidFields.push('deliverAddress-lastName')
      }

      if (deliveryAddressData.city.length < 1) {
        invalidFields.push('deliverAddress-city')
      }

      if (deliveryAddressData.street.length < 1) {
        invalidFields.push('deliverAddress-street')
      }

      if (deliveryAddressData.number.length < 1) {
        invalidFields.push('deliverAddress-number')
      }
    }
  }

  if (desiredInstallationDate) {
    if (
      desiredInstallationDateData === null ||
      (desiredInstallationDateData !== null && desiredInstallationDateData.toString() === 'Invalid Date')
    )
      invalidFields.push('installationDate')
    else {
      const date = formatStringToDate(desiredInstallationDateData)
      const minimumDate = minDate()

      if (compareAsc(date, minimumDate) === -1 || date.getDay() === 0 || date.getDay() === 6)
        invalidFields.push('installationDate')
    }
  }

  if (accountHolder) {
    if (accountHolderData.name.length < 1) {
      invalidFields.push('accountHolder-name')
    }

    if (accountHolderData.lastName.length < 1) {
      invalidFields.push('accountHolder-lastName')
    }
  }

  if (!accountHolder) {
    if (accountType === AccountType.IBAN) {
      if (!IBAN.isValid(iban)) {
        invalidFields.push('accountData-iban')
      }
    } else {
      if (accountData.accountNumber.length < 1) {
        invalidFields.push('accountData-accountNumber')
      }
      if (accountData.bankCode.length < 1) {
        invalidFields.push('accountData-bankCode')
      }
    }
  }
  if (!paymentAuthorization) {
    invalidFields.push('paymentAuthorization')
  }

  if (invalidFields.length > 0) {
    setInvalidFields(invalidFields)
  }
  return invalidFields.length
}

export const validateAddressData = (ad: AddressData): boolean => {
  return (
    ad.online === true &&
    (ad.startOfMarketing ? new Date(ad.startOfMarketing).getTime() <= new Date().getTime() : true) &&
    (ad.products ? ad.products.length > 0 : false)
  )
}

export const voucherIsValidDate = (voucher: Voucher): boolean => {
  if (
    voucher.validityType === VoucherValidityType.DATE_FROM &&
    voucher.dateFrom &&
    !isFuture(formatStringToDate(formatDateToString(voucher.dateFrom)))
  )
    return true
  if (
    voucher.validityType === VoucherValidityType.DATE_TO &&
    voucher.dateTo &&
    isFuture(formatStringToDate(formatDateToString(voucher.dateTo)))
  )
    return true
  if (
    voucher.validityType === VoucherValidityType.TIME_SPAN &&
    voucher.dateFrom &&
    !isFuture(formatStringToDate(formatDateToString(voucher.dateFrom))) &&
    voucher.dateTo &&
    isFuture(formatStringToDate(formatDateToString(voucher.dateTo)))
  )
    return true
  return false
}

export const voucherIsValid = (voucher: Voucher, vaild: boolean): boolean => {
  if (vaild && voucher.amount - voucher.redeemed > 0) {
    if (voucher.validityType === VoucherValidityType.UNLIMITED) return true
    return voucherIsValidDate(voucher)
  }
  return false
}

export const getAvailableVouchers = (voucherlist: Voucher[], selectedAddress?: AddressData): Voucher[] => {
  const returnVouchers: Voucher[] = []
  if (selectedAddress && selectedAddress.vouchers) {
    selectedAddress.vouchers.forEach((vou) => {
      const voucher = voucherlist.find((v) => v.id === vou)
      if (voucher) {
        returnVouchers.push(voucher)
      }
    })
  }
  return returnVouchers
}

export const getAppliedVouchers = (
  voucherlist: Voucher[],
  userVoucher: string,
  selectedAddress?: AddressData,
): Voucher[] => {
  const availableVouchers = getAvailableVouchers(voucherlist, selectedAddress)
  const returnVouchers = availableVouchers.filter((v) => voucherIsValid(v, v.type === VoucherType.SYSTEM))
  const voucher = availableVouchers.find((v) => voucherIsValid(v, userVoucher !== '' && v.code === userVoucher))
  if (voucher) returnVouchers.push(voucher)
  return returnVouchers
}

export const calcSumVoucher = (voucher: Voucher, options: ProductOption[], product?: Product): number => {
  let sum = 0
  if (voucher.availableType === VoucherAvailableType.PRODUCTS && product !== undefined) {
    const productDiscount = voucher.availableFor.find((p) => p === product.id)
    if (productDiscount) {
      if (voucher.valueType === VoucherValueType.EURO) {
        sum += voucher.value
      } else {
        sum += product.price.value * (voucher.value / 100)
      }
    }
  }
  if (voucher.availableType === VoucherAvailableType.OPTIONS && options.length > 0) {
    options.forEach((option) => {
      const optionDiscount = voucher.availableFor.find((p) => p === option.id)
      if (optionDiscount) {
        if (voucher.valueType === VoucherValueType.EURO) {
          sum += voucher.value
        } else {
          sum += option.price.value * (voucher.value / 100)
        }
      }
    })
  }
  return sum
}

export const calcNormalSumBasket = (
  options: ProductOption[],
  product?: Product,
): { monthlySum: number; oneTimeSum: number } => {
  let monthlySum = options.reduce(
    (sum, curr) =>
      sum + (curr.price.interval === PriceInterval.MONTHLY && curr.basket.displayed ? curr.price.value : 0),
    0,
  )
  let oneTimeSum = options.reduce(
    (sum, curr) =>
      sum + (curr.price.interval === PriceInterval.ONE_TIME && curr.basket.displayed ? curr.price.value : 0),
    0,
  )
  if (product && product.basket.displayed) {
    if (product.price.interval === PriceInterval.ONE_TIME) {
      oneTimeSum += product.price.value
    } else {
      monthlySum += product.price.value
    }
  }
  return { monthlySum, oneTimeSum }
}

export const calcSumBundleProduct = (bundleProduct: Product, options: ProductOption[], product?: Product): number => {
  let sum = 0
  const bundleDependencies = bundleProduct.basket.dependentOn
  if (bundleDependencies === undefined || bundleDependencies === null) {
    return sum
  }
  if (product !== undefined) {
    const productDiscount = bundleDependencies.products.find((p) => p.id === product.id)
    if (productDiscount) {
      sum += productDiscount.oneTimeCut
    }
  }
  if (options.length > 0) {
    options.forEach((option) => {
      const optionDiscount = bundleDependencies.options.find((o) => o.id === option.id)
      if (optionDiscount) {
        sum += optionDiscount.oneTimeCut
      }
    })
  }
  return sum
}

export type SumBasketReturn = { text: string; value: number }

export const calcSumBasket = (
  monthlySum: number,
  voucherlist: Voucher[],
  options: ProductOption[],
  product?: Product,
): SumBasketReturn[] => {
  const returnArray: SumBasketReturn[] = []
  const newList = voucherlist
    .filter(
      (v) =>
        v.discountType === VoucherDiscountType.MONTHLY && v.numberOfMonth !== undefined && v.numberOfMonth !== null,
    )
    .sort((a, b) => (b.numberOfMonth ?? 0) - (a.numberOfMonth ?? 0))
  if (newList.length < 1) return [{ text: '', value: monthlySum }]
  const newIdMap: SumBasketReturn[] = []
  newList.forEach((v, i) => {
    const newText = `${(v.numberOfMonth ?? 0) + 1}`
    const indexOfObject = newIdMap.findIndex((v) => v.text === newText)
    if (indexOfObject === -1 && i === 0) {
      newIdMap.push({ text: newText, value: calcSumVoucher(v, options, product) })
    } else if (indexOfObject === -1 && i !== 0) {
      newIdMap.push({ text: newText, value: calcSumVoucher(v, options, product) + newIdMap[i - 1].value })
    } else {
      newIdMap[indexOfObject].value += calcSumVoucher(v, options, product)
    }
  })
  if (newIdMap.length === 1) {
    returnArray.push({ text: 'ab dem 1. Monat', value: monthlySum - newIdMap[0].value })
    returnArray.push({ text: `ab dem ${newIdMap[0].text}. Monat`, value: monthlySum })
  } else {
    newIdMap
      .sort((a, b) => Number(a.text) - Number(b.text))
      .forEach((m, i) => {
        if (i === 0) {
          returnArray.push({ text: 'ab dem 1. Monat', value: monthlySum - m.value })
        } else {
          returnArray.push({ text: `ab dem ${newIdMap[i - 1].text}. Monat`, value: monthlySum - m.value })
        }
      })
    returnArray.push({ text: `ab dem ${newIdMap[newIdMap.length - 1].text}. Monat`, value: monthlySum })
  }

  return returnArray
}

export const priseTofixed = (price: number): string =>
  price % 1 !== 0 ? price.toFixed(2).replace('.', ',') : price.toString()
