import { Box, Button, Fade, Grid, TextField, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import clsx from 'clsx'
import Dropdown from 'components/Dropdown'
import { isEqual } from 'lodash'
import * as React from 'react'
import InputMask from 'react-input-mask'
import { Address, PortabilityNumber, PreviousContractor } from 'store/Checkout/Checkout.reducer'
import TestIDs from 'utils/TestIDs'
import { OptionItemRenderer, TextFieldRenderer } from '.'
import ErrorList from '../../utils/ErrorList'

export interface PortabilityFieldsProps {
  portability: boolean
  portabilityNumbers: PortabilityNumber[]
  previousContractor: boolean
  previousContractorData: PreviousContractor[]
  previousConnectionAddress: boolean
  previousConnectionAddressData: Address
  deliverAddressData: Address
  previousConnectionTermination: boolean
  previousConnectionTerminationData: string
  togglePortability: (payload: boolean) => void
  editPortabilityNumber: (payload: PortabilityNumber, index: number) => void
  addPortabilityNumber: () => void
  removePortabilityNumber: (index: number) => void
  togglePreviousContractor: (payload: boolean) => void
  editPreviousContractor: (payload: PreviousContractor, index: number) => void
  addPreviousContractor: () => void
  removePreviousContractor: (index: number) => void
  toggleConnectionAddress: (payload: boolean) => void
  editConnectionAddress: (payload: Address) => void
  toggleConnectionTermination: (payload: boolean) => void
  editConnectionTermination: (payload: string) => void
  invalidFields: string[]
  clearInvalidField: (field: string) => void
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
  previousProviderDropdownValue: string
  previousProviderDropdownChange: (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    child: React.ReactNode,
  ) => void
  previousProviderDropdownOptions: string[]
  previousProviderTextFieldValue: string
  previousProviderTextFieldChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  toggleIsLandlineContractCancelled: (payload: boolean) => void
  isLandlineContractCancelled: boolean
  landlineContractCancelDate: string
  landlineContractCancelDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PortabilityFields = (props: PortabilityFieldsProps): JSX.Element => {
  const {
    portability,
    portabilityNumbers,
    previousConnectionAddress,
    previousConnectionAddressData,
    // previousConnectionTermination,
    // previousConnectionTerminationData,
    previousContractor,
    previousContractorData,
    togglePortability,
    editPortabilityNumber,
    addPortabilityNumber,
    removePortabilityNumber,
    togglePreviousContractor,
    editPreviousContractor,
    addPreviousContractor,
    removePreviousContractor,
    toggleConnectionAddress,
    editConnectionAddress,
    // toggleConnectionTermination,
    // editConnectionTermination,
    clearInvalidField,
    invalidFields,
    focusedField,
    setFocusedField,
    previousProviderDropdownValue,
    previousProviderDropdownChange,
    previousProviderDropdownOptions,
    previousProviderTextFieldValue,
    previousProviderTextFieldChange,
    toggleIsLandlineContractCancelled,
    isLandlineContractCancelled,
    landlineContractCancelDate,
    landlineContractCancelDateChange,
  } = props

  const combineAndEditPortabilityNumber = React.useCallback(
    // eslint-disable-next-line
    (field: string, index: number, value: any, errorField: string) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        clearInvalidField(errorField)
      }
      switch (field) {
        case 'AreaCode':
          editPortabilityNumber({ ...portabilityNumbers[index], areaCode: value }, index)
          break
        case 'Number':
          editPortabilityNumber({ ...portabilityNumbers[index], number: value }, index)
          break
      }
    },
    [editPortabilityNumber, portabilityNumbers, invalidFields, clearInvalidField],
  )

  const combineAndEditPreviousContractor = React.useCallback(
    // eslint-disable-next-line
    (field: string, index: number, value: any, errorField: string) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        clearInvalidField(errorField)
      }
      switch (field) {
        case 'Name':
          editPreviousContractor({ ...previousContractorData[index], name: value }, index)
          break
        case 'LastName':
          editPreviousContractor({ ...previousContractorData[index], lastName: value }, index)
          break
      }
    },
    [editPreviousContractor, previousContractorData, invalidFields, clearInvalidField],
  )

  const combineAndEditPreviousAddress = React.useCallback(
    (field: string, index: number, value: any, errorField: any) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        clearInvalidField(errorField)
      }
      switch (field) {
        case 'Zip':
          editConnectionAddress({ ...previousConnectionAddressData, zip: value })
          break
        case 'City':
          editConnectionAddress({ ...previousConnectionAddressData, city: value })
          break
        case 'Street':
          editConnectionAddress({ ...previousConnectionAddressData, street: value })
          break
        case 'Number':
          editConnectionAddress({ ...previousConnectionAddressData, number: value })
          break
        case 'AdditionalInfo':
          editConnectionAddress({ ...previousConnectionAddressData, additionalInfo: value })
          break
      }
    },
    [editConnectionAddress, previousConnectionAddressData, invalidFields, clearInvalidField],
  )

  const isLastPortabilityNumberEmpty = (portabilityNumber: PortabilityNumber): boolean => {
    return portabilityNumber.areaCode === '' && portabilityNumber.number === ''
  }

  const isLastPreviousContractorEmpty = (previousContractor: PreviousContractor): boolean => {
    return previousContractor.name === '' && previousContractor.lastName === ''
  }

  return (
    <>
      <Grid id={'Rufnummernmitnahme'} container item spacing={2} className={'PaddingTop'}>
        <Grid item>
          <Typography variant={'h3'} className={'sectionH3'} {...TestIDs.PORTABILITY_TAKE_PHONE_NUMBER_HEADER}>
            Rufnummern&shy;mitnahme
          </Typography>
        </Grid>
        <OptionItemRenderer
          editOptionItem={togglePortability}
          selectedValue={portability}
          values={[
            { selectionValue: false, title: 'Ich möchte eine neue Telefon-Nummer' },
            { selectionValue: true, title: 'Bisherige Rufnummer mitnehmen' },
          ]}
        />
        {portability === true && (
          <>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography
                  {...TestIDs.PORTABILITY_TAKE_PHONE_NUMBER_SUBHEADER}
                  variant={'h4'}
                  className={'centerWhenSmall'}
                >
                  Rufnummern&shy;mitnahme
                </Typography>
              </Grid>
              <Grid container item spacing={2}>
                {portabilityNumbers.map((value: PortabilityNumber, index: number) => {
                  return (
                    <TextFieldRenderer
                      key={'portabilityNumber' + index}
                      values={[
                        {
                          editField: 'AreaCode',
                          label: 'Vorwahl *',
                          value: value.areaCode,
                          columnValue: 6,
                          errorField: 'portabilityNumber-areaCode-' + index,
                          tooltipTitle: ErrorList.PortabilityAreaCode,
                          mobileColumnValue: 12,
                        },
                        {
                          editField: 'Number',
                          label: `Rufnummer ${index === 0 ? '*' : ''}`,
                          value: value.number,
                          columnValue: 6,
                          errorField: 'portabilityNumber-number-' + index,
                          tooltipTitle: ErrorList.PortabilityNumber,
                          mobileColumnValue: 12,
                        },
                      ]}
                      focusedField={focusedField}
                      setFocusedField={setFocusedField}
                      invalidFields={invalidFields}
                      combineAndEdit={combineAndEditPortabilityNumber}
                      index={index}
                    />
                  )
                })}
                <Grid item md={6}>
                  {!(
                    portabilityNumbers.length > 2 &&
                    !isLastPortabilityNumberEmpty(portabilityNumbers[portabilityNumbers.length - 1])
                  ) && (
                    <Box textAlign={'left'}>
                      <Button
                        disableElevation
                        {...TestIDs.PORTABILITY_TAKE_PHONE_NUMBER_ADD_NEW_FIELD}
                        variant="contained"
                        startIcon={
                          portabilityNumbers.length > 1 &&
                          isLastPortabilityNumberEmpty(portabilityNumbers[portabilityNumbers.length - 1]) ? (
                            <RemoveIcon fontSize={'small'} className={'AddFieldButton'} />
                          ) : (
                            <AddIcon fontSize={'small'} className={'AddFieldButton'} />
                          )
                        }
                        onClick={
                          portabilityNumbers.length > 1 &&
                          isLastPortabilityNumberEmpty(portabilityNumbers[portabilityNumbers.length - 1])
                            ? (): void => removePortabilityNumber(portabilityNumbers.length - 1)
                            : (): void => {
                                if (portabilityNumbers.length < 3) {
                                  addPortabilityNumber()
                                }
                              }
                        }
                      >
                        <Typography
                          {...TestIDs.PORTABILITY_TAKE_PHONE_NUMBER_ADD_NEW_FIELD_LABEL}
                          className={'AddFieldButton'}
                        >
                          {portabilityNumbers.length > 1 &&
                          isLastPortabilityNumberEmpty(portabilityNumbers[portabilityNumbers.length - 1])
                            ? 'Rufnummer entfernen'
                            : 'weitere Rufnummer angeben'}
                        </Typography>
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} className={'PaddingTop'}>
              <Grid item xs={12}>
                <Typography className={'centerWhenSmall'} variant={'h4'}>
                  Bisheriger Anbieter
                </Typography>
                <Typography variant={'subtitle2'} className={clsx(['marginTopWhenSmall', 'centerWhenSmall'])}>
                  Die Firmierung ihres vorherigen Anbieters finden Sie auf Ihrer Rechnung oder in Ihren
                  Vertragsunterlagen.
                </Typography>
              </Grid>
              <Tooltip
                open={invalidFields.indexOf('previousProvider-0') !== -1 && focusedField === 'previousProviderDropdown'}
                PopperProps={{
                  className:
                    ErrorList.PreviousProviderDropdown.length > 50
                      ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                      : ErrorList.PreviousProviderDropdown.length > 40
                      ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                      : 'MuiTooltip-popper',
                }}
                placement={'top-start'}
                title={ErrorList.PreviousProviderDropdown}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 100 }}
              >
                <Grid
                  item
                  xs={12}
                  onMouseEnter={(): void => {
                    setFocusedField('previousProviderDropdown')
                  }}
                  onMouseLeave={(): void => {
                    setFocusedField('')
                  }}
                  style={{ paddingTop: 0 }}
                >
                  <Dropdown
                    label={'Bisheriger Anbieter *'}
                    value={previousProviderDropdownValue}
                    onChange={previousProviderDropdownChange}
                    options={previousProviderDropdownOptions}
                    className={'fallbackLightBackground'}
                    error={invalidFields.indexOf('previousProvider-0') !== -1}
                  />
                </Grid>
              </Tooltip>
              {previousProviderDropdownValue === 'Sonstiger Anbieter' && (
                <Grid item xs={12}>
                  <Tooltip
                    open={
                      invalidFields.indexOf('previousProvider-1') !== -1 && focusedField === 'previousProviderTextField'
                    }
                    PopperProps={{
                      className:
                        ErrorList.PreviousProviderTextField.length > 50
                          ? clsx('BigErrorMessage', 'MuiTooltip-popper')
                          : ErrorList.PreviousProviderTextField.length > 40
                          ? clsx('MediumErrorMessage', 'MuiTooltip-popper')
                          : 'MuiTooltip-popper',
                    }}
                    placement={'top-start'}
                    title={ErrorList.PreviousProviderTextField}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 100 }}
                  >
                    <TextField
                      inputProps={{
                        maxLength: 100,
                      }}
                      fullWidth={true}
                      label={'Sonstigen Anbieter eingeben  *'}
                      variant={'filled'}
                      onClick={(): void => setFocusedField('previousProviderTextField')}
                      onBlur={(): void => setFocusedField('')}
                      value={previousProviderTextFieldValue}
                      error={invalidFields.indexOf('previousProvider-1') !== -1}
                      onChange={previousProviderTextFieldChange}
                      InputProps={{
                        className: 'fallbackLightBackground',
                      }}
                    />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <Grid container item spacing={2} className={'PaddingTop'}>
              <Grid item xs={12}>
                <Typography
                  className={'centerWhenSmall'}
                  {...TestIDs.PORTABILITY_CONTRACT_HOLDER_HEADER}
                  variant={'h4'}
                >
                  Bisherige Vertragsinhaber
                </Typography>
              </Grid>
              <Grid container item spacing={2} className={'PaddingTop'}>
                <OptionItemRenderer
                  editOptionItem={togglePreviousContractor}
                  selectedValue={previousContractor}
                  values={[
                    { selectionValue: false, title: 'Ich selbst' },
                    { selectionValue: true, title: 'Andere Person(en)' },
                  ]}
                />
                {previousContractor && (
                  <Grid container item spacing={2}>
                    {/* Here goes the map of something */}
                    {previousContractorData.map((value: PreviousContractor, index: number) => {
                      return (
                        <TextFieldRenderer
                          key={'previousContractor' + index}
                          values={[
                            {
                              editField: 'Name',
                              label: `Vorname ${index === 0 ? '*' : ''}`,
                              value: value.name,
                              columnValue: 6,
                              errorField: index === 0 ? 'previousContractor-name' : '',
                              tooltipTitle: ErrorList.PreviousContractorName,
                              mobileColumnValue: 12,
                            },
                            {
                              editField: 'LastName',
                              label: `Nachname ${index === 0 ? '*' : ''}`,
                              value: value.lastName,
                              columnValue: 6,
                              errorField: index === 0 ? 'previousContractor-lastName' : '',
                              tooltipTitle: ErrorList.PreviousContractorLastName,
                              mobileColumnValue: 12,
                            },
                          ]}
                          focusedField={focusedField}
                          setFocusedField={setFocusedField}
                          invalidFields={invalidFields}
                          combineAndEdit={combineAndEditPreviousContractor}
                          index={index}
                        />
                      )
                    })}
                    <Grid item md={6}>
                      <Box textAlign={'left'}>
                        <Button
                          disableElevation
                          {...TestIDs.PORTABILITY_CONTRACT_HOLDER_ADD_NEW_FIELD}
                          variant="contained"
                          startIcon={
                            previousContractorData.length > 1 &&
                            isLastPreviousContractorEmpty(previousContractorData[previousContractorData.length - 1]) ? (
                              <RemoveIcon fontSize={'small'} className={'AddFieldButton'} />
                            ) : (
                              <AddIcon fontSize={'small'} className={'AddFieldButton'} />
                            )
                          }
                          onClick={
                            previousContractorData.length > 1 &&
                            isLastPreviousContractorEmpty(previousContractorData[previousContractorData.length - 1])
                              ? (): void => removePreviousContractor(previousContractorData.length - 1)
                              : (): void => {
                                  if (previousContractorData.length < 10) {
                                    addPreviousContractor()
                                  }
                                }
                          }
                        >
                          <Typography
                            className={'AddFieldButton'}
                            {...TestIDs.PORTABILITY_CONTRACT_HOLDER_ADD_NEW_FIELD_LABEL}
                          >
                            weiterer Vertragsinhaber
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item spacing={2} className={'PaddingTop'}>
              <Grid item xs={12}>
                <Typography className={'centerWhenSmall'} {...TestIDs.PORTABILITY_CURRENT_ADRESS_HEADER} variant={'h4'}>
                  Bisherige Anschlussadresse
                </Typography>
                <Typography
                  {...TestIDs.PORTABILITY_CURRENT_ADRESS_SUBTITLE}
                  variant={'subtitle2'}
                  className={clsx(['marginTopWhenSmall', 'centerWhenSmall'])}
                >
                  Sie können Ihre Rufnummer nur mitnehmen, wenn Sie innerhalb des gleichen Vorwahlbereichs wechseln.
                </Typography>
              </Grid>
              <Grid container item spacing={2} className={'PaddingTop'}>
                <OptionItemRenderer
                  editOptionItem={toggleConnectionAddress}
                  selectedValue={previousConnectionAddress}
                  values={[
                    { selectionValue: false, title: 'Identisch mit Anschlussadresse' },
                    { selectionValue: true, title: 'andere Adresse (Umzug)' },
                  ]}
                />
                {previousConnectionAddress === true && (
                  <Grid container item spacing={2}>
                    <TextFieldRenderer
                      combineAndEdit={combineAndEditPreviousAddress}
                      index={0}
                      invalidFields={invalidFields}
                      focusedField={focusedField}
                      setFocusedField={setFocusedField}
                      values={[
                        {
                          columnValue: 3,
                          mobileColumnValue: 12,
                          editField: 'Zip',
                          label: 'Postleitzahl *',
                          value: previousConnectionAddressData.zip,
                          errorField: 'previousConnectionAddress-zip',
                          tooltipTitle: ErrorList.PreviousContractorAddressZip,
                        },
                        {
                          columnValue: 9,
                          mobileColumnValue: 12,
                          editField: 'City',
                          label: 'Ort *',
                          value: previousConnectionAddressData.city,
                          errorField: 'previousConnectionAddress-city',
                          tooltipTitle: ErrorList.PreviousContractorAddressCity,
                        },
                        {
                          columnValue: 6,
                          mobileColumnValue: 12,
                          editField: 'Street',
                          label: 'Straße *',
                          value: previousConnectionAddressData.street,
                          errorField: 'previousConnectionAddress-street',
                          tooltipTitle: ErrorList.PreviousContractorAddressStreet,
                        },
                        {
                          columnValue: 2,
                          mobileColumnValue: 4,
                          editField: 'Number',
                          label: 'Nr. *',
                          value: previousConnectionAddressData.number,
                          errorField: 'previousConnectionAddress-number',
                          tooltipTitle: ErrorList.PreviousContractorAddressNumber,
                        },
                        {
                          columnValue: 4,
                          mobileColumnValue: 8,
                          editField: 'AdditionalInfo',
                          label: 'Zusatz',
                          value: previousConnectionAddressData.additionalInfo,
                          errorField: '',
                          tooltipTitle: '',
                        },
                      ]}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item spacing={2} className={'PaddingTop'}>
              <Grid item xs={12}>
                <Typography className={'centerWhenSmall'} variant={'h4'}>
                  Ist Ihr bisheriger Festnetzvertrag bereits gekündigt?
                </Typography>
                <Typography variant={'subtitle2'} className={clsx(['marginTopWhenSmall', 'centerWhenSmall'])}>
                  Hinweistext zur Mitnahme Ihrer Festnetznummer
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} className={'PaddingTop'}>
              <OptionItemRenderer
                editOptionItem={toggleIsLandlineContractCancelled}
                selectedValue={isLandlineContractCancelled}
                values={[
                  { selectionValue: false, title: 'Nein' },
                  { selectionValue: true, title: 'Ja (bitte angeben, falls bekannt)' },
                ]}
              />
              {isLandlineContractCancelled && (
                <Grid item md={4} xs={12}>
                  <InputMask
                    onClick={(): void => setFocusedField('basicData-date')}
                    onBlur={(): void => setFocusedField('')}
                    mask={'99.99.9999'}
                    alwaysShowMask={false}
                    placeholder={''}
                    value={landlineContractCancelDate}
                    onChange={landlineContractCancelDateChange}
                  >
                    <TextField
                      inputProps={{
                        maxLength: 100,
                      }}
                      label={'Vertragsende'}
                      variant={'filled'}
                      fullWidth={true}
                      InputProps={{
                        className: 'fallbackLightBackground',
                      }}
                    />
                  </InputMask>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

// export default PortabilityFields
export default React.memo(PortabilityFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
