import * as React from 'react'

interface BasketIconProps {
  width: number
  height: number
}

const BasketIcon = (props: BasketIconProps): JSX.Element => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M44.1423 8.91948L40.823 11.4529C44.0621 15.2242 45.8291 19.9763 45.8291 24.9999C45.8291 30.5636 43.6626 35.7941 39.7285 39.7283C35.7942 43.6624 30.5637 45.8289 25 45.8289C19.4365 45.8289 14.2058 43.6624 10.2717 39.7283C6.33766 35.7941 4.1711 30.5636 4.1711 24.9999C4.1711 19.4364 6.33766 14.2057 10.2717 10.2717C14.2058 6.33763 19.4365 4.17108 25 4.17108C29.2148 4.17108 33.2378 5.41532 36.6514 7.72838C37.2624 8.079 38.1393 8.43387 39.3631 8.305C40.9168 8.14132 41.9334 7.04617 42.1415 6.80301C37.4855 2.41089 31.4284 0 25 0C18.3223 0 12.0441 2.60043 7.32229 7.32225C2.60044 12.0441 0 18.3222 0 24.9999C0 31.6778 2.60044 37.9557 7.32229 42.6775C12.0441 47.3993 18.3223 50 25 50C31.6779 50 37.9559 47.3993 42.6777 42.6775C47.3996 37.9557 50 31.6778 50 24.9999C50 19.0493 47.9334 13.417 44.1423 8.91948Z"
          fill="#00607B"
        />
        <path
          d="M33.8543 32.2896H17.9902C17.0645 32.2896 16.2706 31.6282 16.1047 30.7171L13.815 18.1732H11.7483C10.6896 18.1732 9.83154 17.3152 9.83154 16.2563C9.83154 15.1975 10.6896 14.3395 11.7483 14.3395H15.414C16.3396 14.3395 17.1335 15.0017 17.2994 15.912L19.5891 28.4559H32.27L33.8216 20.3759H22.7571C21.6983 20.3759 20.8403 19.5178 20.8403 18.459C20.8403 17.4002 21.6983 16.5421 22.7571 16.5421H36.1412C36.7128 16.5421 37.2551 16.7973 37.6189 17.2385C37.9828 17.679 38.1312 18.2589 38.0239 18.8208L35.7363 30.7345C35.5641 31.6372 34.7744 32.2896 33.8543 32.2896Z"
          fill="#00607B"
        />
        <path
          d="M21.5771 39.9013C23.2212 39.9013 24.554 38.5684 24.554 36.9242C24.554 35.2801 23.2212 33.9472 21.5771 33.9472C19.9329 33.9472 18.6001 35.2801 18.6001 36.9242C18.6001 38.5684 19.9329 39.9013 21.5771 39.9013Z"
          fill="#00607B"
        />
        <path
          d="M30.3852 39.9013C32.0293 39.9013 33.3621 38.5684 33.3621 36.9242C33.3621 35.2801 32.0293 33.9472 30.3852 33.9472C28.741 33.9472 27.4082 35.2801 27.4082 36.9242C27.4082 38.5684 28.741 39.9013 30.3852 39.9013Z"
          fill="#00607B"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H50V50H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BasketIcon
