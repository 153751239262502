import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import AvailabilityCheckInput from 'components/AvailabilityCheckInput'
import ContractSummaryInput from 'components/ContractSummaryInput'
import LoadingSpinner from 'components/LoadingSpinner'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AvailabilityCheckField } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import TestIDs from 'utils/TestIDs'
import { useAvailabilityCheckState } from './useAvailabilityCheckState'

const useStyles = makeStyles(() => ({
  darkBackground: {
    background: '#e9edee',
    minHeight: 'calc(100vh - 353px)',
  },
}))

const AvailabilityCheck = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const loading = false

  const {
    addressList,
    additionalInfos,
    cities,
    focusedFieldId,
    numbers,
    selectedAdditionalInfo,
    selectedCity,
    selectedNumber,
    selectedStreet,
    setSelectedAdditionalInfo,
    setSelectedAddress,
    setSelectedCity,
    setSelectedNumber,
    setSelectedStreet,
    setZip,
    streets,
    zip,
    handleCheckVZF,
    vzfError,
  } = useAvailabilityCheckState()

  const renderIntroText = (): JSX.Element => {
    if (zip.length === 5 && cities.length === 0 && selectedCity === '') {
      return (
        <Box {...TestIDs.AVAILABILITY_CHECK_SUBHEADER_TYPOGRAPHY} className="TextError">
          <Typography className="TextError TextBig" align={'center'}>
            Leider bieten wir unseren Service an der von Ihnen gewünschten Postleitzahl noch nicht an.
          </Typography>
        </Box>
      )
    }
    return (
      <Box {...TestIDs.AVAILABILITY_CHECK_SUBHEADER_TYPOGRAPHY}>
        <Typography className="TextBig" align={'center'}>
          Warum wird die Verfügbarkeit geprüft?
        </Typography>
        <Typography className="TextBig" align={'center'}>
          Wir bauen unser Netz stetig aus. Allerdings sind unsere Tarife noch nicht an jeder Adresse verfügbar. Je nach
          Netzausbau kommt es auch regional zu unterschiedlichen Verfügbarkeiten der Geschwindigkeiten.
        </Typography>
        <Typography className="TextBig" align={'center'}>
          Diese Verfügbarkeitsprüfung ist selbstverständlich unverbindlich.
        </Typography>
      </Box>
    )
  }

  if (addressList.length === 0) {
    navigate('/')
  }

  return (
    <>
      <Paper elevation={0} square className={classes.darkBackground}>
        <Typography
          {...TestIDs.AVAILABILITY_CHECK_HEADER_TYPOGRAPHY}
          variant={'h1'}
          className={zip.length === 5 && cities.length === 0 && selectedCity === '' ? 'TextError' : ''}
          align={'center'}
        >
          {zip.length === 5 && cities.length === 0 && selectedCity === '' ? 'Es tut uns leid.' : 'Verfügbarkeit prüfen'}
        </Typography>
        <Box width={{ xs: '90%', md: '70%', lg: '850px' }} mt={2} mr={'auto'} mb={0} ml={'auto'}>
          {renderIntroText()}
        </Box>
        <AvailabilityCheckInput
          buttonText={'Verfügbarkeit prüfen'}
          disabledAdditionalInfo={additionalInfos.length < 2}
          disabledCity={cities.length < 2}
          disabledNumber={numbers.length < 2}
          disabledStreet={streets.length < 2}
          disabledZip={false}
          disabledSubmit={focusedFieldId !== AvailabilityCheckField.SUBMIT}
          additionalInfos={additionalInfos}
          cities={cities}
          focusedFieldId={focusedFieldId}
          numbers={numbers}
          onSubmit={(): void => {
            setSelectedAddress()
            navigate('/auschecken')
          }}
          selectedAdditionalInfo={selectedAdditionalInfo}
          selectedCity={selectedCity}
          selectedNumber={selectedNumber}
          selectedStreet={selectedStreet}
          setSelectedAdditionalInfo={setSelectedAdditionalInfo}
          setSelectedCity={setSelectedCity}
          setSelectedNumber={setSelectedNumber}
          setSelectedStreet={setSelectedStreet}
          setZip={setZip}
          streets={streets}
          zip={zip}
          autoFocusOnMount={true}
        />
        <ContractSummaryInput onClick={handleCheckVZF} error={vzfError} disabledSubmit={false} />
      </Paper>
      <LoadingSpinner loading={loading} />
    </>
  )
}

export default AvailabilityCheck
