import { useLazyQuery } from '@apollo/client'
import getAddresses from 'graphql/queries/getAddresses'
import getCustomLandingPage from 'graphql/queries/getCustomLandingPage'
import getLegalNotices from 'graphql/queries/getLegalNotices'
import getProductOptions from 'graphql/queries/getProductOptions'
import getProducts from 'graphql/queries/getProducts'
import { AddressData, LandingPageResponse, Query, QueryGetCustomLandingPageArgs } from 'graphql/types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, { AvailabilityCheckAction } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import CheckoutActions, { CheckoutAction } from 'store/Checkout/Checkout.actions'
import { CheckoutState } from 'store/Checkout/Checkout.reducer'
import { AppState } from 'store/store'

export interface AddressesDataReturn {
  addressList: AddressData[]
  loading: boolean
  lps: LandingPageResponse | undefined
}
export const useAddressesData = (path?: string | undefined): AddressesDataReturn => {
  const dispatch = useDispatch<Dispatch<AvailabilityCheckActions | CheckoutActions>>()
  const [startedLoading, setStartedLoading] = useState(false)
  const { addressList, loading } = useSelector((appState: AppState) => {
    return {
      addressList: appState.availabilityCheck.addressList,
      loading: appState.availabilityCheck.loading,
    }
  })
  const setAddressList = useCallback(
    (payload: AddressData[]) => {
      dispatch({ type: AvailabilityCheckAction.SET_ADDRESS_LIST, payload })
    },
    [dispatch],
  )

  const setLoading = useCallback(
    (payload: boolean) => {
      dispatch({ type: AvailabilityCheckAction.SET_LOADING, payload })
    },
    [dispatch],
  )
  const setCheckoutState = useCallback(
    (payload: Partial<CheckoutState>) => {
      dispatch({ type: CheckoutAction.SET_STATE, payload })
    },
    [dispatch],
  )

  const [getAddressesQuery, getAddressesStatus] = useLazyQuery(getAddresses, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data: { addresses: AddressData[] }): void => {
      if (data && data.addresses) {
        setAddressList(
          data.addresses
            .filter((a) => a.online === true && a.products && a.products.length > 0)
            .map((address) => ({
              ...address,
            })),
        )
      }
    },
  })

  const [lps, setLps] = useState<LandingPageResponse | undefined>(undefined)
  const [getLPsQuery, getLPsQueryStatus] = useLazyQuery<Query>(getCustomLandingPage, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data): void => {
      if (data.getCustomLandingPage) {
        setLps(data.getCustomLandingPage)
      }
    },
  })

  const [getProductsQuery, getProductsQueryStatus] = useLazyQuery<Query>(getProducts, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data): void => {
      if (data.products) {
        setCheckoutState({ products: data.products })
      }
    },
  })
  const [getProductOptionsQuery, getProductOptionsQueryStatus] = useLazyQuery(getProductOptions, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data: Query): void => {
      if (data) {
        setCheckoutState({ productOptions: data.productOptions })
      }
    },
  })
  const [getLegalNoticesQuery, getLegalNoticesQueryStatus] = useLazyQuery(getLegalNotices, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data: Query): void => {
      if (data) {
        setCheckoutState({ legalNotices: data.legalNotice })
      }
    },
  })
  useEffect(() => {
    if (!startedLoading) {
      getAddressesQuery()
      getProductsQuery()
      getProductOptionsQuery()
      getLegalNoticesQuery()
      setStartedLoading(true)
    }

    if (path) {
      const vars: QueryGetCustomLandingPageArgs = { input: { path } }
      getLPsQuery({ variables: vars })
    } else {
      const vars: QueryGetCustomLandingPageArgs = { input: { path: '' } }
      getLPsQuery({ variables: vars })
    }
  }, [
    startedLoading,
    getAddressesQuery,
    getProductsQuery,
    getProductOptionsQuery,
    getLegalNoticesQuery,
    path,
    getLPsQuery,
  ])

  useEffect(() => {
    setLoading(
      getAddressesStatus.loading ||
        getProductsQueryStatus.loading ||
        getProductOptionsQueryStatus.loading ||
        getLegalNoticesQueryStatus.loading ||
        getLPsQueryStatus.loading,
    )
  }, [
    getAddressesStatus.loading,
    getProductsQueryStatus.loading,
    getProductOptionsQueryStatus.loading,
    getLegalNoticesQueryStatus.loading,
    getLPsQueryStatus.loading,
    setLoading,
  ])

  return {
    addressList,
    loading,
    lps,
  }
}
