import * as React from 'react'

interface Props {
  color: string
  width?: string
  height?: string
}

const HelpIcon = ({ color, height, width }: Props): JSX.Element => {
  return (
    <svg
      width={width ? width : '50'}
      height={height ? height : '50'}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.3929 26.7656C34.03 24.9281 34.8954 22.7464 34.8954 20.4561C34.8954 15.5784 30.7582 11.6333 25.8808 11.6333C24.0479 11.6333 22.2889 12.2421 20.7938 13.2943C19.3659 14.299 18.276 15.6882 17.6416 17.3106L17.6377 17.3202C17.5215 17.5917 17.4624 17.8803 17.4624 18.1774C17.4624 19.379 18.44 20.3566 19.6416 20.3566C20.5535 20.3566 21.3763 19.7813 21.6889 18.9256C22.3527 17.202 24.0371 16.044 25.8808 16.044C28.3552 16.044 30.5371 17.9814 30.5371 20.4561C30.5371 23.301 27.9495 25.4032 25.3849 26.2245L23.8706 26.7095V31.9872H28.2284V29.7932L28.4069 29.7087C29.9682 28.9719 31.3094 27.9818 32.3929 26.7656Z"
        fill={color}
      />
      <path
        d="M26.1719 34.8947C24.5687 34.8947 23.2642 36.1992 23.2642 37.8026C23.2642 39.406 24.5687 40.7103 26.1719 40.7103C27.7753 40.7103 29.0796 39.406 29.0796 37.8026C29.0796 36.1992 27.7753 34.8947 26.1719 34.8947Z"
        fill={color}
      />
      <path
        d="M44.1423 8.91948L40.823 11.4529C44.0621 15.2242 45.8291 19.9763 45.8291 24.9999C45.8291 30.5636 43.6626 35.7941 39.7285 39.7283C35.7942 43.6624 30.5637 45.8289 25 45.8289C19.4365 45.8289 14.2058 43.6624 10.2717 39.7283C6.33766 35.7941 4.1711 30.5636 4.1711 24.9999C4.1711 19.4364 6.33766 14.2057 10.2717 10.2717C14.2058 6.33763 19.4365 4.17108 25 4.17108C29.2148 4.17108 33.2378 5.41532 36.6514 7.72838C37.2624 8.079 38.1393 8.43387 39.3631 8.305C40.9168 8.14132 41.9334 7.04617 42.1415 6.80301C37.4855 2.41089 31.4284 0 25 0C18.3223 0 12.0441 2.60043 7.32229 7.32225C2.60044 12.0441 0 18.3222 0 24.9999C0 31.6778 2.60044 37.9557 7.32229 42.6775C12.0441 47.3993 18.3223 50 25 50C31.6779 50 37.9559 47.3993 42.6777 42.6775C47.3996 37.9557 50 31.6778 50 24.9999C50 19.0493 47.9334 13.417 44.1423 8.91948Z"
        fill={color}
      />
    </svg>
  )
}

export default HelpIcon
