import { Box, Grid } from '@material-ui/core'
import OptionItem from 'components/OptionItem'
import { Product } from 'graphql/types'
import * as React from 'react'
import { replaceLiToSpanTags } from 'utils/function'
import TestIDs from 'utils/TestIDs'

export interface ProductSelectorProps {
  onlyShowSelected: boolean
  products?: Product[] | null
  selectedProduct: Product | undefined
  setSelectedProduct: (product: Product) => void
  setUserPreviousContract: (value: boolean | undefined) => void
}

const ProductSelector = (props: ProductSelectorProps): JSX.Element | null => {
  const { selectedProduct, setSelectedProduct, products, onlyShowSelected, setUserPreviousContract } = props

  if (!products) {
    return null
  }

  const renderOptionItem = (product: Product): JSX.Element | null => {
    const isSelected = selectedProduct !== undefined ? selectedProduct.id === product.id : false
    if (product.orderPage.displayed === false || (onlyShowSelected && !isSelected) || !product.orderPage.title) {
      return null
    }
    return (
      <Box
        key={product.id + '' + isSelected}
        width={1}
        {...TestIDs.GET_PRODUCTS('CHECKOUT_FORM', product.id)}
        style={{ paddingBottom: 10 }}
      >
        <OptionItem
          disable={onlyShowSelected}
          onClick={(): void => {
            setSelectedProduct(product)
            setUserPreviousContract(undefined)
          }}
          selected={isSelected}
          title={product.orderPage.title}
          price={product.price.value}
          subtitle={
            product.orderPage.subTitle ? (
              <div
                className={'ProductSelectorSubtitle'}
                dangerouslySetInnerHTML={{
                  __html: replaceLiToSpanTags(product.orderPage.subTitle),
                }}
              />
            ) : undefined
          }
          variant={'h3'}
        />
      </Box>
    )
  }
  return (
    <Grid item xs={12}>
      <Box display={'flex'} flexDirection={'column'} style={{ paddingBottom: '16px' }}>
        {products.map(renderOptionItem)}
      </Box>
    </Grid>
  )
}

export default ProductSelector
