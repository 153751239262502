import { Box, Grid, Link, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import * as React from 'react'
import { AccountHolder } from 'store/Checkout/Checkout.reducer'
import ErrorList from 'utils/ErrorList'
import TestIDs from 'utils/TestIDs'
import { OptionItemRenderer, TextFieldRenderer } from '.'

export interface AccountHolderFieldsProps {
  accountHolder: boolean
  accountHolderData: AccountHolder
  toggleAccountHolder: (payload: boolean) => void
  editAccountHolderData: (payload: AccountHolder) => void
  invalidFields: string[]
  clearInvalidField: (field: string) => void
  focusedField: string
  setFocusedField: React.Dispatch<React.SetStateAction<string>>
}

const AccountHolderFields = (props: AccountHolderFieldsProps): JSX.Element => {
  const {
    accountHolder,
    accountHolderData,
    editAccountHolderData,
    toggleAccountHolder,
    clearInvalidField,
    invalidFields,
    focusedField,
    setFocusedField,
  } = props

  const combineAndEditAccountHolderData = React.useCallback(
    // eslint-disable-next-line
    (field: string, index: number, value: any, errorField: string) => {
      if (errorField !== '' && invalidFields.indexOf(errorField) !== -1) {
        clearInvalidField(errorField)
      }
      switch (field) {
        case 'Name':
          editAccountHolderData({
            ...accountHolderData,
            name: value,
          })
          break
        case 'LastName':
          editAccountHolderData({
            ...accountHolderData,
            lastName: value,
          })
          break
      }
    },
    [editAccountHolderData, accountHolderData, invalidFields, clearInvalidField],
  )

  return (
    <Box marginBottom={9} padding={1}>
      <Grid container item spacing={2} className={'noPaddingTop'}>
        <Grid item xs={12}>
          <Typography className={'centerWhenSmall'} {...TestIDs.CHECKOUT_FIELDS_ACCOUNT_HOLDER_HEADER} variant={'h4'}>
            Kontoinhaber
          </Typography>
        </Grid>
        <OptionItemRenderer
          editOptionItem={toggleAccountHolder}
          selectedValue={accountHolder}
          values={[
            {
              selectionValue: false,
              title: 'Ich selbst',
            },
            {
              selectionValue: true,
              title: 'Eine andere Person bezahlt für mich',
            },
          ]}
        />
      </Grid>
      {accountHolder && (
        <Grid container item spacing={2}>
          <Grid item>
            <Box marginBottom={2} marginTop={4}>
              <Grid container spacing={2}>
                <TextFieldRenderer
                  combineAndEdit={combineAndEditAccountHolderData}
                  setFocusedField={setFocusedField}
                  index={0}
                  values={[
                    {
                      columnValue: 6,
                      mobileColumnValue: 12,
                      editField: 'Name',
                      label: 'Vorname *',
                      value: accountHolderData.name,
                      errorField: 'accountHolder-name',
                      tooltipTitle: ErrorList.AccountHolderName,
                    },
                    {
                      columnValue: 6,
                      mobileColumnValue: 12,
                      editField: 'LastName',
                      label: 'Nachname *',
                      value: accountHolderData.lastName,
                      errorField: 'accountHolder-lastName',
                      tooltipTitle: ErrorList.AccountHolderLastName,
                    },
                  ]}
                  focusedField={focusedField}
                  invalidFields={invalidFields}
                />
              </Grid>
            </Box>
            <Box marginBottom={2}>
              <Typography variant={'body2'}>
                Bitte schicken Sie uns das ausgefüllte und unterschriebene{' '}
                <Link
                  href="/assets/docs/SEPA-Lastschriftmandat_Stand_Maerz_2020.pdf"
                  display={'inline'}
                  target="_blank"
                  className={clsx('AccountHolderLink', 'InlineLink')}
                >
                  SEPA-Lastschriftmandat
                </Link>{' '}
                per E-Mail an{' '}
                <Link display={'inline'} href="mailto:internet@vonovia.de" className={'InlineLink'}>
                  internet@vonovia.de
                </Link>{' '}
                oder per Post an folgende Adresse:
              </Typography>
            </Box>
            <Box>
              <Typography variant={'body2'}>Deutsche Multimedia Service GmbH</Typography>
              <Typography variant={'body2'}>Universitätsstr. 133</Typography>
              <Typography variant={'body2'}>44803 Bochum</Typography>
              <Typography variant={'body2'}>Deutschland</Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default React.memo(AccountHolderFields, (prevProps, nextProps) => isEqual(prevProps, nextProps))
