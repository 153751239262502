import { Box, Button, Container, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import TestIDs from 'utils/TestIDs'

const Succeeded = (): JSX.Element => {
  const navigate = useNavigate()
  const onClickReturn = (): void => {
    navigate('/')
  }

  return (
    <Paper elevation={0}>
      <Container maxWidth={'lg'} className={'LeftAlignedText'}>
        <Typography {...TestIDs.SUCCEEDED_PAGE_HEADER_TYPOGRAPHY} variant={'h1'}>
          Vielen Dank für Ihre Bestellung
        </Typography>
        <Box mt={3}>
          <Typography
            className={'succeededPage_bodyTypography'}
            {...TestIDs.SUCCEEDED_PAGE_BODY_TYPOGRAPHY}
            variant={'h4'}
          >
            In Kürze bekommen Sie von uns eine Vertragsbestätigung zugesendet.
          </Typography>
        </Box>
        <Box mt={4}>
          <Button
            {...TestIDs.SUCCEEDED_PAGE_RETURN_BUTTON}
            className={'AvailabilityCheck'}
            color={'secondary'}
            onClick={onClickReturn}
            startIcon={
              <img alt={'button chevron'} style={{ marginRight: 16 }} src={require('./assets/ButtonChevron.png')} />
            }
          >
            Zurück zur Startseite
          </Button>
        </Box>
      </Container>
    </Paper>
  )
}

export default Succeeded
