import { Box, Button, Container, Paper, Typography } from '@material-ui/core'
import ChevronRightIcon from 'components/ChevronRightIcon'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import TestIDs from 'utils/TestIDs'

const ErrorPage = (): JSX.Element => {
  const navigate = useNavigate()
  const onClickReturn = (): void => {
    navigate('/')
  }

  return (
    <Paper elevation={0}>
      <Container maxWidth={'lg'} className={'LeftAlignedText'}>
        <Typography {...TestIDs.ERROR_PAGE_HEADER_TYPOGRAPHY} variant={'h1'}>
          Hoppla...
          {'\n'}diese Seite existiert leider nicht.
        </Typography>
        <Box>
          <Typography className={'errorPage_bodyTypography'} {...TestIDs.ERROR_PAGE_BODY_TYPOGRAPHY} variant={'h4'}>
            Aber hier kommen Sie wieder zurück zu unseren Inhalten
          </Typography>
        </Box>
        <Box mt={4}>
          <Button
            {...TestIDs.ERROR_PAGE_RETURN_BUTTON}
            color={'secondary'}
            className={'AvailabilityCheck'}
            onClick={onClickReturn}
            startIcon={<ChevronRightIcon />}
          >
            Zurück zur Startseite
          </Button>
        </Box>
      </Container>
    </Paper>
  )
}

export default ErrorPage
