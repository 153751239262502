import { Product, ProductOption, Voucher } from 'graphql/types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, { AvailabilityCheckAction } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import CheckoutActions, { CheckoutAction } from 'store/Checkout/Checkout.actions'
import {
  AccountData,
  AccountHolder,
  AccountType,
  Address,
  AppartmentData,
  DeliveryAddressData,
  MainData,
  MainTenantData,
  PortabilityNumber,
  PreviousContractor,
} from 'store/Checkout/Checkout.reducer'

export interface CheckoutReducerState {
  addPortabilityNumber: () => void
  addPreviousContractor: () => void
  clearInvalidField: (payload: string) => void
  clearProductOptions: () => void
  deleteUploadFile: (payload: File) => void
  editAccountData: (payload: AccountData) => void
  editAccountHolder: (payload: AccountHolder) => void
  editAppartmentData: (payload: AppartmentData) => void
  editConnectionAddress: (payload: Address) => void
  editConnectionTermination: (payload: string) => void
  editDeliveryAddress: (payload: DeliveryAddressData) => void
  editDesiredInstallationDate: (payload: string) => void
  editIban: (payload: string) => void
  editMainData: (payload: MainData) => void
  editMainTenantData: (payload: MainTenantData) => void
  editPortabilityNumber: (payload: PortabilityNumber, index: number) => void
  editPreviousContractor: (payload: PreviousContractor, index: number) => void
  removePortabilityNumber: (index: number) => void
  removePreviousContractor: (index: number) => void
  setInvalidFields: (payload: string[]) => void
  setLandlineContractCancelDate: (payload: string) => void
  setPreviousProvider: (payload: string) => void
  setSelectedProduct: (payload: Product | undefined) => void
  setVZFID: (payload: string | undefined) => void
  setUploadFile: (payload: File[]) => void
  toggleAccountHolder: (payload: boolean) => void
  toggleAccountType: (payload: AccountType) => void
  toggleBundleLegalRequirementAcceptance: (payload: boolean) => void
  toggleConnectionAddress: (payload: boolean) => void
  toggleConnectionTermination: (payload: boolean) => void
  toggleDesiredInstallationDate: (payload: boolean) => void
  toggleIsLandlineContractCancelled: (payload: boolean) => void
  toggleMainTenant: (payload: boolean) => void
  togglePaymentAuthorization: (payload: boolean) => void
  togglePortability: (payload: boolean) => void
  togglePreviousContractor: (payload: boolean) => void
  toggleProductOption: (payload: ProductOption) => void
  setUserPreviousContract: (payload?: boolean | undefined) => void
  resetStateNotQueriesAvaCheck: () => void
  resetStateNotQueriesCheckout: () => void
  setUserVoucherCode: (payload: string) => void
  setAppliedVoucher: (payload: Voucher) => void
}

export const useCheckoutReducer = (): CheckoutReducerState => {
  const dispatch = useDispatch<Dispatch<AvailabilityCheckActions | CheckoutActions>>()

  const deleteUploadFile = useCallback(
    (payload: File) => {
      dispatch({ type: CheckoutAction.DELETE_UPLOADED_FILE, payload })
    },
    [dispatch],
  )
  const setUploadFile = useCallback(
    (payload: File[]) => {
      dispatch({ type: CheckoutAction.ADD_UPLOADED_FILES, payload })
    },
    [dispatch],
  )
  const editMainData = useCallback(
    (payload: MainData) => {
      dispatch({ type: CheckoutAction.EDIT_MAIN_DATA, payload })
    },
    [dispatch],
  )

  const editAppartmentData = useCallback(
    (payload: AppartmentData) => {
      dispatch({ type: CheckoutAction.EDIT_APPARTMENT_DATA, payload })
    },
    [dispatch],
  )

  const toggleMainTenant = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_MAIN_TENANT, payload })
    },
    [dispatch],
  )

  const editMainTenantData = useCallback(
    (payload: MainTenantData) => {
      dispatch({ type: CheckoutAction.EDIT_MAIN_TENANT, payload })
    },
    [dispatch],
  )

  const togglePortability = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_PORTABILITY, payload })
    },
    [dispatch],
  )
  const editPortabilityNumber = useCallback(
    (payload: PortabilityNumber, index: number) => {
      dispatch({
        type: CheckoutAction.EDIT_PORTABILITY_NUMBER,
        payload: {
          index,
          portabilityNumber: payload,
        },
      })
    },
    [dispatch],
  )
  const addPortabilityNumber = useCallback(() => {
    dispatch({ type: CheckoutAction.ADD_PORTABILITY_NUMBER })
  }, [dispatch])
  const removePortabilityNumber = useCallback(
    (index: number) => {
      dispatch({ type: CheckoutAction.REMOVE_PORTABILITY_NUMBER, payload: index })
    },
    [dispatch],
  )
  const togglePreviousContractor = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_PREVIOUS_CONTRACTOR, payload })
    },
    [dispatch],
  )
  const editPreviousContractor = useCallback(
    (payload: PreviousContractor, index: number) => {
      dispatch({
        type: CheckoutAction.EDIT_PREVIOUS_CONTRACTOR,
        payload: {
          index,
          previousContractor: payload,
        },
      })
    },
    [dispatch],
  )
  const addPreviousContractor = useCallback(() => {
    dispatch({ type: CheckoutAction.ADD_PREVIOUS_CONTRACTOR })
  }, [dispatch])
  const removePreviousContractor = useCallback(
    (index: number) => {
      dispatch({ type: CheckoutAction.REMOVE_PREVIOUS_CONTRACTOR, payload: index })
    },
    [dispatch],
  )
  const toggleConnectionAddress = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_PREVIOUS_CONNECTION_ADDRESS, payload })
    },
    [dispatch],
  )
  const editConnectionAddress = useCallback(
    (payload: Address) => {
      dispatch({ type: CheckoutAction.EDIT_PREVIOUS_CONNECTION_ADDRESS, payload })
    },
    [dispatch],
  )
  const toggleConnectionTermination = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_PREVIOUS_CONNECTION_TERMINATION, payload })
    },
    [dispatch],
  )
  const editConnectionTermination = useCallback(
    (payload: string) => {
      dispatch({ type: CheckoutAction.EDIT_PREVIOUS_CONNECTION_TERMINATION, payload })
    },
    [dispatch],
  )
  const setUserVoucherCode = useCallback(
    (payload: string) => {
      dispatch({ type: CheckoutAction.SET_USER_VOUCHER, payload })
    },
    [dispatch],
  )

  const setAppliedVoucher = useCallback(
    (payload: Voucher) => {
      dispatch({ type: CheckoutAction.SET_APPLIED_VOUCHER, payload })
    },
    [dispatch],
  )

  const toggleDesiredInstallationDate = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_DESIRED_INSTALLATION_DATE, payload })
    },
    [dispatch],
  )

  const editDesiredInstallationDate = useCallback(
    (payload: string) => {
      dispatch({ type: CheckoutAction.EDIT_DESIRED_INSTALLATION_DATE, payload })
    },
    [dispatch],
  )

  const editDeliveryAddress = useCallback(
    (payload: DeliveryAddressData) => {
      dispatch({ type: CheckoutAction.EDIT_DELIVERY_ADDRESS, payload })
    },
    [dispatch],
  )

  const toggleAccountHolder = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_ACCOUNT_HOLDER, payload })
    },
    [dispatch],
  )

  const editAccountHolder = useCallback(
    (payload: AccountHolder) => {
      dispatch({ type: CheckoutAction.EDIT_ACCOUNT_HOLDER, payload })
    },
    [dispatch],
  )

  const toggleAccountType = useCallback(
    (payload: AccountType) => {
      dispatch({ type: CheckoutAction.TOGGLE_ACCOUNT_TYPE, payload })
    },
    [dispatch],
  )

  const editIban = useCallback(
    (payload: string) => {
      dispatch({ type: CheckoutAction.EDIT_IBAN, payload })
    },
    [dispatch],
  )
  const editAccountData = useCallback(
    (payload: AccountData) => {
      dispatch({ type: CheckoutAction.EDIT_ACCOUNT_DATA, payload })
    },
    [dispatch],
  )
  const togglePaymentAuthorization = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_PAYMENT_AUTHORIZATION, payload })
    },
    [dispatch],
  )
  const setInvalidFields = useCallback(
    (payload: string[]) => {
      dispatch({ type: CheckoutAction.SET_INVALID_FIELDS, payload })
    },
    [dispatch],
  )

  const clearInvalidField = useCallback(
    (payload: string) => {
      dispatch({ type: CheckoutAction.CLEAR_INVALID_FIELD, payload })
    },
    [dispatch],
  )
  const setPreviousProvider = useCallback(
    (payload: string) => {
      dispatch({ type: CheckoutAction.SET_PREVIOUS_PROVIDER, payload })
    },
    [dispatch],
  )
  const setLandlineContractCancelDate = useCallback(
    (payload: string) => {
      dispatch({ type: CheckoutAction.SET_LANDLINE_CONTRACT_CANCEL_DATE, payload })
    },
    [dispatch],
  )
  const toggleIsLandlineContractCancelled = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_IS_LANDLINE_CONTRACT_CANCELLED, payload })
    },
    [dispatch],
  )

  const setSelectedProduct = useCallback(
    (payload: Product | undefined) => {
      dispatch({ payload, type: CheckoutAction.SET_SELECTED_PRODUCT })
    },
    [dispatch],
  )

  const toggleProductOption = useCallback(
    (payload: ProductOption) => {
      dispatch({ payload, type: CheckoutAction.TOGGLE_PRODUCT_OPTION })
    },
    [dispatch],
  )

  const clearProductOptions = useCallback(() => {
    dispatch({ type: CheckoutAction.CLEAR_SELECTED_PRODUCT_OPTIONS })
  }, [dispatch])

  const setVZFID = useCallback(
    (payload: string | undefined) => {
      dispatch({
        type: CheckoutAction.SET_VZF_ID,
        payload,
      })
    },
    [dispatch],
  )

  const toggleBundleLegalRequirementAcceptance = useCallback(
    (payload: boolean) => {
      dispatch({ type: CheckoutAction.TOGGLE_BUNDLE_LEGAL_REQUIREMENT, payload })
    },
    [dispatch],
  )

  const setUserPreviousContract = useCallback(
    (payload?: boolean) => {
      dispatch({ type: CheckoutAction.SET_USER_PREVIOUS_CONTRACT, payload })
    },
    [dispatch],
  )

  const resetStateNotQueriesAvaCheck = useCallback(() => {
    dispatch({ type: AvailabilityCheckAction.SET_TO_INIT_STATE_EXCEPT_QUERY })
  }, [dispatch])

  const resetStateNotQueriesCheckout = useCallback(() => {
    dispatch({ type: CheckoutAction.SET_TO_INIT_STATE_EXCEPT_QUERY })
  }, [dispatch])

  return {
    addPortabilityNumber,
    addPreviousContractor,
    clearInvalidField,
    clearProductOptions,
    deleteUploadFile,
    editAccountData,
    editAccountHolder,
    editAppartmentData,
    editConnectionAddress,
    editConnectionTermination,
    editDesiredInstallationDate,
    editDeliveryAddress,
    editIban,
    editMainData,
    editMainTenantData,
    editPortabilityNumber,
    editPreviousContractor,
    removePortabilityNumber,
    removePreviousContractor,
    setInvalidFields,
    setLandlineContractCancelDate,
    setPreviousProvider,
    setSelectedProduct,
    setUploadFile,
    setVZFID,
    toggleAccountHolder,
    toggleAccountType,
    toggleBundleLegalRequirementAcceptance,
    toggleConnectionAddress,
    toggleConnectionTermination,
    toggleDesiredInstallationDate,
    toggleIsLandlineContractCancelled,
    toggleMainTenant,
    togglePaymentAuthorization,
    togglePortability,
    togglePreviousContractor,
    toggleProductOption,
    setUserPreviousContract,
    resetStateNotQueriesAvaCheck,
    resetStateNotQueriesCheckout,
    setUserVoucherCode,
    setAppliedVoucher,
  }
}
