import { useMutation } from '@apollo/client'
import { Button, Container, Grid, Typography } from '@material-ui/core'
import ChevronRightIcon from 'components/ChevronRightIcon'
import { VALIDATE_MAIL } from 'graphql/mutations/ValidateMail'
import { MutationValidateMailArgs } from 'graphql/types'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const ValidateMail = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const [vm] = useMutation(VALIDATE_MAIL, {
    onCompleted: () => {
      setSuccess(true)
    },
  })

  useEffect(() => {
    if (id !== undefined && id !== '') {
      //validate
      const vars: MutationValidateMailArgs = {
        input: {
          id: id,
        },
      }
      vm({ variables: vars })
    }
  }, [id])

  return (
    <Container maxWidth="lg">
      {success && (
        <Grid container justifyContent="center" direction="column" spacing={6} style={{ marginTop: 60 }}>
          <Grid item xs={12}>
            <Typography variant="h1">Vielen Dank für die Validierung Ihrer E-Mailadresse</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">In Kürze bekommen Sie von uns eine Vertragsbestätigung zugesendet.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button color={'secondary'} startIcon={<ChevronRightIcon />} onClick={() => navigate('/')}>
              Zurück zur Startseite
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ValidateMail
